import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setObservacionesPedido } from "../../actions/pedidos";
import i18n from "../../lang/idiomas";

export class ObservacionesPedido extends Component {
  static propTypes = {
    pedido: PropTypes.object.isRequired,
    setObservacionesPedido: PropTypes.func.isRequired,
    pedido_cargando: PropTypes.bool.isRequired,
  };

  onchange = (e) => {
    if (e.target.value && !this.props.pedido_cargando) {
      this.props.setObservacionesPedido(e.target.value);
    }
  };

  render() {
    return (
      <form className="form-default">
        <div className="form-group">
          <label htmlFor="observaciones">{i18n.t("pedido.pobsr")}</label>
          <textarea
            className="form-control"
            rows="7"
            name="observaciones"
            id="observaciones"
            value={this.props.pedido && this.props.pedido.observaciones && this.props.pedido.observaciones !== "" ? this.props.pedido.observaciones : ""}
            onChange={this.onchange}
            disabled={this.props.pedidoGuardado}
          />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  pedido_cargando: state.pedidos.isLoading,
  pedido: state.pedidos.pedido,
});

export default connect(mapStateToProps, { setObservacionesPedido })(ObservacionesPedido);
