import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getPrecios } from "../../actions/precio";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Imagen from "./Imagen";
import Precio from "./Precio";
import Spinner from "../layout/Spinner";
import { setCarnetProfesional } from "../../actions/modalGenerica";
import i18n from "../../lang/idiomas";
import { comprobarCondiciones, cargarArrays, cargarParametros, dynamicSort } from "../../helpers/funciones";
import { deleteLinea, nuevoPedido, setIdPedidoSeleccionado, getPedidoActivo } from "../../actions/pedidos";
import ArticuloNecesidades from "./ArticuloNecesidades";
import { Trash } from "phosphor-react";

let articulos_aux = [];
let parametros_usados = ["mostrar_iva", "maxim_agrup"];
let parametros;

export class Carrito extends Component {
  static propTypes = {
    pedido: PropTypes.object.isRequired,
    deleteLinea: PropTypes.func.isRequired,
    hash: PropTypes.string,
    invitado: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    moneda: PropTypes.string.isRequired,
    setIdPedidoSeleccionado: PropTypes.func.isRequired,
    nuevoPedido: PropTypes.func.isRequired,
    getPrecios: PropTypes.func.isRequired,
    setCarnetProfesional: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    //Comprobamos si solicitan precios grupalmente
    if (comprobarCondiciones("carrito", this.props.pedido, prevProps.pedido, "S", this.props.precio)) {
      articulos_aux = cargarArrays("carrito", this.props.pedido, "precios", this.props.precio);
      if (articulos_aux && articulos_aux.length > 0) {
        //Solo llamamos a getPrecios si hai al menos un elemento en el array
        this.props.getPrecios(
          articulos_aux, //articulos_cantidades
          "carrito", //origen
          null, //domicilio_envio
          parametros.maxim_agrup
        );
      }
    }
  }

  onClick = (e) => {
    if (!this.props.pedido_cargando) {
      e.preventDefault();
      this.props.deleteLinea(e.currentTarget.dataset.linea, this.props.hash, this.props.id_pedido_seleccionado);
    }
  };

  ocultarCarrito = (e) => {
    if (document.getElementsByClassName("tt-dropdown-obj active").length > 0) {
      document.getElementsByClassName("tt-dropdown-obj active")[0].getElementsByClassName("tt-dropdown-menu")[0].style = "";

      document.getElementsByClassName("tt-dropdown-obj active")[0].classList.remove("active");
    }
    if (document.getElementsByTagName("body").length > 0) {
      document.getElementsByTagName("body")[0].classList.remove("tt-popup-dropdown");
    }
  };

  nuevoPedido = () => {
    this.props.setIdPedidoSeleccionado("");
    this.props.nuevoPedido();
    this.props.getPedidoActivo("", "S");
  };

  cargarPrecio(linea) {
    if (linea.tipo_linea !== "R") {
      if (linea.precio_manual === "S") {
        return (
          <span>
            {/* Linea precio fijo */}
            <Precio codigo_articulo={linea.articulo} precio_manual={linea.precio_venta} mostrar_iva={parametros.mostrar_iva} />
          </span>
        );
      } else {
        return (
          <span>
            {/* Linea precio calculado */}
            <Precio codigo_articulo={linea.articulo} mostrar_iva={parametros.mostrar_iva} />
          </span>
        );
      }
    } else {
      return (
        <span>
          {/* Regalos */}
          <Precio codigo_articulo={linea.articulo} precio_manual={0} mostrar_iva={parametros.mostrar_iva} />
        </span>
      );
    }
  }

  cargarLineasPedido() {
    return (
      <div className="info-pedido">
        <div>
          <h3 className="titulo-carrito">{i18n.t("general.pedido")}</h3>
        </div>
        <div className="tt-cart-list">
          {this.props.pedido_cargando ? (
            <Spinner showSpinner={this.props.pedido_cargando} />
          ) : this.props.pedido.lineas === undefined || this.props.pedido.lineas.length === 0 ? (
            ""
          ) : (
            this.props.pedido.lineas
              .sort(dynamicSort("articulo"))
              .filter((linea) => {
                if (linea.tipo_linea === "P") {
                  return true;
                } else if (linea.tipo_linea === "R") {
                  return this.props.pedido.lineas.some((linea_padre) => (linea_padre.numero_linea = linea.numero_linea_origen && linea_padre.tipo_linea === "P"));
                } else return false;
              })
              .map((linea) => (
                <div className="tt-item" key={linea.id}>
                  {
                    <Fragment>
                      <div className="tt-item-img">
                        {linea.tipo_articulo !== "F" ? (
                          <Link to={"/articulo/" + linea.articulo + "/" + (linea.descripcion ? linea.descripcion.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase() : "")}>
                            <Imagen codigo_articulo={linea.articulo} principal="S" numero_imagen={1} />
                          </Link>
                        ) : (
                          <Imagen codigo_articulo={linea.articulo} principal="S" numero_imagen={1} />
                        )}
                      </div>
                      <div className="tt-item-descriptions">
                        <div className="tt-base row">
                          <h2 className="tt-title">
                            {linea.tipo_articulo !== "F" ? (
                              <Link to={"/articulo/" + linea.id_articulo + "/" + linea.descripcion.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase()}>{linea.articulo}</Link>
                            ) : (
                              linea.articulo
                            )}
                          </h2>

                          {linea.tipo_articulo !== "R" && linea.tipo_articulo !== "T" && linea.tipo_articulo !== "F" ? (
                            <div className="tt-item-close">
                              <button onClick={this.onClick} data-linea={linea.id} className="btn-borrar-linea">
                                <Trash size={16} />
                                <span className="ocultar">{i18n.t("general.cerrar")}</span>
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="tt-add-info row">
                          {linea.tipo_articulo !== "F" ? (
                            <Link to={"/articulo/" + linea.id_articulo + "/" + linea.descripcion.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase()}>
                              <span>{linea.descripcion}</span>
                            </Link>
                          ) : (
                            <span>{linea.descripcion}</span>
                          )}
                        </div>
                        <div className="tt-inf-qty-price row">
                          <div className="col-4 info-articulo">
                            {linea.datos_articulo ? (
                              <ArticuloNecesidades articulo={linea.datos_articulo[0]} origen="resumen" carnets={this.props.carnets} setCarnetProfesional={this.props.setCarnetProfesional} />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-8 qty-price">
                            <div className="tt-quantity">
                              {linea.sub_pres && linea.sub_pres !== ""
                                ? linea.sub_pres_cant + " X " + linea.sub_pres + " X "
                                : parseFloat(linea.cantidad_pedida).toFixed(0) + " (" + linea.presentacion_pedido + ") X "}
                            </div>
                            <div className="tt-price">{this.cargarPrecio(linea)}</div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  }
                </div>
              ))
          )}
        </div>
        <div className="tt-cart-total-row">
          <div className="tt-cart-total-title">{i18n.t("general.total").toUpperCase() + " :"}</div>
          <div className="tt-cart-total-price">
            {this.props.pedido.lineas === undefined || this.props.pedido.lineas.length === 0
              ? 0
              : Intl.NumberFormat(this.props.locale, {
                  style: "currency",
                  currency: this.props.moneda,
                }).format(
                  Number.parseFloat(
                    this.props.pedido.lineas
                      .filter((linea) => linea.tipo_linea === "P")
                      .map((linea) => {
                        return Number.parseFloat(linea.precio_venta);
                      })
                      .reduce((accumulator, currentValue) => accumulator + currentValue)
                  ).toFixed(this.props.precio.decimales_pvp)
                )}
          </div>
        </div>
        <div className="tt-cart-total-row">
          <div className="tt-cart-total-title">{i18n.t("general.total").toUpperCase() + " + " + i18n.t("general.impuesto") + " :"}</div>
          <div className="tt-cart-total-price">
            {this.props.pedido.lineas === undefined || this.props.pedido.lineas.length === 0
              ? 0
              : new Intl.NumberFormat(this.props.locale, {
                  style: "currency",
                  currency: this.props.moneda,
                }).format(
                  Number.parseFloat(
                    this.props.pedido.lineas
                      .filter((linea) => linea.tipo_linea === "P")
                      .map((linea) => {
                        let impuesto_articulo = 0;
                        if (this.props.precio[linea.articulo] && !Array.isArray(this.props.precio[linea.articulo].data.impuesto_articulo) && this.props.precio[linea.articulo].data.impuesto_articulo) {
                          impuesto_articulo = Number.parseInt(this.props.precio[linea.articulo].data.impuesto_articulo) / 100;
                        }

                        return Number.parseFloat(linea.precio_venta) * (1 + impuesto_articulo);
                      })
                      .reduce((accumulator, currentValue) => {
                        return accumulator + currentValue;
                      })
                  ).toFixed(this.props.precio.decimales_pvp)
                )}
          </div>
        </div>
      </div>
    );
  }

  cargarLineasPresupuesto() {
    return (
      <div className="info-presupuesto">
        <div>
          <h3 className="titulo-carrito">{i18n.t("general.presup")}</h3>
        </div>
        <div className="tt-cart-list">
          {this.props.pedido_cargando ? (
            <Spinner showSpinner={this.props.pedido_cargando} />
          ) : this.props.pedido.lineas === undefined || this.props.pedido.lineas.length === 0 ? (
            ""
          ) : (
            this.props.pedido.lineas
              .sort(dynamicSort("articulo"))
              .filter((linea) => {
                return linea.tipo_linea === "O";
              })
              .map((linea) => (
                <div className="tt-item" key={linea.id}>
                  {
                    <Fragment>
                      {linea.tipo_articulo !== "F" ? (
                        <div className="tt-item-img">
                          <Link to={"/articulo/" + linea.articulo + "/" + (linea.descripcion ? linea.descripcion.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase() : "")}>
                            <Imagen codigo_articulo={linea.articulo} principal="S" numero_imagen={1} />
                          </Link>
                        </div>
                      ) : (
                        <div className="tt-item-img">
                          <Imagen codigo_articulo={linea.articulo} principal="S" numero_imagen={1} />
                        </div>
                      )}
                      <div className="tt-item-descriptions">
                        <div className="tt-base row">
                          <h2 className="tt-title">
                            {linea.tipo_articulo !== "F" ? (
                              <Link to={"/articulo/" + linea.id_articulo + "/" + linea.descripcion.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase()}>{linea.articulo}</Link>
                            ) : (
                              linea.articulo
                            )}
                          </h2>

                          {linea.tipo_articulo !== "R" && linea.tipo_articulo !== "T" && linea.tipo_articulo !== "F" ? (
                            <div className="tt-item-close">
                              <button onClick={this.onClick} data-linea={linea.id} className="btn-borrar-linea">
                                <Trash size={16} />
                                <span className="ocultar">{i18n.t("general.cerrar")}</span>
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="tt-add-info row">
                          {linea.tipo_articulo !== "F" ? (
                            <Link to={"/articulo/" + linea.id_articulo + "/" + linea.descripcion.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase()}>
                              <span>{linea.descripcion}</span>
                            </Link>
                          ) : (
                            <span>{linea.descripcion}</span>
                          )}
                        </div>
                        <div className="tt-inf-qty-price row">
                          <div className="col-4 info-articulo">
                            {linea.datos_articulo ? (
                              <ArticuloNecesidades articulo={linea.datos_articulo[0]} origen="resumen" carnets={this.props.carnets} setCarnetProfesional={this.props.setCarnetProfesional} />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-8 qty-price">
                            <div className="tt-quantity">
                              {linea.sub_pres && linea.sub_pres !== ""
                                ? linea.sub_pres_cant + " X " + linea.sub_pres + " X "
                                : parseFloat(linea.cantidad_pedida).toFixed(0) + " (" + linea.presentacion_pedido + ") X "}
                            </div>
                            <div className="tt-price">{this.cargarPrecio(linea)}</div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  }
                </div>
              ))
          )}
        </div>
      </div>
    );
  }

  render() {
    parametros = cargarParametros(parametros_usados, this.props.portal.parametros_ecom, "CAR");
    return (
      <Fragment>
        <div className="tt-dropdown-menu">
          <div className="tt-mobile-add">
            <h6 className="tt-title">{i18n.t("general.carrito").toUpperCase()}</h6>
            <button className="tt-close">{i18n.t("general.cerrar")}</button>
          </div>
          <div className="tt-dropdown-inner">
            <div className="tt-cart-layout">
              <div className="tt-cart-content">
                <div className="info-ped-pres">
                  {this.props.pedido.lineas && this.props.pedido.lineas.length > 0 && this.props.pedido.lineas.some((linea) => linea.tipo_linea === "P") ? this.cargarLineasPedido() : ""}

                  {this.props.pedido.lineas && this.props.pedido.lineas.length > 0 && this.props.pedido.lineas.some((linea) => linea.tipo_linea === "O") ? this.cargarLineasPresupuesto() : ""}
                </div>
                <div className="tt-cart-btn">
                  <div className="tt-item">
                    {this.props.pedido.lineas === undefined || this.props.pedido.lineas.length === 0 ? (
                      i18n.t("carrito.crrnoitm").toUpperCase()
                    ) : (
                      <Fragment>
                        {this.props.invitado && this.props.invitado === true ? (
                          <Link to="/login" className="btn" onClick={this.ocultarCarrito}>
                            {i18n.t("login.login").toUpperCase()}
                          </Link>
                        ) : (
                          <Fragment>
                            {!this.props.pedido_cargando ? (
                              <Link to="/resumen" className="btn" onClick={this.ocultarCarrito}>
                                {i18n.t("carrito.crrfinal").toUpperCase()}
                              </Link>
                            ) : (
                              ""
                            )}

                            <button type="submit" onClick={this.nuevoPedido} className="btn btn-primary btn-nuevo-pedido">
                              {i18n.t("carrito.crrnew").toUpperCase()}
                            </button>
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  portal: state.portal.portal,
  articulos: state.listadoArticulos.articulos,
  pedido: state.pedidos.pedido,
  hash: state.auth.hash,
  invitado: state.auth.invitado,
  locale: state.precio.locale,
  moneda: state.precio.moneda,
  precio: state.precio.precio,
  pedido_cargando: state.pedidos.isLoading,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
  carnets: state.auth.user.carnets,
});

export default connect(mapStateToProps, {
  deleteLinea,
  setIdPedidoSeleccionado,
  nuevoPedido,
  getPedidoActivo,
  getPrecios,
  setCarnetProfesional,
  dynamicSort,
})(Carrito);
