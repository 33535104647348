import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getFormasPago, setFormaPago } from "../../actions/formasPago";
import i18n from "../../lang/idiomas";

export class FormasPago extends Component {
  static propTypes = {
    getFormasPago: PropTypes.func.isRequired,
    setFormaPago: PropTypes.func.isRequired,
    formas_pago: PropTypes.array.isRequired,
    forma_pago: PropTypes.object.isRequired,
    pedido_cargando: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.getFormasPago();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.pedido_cargando !== this.props.pedido_cargando || JSON.stringify(prevProps.formas_pago) !== JSON.stringify(this.props.formas_pago)) && !this.props.pedido_cargando) {
      if (this.props.formas_pago && this.props.formas_pago.length === 1) {
        this.props.setFormaPago(this.props.formas_pago[0]);
      }

      if (this.props.formas_pago && this.props.formas_pago.length > 1 && this.props.pedido.forma_pago && this.props.pedido.forma_pago !== "") {
        const forma_pago = this.props.formas_pago.filter((form_pag) => form_pag.codigo_forma_pago_web === this.props.pedido.forma_pago);

        this.props.setFormaPago(forma_pago[0]);
      }
    }
  }

  onchange = (e) => {
    if (e.target.value !== "") {
      const forma_pago = this.props.formas_pago.filter((form_pag) => form_pag.codigo_forma_pago_web === e.target.value);

      this.props.setFormaPago(forma_pago[0]);
    }
  };

  render() {
    return (
      <Fragment>
        {this.props.formas_pago && this.props.formas_pago.length > 0 ? (
          <div className="tt-shopcart-box">
            <h4 className="tt-title">{i18n.t("formas.forspag")}</h4>
            <p>{i18n.t("formas.selfpagl")}</p>
            <form className="form-default">
              <div className="form-group">
                <label htmlFor="forma_envio">
                  {i18n.t("formas.forpamay")} <sup>*</sup>
                </label>
                <select className="form-control" name="forma_pago" onChange={this.onchange} disabled={this.props.pedido_cargando || this.props.pedidoGuardado ? true : false}>
                  <option key="0" value="">
                    {i18n.t("formas.selfpa")}
                  </option>
                  {this.props.formas_pago && this.props.formas_pago.length > 0 && this.props.formas_pago[0].datos_plantilla
                    ? this.props.formas_pago.map((forma_pago, index) => {
                        return forma_pago.datos_plantilla.map((dato_plantilla) => {
                          return (
                            <option key={index} value={forma_pago.codigo_forma_pago_web}>
                              {forma_pago.datos_plantilla
                                ? `${forma_pago.descripcion_forma_pago} - Hasta Importe: ${new Intl.NumberFormat(this.props.locale, {
                                    style: "currency",
                                    currency: this.props.moneda,
                                  }).format(dato_plantilla.hasta_importe)} - Descuento: ${dato_plantilla.dto_abono * 100}%`
                                : forma_pago.descripcion_forma_pago}
                            </option>
                          );
                        });
                      })
                    : this.props.formas_pago.map((forma_pago, index) => {
                        if (forma_pago.codigo_forma_pago_web === this.props.forma_pago.codigo_forma_pago_web) {
                          return (
                            <option key={index} value={forma_pago.codigo_forma_pago_web} selected>
                              {forma_pago.descripcion_forma_pago}
                            </option>
                          );
                        } else {
                          return (
                            <option key={index} value={forma_pago.codigo_forma_pago_web}>
                              {forma_pago.descripcion_forma_pago}
                            </option>
                          );
                        }
                      })}
                </select>
              </div>
            </form>
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  formas_pago: state.formasPago.formas_pago,
  forma_pago: state.formasPago.forma_pago,
  pedido_cargando: state.pedidos.isLoading,
  pedido: state.pedidos.pedido,
  moneda: state.precio.moneda,
});

export default connect(mapStateToProps, { getFormasPago, setFormaPago })(FormasPago);
