import axios from "../axiosConfig";

import {
  GET_ARTICULOS,
  FETCH_ARTICULOS,
  GET_MAS_ARTICULOS,
  CHANGE_ARTICULOS_PAGINA,
  SET_BUSQUEDA,
  CHANGE_ARTICULOS_ORDEN,
  FETCH_MAS_ARTICULOS,
  SET_ARTICULO_EQUIVALENTES,
  SET_BUSQUEDA_AVANZADA,
  LIMPIAR_BUSQUEDA_AVANZADA,
  GET_STOCKS_ARTICULOS,
  FETCH_STOCKS_ARTICULOS,
  GET_STOCKS_ARTICULOS_LISTA,
  FETCH_STOCKS_ARTICULOS_LISTA,
} from "./types";

import { tokenConfig } from "./auth";

let body;

// GET ARTICULOS
export const getArticulos =
  (
    search = "",
    limit = 12,
    order = "",
    domicilio_envio = "",
    avanzada = "N",
    codigo_articulo = "",
    codigo_barras = "",
    descripcion = "",
    palabras_clave = "",
    equivalentes = "",
    referencia_cliente = "",
    codigo_sinonimo = ""
  ) =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_ARTICULOS,
    });

    let url = `/articulos/?search=${search}&limit=${limit}&order=${order}&domicilio_envio=${domicilio_envio}`;

    if (avanzada === "S") {
      url =
        url +
        `&avanzada=${avanzada}&codigo_articulo=${codigo_articulo}&descripcion=${descripcion}&codigo_barras=${codigo_barras}&palabras_clave=${palabras_clave}&equivalentes=${equivalentes}&referencia_cliente=${referencia_cliente}&codigo_sinonimo=${codigo_sinonimo}`;
    }
    axios
      .get(url, tokenConfig(getState))
      .then((res) => {
        //Añado el término de búsqueda a la respuesta
        //para poder guardarlo en el estado dentro del reducer
        res.data.search = search;
        res.data.articulos_pagina = limit;
        res.data.articulos_orden = order;

        dispatch({
          type: GET_ARTICULOS,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

export const getArticulosByFamily =
  (
    filtros_aplicados,
    limit = "",
    order = "",
    search = "",
    domicilio_envio = "",
    avanzada = "N",
    codigo_articulo = "",
    codigo_barras = "",
    descripcion = "",
    palabras_clave = "",
    equivalentes = "",
    referencia_cliente = "",
    codigo_sinonimo = ""
  ) =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_ARTICULOS,
    });

    let url = `/articulos/listByFamily/?search=${search}&limit=${limit}&order=${order}&domicilio_envio=${domicilio_envio}`;

    if (avanzada === "S") {
      url =
        url +
        `&avanzada=${avanzada}&codigo_articulo=${codigo_articulo}&descripcion=${descripcion}&codigo_barras=${codigo_barras}&palabras_clave=${palabras_clave}&equivalentes=${equivalentes}&referencia_cliente=${referencia_cliente}&codigo_sinonimo=${codigo_sinonimo}`;
    }

    axios
      .post(
        url,
        {
          filtros: filtros_aplicados,
        },
        tokenConfig(getState)
      )
      .then((res) => {
        res.data.search = search;
        res.data.articulos_pagina = limit;
        res.data.articulos_orden = order;
        dispatch({
          type: GET_ARTICULOS,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

// GET MÁS ARTÍCULOS
export const getMasArticulos =
  (url, filtros_aplicados = [], origen = "articulos") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_MAS_ARTICULOS,
      origen: origen,
    });

    if (filtros_aplicados.length === 0) {
      axios
        .get(url, tokenConfig(getState))
        .then((res) => {
          dispatch({
            type: GET_MAS_ARTICULOS,
            payload: res.data,
            origen: origen,
          });
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post(
          url,
          {
            filtros: filtros_aplicados,
          },
          tokenConfig(getState)
        )
        .then((res) => {
          dispatch({
            type: GET_MAS_ARTICULOS,
            payload: res.data,
            origen: origen,
          });
        })
        .catch((err) => console.log(err));
    }
  };

export const cambiaArticulosPagina = (art_pagina) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_ARTICULOS_PAGINA,
    payload: art_pagina,
  });
};

export const setBusqueda = (search) => (dispatch, getState) => {
  dispatch({
    type: SET_BUSQUEDA,
    payload: search,
  });
};

export const cambiaArticulosOrden = (orden) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_ARTICULOS_ORDEN,
    payload: orden,
  });
};

export const setArticuloEquivalentes = (codigo_articulo) => (dispatch, getState) => {
  dispatch({
    type: SET_ARTICULO_EQUIVALENTES,
    payload: codigo_articulo,
  });
};

export const setBusquedaAvanzada = (avanzada, palabras_clave, descripcion, codigo_articulo, codigo_barras, equivalentes, referencia_cliente, codigo_sinonimo) => (dispatch, getState) => {
  dispatch({
    type: SET_BUSQUEDA_AVANZADA,
    payload: {
      palabras_clave: palabras_clave,
      descripcion: descripcion,
      codigo_articulo: codigo_articulo,
      codigo_barras: codigo_barras,
      equivalentes: equivalentes,
      avanzada: avanzada,
      referencia_cliente: referencia_cliente,
      codigo_sinonimo: codigo_sinonimo,
    },
  });
};

export const limpiarBusquedaAvanzada = () => (dispatch, getState) => {
  dispatch({
    type: LIMPIAR_BUSQUEDA_AVANZADA,
  });
};

export const limpiarListaArticulos = () => (dispatch) => {
  dispatch({
    type: FETCH_ARTICULOS,
  });
};

export const getStocksArticulos =
  (articulos_stock = [], origen = "", pedir_cantidad = false, lista_compra = "", maximo_agrupados = 36) =>
  (dispatch, getState) => {
    //Comprobamos si supera el maximo
    if (articulos_stock.length > Number(maximo_agrupados)) {
      let z = 0; //Indice del array de arrays
      let array_aux = []; //Declaramos un array auxiliar
      array_aux[z] = []; //Lo iniciamos como array de arrays

      for (let x = 0; x < articulos_stock.length; x++) {
        //Hacemos push del valor actual
        array_aux[z].push({
          codigo_articulo: articulos_stock[x].codigo_articulo,
          presentacion: articulos_stock[x].presentacion,
          codigo_almacen: articulos_stock[x].codigo_almacen,
          situacion: articulos_stock[x].situacion,
        });

        //Si x es divisor entero del maximo (sin ser 0 o 1)
        if (x !== 0 && x !== 1 && (x + 1) % Number(maximo_agrupados) === 0 && x + 1 !== articulos_stock.length) {
          z++; //Aumentamos el indice
          array_aux[z] = []; //Iniciamos el siguiente valor
        }
      }
      z = 0;
      //Lanzamos peticion de precios variable del array de arrays
      array_aux.forEach((array_articulos) => {
        body = JSON.stringify({
          articulos_stock: array_articulos,
        });
        z++;
        if (lista_compra !== null && lista_compra !== "") {
          dispatch({
            type: FETCH_STOCKS_ARTICULOS_LISTA,
          });
        } else {
          dispatch({
            type: FETCH_STOCKS_ARTICULOS,
            articulos_stock: array_articulos,
            origen: origen,
          });
        }

        let url = `/stock/multipleStock/?origen=${origen}&total_articulos_stock=${articulos_stock.length}&parte=${z}`;
        if (pedir_cantidad === true) {
          url = `/stock/multipleStockCantidad/?origen=${origen}&total_articulos_stock=${articulos_stock.length}&parte=${z}`;
        }
        axios
          .post(url, body, tokenConfig(getState))
          .then((res) => {
            if (lista_compra !== null && lista_compra !== "") {
              dispatch({
                type: GET_STOCKS_ARTICULOS_LISTA,
                articulos_stock: array_articulos,
                lista_compra: lista_compra,
                payload: res.data,
              });
            } else {
              dispatch({
                type: GET_STOCKS_ARTICULOS,
                articulos_stock: array_articulos,
                payload: res.data,
              });
            }
          })
          .catch((err) => console.log(err));
      });
    } else {
      if (lista_compra !== null && lista_compra !== "") {
        dispatch({
          type: FETCH_STOCKS_ARTICULOS_LISTA,
        });
      } else {
        dispatch({
          type: FETCH_STOCKS_ARTICULOS,
          articulos_stock: articulos_stock,
          origen: origen,
        });
      }

      body = JSON.stringify({ articulos_stock });
      let url = `/stock/multipleStock/?origen=${origen}&total_articulos_stock=${articulos_stock.length}`;
      if (pedir_cantidad === true) {
        url = `/stock/multipleStockCantidad/?origen=${origen}&total_articulos_stock=${articulos_stock.length}`;
      }
      axios
        .post(url, body, tokenConfig(getState))
        .then((res) => {
          if (lista_compra !== null && lista_compra !== "") {
            dispatch({
              type: GET_STOCKS_ARTICULOS_LISTA,
              articulos_stock: articulos_stock,
              lista_compra: lista_compra,
              payload: res.data,
            });
          } else {
            dispatch({
              type: GET_STOCKS_ARTICULOS,
              articulos_stock: articulos_stock,
              payload: res.data,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };
