import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getModularDet } from "../../actions/modular";

import Articulos from "../ecommerce/Articulos";
import Cuenta from "../usuarios/Cuenta";
import ListasCompra from "../ecommerce/ListasCompra";
import Contacto from "./Contacto";
import Informes from "../ecommerce/Informes";
import Registro from "../usuarios/Registro";
import Estatico from "./Estatico";
import PedidosAnteriores from "../ecommerce/PedidosAnteriores";
import PedidoHabitual from "../ecommerce/PedidoHabitual";
import NovedadesOfertas from "../ecommerce/NovedadesOfertas";
import Widgets from "../widgets/Widgets";
import RegistroCompleto from "../usuarios/RegistroCompleto";
import Expedientes from "../expedientes/Expedientes";
import Pedido from "../ecommerce/Pedido";
import Banners from "../ecommerce/Banners";
import Buscador from "../ecommerce/Buscador";
import Novedades from "../ecommerce/Novedades";
import Ofertas from "../ecommerce/Ofertas";
import Tiras from "../layout/Tira";
import Login from "../usuarios/Login";
import FamiliasMenu from "../ecommerce/FamiliasMenu";

export class Modular extends Component {
  lista_componentes = {
    articulos: Articulos,
    cuenta: Cuenta,
    listascompra: ListasCompra,
    contacto: Contacto,
    informes: Informes,
    registro: Registro,
    estatico: Estatico,
    pedidosanteriores: PedidosAnteriores,
    pedidohabitual: PedidoHabitual,
    novedadesofertas: NovedadesOfertas,
    widgets: Widgets,
    registrocompleto: RegistroCompleto,
    expedientes: Expedientes,
    pedido: Pedido,
    banners: Banners,
    buscador: Buscador,
    novedades: Novedades,
    ofertas: Ofertas,
    tiras: Tiras,
    login: Login,
    familiasmenu: FamiliasMenu,
  };

  static propTypes = {
    componentes: PropTypes.array.isRequired,
    getModularDet: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // Partimos la URL
    let url = window.location.pathname;
    let splittedURL = url.split("/");
    // Cogemos el codigo_articulo
    const modulo = splittedURL[2];

    const componentes = this.props.componentes.filter((c) => c.codigo_modulo === modulo);

    if (componentes.length === 0) {
      this.props.getModularDet(modulo);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    window.wookie();
  }

  render() {
    const { componentes } = this.props;
    if (componentes && componentes.length > 0) {
      return (
        <Fragment>
          {componentes.map((c) => {
            const Componente = this.lista_componentes[c.componente.toLowerCase()];
            console.log("Modular - Componente: ", c);

            let parametros = {
              key: c.id,
            };

            if (c.parametros.length > 0) {
              for (let p of c.parametros) {
                parametros[p.parametro] = p.valor;
              }

              //return React.cloneElement(Componente, { parametros });
            } else {
              //return <Componente />;
              //return React.cloneElement(Componente, { parametros });
            }

            return <Componente {...parametros} />;
          })}
        </Fragment>
      );
    }
    return <Fragment />;
  }
}

const mapStateToProps = (state) => ({
  componentes: state.modular.componentes,
});

export default connect(mapStateToProps, {
  getModularDet,
})(Modular);
