import React, { Component, Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { loadUser } from "./actions/auth";
import { getPortal, getMenus, setAceptacionCookies } from "./actions/portal";
import { showLoader } from "./actions/loader";

import "./App.css";

import { Provider } from "react-redux";
import store from "./store";

import ControladorRutas from "./components/common/ControladorRutas";
import CookieConsent from "react-cookie-consent";

import config from "./config";

import Header from "./components/layout/Header";
import Loader from "./components/layout/Loader";
import Alerts from "./components/layout/Alerts";
import Footer from "./components/layout/Footer";
import ModalGenerica from "./components/ecommerce/ModalGenerica";
import Avisos from "./components/layout/Avisos";
import RedesSociales from "./components/layout/RedesSociales";

// Alert Options
const alertOptions = {
  timeout: 3000,
  position: "top right",
};

export class App extends Component {
  UNSAFE_componentWillMount() {
    store.dispatch(showLoader());
    let hash_usuario = store.getState().auth.hash !== null && store.getState().auth.hash !== "" ? store.getState().auth.hash : "";
    store.dispatch(getPortal(hash_usuario));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.portal !== this.props.portal && store.getState().portal.descripcion !== undefined) {
      store.dispatch(getMenus());
    }
  }

  componentDidMount() {
    store.dispatch(loadUser());
  }

  configurarCookies = (e) => {
    window.history.pushState({}, "", "/configurarCookies");
    window.history.go();
  };

  aceptarCookies = (e) => {
    store.dispatch(setAceptacionCookies(true, Date.now(), true, true));
  };

  render() {
    return (
      <Provider store={store}>
        <Router>
          <Fragment>
            <Loader />
            <Header />
            <Alerts />
            <Avisos />
            <ControladorRutas />
            <Footer />
            {store.getState().portal.cookies.acepta_cookies === undefined ||
            store.getState().portal.cookies.acepta_cookies === null ||
            store.getState().portal.cookies.fecha_consentimiento + 15770000000000 < Date.now() ? (
              <CookieConsent
                location="bottom"
                buttonText={config.cookies.texto_boton}
                declineButtonText={config.cookies.texto_boton_declined}
                expires={150}
                onAccept={this.aceptarCookies}
                onDecline={this.configurarCookies}
                enableDeclineButton={true}
                flipButtons={true}
                hideOnDecline={true}
                hideOnAccept={true}
              >
                {config.cookies.mensaje}
              </CookieConsent>
            ) : (
              ""
            )}

            <ModalGenerica />
            <RedesSociales />
          </Fragment>
        </Router>
      </Provider>
    );
  }
}

export default App;
