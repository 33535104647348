import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getTransportistas, setTransportista } from "../../actions/transportistas";
import i18n from "../../lang/idiomas";

export class Transportistas extends Component {
  static propTypes = {
    getTransportistas: PropTypes.func.isRequired,
    setTransportista: PropTypes.func.isRequired,
    transportistas: PropTypes.array.isRequired,
    transportista: PropTypes.object.isRequired,
    pedido_cargando: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.getTransportistas();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.pedido_cargando !== this.props.pedido_cargando || JSON.stringify(prevProps.transportistas) !== JSON.stringify(this.props.transportistas)) && !this.props.pedido_cargando) {
      if (this.props.transportistas && this.props.transportistas.length === 1) {
        this.props.setTransportista(this.props.transportistas[0]);
      }

      if (this.props.transportistas && this.props.transportistas.length > 1 && this.props.pedido.transportista && this.props.pedido.transportista !== "") {
        const transportista = this.props.transportistas.filter((trans) => trans.codigo_rapido === this.props.pedido.transportista);

        this.props.setTransportista(transportista[0]);
      }
    }
  }

  onchange = (e) => {
    if (e.target.value !== "") {
      const transportista = this.props.transportistas.filter((trans) => trans.codigo_rapido === e.target.value);

      this.props.setTransportista(transportista[0]);
    }
  };

  render() {
    return (
      <Fragment>
        {this.props.transportistas && this.props.transportistas.length > 0 ? (
          <div className="tt-shopcart-box">
            <h4 className="tt-title">{i18n.t("transportista.trtransp")}</h4>
            <p>{i18n.t("transportista.trseltra")}</p>
            <form className="form-default">
              <div className="form-group">
                <label htmlFor="input_transportista">
                  {i18n.t("transportista.trtransp")} <sup>*</sup>
                </label>
                <select
                  className="form-control"
                  id="input_transportista"
                  name="transportista"
                  onChange={this.onchange}
                  disabled={this.props.pedido_cargando || this.props.pedidoGuardado ? true : false}
                  readOnly={!this.props.editable}
                >
                  <option key="0" value="">
                    {i18n.t("transportista.trstras")}
                  </option>
                  {this.props.transportistas.map((trans, index) => {
                    if (trans.codigo_rapido === this.props.transportista.codigo_rapido) {
                      return (
                        <option key={index} value={trans.codigo_rapido} selected>
                          {trans.nombre}
                        </option>
                      );
                    } else {
                      return (
                        <option key={index} value={trans.codigo_rapido}>
                          {trans.nombre}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
            </form>
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  transportistas: state.transportistas.transportistas,
  transportista: state.transportistas.transportista,
  pedido_cargando: state.pedidos.isLoading,
  pedido: state.pedidos.pedido,
});

export default connect(mapStateToProps, {
  getTransportistas,
  setTransportista,
})(Transportistas);
