import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { postLinea } from "../../actions/pedidos";
import BotonAnadirLista from "./BotonAnadirLista";
import { getPrecio } from "../../actions/precio";
import AnalyticsEdisa from "../common/AnalyticsEdisa";
import { getStockArticulo } from "../../actions/articulo";
import { cargarParametros } from "../../helpers/funciones";
import { nuevaReceta } from "../../actions/modalGenerica";
import { limpiarPresentacion } from "../../actions/presentaciones";
import i18n from "../../lang/idiomas";
import { creaSubscripcionStock, actualizaSubscripcionStock } from "../../actions/subscripcionesStock";
import { Bell, BellRinging, ShoppingCart, ClipboardText } from "phosphor-react";

let parametros_usados = ["btn_presup", "stock_cantidad", "btn_subs", "cant_def", "def_input"];
let parametros;

export class AnadirCarrito extends Component {
  state = {
    valor: 0,
    articulo: this.props.articulo,
    filesAux: [],
    fileInput: React.createRef(),
  };

  static propTypes = {
    postLinea: PropTypes.func.isRequired,
    getPrecio: PropTypes.func.isRequired,
    usuario: PropTypes.object,
    precio: PropTypes.object.isRequired,
    hash: PropTypes.string,
    pedido: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    // Buscamos los elementos a tratar
    let input_cant = document.getElementById("input-cant-" + this.props.articulo.codigo_articulo);
    let input_cant_mob = document.getElementById("input-cant-mob" + this.props.articulo.codigo_articulo);

    if (prevProps.articulo !== this.props.articulo) {
      // Seteamos el nuevo articulo
      this.setState({ articulo: this.props.articulo });
      if (parametros && this.props.pedido && this.props.pedido.lineas && this.props.pedido.lineas.length > 0) {
        switch (parametros.def_input) {
          case "CANT_MIN":
            // Se comprueba si tiene cantidad minima y si es distinta de 0
            if (this.props.articulo.cantidad_minima && Number(this.props.articulo.cantidad_minima).toFixed(0) !== 0) {
              let cantidad_min = Number(this.props.articulo.cantidad_minima).toFixed(0);
              if (this.props.datos && this.props.datos.cantidad_pedida) {
                this.setearValorInput(input_cant, input_cant_mob, Number(this.props.datos.cantidad_pedida).toFixed(0));
              } else {
                this.setearValorInput(input_cant, input_cant_mob, cantidad_min);
              }
            }
            break;
          case "PEDIDO":
            let index = -1;
            // Buscamos la linea en el pedido
            index = this.props.pedido.lineas.findIndex((linea) => linea.articulo === this.props.articulo.codigo_articulo && linea.tipo_linea === "P");
            if (index !== -1) {
              // Si el valor no es el mismo que el del pedido
              if (this.state.valor !== Number(this.props.pedido.lineas[index].cantidad_pedida).toFixed(0)) {
                // Se setea el valor en los inputs
                let cantidad_pedido = Number(this.props.pedido.lineas[index].cantidad_pedida).toFixed(0);
                if (this.props.datos && this.props.datos.cantidad_pedida) {
                  this.setearValorInput(input_cant, input_cant_mob, Number(this.props.datos.cantidad_pedida).toFixed(0));
                } else {
                  this.setearValorInput(input_cant, input_cant_mob, cantidad_pedido);
                }
              }
            }
            break;
          default:
            if (this.props.datos && this.props.datos.cantidad_pedida) {
              this.setearValorInput(input_cant, input_cant_mob, Number(this.props.datos.cantidad_pedida).toFixed(0));
            } else {
              this.setearValorInput(input_cant, input_cant_mob, parametros.cant_def);
            }

            break;
        }
      }
    }
    //Si el valor es 0 seteamos el defectivo
    if ((prevState.valor !== this.state.valor && this.state.valor === 0) || (prevState.valor === this.state.valor && this.state.valor === 0)) {
      if (parametros) {
        if (this.state.valor !== parseInt(parametros.cant_def)) {
          this.setearValorInput(input_cant, input_cant_mob, parametros.cant_def);
        }
      }
    }
    if (prevProps.subpresentacion !== this.props.subpresentacion) {
      this.props.getPrecio(
        this.props.articulo.codigo_articulo,
        Number.parseInt(this.state.valor) * this.props.subpresentacion.factor_conversion,
        this.props.id_pedido_seleccionado,
        this.props.pedido.domicilio_envio
      );
    }

    if (prevProps.presentacion !== this.props.presentacion && Object.keys(this.props.presentacion).length > 0) {
      this.props.getPrecio(
        this.props.articulo.codigo_articulo,
        Number.parseInt(this.state.valor) * this.props.presentacion.factor_conversion,
        this.props.id_pedido_seleccionado,
        this.props.pedido.domicilio_envio
      );
    }

    if (this.props.pedido.almacen !== prevProps.pedido.almacen && this.props.origen === "ficha") {
      this.props.getStockArticulo(
        this.props.articulo.codigo_articulo,
        this.props.articulo.presentacion_web,
        this.props.pedido.almacen,
        "N",
        this.props.id_pedido_seleccionado,
        "",
        parametros.stock_cantidad
      );
    }
  }

  comprobarCarnet(articulo, linea, comprobar_especificos, valida) {
    if (this.props.usuario.carnets && this.props.usuario.carnets.lineas.length > 0) {
      let index = -1;
      if (comprobar_especificos === "S") {
        // AUTORIZACIÓN VENTA MAIORISTA PROD. ZOOSANITARIOS
        index = this.props.usuario.carnets.lineas.findIndex((carnet) => carnet.tipo_carnet == "00002");
        if (index !== -1) {
          // Procesamos sin mensaje
          if (valida === "otros") {
            linea.valida_otros = "S";
          } else {
            linea.valida_receta = "S";
          }
        } else {
          // AUTORIZACIÓN VENTA MEDICAMENTOS OUTRAS CANLES
          index = this.props.usuario.carnets.lineas.findIndex((carnet) => carnet.tipo_carnet == "00003");
          if (index !== -1) {
            // Procesamos con aviso
            if (valida === "otros") {
              linea.valida_otros = "M";
            } else {
              linea.valida_receta = "M";
            }
          }
        }
      } else {
        index = this.props.usuario.carnets.lineas.findIndex((carnet) => carnet.tipo_carnet == articulo.tipo_carnet_profesional);

        if (index !== -1) {
          // Si existe carnet valido
          linea.valida_carnet = "S";
        } else {
          linea.valida_carnet = "N";
        }
      }
    } else {
      if (comprobar_especificos === "S") {
        if (valida === "receta") {
          linea.valida_receta = "N";
        }
      } else {
        linea.valida_carnet = "N";
      }
    }

    return linea;
  }

  anadirCarro = (tipo) => {
    if (!this.props.pedido_cargando) {
      const { valor, articulo } = this.state;

      if (
        this.props.precio[articulo.codigo_articulo] &&
        this.props.precio[articulo.codigo_articulo].error === false &&
        this.props.precio[articulo.codigo_articulo].isLoading === false &&
        this.props.precio[articulo.codigo_articulo].data
      ) {
        let cantidad_pedida = valor;

        if ((cantidad_pedida === 0 && cantidad_pedida !== parametros.cant_def) || !cantidad_pedida) {
          cantidad_pedida = parseInt(parametros.cant_def);
        }
        if (this.props.subpresentacion.subpresentacion) {
          cantidad_pedida = cantidad_pedida * this.props.subpresentacion.factor_conversion;
        }

        if (this.props.presentacion.presentacion) {
          cantidad_pedida = Number.parseFloat(cantidad_pedida) * Number.parseFloat(this.props.presentacion.factor_conversion);

          this.props.getPrecio(this.props.articulo.codigo_articulo, cantidad_pedida, this.props.id_pedido_seleccionado, this.props.pedido.domicilio_envio);
        }

        let linea = {
          articulo: articulo.codigo_articulo,
          descripcion: articulo.descrip_comercial,
          precio_venta: Number.parseFloat(this.props.precio[articulo.codigo_articulo].data.precio_neto),
          observaciones: "",
          estado_linea: "B",
          cantidad_pedida: cantidad_pedida,
          presentacion_pedido: articulo.presentacion_web,
          tipo_linea: tipo,
          sub_pres: "",
          sub_pres_cant: "",
          sub_pres_fc: "",
          presentacion_escogida: "",
          pres_fc: "",
          valida_carnet: "",
        };

        // Comprobamos si el articulo necesita carnet profesional
        if (articulo.tipo_carnet_profesional) {
          linea = this.comprobarCarnet(articulo, linea, "N", null);
        }

        // Comprobamos si el articulo necesita receta
        if (articulo.numero_receta) {
          if (articulo.codigo_estad10 && articulo.codigo_estad10 === "10") {
            // Comprobamos si necesita autorizacion por otros canales
            if (this.props.usuario.con_receta && this.props.usuario.con_receta === "S") {
              // Comprobamos si es mayorista
              // 01 - Mayorista
              // 02 - Minorista
              if (this.props.usuario.tipo_cliente === "01") {
                // Se envia mensaje a usuario
                linea = this.comprobarCarnet(articulo, linea, "S", "otros");
              } else {
                // Se indica que pasa a estar pendiente de validacion otros canales
                linea.valida_otros = "N";
              }
            } else {
              // Se permite añadir sin otro control
              linea.valida_otros = "S";
            }
          } else {
            // Comprobamos si el usuario está autorizado o no
            if (this.props.usuario.con_receta && this.props.usuario.con_receta === "S") {
              // Comprobamos si es mayorista
              // 01 - Mayorista
              // 02 - Minorista
              if (this.props.usuario.tipo_cliente === "01") {
                // Se envia mensaje a usuario
                linea = this.comprobarCarnet(articulo, linea, "S", "receta");
              } else {
                // Se obliga al usuario a adjuntar receta
                linea.valida_receta = "N";
              }
            } else {
              // Se permite añadir sin otro control
              linea.valida_receta = "S";
            }
          }
        } else {
          // Comprobamos si necesita autorizacion por otros canales
          if (articulo.codigo_estad10 && articulo.codigo_estad10 === "10") {
            // Comprobamos si es mayorista
            // 01 - Mayorista
            // 02 - Minorista
            if (this.props.usuario.tipo_cliente === "01") {
              // Se envia mensaje a usuario
              linea = this.comprobarCarnet(articulo, linea, "S", "otros");
            }
          }
        }

        if (this.props.subpresentacion.subpresentacion) {
          linea.sub_pres = this.props.subpresentacion.subpresentacion;
          linea.sub_pres_cant = Number.parseInt(valor);
          linea.sub_pres_fc = Number.parseFloat(this.props.subpresentacion.factor_conversion);
          linea.observaciones = linea.sub_pres_cant + " de " + linea.sub_pres;
        }

        if (this.props.presentacion.presentacion) {
          linea.pres_fc = Number.parseFloat(this.props.presentacion.factor_conversion);

          linea.presentacion_escogida = this.props.presentacion.presentacion;
        }

        // Si se incluyeron observaciones en la linea se añaden a la linea de pedido
        if (this.props.getObservaciones) {
          linea.observaciones = this.props.getObservaciones(linea.articulo);
        }

        if (!linea.valida_receta || linea.valida_receta === "S" || linea.valida_receta === "M") {
          // Añadimos la linea a pedido
          this.props.postLinea(linea, this.props.hash, this.props.id_pedido_seleccionado);
        } else {
          //Se solicita el ingreso de la receta
          this.props.nuevaReceta(linea, "receta");
        }

        let analytics = new AnalyticsEdisa();

        analytics.registraEvento({
          modulo: "Pedido",
          tipo: "AddToCart",
          valor: this.props.articulo.codigo_articulo,
          params: {
            codigo_articulo: this.props.articulo.codigo_articulo,
            descrip_comercial: this.props.articulo.descrip_comercial,
            divisa: this.props.precio[this.props.articulo.codigo_articulo].data.divisa_precio,
            precio: parseFloat(
              this.props.netear_precio === "S"
                ? this.props.precio[articulo.codigo_articulo].data.precio_precio_neto
                : this.props.precio[articulo.codigo_articulo].data.precio_presentacion * cantidad_pedida
            ),
          },
        });
        this.props.limpiarPresentacion();
      }
    }
  };

  onBlur = (e) => {
    if (e.target.value && e.target.value !== "" && parseInt(e.target.value) !== 0) {
      let cantidad = parseInt(e.target.value);

      if (this.props.subpresentacion.subpresentacion) {
        cantidad = cantidad * this.props.subpresentacion.factor_conversion;
      }

      if (this.props.presentacion.presentacion) {
        cantidad = cantidad * this.props.presentacion.factor_conversion;
      }
      this.props.getPrecio(this.props.articulo.codigo_articulo, cantidad, this.props.id_pedido_seleccionado, this.props.pedido.domicilio_envio);
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: parseInt(e.target.value) });
  };

  setearValorInput(input_normal, input_mobil, valor_cant) {
    if (input_normal) {
      input_normal.value = valor_cant;
    }
    if (input_mobil) {
      input_mobil.value = valor_cant;
    }
    // Se setea el valor en el estado
    this.setState({
      valor: parseInt(valor_cant),
    });
  }

  changeQuantity = (e) => {
    e.preventDefault();

    let count = parseInt(this.state.valor, 10) + parseInt(e.currentTarget.className === "plus-btn" ? 1 : -1, 10);

    let value = Math.max(count, 1);
    this.setState({
      valor: value,
    });

    let cantidad = value;

    if (this.props.subpresentacion.subpresentacion) {
      cantidad = cantidad * this.props.subpresentacion.factor_conversion;
    }

    if (this.props.presentacion.presentacion) {
      cantidad = cantidad * this.props.presentacion.factor_conversion;
    }
    this.props.getPrecio(this.props.articulo.codigo_articulo, cantidad, this.props.id_pedido_seleccionado, this.props.pedido.domicilio_envio);
  };

  pintaBotonSubscripcion = () => {
    if (this.props.usuario.subscripciones && this.props.usuario.subscripciones.lineas && this.props.usuario.subscripciones.lineas.length > 0) {
      let existe_sub = false;
      let sub_aux = null;
      this.props.usuario.subscripciones.lineas.forEach((sub) => {
        if (sub.codigo_articulo === this.props.articulo.codigo_articulo) {
          existe_sub = true;
          sub_aux = sub;
        }
        if (existe_sub === true) {
          return;
        }
      });
      if (existe_sub === true && sub_aux) {
        return (
          <button
            type="submit"
            className="btn btn-primary sub"
            onClick={(e) => {
              e.preventDefault();
              this.props.actualizaSubscripcionStock(
                this.state.articulo.codigo_articulo, // codigo_articulo
                sub_aux.status, //status
                sub_aux.aviso_unico, //aviso_unico
                "articulos"
              );
            }}
            tabIndex={2 + (this.props.index && this.props.index !== "" ? this.props.index * 4 : 1)}
          >
            <BellRinging size={20} weight="duotone" alt={i18n.t("subscripciones.altdelsb")} />
            {this.props.mostrar_texto_btn === "S" ? i18n.t("subscripciones.altdelsb").toUpperCase() : ""}
          </button>
        );
      } else {
        return (
          <button
            type="submit"
            className="btn btn-primary no-sub"
            onClick={(e) => {
              e.preventDefault();
              this.props.creaSubscripcionStock(
                this.state.articulo.codigo_articulo, // codigo_articulo
                null, //status
                "S" //aviso_unico
              );
            }}
            tabIndex={2 + (this.props.index && this.props.index !== "" ? this.props.index * 4 : 1)}
          >
            <Bell size={20} alt={i18n.t("subscripciones.altaddsb")} />
            {this.props.mostrar_texto_btn === "S" ? i18n.t("subscripciones.altaddsb").toUpperCase() : ""}
          </button>
        );
      }
    } else {
      return (
        <button
          type="submit"
          className="btn btn-primary no-sub"
          onClick={(e) => {
            e.preventDefault();
            this.props.creaSubscripcionStock(
              this.state.articulo.codigo_articulo, // codigo_articulo
              null, //status
              "S" //aviso_unico
            );
          }}
          tabIndex={2 + (this.props.index && this.props.index !== "" ? this.props.index * 4 : 1)}
        >
          <Bell size={20} alt={i18n.t("subscripciones.altaddsb")} />
          {this.props.mostrar_texto_btn === "S" ? i18n.t("subscripciones.altaddsb").toUpperCase() : ""}
        </button>
      );
    }
  };

  render() {
    const { valor } = this.state.valor;
    parametros = cargarParametros(parametros_usados, this.props.portal.parametros_ecom, "GEN");
    if (this.props.precio[this.props.articulo.codigo_articulo] && this.props.precio[this.props.articulo.codigo_articulo].error === false) {
      if (this.props.origen) {
        switch (this.props.origen) {
          case "ficha":
            return (
              <form className="form-inline form-default control_anadir_carrito_ficha">
                <div className="input-group">
                  <input
                    className="form-control desctop"
                    id={"input-cant-" + this.props.articulo.codigo_articulo}
                    disabled={
                      this.props.articulo.info_stock &&
                      parametros &&
                      (this.props.articulo.info_stock.stock === "S" || this.props.articulo.info_stock.stock > 0 || parametros.btn_presup === "S") &&
                      !this.props.pedido_cargando
                        ? false
                        : true
                    }
                    //data-tooltip="Cantidad"
                    type="number"
                    name="valor"
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    placeholder={parametros.cant_def}
                    tabIndex={1 + (this.props.index && this.props.index !== "" ? this.props.index * 4 : 1)}
                  />
                  <div className="detach-quantity-desctope mobile">
                    <div className="tt-input-counter style-01">
                      <span className="minus-btn" onClick={this.changeQuantity}></span>
                      <input
                        className="quantity-input"
                        id={"input-cant-mob-" + this.props.articulo.codigo_articulo}
                        disabled={
                          this.props.articulo.info_stock &&
                          parametros &&
                          (this.props.articulo.info_stock.stock === "S" || this.props.articulo.info_stock.stock > 0 || parametros.btn_presup === "S") &&
                          !this.props.pedido_cargando
                            ? false
                            : true
                        }
                        type="number"
                        name="valor"
                        size="5"
                      />
                      <span className="plus-btn" onClick={this.changeQuantity}></span>
                    </div>
                  </div>
                  <button
                    type="submit"
                    //data-tooltip="Añadir a pedido"
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      this.anadirCarro("P");
                    }}
                    tabIndex={2 + (this.props.index && this.props.index !== "" ? this.props.index * 4 : 1)}
                    disabled={
                      this.props.articulo.info_stock && parametros && (this.props.articulo.info_stock.stock === "S" || this.props.articulo.info_stock.stock > 0) && !this.props.pedido_cargando
                        ? false
                        : true
                    }
                  >
                    <ShoppingCart size={20} alt={i18n.t("general.altaddsc")} />
                    {this.props.mostrar_texto_btn === "S" ? i18n.t("general.altaddsc").toUpperCase() : ""}
                  </button>
                  <div className="row botones-secundarios">
                    <div className="tt-wrapper">
                      <ul className="tt-list-btn">
                        {parametros && parametros.btn_presup === "S" ? (
                          <li>
                            <button
                              className="btn-link"
                              onClick={(e) => {
                                e.preventDefault();
                                this.anadirCarro("O");
                              }}
                              tabIndex={3 + (this.props.index && this.props.index !== "" ? this.props.index * 4 : 1)}
                              disabled={this.props.pedido_cargando ? true : false}
                            >
                              <ClipboardText size={20} alt={i18n.t("general.altaddbg")} />
                              {this.props.mostrar_texto_btn === "S" ? i18n.t("general.altaddbg").toUpperCase() : ""}
                            </button>
                          </li>
                        ) : (
                          ""
                        )}
                        {this.props.articulo.codigo_articulo && this.props.anadir_lista === true ? (
                          <li>
                            {this.props.articulo.codigo_articulo && this.props.anadir_lista === true ? (
                              <BotonAnadirLista
                                origen="articulo"
                                codigo_articulo={this.props.articulo.codigo_articulo}
                                tabIndex={4 + (this.props.index && this.props.index !== "" ? this.props.index * 4 : 1)}
                              />
                            ) : (
                              ""
                            )}
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </form>
            );
          case "tabla":
            if (this.props.datos && (this.props.datos.tipo_articulo === "F" || this.props.datos.tipo_articulo === "R")) {
              return "";
            } else {
              return (
                <form className="form-inline form-default control_anadir_carrito_tabla">
                  <div className="input-group">
                    <input
                      className="form-control desctop"
                      id={"input-cant-" + this.props.articulo.codigo_articulo}
                      //data-tooltip="Cantidad"
                      type="number"
                      name="valor"
                      disabled={
                        this.props.articulo.info_stock &&
                        parametros &&
                        (this.props.articulo.info_stock.stock === "S" || this.props.articulo.info_stock.stock > 0 || parametros.btn_presup === "S") &&
                        !this.props.pedido_cargando
                          ? false
                          : true
                      }
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      value={valor === 0 ? parametros.cant_def : valor}
                      placeholder={parametros.cant_def}
                      tabIndex={1 + (this.props.index && this.props.index !== "" ? this.props.index * 4 : 1)}
                    />
                    <div className="col-12 detach-quantity-desctope mobile">
                      <div className="tt-input-counter style-01">
                        <span className="minus-btn" onClick={this.changeQuantity}></span>
                        <input className="quantity-input" id={"input-cant-mob-" + this.props.articulo.codigo_articulo} type="number" name="valor" size="5" />
                        <span className="plus-btn" onClick={this.changeQuantity}></span>
                      </div>
                    </div>
                    <div className="botonera">
                      {parametros.btn_subs === "S" ? (
                        this.props.articulo.info_stock && (this.props.articulo.info_stock.stock === "S" || this.props.articulo.info_stock.stock > 0) ? (
                          <button
                            type="submit"
                            //data-tooltip="Añadir a pedido"
                            className="btn btn-primary boton_carrito"
                            onClick={(e) => {
                              e.preventDefault();
                              this.anadirCarro("P");
                            }}
                            tabIndex={2 + (this.props.index && this.props.index !== "" ? this.props.index * 4 : 1)}
                            disabled={this.props.pedido_cargando ? true : false}
                          >
                            {this.props.mostrar_texto_btn === "S" ? "Pedido" : <ShoppingCart size={20} alt={i18n.t("general.altaddsc")} />}
                          </button>
                        ) : (
                          this.pintaBotonSubscripcion()
                        )
                      ) : (
                        <button
                          type="submit"
                          //data-tooltip="Añadir a pedido"
                          className="btn btn-primary boton_carrito"
                          onClick={(e) => {
                            e.preventDefault();
                            this.anadirCarro("P");
                          }}
                          tabIndex={2 + (this.props.index && this.props.index !== "" ? this.props.index * 4 : 1)}
                          disabled={
                            this.props.articulo.info_stock && (this.props.articulo.info_stock.stock === "S" || this.props.articulo.info_stock.stock > 0) && !this.props.pedido_cargando ? false : true
                          }
                        >
                          {this.props.mostrar_texto_btn === "S" ? i18n.t("general.pedido") : <ShoppingCart size={20} alt={i18n.t("general.altaddsc")} />}
                        </button>
                      )}
                      {parametros && parametros.btn_presup === "S" ? (
                        <button
                          className="btn boton-carrito"
                          onClick={(e) => {
                            e.preventDefault();
                            this.anadirCarro("O");
                          }}
                          tabIndex={3 + (this.props.index && this.props.index !== "" ? this.props.index * 4 : 1)}
                          disabled={this.props.pedido_cargando ? true : false}
                        >
                          {this.props.mostrar_texto_btn === "S" ? i18n.t("general.presup") : <ClipboardText size={20} alt={i18n.t("general.altaddbg")} />}
                        </button>
                      ) : (
                        ""
                      )}
                      {this.props.articulo.codigo_articulo && this.props.anadir_lista === true ? (
                        <Fragment>
                          {this.props.articulo.codigo_articulo && this.props.anadir_lista === true ? (
                            <BotonAnadirLista
                              origen="tabla"
                              mostrar_texto_btn={this.props.mostrar_texto_btn}
                              codigo_articulo={this.props.articulo.codigo_articulo}
                              tabIndex={4 + (this.props.index && this.props.index !== "" ? this.props.index * 4 : 1)}
                            />
                          ) : (
                            ""
                          )}
                        </Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </form>
              );
            }

          default:
            return (
              <Fragment>
                <form className="form-inline form-default control_anadir_carrito">
                  <div className="input-group">
                    <input
                      className="form-control desctop"
                      id={"input-cant-" + this.props.articulo.codigo_articulo}
                      type="number"
                      name="valor"
                      disabled={this.props.articulo.info_stock && (this.props.articulo.info_stock.stock === "S" || this.props.articulo.info_stock.stock > 0) ? false : true}
                      //data-tooltip="Cantidad"
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      placeholder={parametros.cant_def}
                      tabIndex={1 + (this.props.index && this.props.index !== "" ? this.props.index * 2 : 1)}
                    />
                    <div className="detach-quantity-desctope mobile">
                      <div className="tt-input-counter style-01">
                        <span className="minus-btn" onClick={this.changeQuantity}></span>
                        <input id={"input-cant-mob" + this.props.articulo.codigo_articulo} className="quantity-input" type="number" name="valor" size="5" />
                        <span className="plus-btn" onClick={this.changeQuantity}></span>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      //data-tooltip="Añadir a pedido"
                      onClick={(e) => {
                        e.preventDefault();
                        this.anadirCarro("P");
                      }}
                      tabIndex={2 + (this.props.index && this.props.index !== "" ? this.props.index * 2 : 1)}
                      disabled={
                        this.props.articulo.info_stock && (this.props.articulo.info_stock.stock === "S" || this.props.articulo.info_stock.stock > 0) && !this.props.pedido_cargando ? false : true
                      }
                    >
                      <ShoppingCart size={20} alt={i18n.t("general.altaddsc")} />
                      {this.props.mostrar_texto_btn === "S" ? i18n.t("general.pedido").toUpperCase() : ""}
                    </button>
                  </div>
                </form>
              </Fragment>
            );
        }
      } else {
        return <Fragment></Fragment>;
      }
    } else {
      return <Fragment></Fragment>;
    }
  }
}

const mapStateToProps = (state) => ({
  precio: state.precio.precio,
  hash: state.auth.hash,
  pedido: state.pedidos.pedido,
  pedido_cargando: state.pedidos.isLoading,
  portal: state.portal.portal,
  cookies: state.portal.cookies,
  subpresentacion: state.subpresentaciones.subpresentacion,
  presentacion: state.presentaciones.presentacion,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
  usuario: state.auth.user,
});

export default connect(mapStateToProps, {
  postLinea,
  nuevaReceta,
  getPrecio,
  getStockArticulo,
  limpiarPresentacion,
  creaSubscripcionStock,
  actualizaSubscripcionStock,
})(AnadirCarrito);
