import React, { Component } from "react";
import { connect } from "react-redux";
import i18n from "../../../lang/idiomas";

class DatosPlantilla extends Component {
  render() {
    return this.props.plantilla ? (
      <div className="plantilla-details">
        <span className="separator"></span>
        <div className="row">
          <div className="details-col col-sm-12 col-md-4 col-lg-4">
            <span className="title">{i18n.t("plantillas.pcodplan")}</span>
            <span className="plantilla-tag ">{this.props.plantilla.codigo_plantilla}</span>
          </div>
          <div className="details-col col-sm-12 col-md-4 col-lg-4">
            <span className="title">{i18n.t("plantillas.pfechini")}</span>
            <span className="plantilla-tag ">{new Date(Date.parse(this.props.plantilla.fecha_inicio)).toLocaleDateString(this.props.locale).replaceAll("/", " - ")}</span>
          </div>
          <div className="details-col col-sm-12 col-md-4 col-lg-4">
            <span className="title">{i18n.t("plantillas.pfechafi")}</span>
            <span className="plantilla-tag ">{new Date(Date.parse(this.props.plantilla.fecha_fin)).toLocaleDateString(this.props.locale).replaceAll("/", " - ")}</span>
          </div>
        </div>
        <span className="separator"></span>
        <div className="external-items">
          <span className={this.props.plantilla.permitir_otros_articulos === "S" ? "plantilla-tag blank" : "plantilla-tag warn"}>
            {this.props.plantilla.permitir_otros_articulos === "S" ? i18n.t("plantillas.pperarte") : i18n.t("plantillas.pnoartex")}
          </span>
        </div>
      </div>
    ) : (
      <></>
    );
  }
}

const mapStateToProps = (state) => ({
  plantilla: state.plantillas.plantilla_seleccionada,
  locale: state.precio.locale,
});
export default connect(mapStateToProps, {})(DatosPlantilla);
