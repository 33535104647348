import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Paperclip } from "phosphor-react";
import { setExpediente, setOrdenExpedientes, setMostrarExpedientes, mostrarMasExpedientes, setTipoExpediente } from "../../actions/expedientes";
import { FilePlus } from "phosphor-react";
import Spinner from "../layout/Spinner";
import i18n from "../../lang/idiomas";

export class ExpedientesListado extends Component {
  static propTypes = {
    expedientes: PropTypes.array.isRequired,
    setExpediente: PropTypes.func.isRequired,
    setOrdenExpedientes: PropTypes.func.isRequired,
    setMostrarExpedientes: PropTypes.func.isRequired,
    mostrarMasExpedientes: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
  };

  onChange = (e) => {
    e.preventDefault();
    this.props.setOrdenExpedientes(e.target.value);
  };

  changeExpPag = (e) => {
    e.preventDefault();
    this.props.setMostrarExpedientes(Number(e.target.value));
  };

  pintarSelector(tipo) {
    if (tipo === "orden") {
      return (
        <Fragment>
          <label id="label-select-orden" htmlFor="selector-orden">
            {i18n.t("general.sortby")}
          </label>
          <select id="selector-orden" name="orden" onChange={this.onChange}>
            <option value="num_asc" selected={this.props.orden === "num_asc" ? true : false}>
              {i18n.t("general.sortbyna")}
            </option>
            <option value="num_des" selected={this.props.orden === "num_des" ? true : false}>
              {i18n.t("general.sortbynd")}
            </option>
            <option value="fec_asc" selected={this.props.orden === "fec_asc" ? true : false}>
              {i18n.t("general.sortbyfa")}
            </option>
            <option value="fec_des" selected={this.props.orden === "fec_des" ? true : false}>
              {i18n.t("general.sortbyfd")}
            </option>
          </select>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <label id="label-select-resultados" htmlFor="selector-resultados">
            {i18n.t("general.mostrar")}
          </label>
          <select id="selector-resultados" name="resultados" onChange={this.changeExpPag}>
            <option value="12" selected={this.props.mostrar === 12 ? true : false}>
              12
            </option>
            <option value="24" selected={this.props.mostrar === 24 ? true : false}>
              24
            </option>
            <option value="36" selected={this.props.mostrar === 36 ? true : false}>
              36
            </option>
          </select>
        </Fragment>
      );
    }
  }

  masExpedientes = (e) => {
    e.preventDefault();
    let nuevo_inicio = Number(this.props.inicio) + Number(this.props.mostrar);
    this.props.mostrarMasExpedientes(this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado], this.props.mostrar, this.props.orden, nuevo_inicio, this.props.mostrar);
  };

  render() {
    return (
      <Fragment>
        <div className="content-indent expedientes">
          <div className="titulo-listado-expedientes">{this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].nombre.toUpperCase()}</div>
          <div className="ordenar">
            {this.props.expedientes && this.props.expedientes.length ? this.pintarSelector("orden") : ""}
            {this.props.expedientes && this.props.expedientes.length ? this.pintarSelector("resultados") : ""}
          </div>
          <div className="acciones-expedientes">
            <button
              className="btn-add-expediente"
              onClick={(e) => {
                e.preventDefault();
                this.props.setExpediente("new");
              }}
            >
              <FilePlus size={20} />
              <span>{i18n.t("expedientes.exnuexp")}</span>
            </button>
          </div>
          <div className="listado_expedientes">
            {this.props.expedientes.map((expediente, index) => (
              <div key={index} className="col-6 col-md-4 col-lg-3 col-xl-3 expediente">
                <div
                  className="tarjeta"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.setExpediente(index);
                  }}
                >
                  <div className="titulo">
                    <div className={"numero_exp"}>{expediente.numero_expediente}</div>
                    <div className={"fecha_alta"}>{expediente.fecha_alta}</div>
                  </div>
                  <div className="iconos_informativos">
                    <div className={"tiene_ficheros"} hidden={expediente.tiene_ficheros === "S" ? false : true}>
                    <Paperclip size={16} alt={'Tiene ficheros adjuntos'}/>
                    </div>
                  </div>
                  <div className="campos">
                    {expediente.campos && expediente.campos
                      ? expediente.campos.map((campo, index) =>
                          campo.preview === "S" ? (
                            <div key={campo.columna + "-" + index} className={"campo " + campo.columna}>
                              <div className="literal" style={campo.tipo_input === "number" ? { width: "50%" } : { width: "100%" }}>
                                <h6>{campo.literal}</h6>
                              </div>
                              <div className="valor" style={campo.tipo_input === "number" ? { width: "50%" } : { width: "100%" }}>
                                {campo.valor}
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        )
                      : ""}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Spinner showSpinner={this.props.expedientes_loading} />
          <div className="botonera-mas">
            {this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado] &&
            this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].totales &&
            this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].totales >= this.props.inicio + this.props.mostrar ? (
              <button onClick={this.masExpedientes} className="btn btn-border">
                {i18n.t("expedientes.mosmasex")}
              </button>
            ) : (
              <button className="btn btn-border01">{i18n.t("expedientes.nomasex")}</button>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  expedientes: state.expedientes.expedientes,
  language: state.languageSelector.language,
  tipos_expedientes: state.expedientes.tipos_expedientes,
  tipo_expediente_seleccionado: state.expedientes.tipo_expediente_seleccionado,
  expedientes_loading: state.expedientes.isLoading,
  mostrar: state.expedientes.mostrar,
  inicio: state.expedientes.inicio,
  orden: state.expedientes.orden,
});

export default connect(mapStateToProps, {
  setExpediente,
  setOrdenExpedientes,
  setMostrarExpedientes,
  mostrarMasExpedientes,
  setTipoExpediente
})(ExpedientesListado);
