import {
  FETCH_MENU_INFORMES,
  GET_MENU_INFORMES,
  GET_FILTROS_PLUGINS_INFORME,
  SET_INFORME_SELECCIONADO,
  SET_DATOS_INFORME,
  GET_DATOS_INFORME,
  OBTENER_EFACTURA,
  DESCARGA_EFACTURA,
  OBTENER_REPORT,
  DESCARGA_REPORT,
  GET_MAS_DATOS_INFORME,
  SET_MAS_DATOS_INFORME,
  FETCH_PERMISOS_MENU_INFORMES,
  GET_PERMISOS_MENU_INFORMES,
  OBTENER_EFACTURA_MASIVA,
  DESCARGA_EFACTURA_MASIVA,
  GENERA_PEDIDO_INFORME,
  GENERA_PEDIDO_INFORME_SUCCESS,
  GENERA_PEDIDO_INFORME_FAIL,
  GET_DATOS_INFORME_SUCCESS,
  GET_DATOS_INFORME_FAIL,
} from "../actions/types.js";

const initialState = {
  informes: [],
  permisos_informes: [],
  filtros: [],
  plugins: [],
  informe_seleccionado: "",
  datos: [],
  numero_registros: 0,
  isLoading: false,
  permitir_excel: "S",
  pathLoc: ""
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GENERA_PEDIDO_INFORME:
    case GET_MAS_DATOS_INFORME:
    case FETCH_MENU_INFORMES:
    case GET_DATOS_INFORME:
    case FETCH_PERMISOS_MENU_INFORMES:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MENU_INFORMES:
      return {
        ...state,
        isLoading: false,
        informes: action.payload["informes"] ? (Array.isArray(action.payload["informes"]) ? action.payload["informes"] : [action.payload["informes"].informe]) : [],
      };
    case GET_FILTROS_PLUGINS_INFORME:
      return {
        ...state,
        isLoading: false,
        filtros: action.payload["filtros"] ? (Array.isArray(action.payload["filtros"]) ? action.payload["filtros"] : [action.payload["filtros"].filtro]) : [],
        plugins: action.payload["plugins"] ? (Array.isArray(action.payload["plugins"]) ? action.payload["plugins"] : [action.payload["plugins"].plugin_e]) : [],
        permitir_excel: action.payload["permitir_excel"],
      };
    case SET_INFORME_SELECCIONADO:
      return {
        ...state,
        isLoading: true,
        informe_seleccionado: action.payload,
        datos: [],
        filtros: [],
        plugins: [],
        numero_registros: 0,
        permitir_excel: "S",
      };
    case SET_DATOS_INFORME:
      return {
        ...state,
        isLoading: false,
        datos: action.payload.registros ? (Array.isArray(action.payload.registros) ? action.payload.registros : [action.payload.registros.registro]) : [],
        numero_registros: Number(action.payload.numero_registros),
      };
    case OBTENER_EFACTURA_MASIVA:
    case OBTENER_EFACTURA:
    case OBTENER_REPORT:
      return {
        ...state,
        isLoading: true,
      };
    case DESCARGA_EFACTURA_MASIVA:
    case DESCARGA_EFACTURA:
    case DESCARGA_REPORT:
    case GET_DATOS_INFORME_SUCCESS:
    case GET_DATOS_INFORME_FAIL:
    case GENERA_PEDIDO_INFORME_SUCCESS:
    case GENERA_PEDIDO_INFORME_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case SET_MAS_DATOS_INFORME:
      return {
        ...state,
        isLoading: false,
        datos: action.payload.registros ? (Array.isArray(action.payload.registros) ? [...state.datos, ...action.payload.registros] : [...state.datos, action.payload.registros.registro]) : [],
        numero_registros: Number(action.payload.numero_registros),
      };
    case GET_PERMISOS_MENU_INFORMES:
      return {
        ...state,
        isLoading: false,
        permisos_informes: action.payload,
      };

    default:
      return state;
  }
}
