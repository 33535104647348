import axios from "../axiosConfig";

import { FETCH_PEDIDOS_PENDIENTES, GET_PEDIDOS_PENDIENTES, SET_VER_PEDIDO_PENDIENTE } from "./types";

import { tokenConfig } from "./auth";

//////////////////////////////////////
//////////////CABECERA///////////////
/////////////////////////////////////

export const getPedidosPendientes = (id_pedido_seleccionado) => (dispatch, getState) => {
  dispatch({
    type: FETCH_PEDIDOS_PENDIENTES,
  });
  axios
    .get(`/pedidos/?pendientes=S&id_pedido_seleccionado=${id_pedido_seleccionado}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_PEDIDOS_PENDIENTES,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const setPedido = (id_pedido) => (dispatch, getState) => {
  dispatch({
    type: SET_VER_PEDIDO_PENDIENTE,
    payload: id_pedido,
  });
};
