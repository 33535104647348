import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFontAwesomeFlag } from "@fortawesome/free-brands-svg-icons";

import { changeLanguage, getLanguages } from "../../actions/languageSelector";

import i18n from "../../lang/idiomas";
import Select from "react-select";

export class LanguageSelector extends Component {
  static propTypes = {
    language: PropTypes.string,
    languages: PropTypes.array,
    isLoading: PropTypes.bool,
    changeLanguage: PropTypes.func.isRequired,
    getLanguages: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    this.props.getLanguages();
  };

  changeLang = (e) => {
    this.props.changeLanguage(e.value);
    i18n.changeLanguage(e.value.toLowerCase());
  };

  render() {
    let options = [];
    for (const elem in this.props.languages) {
      options.push({
        value: this.props.languages[elem].codigo_idioma,
        label: this.props.languages[elem].descripcion,
      });
    }

    let placeholderLang = options[options.findIndex((lang) => lang.value.toLowerCase() == this.props.language.toLowerCase())];

    let fallbackLng = options[options.findIndex((lang) => lang.value.toLowerCase() == i18n.options.fallbackLng[0].toLowerCase())];

    if (fallbackLng !== undefined && this.props.isLoading !== true) {
      fallbackLng = fallbackLng.label;
    } else {
      fallbackLng = `${i18n.t("general.cargando")}...`;
    }

    return (
      <div>
        {this.props.isLoading ? (
          <span>{i18n.t("general.cargando")}...</span>
        ) : (
          <Select options={options} onChange={this.changeLang} placeholder={placeholderLang === undefined ? fallbackLng : placeholderLang.label} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.languageSelector.language,
  languages: state.languageSelector.languages,
  isLoading: state.languageSelector.isLoading,
});

export default connect(mapStateToProps, { changeLanguage, getLanguages })(LanguageSelector);
