import React, { Component } from "react";
import PropTypes from "prop-types";
import { createMessage } from "../../actions/messages";
import { postLineaLista } from "../../actions/listasCompra";
import { connect } from "react-redux";
import { postLinea } from "../../actions/pedidos";
import AnalyticsEdisa from "../common/AnalyticsEdisa";
import Spinner from "../layout/Spinner";
import { getPrecios } from "../../actions/precio";
import i18n from "../../lang/idiomas";
import { comprobarCondiciones, cargarArrays, cargarParametros } from "../../helpers/funciones";
import { setBusqueda, getArticulos, limpiarListaArticulos, getStocksArticulos } from "../../actions/articulos";

let lineas_coincidentes = 0;
let linea_existe = false;
let timer = undefined;
let linea;
let articulos_aux = [];
let parametros_usados = ["maxim_agrup", "stock_cantidad"];
let parametros;

export class BuscadorRapido extends Component {
  state = {
    valor: "",
    isShowingContent: true,
  };

  static propTypes = {
    getArticulos: PropTypes.func.isRequired,
    limpiarListaArticulos: PropTypes.func.isRequired,
    articulos_pagina: PropTypes.number.isRequired,
    articulos_orden: PropTypes.string.isRequired,
    articulos: PropTypes.object.isRequired,
    postLineaLista: PropTypes.func.isRequired,
    postLinea: PropTypes.func.isRequired,
    getPrecios: PropTypes.func.isRequired,
    getStocksArticulos: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.limpiarListaArticulos();
    this.props.setBusqueda("");
  }

  componentWillUnmount() {
    this.props.limpiarListaArticulos();
    this.props.setBusqueda("");
  }

  componentDidUpdate(prevProps, prevState) {
    lineas_coincidentes = 0;
    if (this.props.articulos.articulos && this.props.articulos.articulos.length > 0) {
      //Comprobamos si solicitan precios grupalmente
      if (comprobarCondiciones("buscadorRapido", this.props.articulos, prevProps.articulos, "S")) {
        articulos_aux = cargarArrays("buscadorRapido", this.props.articulos, "precios", this.props.precio);
        if (articulos_aux && articulos_aux.length > 0) {
          //Solo llamamos a getPrecios si hai al menos un elemento en el array
          this.props.getPrecios(
            articulos_aux, //articulos_cantidades
            "buscadorRapido", //origen
            null, //domicilio_envio
            parametros.maxim_agrup
          );
        }
      }
      if (this.props.añadir_a === "carrito") {
        //Comprobamos si solicitan stocks grupalmente
        if (comprobarCondiciones("buscadorRapido", this.props.articulos, prevProps.articulos, "S")) {
          articulos_aux = cargarArrays("buscadorRapido", this.props.articulos, "stocks", this.props.pedido);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getStocks si hai al menos 1 elemento en el array
            this.props.getStocksArticulos(
              articulos_aux, //articulos_stock
              "buscadorRapido", //origen
              parametros.stock_cantidad, //Tipo de peticion (PorUnidades/Situacion)
              null, //lista_compra
              parametros.maxim_agrup //Maximo de peticiones agrupadas
            );
          }
        }
      }
    }
  }

  onClick = (e) => {
    if (this.props.añadir_a) {
      switch (this.props.añadir_a) {
        case "carrito":
          this.anadirCarro("P", 1, e.currentTarget.id);
          break;
        default:
          linea = {
            codigo_lista: this.props.lista.lista_detalle,
            codigo_articulo: e.currentTarget.id,
          };
          this.setState({ valor: "", isShowingContent: false });
          this.props.postLineaLista(linea);
          this.props.limpiarListaArticulos();
          this.props.setBusqueda("");
          break;
      }
    }
  };

  onBlur = (e) => {
    e.preventDefault();
    if (e.relatedTarget === null || e.currentTarget.className !== e.relatedTarget.offsetParent.className) {
      if (this.props.articulos.articulos && this.props.articulos.articulos.length > 0 && this.state.isShowingContent === true) {
        this.onClickButton();
      }
    }
  };

  onClickDisabled = (e) => {
    this.props.createMessage(i18n.t("buscador.busrpnst"), "error");
  };

  onClickButton = () => {
    if (this.state.isShowingContent) {
      this.setState({ isShowingContent: false });
    } else {
      this.setState({ isShowingContent: true });
    }
  };

  onChange = (e) => {
    if (timer) {
      clearTimeout(timer);
    }
    this.setState({ valor: e.currentTarget.value });
    this.props.limpiarListaArticulos();
    this.props.setBusqueda("");
    timer = setTimeout(() => {
      if (this.state.valor.length >= 3) {
        this.props.getArticulos(this.state.valor, this.props.articulos_pagina, this.props.articulos_orden, this.props.pedido.domicilio_envio ? this.props.pedido.domicilio_envio : "");
      }
    }, 1000);
  };

  anadirCarro = (tipo, cantidad, articulo) => {
    if (!this.props.pedido_cargando) {
      if (this.props.precio[articulo] && this.props.precio[articulo].error === false && this.props.precio[articulo].isLoading === false && this.props.precio[articulo].data) {
        let index = -1;
        index = this.props.articulos.articulos.findIndex((linea) => linea.codigo_articulo === articulo);
        if (index !== -1) {
          if (this.props.articulos.articulos[index].cantidad_minima) {
            cantidad = parseInt(this.props.articulos.articulos[index].cantidad_minima);
          }

          let cantidad_pedida = cantidad;

          let linea = {
            articulo: articulo,
            descripcion: this.props.articulos.articulos[index].descrip_comercial,
            precio_venta: this.props.precio[articulo].data.precio_neto,
            observaciones: "",
            estado_linea: "B",
            cantidad_pedida: cantidad_pedida,
            presentacion_pedido: this.props.articulos.articulos[index].presentacion_web,
            tipo_linea: tipo,
            sub_pres: "",
            sub_pres_cant: "",
            sub_pres_fc: "",
            presentacion_escogida: "",
            pres_fc: "",
          };

          this.props.postLinea(linea, this.props.hash, this.props.id_pedido_seleccionado);

          let analytics = new AnalyticsEdisa();

          analytics.registraEvento({
            modulo: "Pedido",
            tipo: "AddToCart",
            valor: articulo,
            params: {
              codigo_articulo: articulo,
              descrip_comercial: this.props.articulos.articulos[index].descrip_comercial,
              divisa: this.props.precio[articulo].data.divisa_precio,
              precio: parseFloat(this.props.netear_precio === "S" ? this.props.precio[articulo].data.precio_precio_neto : this.props.precio[articulo].data.precio_presentacion * cantidad_pedida),
            },
          });
          this.setState({ valor: "", isShowingContent: false });
          this.props.limpiarPresentacion();
        }
      }
    }
  };

  cargarBuscadorCarrito = () => {
    return (
      <div className="buscador-rapido" onBlur={this.onBlur}>
        <label htmlFor="buscador_rapido" className="label_busc_rap">
          {i18n.t("buscador.busrptit")}
        </label>
        <div className="input-buscador-rap">
          <input type="text" className="form-control inp-busc-rap" id="buscador_rapido" value={this.state.valor} placeholder="Teclee busqueda..." onChange={this.onChange} name="buscador_rapido" />
          <button
            className="btn busc-rap-btn"
            id="busc_rap_btn"
            onClick={this.onClickButton}
            disabled={this.props.articulos.articulos && this.props.articulos.articulos.length > 0 && !this.props.isLoadingListArt ? false : true}
          >
            {this.props.articulos.articulos === undefined || this.props.articulos.articulos === null || this.props.articulos.articulos.length <= 0 || this.props.isLoadingListArt ? (
              <i className="icon-e-01" />
            ) : this.state.isShowingContent ? (
              <i className="icon-e-04" />
            ) : (
              <i className="icon-e-01" />
            )}
          </button>
        </div>

        {this.props.articulos.articulos && this.props.articulos.articulos.length > 0 && !this.props.isLoadingListArt ? (
          <div className="tt-article-table listado-buscador" hidden={this.state.isShowingContent ? false : true} tabIndex={0}>
            <div className="col-12">
              {this.props.articulos.articulos.map((articulo) => {
                if (articulo.info_stock && (articulo.info_stock.stock > 0 || articulo.info_stock.stock === "S")) {
                  return (
                    <div className={"row articulo-busc-rap"} value={articulo.codigo_articulo} id={articulo.codigo_articulo} key={articulo.codigo_articulo} onClick={this.onClick}>
                      {articulo.descrip_comercial + " | " + articulo.codigo_articulo}
                    </div>
                  );
                } else {
                  return (
                    <div className={"row articulo-busc-rap-dis"} key={articulo.codigo_articulo} value={articulo.codigo_articulo} id={articulo.codigo_articulo} onClick={this.onClickDisabled}>
                      {articulo.descrip_comercial + " | " + articulo.codigo_articulo}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        ) : this.state.valor.length < 3 ? (
          <div className={"row articulo-busc-rap-helper"} id={-1}>
            {"Debe introducir un mínimo de 3 caracteres."}
          </div>
        ) : this.props.isLoading || this.props.isLoadingListArt ? (
          <div className={"row articulo-busc-rap-loader"} id={-1}>
            <Spinner showSpinner={this.props.isLoading || this.props.isLoadingListArt ? true : false} />
          </div>
        ) : (
          <div className={"row articulo-busc-rap-helper"} id={-1}>
            {i18n.t("buscador.busrpnrs")}
          </div>
        )}
      </div>
    );
  };

  cargarBuscadorListas = () => {
    return (
      <div className="buscador-rapido" onBlur={this.onBlur}>
        <label htmlFor="buscador_rapido" className="label_busc_rap">
          {i18n.t("buscador.busrptit")}
        </label>
        <div className="input-buscador-rap">
          <input type="text" className="form-control inp-busc-rap" id="buscador_rapido" value={this.state.valor} placeholder="Teclee busqueda..." onChange={this.onChange} name="buscador_rapido" />
          <button className="btn busc-rap-btn" id="busc_rap_btn" onClick={this.onClickButton} disabled={this.props.articulos.articulos && this.props.articulos.articulos.length > 0 ? false : true}>
            {this.props.articulos.articulos === undefined || this.props.articulos.articulos === null || this.props.articulos.articulos.length <= 0 ? (
              <i className="icon-e-01" />
            ) : this.state.isShowingContent ? (
              <i className="icon-e-04" />
            ) : (
              <i className="icon-e-01" />
            )}
          </button>
        </div>

        {this.props.articulos.articulos && this.props.articulos.articulos.length > 0 ? (
          <div className="tt-article-table listado-buscador" hidden={this.state.isShowingContent ? false : true} tabIndex={0}>
            <div className="col-12">
              {this.props.articulos.articulos.map((articulo) => {
                linea_existe = false;
                this.props.lista.lineas_lista_detalle.forEach((linea) => {
                  if (linea.codigo_articulo === articulo.codigo_articulo) {
                    lineas_coincidentes = lineas_coincidentes + 1;
                    linea_existe = true;
                  }
                });
                if (linea_existe === false) {
                  return (
                    <div className={"row articulo-busc-rap"} value={articulo.codigo_articulo} id={articulo.codigo_articulo} key={articulo.codigo_articulo} onClick={this.onClick}>
                      {articulo.descrip_comercial + " | " + articulo.codigo_articulo}
                    </div>
                  );
                } else if (this.props.articulos.articulos.length === 1) {
                  return (
                    <div className={"row articulo-busc-rap-dis"} id={-1}>
                      {i18n.t("buscador.busrplil")}
                    </div>
                  );
                } else if (this.props.articulos.articulos.length === lineas_coincidentes) {
                  return (
                    <div className={"row articulo-busc-rap-dis"} id={-1}>
                      {i18n.t("buscador.busrpl2l")}
                    </div>
                  );
                } else {
                  return "";
                }
              })}
            </div>
          </div>
        ) : this.state.valor.length < 3 ? (
          <div className={"row articulo-busc-rap-helper"} id={-1}>
            {i18n.t("buscador.busrpm3c")}
          </div>
        ) : this.props.isLoading ? (
          <div className={"row articulo-busc-rap-loader"} id={-1}>
            <Spinner showSpinner={this.props.isLoading} />
          </div>
        ) : (
          <div className={"row articulo-busc-rap-helper"} id={-1}>
            {i18n.t("buscador.busrpnrs")}
          </div>
        )}
      </div>
    );
  };

  render() {
    parametros = cargarParametros(parametros_usados, this.props.portal.parametros_ecom, "GEN");
    switch (this.props.añadir_a) {
      case "carrito":
        return this.cargarBuscadorCarrito();
      case "listas":
        return this.cargarBuscadorListas();
      default:
        return this.cargarBuscadorListas();
    }
  }
}

const mapStateToProps = (state) => ({
  portal: state.portal.portal,
  lista: state.listasCompra,
  articulos: state.listadoArticulos.articulos,
  articulos_pagina: state.listadoArticulos.articulos_pagina,
  articulos_orden: state.listadoArticulos.articulos_orden,
  isLoading: state.listadoArticulos.articulos.isLoading,
  isLoadingListArt: state.listadoArticulos.isLoading,
  presentacion: state.presentaciones.presentacion,
  subpresentacion: state.subpresentaciones.subpresentacion,
  precio: state.precio.precio,
  pedido: state.pedidos.pedido,
  pedido_cargando: state.pedidos.isLoading,
});

export default connect(mapStateToProps, {
  getArticulos,
  limpiarListaArticulos,
  setBusqueda,
  postLineaLista,
  postLinea,
  getPrecios,
  getStocksArticulos,
  createMessage,
})(BuscadorRapido);
