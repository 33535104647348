import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export const ProtectedRoute = ({ isAllowed, redirectPath = "/login", children }) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export const ordenarMenu = (a, b) => {
  if (a.orden < b.orden) {
    return -1;
  } else if (a.orden > b.orden) {
    return 1;
  } else {
    return 0;
  }
};

export const ordenarLista = (a, b) => {
  if (a.codigo_lista < b.codigo_lista) {
    return -1;
  } else if (a.codigo_lista > b.codigo_lista) {
    return 1;
  } else {
    return 0;
  }
};

export const b64DecodeUnicode = (str) => {
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
};

export const validaEmail = (email) => {
  return email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
};

export const validaPass = (pass) => {
  /*
  6 tamaño mínimo
  1 mayúscula mínimo
  1 minúscula mínimo
  1 número mínimo
  1 símbolo mínimo(*+-_.:,;!¡?¿$&#)
  */

  let regexp = /^(?=(?:[^a-z]*[a-z]){1,})(?=(?:[^A-Z]*[A-Z]){1,})(?=(?:[^0-9]*[0-9]){1,})(?=(?:.*[\*\+\-\_\.\:\,\;\!\¡\?\¿\$\&\#]){1,}).{6,}$/;

  return regexp.test(pass);
};

export const obtenerValorParametro = (lista_parametros, codigo_parametro, codigo_origen) => {
  let parametros_filtrados;
  let valor_esp;

  if (lista_parametros && lista_parametros.length > 0) {
    //Filtramos la lista
    parametros_filtrados = lista_parametros.filter(
      (parametro) =>
        (parametro.modulo === codigo_origen && parametro.clave === codigo_parametro) || (parametro.modulo === "GEN" && parametro.clave === codigo_parametro)
    );

    //Si existen valores
    if (parametros_filtrados && parametros_filtrados.length > 0) {
      //Si solo existe uno lo retornamos
      if (parametros_filtrados.length === 1) {
        return parametros_filtrados[0].valor;
      } else {
        //Si existen más  recorremos el array
        parametros_filtrados.forEach(function (value, index, array) {
          if (value.modulo === codigo_origen && value.clave === codigo_parametro) {
            //Seteamos el valor especifico
            valor_esp = value.valor;
          }
        });
        return valor_esp;
      }
    } else {
      // Si no encuentra valores retorna null
      return null;
    }
  } else {
    return null;
  }
};

export const comprobarCondiciones = (p_origen, p_array, p_array_prev, p_valorGrupal, p_aux) => {
  let pedir_grupos = false;
  switch (p_origen) {
    case "equivalentes":
      //p_array => this.props.equivalentes
      //p_array_prev => prevProps.equivalentes
      //p_valorGrupal => precioGrupal/stockGrupal
      if (
        p_array_prev &&
        p_array_prev.equivalentes &&
        p_array_prev.equivalentes.length > 0 &&
        p_array &&
        p_array.equivalentes &&
        p_array.equivalentes.length > 0 &&
        p_array.equivalentes.length === p_array_prev.equivalentes.length
      ) {
        for (let x = 0; x < p_array.equivalentes.length; x++) {
          if (
            p_array_prev.equivalentes[x].codigo_articulo !== p_array.equivalentes[x].codigo_articulo ||
            p_array_prev.equivalentes[x].isLoading !== p_array.equivalentes[x].isLoading
          ) {
            pedir_grupos = true;
          }
        }
      } else {
        pedir_grupos = true;
      }
      return !!(p_array && p_array.equivalentes.length > 0 && p_valorGrupal && p_valorGrupal === "S" && pedir_grupos === true);
    case "listasCompra":
      //p_array => this.props.lista
      //p_array_prev => prevProps.lista
      //p_valorGrupal => precioGrupal/stockGrupal
      //p_aux => this.props.lineas_lista_detalle
      if (
        p_array_prev &&
        p_array_prev.lista_detalle &&
        p_array_prev.lineas_lista_detalle &&
        p_array &&
        p_array.lista_detalle &&
        p_array.lineas_lista_detalle &&
        p_array.lineas_lista_detalle.length !== p_array_prev.lista_detalle.length
      ) {
        pedir_grupos = true;
      }
      return !!(p_array.lista_detalle && p_aux && p_aux.length > 0 && p_valorGrupal && p_valorGrupal === "S" && pedir_grupos === true);
    case "carrito":
      //p_array => this.props.pedido
      //p_array_prev => prevProps.pedido
      //p_valorGrupal => precio_grupal
      //p_aux => this.props.precio

      if (
        p_array_prev &&
        p_array_prev.lineas &&
        p_array_prev.lineas.length > 0 &&
        p_array &&
        p_array.lineas &&
        p_array.lineas.length > 0 &&
        p_array.lineas.length === p_array_prev.lineas.length &&
        p_aux &&
        p_aux.length > 0
      ) {
        let index = -1;
        for (const linea of p_array.lineas) {
          index = p_array_prev.lineas.findIndex((articulo) => articulo.articulo === linea.articulo);

          if (index !== -1) {
            pedir_grupos = true;
          }
        }
      } else {
        pedir_grupos = true;
      }
      return !!(p_array && p_array.lineas && p_array.lineas.length > 0 && p_valorGrupal && p_valorGrupal === "S" && pedir_grupos === true);
    case "pedidos_anteriores":
      if (
        p_array_prev.lineas_pedido_anterior_detalle &&
        p_array_prev.lineas_pedido_anterior_detalle.length > 0 &&
        p_array.lineas_pedido_anterior_detalle &&
        p_array.lineas_pedido_anterior_detalle.length > 0 &&
        p_array.lineas_pedido_anterior_detalle.length === p_array_prev.lineas_pedido_anterior_detalle.length
      ) {
        for (let x = 0; x < p_array.lineas_pedido_anterior_detalle.length; x++) {
          if (p_array_prev.lineas_pedido_anterior_detalle[x].articulo !== p_array.lineas_pedido_anterior_detalle[x].articulo) {
            pedir_grupos = true;
          }
        }
      } else {
        pedir_grupos = true;
      }
      return !!(p_array && p_array.lineas_pedido_anterior_detalle.length > 0 && p_valorGrupal && p_valorGrupal === "S" && pedir_grupos === true);
    case "pedidos_pendientes":
      if (
        p_array_prev.lineas_pedido_pendiente_detalle &&
        p_array_prev.lineas_pedido_pendiente_detalle.length > 0 &&
        p_array.lineas_pedido_pendiente_detalle &&
        p_array.lineas_pedido_pendiente_detalle.length > 0 &&
        p_array.lineas_pedido_pendiente_detalle.length === p_array_prev.lineas_pedido_pendiente_detalle.length
      ) {
        for (let x = 0; x < p_array.lineas_pedido_pendiente_detalle.length; x++) {
          if (p_array_prev.lineas_pedido_pendiente_detalle[x].articulo !== p_array.lineas_pedido_pendiente_detalle[x].articulo) {
            pedir_grupos = true;
          }
        }
      } else {
        pedir_grupos = true;
      }
      return !!(p_array && p_array.lineas_pedido_pendiente_detalle.length > 0 && p_valorGrupal && p_valorGrupal === "S" && pedir_grupos === true);
    default:
      if (
        p_array_prev &&
        p_array_prev.articulos.length > 0 &&
        p_array &&
        p_array.articulos.length > 0 &&
        p_array.articulos.length === p_array_prev.articulos.length
      ) {
        for (let x = 0; x < p_array.articulos.length; x++) {
          if (p_array_prev.articulos[x].codigo_articulo !== p_array.articulos[x].codigo_articulo) {
            pedir_grupos = true;
          }
        }
      } else {
        pedir_grupos = true;
      }
      return !!(p_array && p_array.articulos.length > 0 && p_valorGrupal && p_valorGrupal === "S" && pedir_grupos === true);
  }
};

export const cargarArrays = (p_origen, p_array, p_tipo, p_aux) => {
  let articulos_aux = [];
  switch (p_origen) {
    case "equivalentes":
      switch (p_tipo) {
        case "precios":
          for (let actual of p_array.equivalentes) {
            for (let articulo_act of actual.articulos) {
              if (p_array[articulo_act.codigo_articulo] === undefined || p_array[articulo_act.codigo_articulo] === null) {
                //Solo añadimos el articulo si no está en el estado
                articulos_aux.push({
                  codigo_articulo: articulo_act.codigo_articulo,
                  cantidad: 1,
                });
              }
            }
          }
          return articulos_aux;
        case "stocks":
          articulos_aux.length = 0;
          for (let actual of p_array.equivalentes) {
            for (let articulo_act of actual.articulos) {
              if (articulo_act.info_stock === undefined || articulo_act.info_stock === null) {
                //Solo añadimos el articulo si no está en el estado
                articulos_aux.push({
                  codigo_articulo: articulo_act.codigo_articulo,
                  presentacion: articulo_act.presentacion_web,
                  codigo_almacen: p_aux.almacen,
                  situacion: "",
                });
              }
            }
          }
          return articulos_aux;
        default:
          break;
      }
      break;
    case "listasCompra":
      let index = -1;
      switch (p_tipo) {
        case "precios":
          //Buscamos la lista en el array de listas
          index = p_array.listas_compra.findIndex((lista) => lista.codigo_lista === p_array.lista_detalle);
          if (index !== -1) {
            for (let actual of p_array.lineas_lista_detalle) {
              if (actual.codigo_lista === p_array.lista_detalle) {
                if (!p_array.isLoading) {
                  if (p_aux[actual.codigo_articulo] === undefined || p_aux[actual.codigo_articulo] === null) {
                    //Solo añadimos el articulo si no está en el estado
                    articulos_aux.push({
                      codigo_articulo: actual.codigo_articulo,
                      cantidad: actual.cantidad_pedida,
                    });
                  }
                }
              }
            }
          }
          return articulos_aux;
        case "stocks":
          //Buscamos la lista en el array de listas
          index = p_array.listas_compra.findIndex((lista) => lista.codigo_lista === p_array.lista_detalle);

          if (index !== -1) {
            //Recorremos el array de lineas de la lista
            for (let actual of p_array.lineas_lista_detalle) {
              if (actual.codigo_lista === p_array.lista_detalle) {
                if (!p_array.isLoading) {
                  if (!actual.stock_articulo || actual.stock_articulo === []) {
                    articulos_aux.push({
                      codigo_articulo: actual.codigo_articulo,
                      presentacion: actual.presentacion_pedido,
                      codigo_almacen: p_aux.almacen,
                      situacion: "",
                    });
                  }
                }
              }
            }
          }

          return articulos_aux;
        default:
          break;
      }
      break;
    case "carrito":
      articulos_aux.length = 0;
      for (let actual of p_array.lineas) {
        if (p_aux[actual.articulo] === undefined || p_aux[actual.articulo] === null) {
          //Solo añadimos el articulo si no está en el estado
          articulos_aux.push({
            codigo_articulo: actual.articulo,
            cantidad: actual.cantidad_pedida,
          });
        }
      }
      return articulos_aux;
    case "pedidos_anteriores":
    case "pedidos_pendientes":
      articulos_aux.length = 0;
      for (let actual of p_array) {
        if (p_aux[actual.articulo] === undefined || p_aux[actual.articulo] === null) {
          //Solo añadimos el articulo si no está en el estado
          articulos_aux.push({
            codigo_articulo: actual.articulo,
            cantidad: actual.cantidad_pedida,
          });
        }
      }
      return articulos_aux;
    default:
      switch (p_tipo) {
        case "precios":
          articulos_aux.length = 0;
          for (let actual of p_array.articulos) {
            if (p_aux[actual.codigo_articulo] === undefined || p_aux[actual.codigo_articulo] === null) {
              //Solo añadimos el articulo si no está en el estado
              articulos_aux.push({
                codigo_articulo: actual.codigo_articulo,
                cantidad: actual.cantidad_minima ? Number(actual.cantidad_minima) : 1,
              });
            }
          }
          return articulos_aux;
        case "stocks":
          for (let actual of p_array.articulos) {
            if (actual.info_stock === undefined || actual.info_stock === null) {
              //Solo añadimos el articulo si no está en el estado
              articulos_aux.push({
                codigo_articulo: actual.codigo_articulo,
                presentacion: actual.presentacion_web,
                codigo_almacen: p_aux.almacen,
                situacion: "",
              });
            }
          }
          return articulos_aux;
        default:
          break;
      }
      break;
  }
};

export const cargarParametros = (p_parametros, p_lista_parametros, p_origen) => {
  let v_parametros = {};
  for (let parametro in p_parametros) {
    switch (p_parametros[parametro]) {
      case "andr_a":
        v_parametros.andr_a = obtenerValorParametro(p_lista_parametros, "ANDR_A", p_origen);
        break;
      case "andr_desc":
        v_parametros.andr_desc = obtenerValorParametro(p_lista_parametros, "ANDR_DESC", p_origen);
        break;
      case "btn_presup":
        v_parametros.btn_presup = obtenerValorParametro(p_lista_parametros, "BTN_PRESUP", p_origen);
        break;
      case "btn_subs":
        v_parametros.btn_subs = obtenerValorParametro(p_lista_parametros, "BTN_SUBS", p_origen);
        break;
      case "bus_avanz":
        v_parametros.bus_avanz = obtenerValorParametro(p_lista_parametros, "BUS_AVANZ", p_origen);
        break;
      case "busc_rapido":
        v_parametros.busc_rapido = obtenerValorParametro(p_lista_parametros, "BUSC_RAPIDO", p_origen);
        break;
      case "img_cargadas":
        v_parametros.img_cargadas = obtenerValorParametro(p_lista_parametros, "IMG_CARGADAS", p_origen);
        break;
      case "maxim_agrup":
        v_parametros.maxim_agrup = obtenerValorParametro(p_lista_parametros, "MAXIM_AGRUP", p_origen);
        break;
      case "mostrar_iva":
        v_parametros.mostrar_iva = obtenerValorParametro(p_lista_parametros, "MOSTRAR_IVA", p_origen);
        break;
      case "msg_tar_env":
        v_parametros.msg_tar_env = obtenerValorParametro(p_lista_parametros, "MSG_TAR_ENV", p_origen);
        break;
      case "tipo_msg_tar_env":
        v_parametros.tipo_msg_tar_env = obtenerValorParametro(p_lista_parametros, "TIPO_MSG_TAR_ENV", p_origen);
        break;
      case "tipo_precio":
        v_parametros.tipo_precio = obtenerValorParametro(p_lista_parametros, "TIPO_PRECIO", p_origen);
        break;
      case "stock_cantidad":
        v_parametros.stock_cantidad = obtenerValorParametro(p_lista_parametros, "STOCK_CANTIDAD", p_origen);
        break;
      case "def_input":
        v_parametros.def_input = obtenerValorParametro(p_lista_parametros, "DEF_INPUT", p_origen);
        break;
      case "cant_def":
        v_parametros.cant_def = obtenerValorParametro(p_lista_parametros, "CANT_DEF", p_origen);
        break;
      case "resumen_simple":
        v_parametros.resumen_simple = obtenerValorParametro(p_lista_parametros, "RESUMEN_SIMPLE", p_origen);
        break;
      default:
        break;
    }
  }
  return v_parametros;
};

export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export function descargarFichero(nombre, fichero, extension = "") {
  const binary = atob(fichero.replace(/\s/g, ""));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);

  for (let i = 0; i < len; i += 1) {
    view[i] = binary.charCodeAt(i);
  }
  // create the blob object with content-type "application/pdf"
  const blob = new Blob([view], { type: "application/octet-stream" });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = url;
  a.download = nombre;

  a.click();
}

export function programaActivo(menus, programa) {
  return menus.some((menu) => menu.programa === programa || (menu.hijos && menu.hijos.length > 0 && menu.hijos.some((hijo) => hijo.programa === programa)));
}
