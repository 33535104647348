import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getTiposExpedientes } from "../../actions/expedientes";
import NuevoExpediente from "./NuevoExpediente";
import ExpedientesCreados from "./ExpedientesCreados";
import Spinner from "../layout/Spinner";
import { Eye, Plus } from "phosphor-react";
import i18n from "../../lang/idiomas";

export class Expedientes extends Component {
  state = {
    accion: "",
    tipo_expediente_seleccionado: "",
  };

  static propTypes = {
    tipos_expedientes: PropTypes.array.isRequired,
    expedientes: PropTypes.array.isRequired,
    getTiposExpedientes: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getTiposExpedientes();
  }

  mostrarNuevo = (tipo_expediente) => {
    this.setState({
      tipo_expediente_seleccionado: tipo_expediente,
      accion: "nuevo",
    });
  };

  mostrarListado = (tipo_expediente) => {
    this.setState({
      tipo_expediente_seleccionado: tipo_expediente,
      accion: "listar",
    });
  };

  render() {
    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent" className="expedientes">
            <div className="container-indent">
              <div className="container">
                <h1 className="tt-title-subpages noborder">{i18n.t("expedientes.extiexs")}</h1>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    {this.props.expedientes_loading === true ? (
                      <Spinner showSpinner={this.props.expedientes_loading} />
                    ) : this.props.tipos_expedientes !== undefined && this.props.tipos_expedientes.length === 0 ? (
                      " No se han encontrado expedientes "
                    ) : (
                      <Fragment>
                        <div className="tt-shopcart-table tipos_expedientes_desctop row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row titulos">
                              <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("expedientes.extiexs")}</div>
                              <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("expedientes.exdescr")}</div>
                              <div className="col-sm-12 col-md-6 col-lg-6">{i18n.t("expedientes.exaccnes")}</div>
                            </div>
                            {this.props.tipos_expedientes !== undefined && this.props.tipos_expedientes.length > 0 ? (
                              this.props.tipos_expedientes.map((tipo_expediente) => {
                                return (
                                  <div className="row" key={tipo_expediente.tipo_expediente}>
                                    <div className="col-sm-12 col-md-3 col-lg-3">{tipo_expediente.tipo_expediente}</div>
                                    <div className="col-sm-12 col-md-3 col-lg-3">{tipo_expediente.descripcion}</div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                      <button
                                        className="btn btn-primary"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.mostrarNuevo(e.currentTarget.dataset.tipoExpediente);
                                        }}
                                        data-tipo-expediente={tipo_expediente.tipo_expediente}
                                      >
                                        <Plus size={22} weight={"bold"} />
                                      </button>
                                      <button
                                        className="btn btn-primary"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.mostrarListado(e.currentTarget.dataset.tipoExpediente);
                                        }}
                                        data-tipo-expediente={tipo_expediente.tipo_expediente}
                                      >
                                        <Eye size={22} weight={"regular"} />
                                      </button>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <Spinner showSpinner={this.props.expedientes_loading} />
                            )}
                          </div>
                        </div>

                        <div className="tt-shopcart-table tipos_expedientes_mobile row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            {this.props.tipos_expedientes !== undefined && this.props.tipos_expedientes.length > 0 ? (
                              this.props.tipos_expedientes.map((tipo_expediente) => {
                                return (
                                  <div className="row" key={tipo_expediente.tipo_expediente}>
                                    <div className="col-12">
                                      <div className="row ">
                                        <div className="col-6 titulos">{i18n.t("expedientes.extiexs")}</div>
                                        <div className="col-6">{tipo_expediente.tipo_expediente}</div>
                                      </div>

                                      <div className="row ">
                                        <div className="col-6 titulos">{i18n.t("expedientes.exdescr")}</div>
                                        <div className="col-6">{tipo_expediente.descripcion}</div>
                                      </div>
                                      <div className="row ">
                                        <div className="col-6 titulos">{i18n.t("expedientes.exaccnes")}</div>
                                        <div className="col-6">
                                          <div className="row">
                                            <div className="col-6">
                                              <button
                                                className="btn btn-primary"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.mostrarNuevo(e.currentTarget.dataset.tipoExpediente);
                                                }}
                                                data-tipo-expediente={tipo_expediente.tipo_expediente}
                                              >
                                                <Plus size={22} weight={"bold"} />
                                              </button>
                                            </div>
                                            <div className="col-6">
                                              <button
                                                className="btn btn-primary"
                                                data-tipo-expediente={tipo_expediente.tipo_expediente}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.mostrarListado(e.currentTarget.dataset.tipoExpediente);
                                                }}
                                              >
                                                <Eye size={22} weight={"regular"} />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <Spinner showSpinner={this.props.expedientes_loading} />
                            )}
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {this.state.accion && this.state.accion === "nuevo" ? (
              <NuevoExpediente tipo_expediente={this.state.tipo_expediente_seleccionado} listar={this.mostrarListado} origen={"nuevo expediente"} />
            ) : (
              ""
            )}

            {this.state.accion && this.state.accion === "listar" ? <ExpedientesCreados tipo_expediente={this.state.tipo_expediente_seleccionado} /> : ""}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  tipos_expedientes: state.expedientes.tipos_expedientes,
  expedientes_loading: state.expedientes.isLoading,
});

export default connect(mapStateToProps, {
  getTiposExpedientes,
})(Expedientes);
