import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getPedidoHabitual } from "../../actions/pedidoHabitual";
import ListadoArticulos from "./ListadoArticulos";

export class PedidoHabitual extends Component {
  static propTypes = {
    getPedidoHabitual: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    let ancla = window.document.getElementById("cabecera-principal");

    if (ancla) {
      ancla.scrollIntoView({ behavior: "smooth" });
    }
  }

  render() {
    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent">
            <div className="container-indent">
              <div className="container-fluid-custom container-fluid-custom-mobile-padding">
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-xl-12">
                    <ListadoArticulos origen="pedido_habitual" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  portal: state.portal.portal,
  pedidoHabitual: state.listadoArticulos.pedidoHabitual,
});

export default connect(mapStateToProps, { getPedidoHabitual })(PedidoHabitual);
