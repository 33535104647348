import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getFormasEnvio, setFormaEnvio } from "../../actions/formasEnvio";
import i18n from "../../lang/idiomas";

export class FormasEnvio extends Component {
  static propTypes = {
    getFormasEnvio: PropTypes.func.isRequired,
    setFormaEnvio: PropTypes.func.isRequired,
    formas_envio: PropTypes.array.isRequired,
    forma_envio: PropTypes.object.isRequired,
    pedido_cargando: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.getFormasEnvio();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.pedido_cargando !== this.props.pedido_cargando || JSON.stringify(prevProps.formas_envio) !== JSON.stringify(this.props.formas_envio)) && !this.props.pedido_cargando) {
      if (this.props.formas_envio && this.props.formas_envio.length === 1) {
        this.props.setFormaEnvio(this.props.formas_envio[0]);
      }

      if (this.props.formas_envio && this.props.formas_envio.length > 1 && this.props.pedido.forma_envio && this.props.pedido.forma_envio !== "") {
        const forma_env = this.props.formas_envio.filter((form_env) => form_env.forma_envio === this.props.pedido.forma_envio);

        this.props.setFormaEnvio(forma_env[0]);
      }
    }
  }

  onchange = (e) => {
    if (e.target.value !== "") {
      const forma_env = this.props.formas_envio.filter((form_env) => form_env.forma_envio === e.target.value);

      this.props.setFormaEnvio(forma_env[0]);
    }
  };

  render() {
    return (
      <Fragment>
        <div className="tt-shopcart-box">
          <h4 className="tt-title">{i18n.t("formas.formdenv")}</h4>
          <p>{i18n.t("formas.selforen")}</p>
          <form className="form-default">
            <div className="form-group">
              <label htmlFor="forma_envio">
                {i18n.t("formas.forenmay")} <sup>*</sup>
              </label>
              <select className="form-control" name="forma_envio" onChange={this.onchange} disabled={this.props.pedido_cargando || this.props.pedidoGuardado ? true : false}>
                <option key="0" value="">
                  {i18n.t("formas.selfen")}
                </option>
                {this.props.formas_envio.map((forma_envio, index) => {
                  if (forma_envio.forma_envio === this.props.forma_envio.forma_envio) {
                    return (
                      <option key={index} value={forma_envio.forma_envio} selected>
                        {forma_envio.descripcion}
                      </option>
                    );
                  } else {
                    return (
                      <option key={index} value={forma_envio.forma_envio}>
                        {forma_envio.descripcion}
                      </option>
                    );
                  }
                })}
              </select>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  formas_envio: state.formasEnvio.formas_envio,
  forma_envio: state.formasEnvio.forma_envio,
  pedido_cargando: state.pedidos.isLoading,
  pedido: state.pedidos.pedido,
});

export default connect(mapStateToProps, { getFormasEnvio, setFormaEnvio })(FormasEnvio);
