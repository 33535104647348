import axios from "../axiosConfig";
import { createMessage } from "./messages";
import {
  GET_TIPOS_EXPEDIENTES,
  GET_TIPOS_EXPEDIENTES_SUCCESS,
  GET_TIPOS_EXPEDIENTES_FAILURE,
  GET_TIPOS_EXPEDIENTES_CAMPOS,
  GET_EXPEDIENTES,
  GET_EXPEDIENTES_SUCCESS,
  GET_EXPEDIENTES_FAILURE,
  FETCH_TIPOS_EXPEDIENTES,
  FETCH_TIPOS_EXPEDIENTES_CAMPOS,
  FETCH_EXPEDIENTES,
  SEND_EXPEDIENTE,
  SEND_EXPEDIENTE_SUCCESS,
  SEND_EXPEDIENTE_FAILURE,
  USER_UNAUTHORIZED,
  GET_LINEAS_EXPEDIENTE,
  GET_LINEAS_EXPEDIENTE_SUCCESS,
  GET_LINEAS_EXPEDIENTE_FAILURE,
  SHOW_ALERT,
  GET_OBSERVACIONES_EXPEDIENTE,
  GET_OBSERVACIONES_EXPEDIENTE_SUCCESS,
  GET_OBSERVACIONES_EXPEDIENTE_FAILURE,
  SEND_OBSERVACIONES_EXPEDIENTE,
  SEND_OBSERVACIONES_EXPEDIENTE_SUCCESS,
  SEND_OBSERVACIONES_EXPEDIENTE_FAILURE,
  MARCAR_LEIDOS,
  MARCAR_LEIDOS_SUCCESS,
  MARCAR_LEIDOS_FAILURE,
  GET_ARCHIVED_CONVERSATIONS,
  GET_ARCHIVED_CONVERSATIONS_SUCCESS,
  GET_ARCHIVED_CONVERSATIONS_FAILURE,
  GET_DELETED_CONVERSATIONS,
  GET_DELETED_CONVERSATIONS_SUCCESS,
  GET_DELETED_CONVERSATIONS_FAILURE,
  ARCHIVE_CONVERSATION,
  DELETE_CONVERSATION,
  ARCHIVE_CONVERSATION_FAILURE,
  ARCHIVE_CONVERSATION_SUCCESS,
  DELETE_CONVERSATION_FAILURE,
  DELETE_CONVERSATION_SUCCESS,
  GET_LOV_EXPEDIENTE,
  GET_LOV_EXPEDIENTE_SUCCESS,
  GET_LOV_EXPEDIENTE_FAIL,
  NEW_EXPEDIENTE,
  NEW_EXPEDIENTE_SUCCESS,
  SET_TIPO_EXPEDIENTE,
  SET_EXPEDIENTE,
  CERRAR_EXPEDIENTE,
  CERRAR_EXPEDIENTE_SUCCESS,
  LIMPIAR_FILTROS_EXPEDIENTES,
  SET_ORDEN_EXPEDIENTES,
  SET_MOSTRAR_EXPEDIENTES,
  GET_DATOS_FICHEROS_EXPEDIENTE,
  GET_DATOS_FICHEROS_EXPEDIENTE_SUCCESS,
  OBTENER_FICHERO_EXPEDIENTE,
  DESCARGAR_FICHERO_EXPEDIENTE,
  GET_MAS_EXPEDIENTES,
  VALIDAR_CAMPO,
  VALIDAR_CAMPO_SUCCESS,
  SET_VALOR_CAMPO_EXPEDIENTE,
  GET_TIPOS_EXPEDIENTES_FAIL,
  REINICIA_LV,
} from "./types";

import { tokenConfig } from "./auth";
import { descargarFichero } from "../helpers/funciones";

export const getTiposExpedientes = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_TIPOS_EXPEDIENTES,
  });
  axios
    .get(`/tiposexpedientes/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_TIPOS_EXPEDIENTES,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getTiposExpedientesCampos =
  (expediente = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_TIPOS_EXPEDIENTES_CAMPOS,
    });
    axios
      .get(`/expedienteslisa/getExpedienteTipo/?tipo=${expediente.tipo_expediente}&id_pers=${expediente.id_pers}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_TIPOS_EXPEDIENTES_CAMPOS,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

export const getExpedientes =
  (search = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_EXPEDIENTES,
    });
    axios
      .get(`/expedientes/?search=${search}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_EXPEDIENTES,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

export const nuevoExpediente = (datos_exp) => (dispatch, getState) => {
  const body = JSON.stringify({ datos: datos_exp });
  dispatch({
    type: NEW_EXPEDIENTE,
  });

  axios
    .post(`/expedienteslisa/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: NEW_EXPEDIENTE_SUCCESS,
        payload: res.data,
        expediente: datos_exp,
      });
      dispatch(createMessage("Operacion realizada correctamente", "success"));
    })
    .catch((err) => {
      console.log(err);
      dispatch(createMessage(err.response.data, "error"));
    });
};

//NUEVOS DE LA APP
export const sendConversation = (expediente) => async (dispatch, getState) => {
  dispatch({ type: SEND_EXPEDIENTE });

  let config = tokenConfig(getState);

  let body = JSON.stringify(expediente);
  axios
    .post("/expedientes/createconversation/", body, config)
    .then((res) => {
      createMessage(res.data, "success");
      dispatch({
        type: SEND_EXPEDIENTE_SUCCESS,
        payload: { expediente: res.data },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch(createMessage(err.response.data, "error"));
    });
};

export const getLineasExpediente = (numero_expediente) => async (dispatch, getState) => {
  dispatch({ type: GET_LINEAS_EXPEDIENTE });

  let config = tokenConfig(getState);

  axios
    .get(`/expedienteslin/?exp=${numero_expediente}`, config)
    .then((res) => {
      dispatch({
        type: GET_LINEAS_EXPEDIENTE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getObservacionesExpediente = (numero_expediente) => async (dispatch, getState) => {
  dispatch({ type: GET_OBSERVACIONES_EXPEDIENTE });

  let config = tokenConfig(getState);

  axios
    .get(`/expedienteslinobs/?exp=${numero_expediente}`, config)
    .then((res) => {
      dispatch({
        type: GET_OBSERVACIONES_EXPEDIENTE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const sendObservacionesExpediente = (numero_expediente, observacion) => async (dispatch, getState) => {
  dispatch({ type: SEND_OBSERVACIONES_EXPEDIENTE });

  let config = tokenConfig(getState);

  let body = JSON.stringify({
    observaciones: observacion,
    exp: numero_expediente,
  });

  axios
    .post(`/expedienteslinobs/`, body, config)
    .then((res) => {
      dispatch({
        type: SEND_OBSERVACIONES_EXPEDIENTE_SUCCESS,
        payload: { observacion_expediente: res.data },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const marcarLeidos = (observaciones_leidas) => async (dispatch, getState) => {
  dispatch({ type: MARCAR_LEIDOS });

  let config = tokenConfig(getState);
  let body = JSON.stringify({ mensajes_leidos: observaciones_leidas });

  await axios
    .post("/expedienteslinobs/marcarleidos/", body, config)
    .then((res) => {
      dispatch({
        type: MARCAR_LEIDOS_SUCCESS,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteConversation = (numero_expediente) => async (dispatch, getState) => {
  dispatch({ type: DELETE_CONVERSATION });

  let config = tokenConfig(getState);

  let body = JSON.stringify({ numero_expediente: numero_expediente });

  await axios
    .post("/expedientes/borrar/", body, config)
    .then((res) => {
      dispatch({
        type: DELETE_CONVERSATION_SUCCESS,
        payload: { numero_expediente: numero_expediente },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const archiveConversation = (numero_expediente) => async (dispatch, getState) => {
  dispatch({ type: ARCHIVE_CONVERSATION });

  let config = tokenConfig(getState);

  let body = JSON.stringify({ numero_expediente: numero_expediente });

  await axios
    .post("/expedientes/archivar/", body, config)
    .then((res) => {
      dispatch({
        type: ARCHIVE_CONVERSATION_SUCCESS,
        payload: { numero_expediente: numero_expediente },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getArchivedConversations = () => async (dispatch, getState) => {
  dispatch({ type: GET_ARCHIVED_CONVERSATIONS });

  let config = tokenConfig(getState);

  await axios
    .get("/expedientes/archivados/", config)
    .then((res) => {
      dispatch({
        type: GET_ARCHIVED_CONVERSATIONS_SUCCESS,
        payload: { expedientes_archivados: res.data },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getDeletedConversations = () => async (dispatch, getState) => {
  dispatch({ type: GET_DELETED_CONVERSATIONS });

  let config = tokenConfig(getState);

  await axios
    .get("/expedientes/borrados/", config)
    .then((res) => {
      dispatch({
        type: GET_DELETED_CONVERSATIONS_SUCCESS,
        payload: { expedientes_borrados: res.data },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getLOVExpediente =
  (expediente, codigo_lov, campo, filtros = []) =>
  (dispatch, getState) => {
    dispatch({
      type: GET_LOV_EXPEDIENTE,
      tipo_expediente: expediente,
      codigo_lov: codigo_lov,
    });

    const body = JSON.stringify({
      expediente,
      codigo_lov,
      campo,
      filtros,
    });

    axios
      .post("/expedienteslisa/getLOV/", body, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_LOV_EXPEDIENTE_SUCCESS,
          tipo_expediente: expediente,
          codigo_lov: codigo_lov,
          payload: { lov: res.data },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LOV_EXPEDIENTE_FAIL,
          tipo_expediente: expediente,
          codigo_lov: codigo_lov,
        });
        if (err.response) {
          dispatch(createMessage(err.response.data, "error"));
        } else {
          dispatch(createMessage("Error al solicitar la lista, por favor intentelo denuevo.", "error"));
        }
      });
  };
/* UNIFERSA */

export const getMenuExpedientes = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_TIPOS_EXPEDIENTES,
  });
  axios
    .get(`/menuexpedientes/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_TIPOS_EXPEDIENTES,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_TIPOS_EXPEDIENTES_FAIL
      });
    });
};

export const setTipoExpediente = (tipo) => (dispatch, getState) => {
  dispatch({
    type: SET_TIPO_EXPEDIENTE,
    payload: tipo,
  });
};

export const setExpediente = (expediente) => (dispatch, getState) => {
  dispatch({
    type: SET_EXPEDIENTE,
    payload: expediente,
  });
};

export const cerrarExpediente = (expediente) => (dispatch, getState) => {
  dispatch({
    type: CERRAR_EXPEDIENTE,
  });
  const body = JSON.stringify({
    expediente,
  });
  axios
    .put(`/expedienteslisa/${expediente.numero_expediente}/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: CERRAR_EXPEDIENTE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getExpedientesTipo =
  (expediente, mostrar, orden, inicio = 0) =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_EXPEDIENTES,
    });
    axios
      .get(`/expedienteslisa/?tipo=${expediente.tipo_expediente}&id_pers=${expediente.id_pers}&mostrar=${mostrar}&orden=${orden}&inicio=${inicio}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_EXPEDIENTES,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

export const limpiarFiltrosExpedientes = () => (dispatch, getState) => {
  dispatch({
    type: LIMPIAR_FILTROS_EXPEDIENTES,
  });
};

export const setOrdenExpedientes = (orden) => (dispatch, getState) => {
  dispatch({
    type: SET_ORDEN_EXPEDIENTES,
    payload: orden,
  });
};

export const setMostrarExpedientes =
  (mostrar = 12) =>
  (dispatch, getState) => {
    dispatch({
      type: SET_MOSTRAR_EXPEDIENTES,
      payload: mostrar,
    });
  };

export const mostrarMasExpedientes =
  (expediente, mostrar, orden, inicio = 0) =>
  (dispatch, getState) => {
    dispatch({
      type: GET_MAS_EXPEDIENTES,
    });

    axios
      .get(`/expedienteslisa/?tipo=${expediente.tipo_expediente}&id_pers=${expediente.id_pers}&mostrar=${mostrar}&orden=${orden}&inicio=${inicio}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_EXPEDIENTES,
          payload: res.data,
          inicio: inicio,
        });
      })
      .catch((err) => console.log(err));
  };

export const getDatosFicherosExpediente = (expediente) => (dispatch, getState) => {
  dispatch({
    type: GET_DATOS_FICHEROS_EXPEDIENTE,
  });
  axios
    .get(`/expedienteslisa/getDatosFicheros/?tipo=${expediente.tipo_expediente}&numero_expediente=${expediente.numero_expediente}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_DATOS_FICHEROS_EXPEDIENTE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const obtenerFicheroExpediente = (fichero) => (dispatch, getState) => {
  dispatch({
    type: OBTENER_FICHERO_EXPEDIENTE,
  });
  let token = fichero.token;

  const body = JSON.stringify({
    token,
  });
  axios
    .post(`/expedienteslisa/getFicheroExpediente/`, body, tokenConfig(getState))
    .then((res) => {
      descargarFichero(res.data.nombre_archivo, res.data.fichero);
      dispatch({
        type: DESCARGAR_FICHERO_EXPEDIENTE,
      });
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err?.response?.status === 403) {
        dispatch(createMessage("Tiempo del validez del token de descarga excedido, por favor vuelva a intentarlo.", "error"));
      }
    });
};

export const validaCampoExpediente = (programa, campo, valor, id_pers, variables) => (dispatch, getState) => {
  const body = JSON.stringify({
    programa,
    campo,
    valor,
    id_pers,
    variables,
  });

  dispatch({
    type: VALIDAR_CAMPO,
  });

  axios
    .post(`/expedienteslisa/validaCampoExpediente/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: VALIDAR_CAMPO_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch(createMessage("Error validando campo", "error"));
    });
};

export const setValorCampoExpediente = (campo, valor) => (dispatch, getState) => {
  dispatch({
    type: SET_VALOR_CAMPO_EXPEDIENTE,
    payload: { campo, valor },
  });
};

export const reiniciaLV = (codigo_lov) => (dispatch, getState) => {
  dispatch({
    type: REINICIA_LV,
    payload: codigo_lov,
  });
};
