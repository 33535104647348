import verificarCarnet from "./comprobarCarnet";
import AnalyticsEdisa from "../../common/AnalyticsEdisa";
import i18n from "../../../lang/idiomas";

export const NO_ESTA_EL_PRECIO = `${i18n.t("plantillas.pnoespre")} `;
export const CANTIDAD_NO_VALIDA = `${i18n.t("plantillas.pnocaval")} `;

/**
 *Añade al carrito una nueva linea para el articulo
 * @param {Object} articulo El id del articulo a añadir
 * @param {string} pedido_seleccionado El id del pedido al que se añadiran las lineas
 * @param {string} tipo Tipo de linea a crear
 * @param {string} codigo_plantilla El codigo de la plantilla
 * @param {Object} precio El objeto que contiene los precios de los articulos
 * @param {string} hash
 * @param {number} cantidad La cantidad del producto a añadir en la linea
 * @param {number} cantidadMinima La cantidad minima permitida del producto a añadir en la linea
 * @param {Object} usuario Los datos del usuario haciendo el pedido
 * @param {Object} subpresentacion Los datos de la subpresentacion
 * @param {Objedt} presentacion Los datos de la presentacion
 * @callback postLinea La funcion que hace el post de la linea a la BD
 * @callback comprobarCarnet Funcion para comprobar el carnet
 * @callback getObservaciones Obtener las observaciones para el articulo
 * @callback nuevaReceta Funcion que crea la nueva receta
 *
 * @typedef {Object} retObject
 * @property {boolean} completado Si fue completada o no la operacion
 * @property {string} mensaje Un mensaje describiendo el fin de la operacion
 *
 * @returns {retObject} Retorna si la operacion fue completada con exito
 */
export default function addToCart(
  articulo,
  pedido_seleccionado,
  tipo,
  codigo_plantilla,
  usuario,
  cantidad,
  cantidadMinima,
  hash,
  precio,
  presentacion,
  subpresentacion,
  postLinea,
  nuevaReceta,
  comprobarCarnet = verificarCarnet
) {
  /**@var {object} artEnPrecio los datos del articulo en el objeto precio  */
  const artEnPrecio = precio[articulo.codigo_articulo];

  const EXISTE_PRECIO_PEDIDO = artEnPrecio && artEnPrecio.error === false && artEnPrecio.isLoading === false && artEnPrecio.data;

  if (!EXISTE_PRECIO_PEDIDO) return { completado: false, mensaje: NO_ESTA_EL_PRECIO + `${i18n.t("plantillas.pdelarti")} ` + articulo.descrip_comercial };

  if (cantidad < cantidadMinima) return { completado: false, mensaje: CANTIDAD_NO_VALIDA + `${i18n.t("plantillas.pdelarti")} ` + articulo.descrip_comercial };

  let linea = {
    articulo: articulo.codigo_articulo,
    descripcion: articulo.descrip_comercial,
    precio_venta: artEnPrecio.data.precio_neto,
    observaciones: "",
    estado_linea: "B",
    cantidad_pedida: cantidad,
    presentacion_pedido: articulo.presentacion_web,
    tipo_linea: tipo,
    sub_pres: "",
    sub_pres_cant: "",
    sub_pres_fc: "",
    presentacion_escogida: "",
    pres_fc: "",
    valida_carnet: "",
  };

  if (articulo.tipo_carnet_profesional) linea = comprobarCarnet(articulo, linea, "N", null, usuario);

  //Comprobar si el articulo necesita receta
  if (articulo.numero_receta) {
    if (articulo.codigo_estad10 && articulo.codigo_estad10 === "10") {
      if (usuario.con_receta && usuario.con_receta === "S") {
        if (usuario.tipo_cliente === "01") linea = comprobarCarnet(articulo, linea, "S", "otros", usuario);
        else linea.valida_otros = "N";
      } else linea.valida_otros = "S";
    } else {
      if (usuario.con_receta && usuario.con_receta === "S") {
        if (usuario.tipo_cliente === "01") linea = comprobarCarnet(articulo, linea, "S", "receta", usuario);
        else linea.valida_receta = "N";
      } else linea.valida_receta = "S";
    }
  } else {
    if (articulo.codigo_estad10 && articulo.codigo_estad10 === "10") {
      if (usuario.tipo_cliente === "01") linea = comprobarCarnet(articulo, linea, "S", "otros", usuario);
    }
  }

  if (subpresentacion.subpresentacion) {
    linea.sub_pres = subpresentacion.subpresentacion;
    linea.sub_pres_cant = Number.parseInt(cantidad);
    linea.sub_pres_fc = Number.parseFloat(subpresentacion.factor_conversion);
    linea.observaciones = linea.sub_pres_cant + " de " + linea.sub_pres;
  }

  if (presentacion.presentacion) {
    linea.pres_fc = Number.parseFloat(presentacion.factor_conversion);
    linea.pres_escogida = presentacion.presentacion;
  }

  if (!linea.valida_receta || linea.valida_receta === "S" || linea.valida_receta === "M") postLinea(linea, hash ?? "", pedido_seleccionado);
  else nuevaReceta(linea, "receta");

  let analytics = new AnalyticsEdisa();

  let netear_precio = "S";

  analytics.registraEvento({
    modulo: "Plantilla",
    tipo: "AddToCart",
    valor: articulo.codigo_articulo,
    params: {
      codigo_articulo: articulo.codigo_articulo,
      descrip_comercial: articulo.descrip_comercial,
      divisa: artEnPrecio.data.divisa_precio,
      precio: parseFloat(netear_precio === "S" ? artEnPrecio.data.precio_precio_neto : artEnPrecio.data.precio_presentacion * cantidad),
    },
  });
  // this.props.limpiarPresentacion();

  return {
    completado: true,
    mensaje: i18n.t("plantillas.pfuncejc"),
  };
}
