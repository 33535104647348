import axios from "../axiosConfig";
import { createMessage } from "./messages";
import { GET_PORTAL, GET_PORTAL_SUCCESS, GET_PORTAL_FAIL, GET_MENUS, GET_MENUS_SUCCESS, GET_MENUS_FAIL, LOGIN_SUCCESS, SET_ACEPTAR_COOKIES } from "./types";

import { tokenConfig } from "./auth";

// GET DATOS DEL PORTAL
export const getPortal =
  (hash_usuario = "") =>
  (dispatch, getState) => {
    dispatch({
      type: GET_PORTAL,
    });

    axios
      .get(`/portal?inv=${hash_usuario}`, tokenConfig(getState))
      .then((res) => {
        if (res.data.invitado !== undefined) {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data.invitado,
          });
        }
        dispatch({
          type: GET_PORTAL_SUCCESS,
          payload: res.data.portal,
        });
      })
      .catch((err) => {
        console.log(err.response.data);
        dispatch({
          type: GET_PORTAL_FAIL,
        });
      });
  };

// GET MENUS
export const getMenus = () => (dispatch, getState) => {
  dispatch({
    type: GET_MENUS,
  });
  axios
    .get(`/menus`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_MENUS_SUCCESS,
        payload: res.data.menus,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_MENUS_FAIL,
      });
    });
};

export const setAceptacionCookies = (acepta_cookies, fecha_consentimiento, publicitarias, analiticas) => (dispatch, getState) => {
  let configuracion_cookies = {
    acepta_cookies: acepta_cookies,
    fecha_consentimiento: fecha_consentimiento,
    cookies_publicitarias: publicitarias,
    cookies_analiticas: analiticas,
  };

  dispatch({
    type: SET_ACEPTAR_COOKIES,
    payload: configuracion_cookies,
  });
  dispatch(createMessage("Configuración de cookies actualizada", "success"));
};
