import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setEmailPedido } from "../../actions/pedidos";
import i18n from "../../lang/idiomas";

export class EmailPedido extends Component {
  static propTypes = {
    pedido: PropTypes.object.isRequired,
    setEmailPedido: PropTypes.func.isRequired,
    pedido_cargando: PropTypes.bool.isRequired,
  };

  onchange = (e) => {
    if (e.target.value && !this.props.pedido_cargando) {
      this.props.setEmailPedido(e.target.value);
    }
  };

  render() {
    return (
      <form className="form-default">
        <div className="form-group">
          <label htmlFor="persona_pedido">{i18n.t('pedido.emapedid')}</label>
          <input
            className="form-control"
            type="text"
            name="email_pedido"
            id="email_pedido"
            onChange={this.onchange}
            value={this.props.pedido && this.props.pedido.email && this.props.pedido.email !== "" ? this.props.pedido.email : ""}
            placeholder={i18n.t('pedido.emapedmi')}
            disabled={this.props.pedidoGuardado}
          />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  pedido_cargando: state.pedidos.isLoading,
  pedido: state.pedidos.pedido,
});

export default connect(mapStateToProps, { setEmailPedido })(EmailPedido);
