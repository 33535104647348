import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cambiarPass } from '../../actions/auth'
import { createMessage } from '../../actions/messages'
import { programaActivo, validaPass } from '../../helpers/funciones'
import i18n from '../../lang/idiomas'
import SubscripcionesStock from './SubscripcionesStock.js'

const Cuenta = props => {
  const dispatch = useDispatch()

  const auth = useSelector(state => state.auth)
  const subscripciones = useSelector(state => state.auth.user.subscripciones)
  const menus = useSelector(state => state.portal.menus)

  const [pass, setPass] = useState('')
  const [nuevo_pass, setNuevo_pass] = useState('')
  const [re_nuevo_pass, setRe_nuevo_pass] = useState('')
  const [error_iguales, setError_iguales] = useState(false)
  const [error_vacios, setError_vacios] = useState(false)
  const [subs_ocultas, setSubs_ocultas] = useState(true)

  useEffect(() => {
    window.wookie()
  })

  const onSubmit = e => {
    e.preventDefault()

    // Validaciones
    if (pass !== '' && nuevo_pass !== '' && re_nuevo_pass !== '') {
      if (nuevo_pass !== re_nuevo_pass) {
        // No coinciden
        dispatch(createMessage(i18n.t('usuarios.ucontnoi'), 'error'))
        setError_iguales(true)
      } else if (!validaPass(nuevo_pass) || !validaPass(re_nuevo_pass)) {
        // No cumple requisitos
        dispatch(createMessage(i18n.t('usuarios.unocumre'), 'error'))
      } else {
        // Se modifica password
        dispatch(cambiarPass(pass, nuevo_pass))
        // Limpiamos campos
        setPass('')
        setNuevo_pass('')
        setRe_nuevo_pass('')
        setError_iguales(false)
        setError_vacios(false)
      }
    } else {
      dispatch(createMessage(i18n.t('usuarios.ucamnopv'), 'error'))
      // Campos vacios
      setError_vacios(true)
    }
  }

  const verSubscripciones = () => setSubs_ocultas(!subs_ocultas)
  const { isAuthenticated, user } = auth
  const subsActivas = programaActivo(menus, 'articulos')

  return (
    <>
      <div id='tt-pageContent'>
        <div className='container-indent'>
          <div className='container container-fluid-custom-mobile-padding'>
            <h1 className='tt-title-subpages noborder'>{i18n.t('usuarios.umicuent')}</h1>
            <div className='tt-shopping-layout'>
              <div className='tt-wrapper'>
                <h3 className='tt-title'>{i18n.t('usuarios.umisdato')}</h3>
                <div className='tt-table-responsive'>
                  <table className='tt-table-shop-02'>
                    <tbody>
                      <tr>
                        <td>{i18n.t('usuarios.uusr')}</td>
                        <td>{isAuthenticated ? user.usuario_web : ''} </td>
                      </tr>
                      <tr>
                        <td>{i18n.t('usuarios.uemail')}</td>
                        <td>{isAuthenticated ? user.email_usuario_web : ''} </td>
                      </tr>
                      <tr>
                        <td>{i18n.t('usuarios.unombr')}</td>
                        <td>{isAuthenticated ? user.nombre : ''} </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='tt-wrapper'>
                <h3 className='tt-title'>{i18n.t('usuarios.ucambcon')}</h3>
                <div className='tt-login-form'>
                  <div className='row justify-content-center'>
                    <div className='col-md-8 col-lg-6'>
                      <div className='tt-item'>
                        <div className='form-default'>
                          <form onSubmit={onSubmit}>
                            <div className='form-group'>
                              <label htmlFor='pass'>{i18n.t('usuarios.ucontact')}</label>
                              <div className='tt-required'>{i18n.t('usuarios.ucamobli')}</div>
                              <input
                                type='password'
                                name='pass'
                                onChange={e => setPass(e.target.value)}
                                value={pass}
                                className={error_vacios ? 'form-control error' : 'form-control'}
                                id='pass'
                                placeholder={i18n.t('usuarios.uconactm')}
                                required
                              />
                            </div>
                            <div className='form-group'>
                              <label htmlFor='nuevo_pass'>{i18n.t('usuarios.unuecont')}</label>
                              <input
                                type='password'
                                name='nuevo_pass'
                                onChange={e => setNuevo_pass(e.target.value)}
                                value={nuevo_pass}
                                className={
                                  error_iguales || error_vacios
                                    ? 'form-control error'
                                    : 'form-control'
                                }
                                id='nuevo_pass'
                                placeholder={i18n.t('usuarios.unueconm')}
                                required
                              />
                              <p>{i18n.t('usuarios.ureqmin')}</p>
                            </div>
                            <div className='form-group'>
                              <label htmlFor='re_nuevo_pass'>{i18n.t('usuarios.urepcont')}</label>
                              <input
                                type='password'
                                name='re_nuevo_pass'
                                onChange={e => setRe_nuevo_pass(e.target.value)}
                                value={re_nuevo_pass}
                                className={
                                  error_iguales || error_vacios
                                    ? 'form-control error'
                                    : 'form-control'
                                }
                                id='re_nuevo_pass'
                                placeholder={i18n.t('usuarios.urecontm')}
                                required
                              />
                              <p>{i18n.t('usuarios.ureqmin')}</p>
                            </div>
                            <div className='row'>
                              <div className='col-auto'>
                                <div className='form-group'>
                                  <button
                                    className='btn btn-border'
                                    type='submit'>
                                    {i18n.t('usuarios.ucambiar')}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {subsActivas ? (
                <div className='tt-wrapper'>
                  <h3 className='tt-title'>{i18n.t('usuarios.missubs')}</h3>
                  <button
                    className='btn btn-border subscripciones'
                    disabled={
                      subscripciones?.lineas && subscripciones.lineas?.length > 0 ? false : true
                    }
                    onClick={e => {
                      e.preventDefault()
                      verSubscripciones()
                    }}>
                    {i18n.t('usuarios.gessubs')}
                  </button>
                  <div
                    id={isAuthenticated ? 'subscripciones-' + user.usuario_web : 'subscripciones'}
                    hidden={subs_ocultas}>
                    <SubscripcionesStock origen={'tabla-subs'} />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Cuenta
