import {
  GET_ARTICULOS,
  FETCH_ARTICULOS,
  GET_MAS_ARTICULOS,
  GET_OFERTAS,
  FETCH_OFERTAS,
  GET_NOVEDADES,
  FETCH_NOVEDADES,
  GET_PEDIDO_HABITUAL,
  FETCH_PEDIDO_HABITUAL,
  GET_PEDIDO_ANTERIOR,
  FETCH_PEDIDO_ANTERIOR,
  GET_RELACIONADOS,
  FETCH_RELACIONADOS,
  GET_EQUIVALENTES,
  FETCH_EQUIVALENTES,
  CHANGE_ARTICULOS_PAGINA,
  SET_BUSQUEDA,
  CHANGE_ARTICULOS_ORDEN,
  GET_STOCK_ARTICULO,
  FETCH_MAS_ARTICULOS,
  SET_ARTICULO_EQUIVALENTES,
  SET_BUSQUEDA_AVANZADA,
  LIMPIAR_BUSQUEDA_AVANZADA,
  GET_STOCKS_ARTICULOS,
  FETCH_STOCKS_ARTICULOS,
} from "../actions/types.js";

const initialState = {
  articulos: {
    articulos: [],
    articulos_total: 0,
    articulos_siguiente: "",
    articulos_anterior: "",
    filtros_aplicables: [],
    filtros_claves_aplicables: [],
    isLoading: false,
  },
  ofertas: {
    articulos: [],
    articulos_total: 0,
    articulos_siguiente: "",
    articulos_anterior: "",
    isLoading: false,
  },
  novedades: {
    articulos: [],
    articulos_total: 0,
    articulos_siguiente: "",
    articulos_anterior: "",
    isLoading: false,
  },
  pedido_habitual: {
    articulos: [],
    articulos_total: 0,
    articulos_siguiente: "",
    articulos_anterior: "",
    isLoading: false,
  },
  pedido_anterior: {
    articulos: [],
    articulos_total: 0,
    isLoading: false,
  },
  relacionados: {
    articulos: [],
    articulos_total: 0,
    articulos_siguiente: "",
    articulos_anterior: "",
    isLoading: false,
  },
  equivalentes: {
    codigo_articulo: "",
    equivalentes: [],
    isLoading: false,
  },
  articulos_pagina: 12,
  articulos_search: "",
  articulos_advanced_search: {
    avanzada: "",
    codigo_articulo: "",
    descripcion: "",
    codigo_barras: "",
    palabras_clave: "",
    equivalentes: "",
    referencia_cliente: "",
  },
  articulos_orden: "",
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  let articulos_nuevo;
  let pedido_habitual_nuevo;
  let pedido_anterior_nuevo;
  let novedades_nuevo;
  let ofertas_nuevo;
  let relacionados_nuevo;
  let equivalentes_nuevo;
  let mas_articulos;
  let i = 0;
  let x = 0;
  let listaArticulos = [];
  let index = -1;

  switch (action.type) {
    case FETCH_MAS_ARTICULOS:
      switch (action.origen) {
        case "articulos":
          mas_articulos = JSON.parse(JSON.stringify(state.articulos));
          mas_articulos.isLoading = true;

          return {
            ...state,
            articulos: mas_articulos,
          };
        case "pedido_habitual":
          mas_articulos = JSON.parse(JSON.stringify(state.pedido_habitual));
          mas_articulos.isLoading = true;

          return {
            ...state,
            pedido_habitual: mas_articulos,
          };
        case "pedido_anterior":
          mas_articulos = JSON.parse(JSON.stringify(state.pedido_anterior));
          mas_articulos.isLoading = true;

          return {
            ...state,
            pedido_anterior: mas_articulos,
          };
        case "novedades":
          mas_articulos = JSON.parse(JSON.stringify(state.novedades));
          mas_articulos.isLoading = true;

          return {
            ...state,
            novedades: mas_articulos,
          };
        case "ofertas":
          mas_articulos = JSON.parse(JSON.stringify(state.ofertas));
          mas_articulos.isLoading = true;

          return {
            ...state,
            ofertas: mas_articulos,
          };

        default:
          return {
            ...state,
          };
      }

    case FETCH_ARTICULOS:
      return {
        ...state,
        articulos: {
          articulos: [],
          articulos_total: 0,
          articulos_siguiente: "",
          articulos_anterior: "",
          filtros_aplicables: [],
          filtros_claves_aplicables: [],
          isLoading: true,
        },
      };

    case FETCH_OFERTAS:
      return {
        ...state,
        ofertas: {
          articulos: [],
          articulos_total: 0,
          articulos_siguiente: "",
          articulos_anterior: "",
          isLoading: true,
        },
      };

    case FETCH_NOVEDADES:
      return {
        ...state,
        novedades: {
          articulos: [],
          articulos_total: 0,
          articulos_siguiente: "",
          articulos_anterior: "",
          isLoading: true,
        },
      };

    case FETCH_PEDIDO_HABITUAL:
      return {
        ...state,
        pedido_habitual: {
          articulos: [],
          articulos_total: 0,
          articulos_siguiente: "",
          articulos_anterior: "",
          isLoading: true,
        },
      };
    case FETCH_PEDIDO_ANTERIOR:
      return {
        ...state,
        pedido_anterior: {
          articulos: [],
          articulos_total: 0,
          articulos_siguiente: "",
          articulos_anterior: "",
          isLoading: true,
        },
      };

    case FETCH_RELACIONADOS:
      return {
        ...state,
        relacionados: {
          articulos: [],
          articulos_total: 0,
          articulos_siguiente: "",
          articulos_anterior: "",
          isLoading: true,
        },
      };

    case FETCH_EQUIVALENTES:
      equivalentes_nuevo = JSON.parse(JSON.stringify(state.equivalentes));

      let art_equ = {
        codigo_articulo: action.codigo_articulo,
        articulos: [],
        articulos_total: 0,
        articulos_siguiente: "",
        articulos_anterior: "",
        isLoading: true,
      };

      let equi_filt = state.equivalentes.equivalentes.filter((equivalente) => equivalente.codigo_articulo !== action.codigo_articulo);
      equivalentes_nuevo.equivalentes = [...equi_filt, art_equ];
      return {
        ...state,
        equivalentes: equivalentes_nuevo,
      };

    case GET_ARTICULOS:
      articulos_nuevo = JSON.parse(JSON.stringify(state.articulos));
      articulos_nuevo.articulos = action.payload.results;
      articulos_nuevo.articulos_total = action.payload.count;
      articulos_nuevo.articulos_siguiente = action.payload.next;
      articulos_nuevo.articulos_anterior = action.payload.previous;
      articulos_nuevo.filtros_aplicables = action.payload.filtros_aplicables;
      articulos_nuevo.filtros_claves_aplicables = action.payload.filtros_claves_aplicables;
      articulos_nuevo.isLoading = false;
      return {
        ...state,
        articulos: articulos_nuevo,
        articulos_search: action.payload.search,
        articulos_pagina: action.payload.articulos_pagina,
        articulos_orden: action.payload.articulos_orden,
      };

    case GET_PEDIDO_HABITUAL:
      pedido_habitual_nuevo = JSON.parse(JSON.stringify(state.pedido_habitual));
      pedido_habitual_nuevo.articulos = action.payload.results;
      pedido_habitual_nuevo.articulos_total = action.payload.count;
      pedido_habitual_nuevo.articulos_siguiente = action.payload.next;
      pedido_habitual_nuevo.articulos_anterior = action.payload.previous;
      pedido_habitual_nuevo.isLoading = false;
      return {
        ...state,
        pedido_habitual: pedido_habitual_nuevo,
        articulos_search: "",
        articulos_pagina: 12,
        articulos_orden: "",
      };
    case GET_PEDIDO_ANTERIOR:
      pedido_anterior_nuevo = JSON.parse(JSON.stringify(state.pedido_anterior));
      pedido_anterior_nuevo.articulos = action.payload;
      pedido_anterior_nuevo.articulos_total = action.payload.length;
      pedido_anterior_nuevo.isLoading = false;
      return {
        ...state,
        pedido_anterior: pedido_anterior_nuevo,
        articulos_search: "",
        articulos_pagina: 12,
        articulos_orden: "",
      };

    case GET_NOVEDADES:
      novedades_nuevo = JSON.parse(JSON.stringify(state.novedades));
      novedades_nuevo.articulos = action.payload.results;
      novedades_nuevo.articulos_total = action.payload.count;
      novedades_nuevo.articulos_siguiente = action.payload.next;
      novedades_nuevo.articulos_anterior = action.payload.previous;
      novedades_nuevo.isLoading = false;
      return {
        ...state,
        novedades: novedades_nuevo,
        articulos_search: "",
        articulos_pagina: 12,
        articulos_orden: "",
      };

    case GET_OFERTAS:
      ofertas_nuevo = JSON.parse(JSON.stringify(state.ofertas));
      ofertas_nuevo.articulos = action.payload.results;
      ofertas_nuevo.articulos_total = action.payload.count;
      ofertas_nuevo.articulos_siguiente = action.payload.next;
      ofertas_nuevo.articulos_anterior = action.payload.previous;
      ofertas_nuevo.isLoading = false;
      return {
        ...state,
        ofertas: ofertas_nuevo,
        articulos_search: "",
        articulos_pagina: 12,
        articulos_orden: "",
      };

    case GET_RELACIONADOS:
      relacionados_nuevo = JSON.parse(JSON.stringify(state.relacionados));
      relacionados_nuevo.articulos = action.payload.results;
      relacionados_nuevo.articulos_total = action.payload.count;
      relacionados_nuevo.articulos_siguiente = action.payload.next;
      relacionados_nuevo.articulos_anterior = action.payload.previous;
      relacionados_nuevo.isLoading = false;
      return {
        ...state,
        relacionados: relacionados_nuevo,
        articulos_search: "",
        articulos_pagina: 12,
        articulos_orden: "",
      };

    case GET_EQUIVALENTES:
      equivalentes_nuevo = JSON.parse(JSON.stringify(state.equivalentes));

      let articulos_equivalentes = {
        codigo_articulo: action.codigo_articulo,
        articulos: action.payload.results,
        articulos_total: action.payload.count,
        articulos_siguiente: action.payload.next,
        articulos_anterior: action.payload.previous,
        isLoading: false,
      };

      let equivalentes_filtrados = state.equivalentes.equivalentes.filter((equivalente) => equivalente.codigo_articulo !== action.codigo_articulo);
      equivalentes_nuevo.equivalentes = [...equivalentes_filtrados, articulos_equivalentes];
      return {
        ...state,
        equivalentes: equivalentes_nuevo,
      };

    case GET_MAS_ARTICULOS:
      switch (action.origen) {
        case "articulos":
          mas_articulos = JSON.parse(JSON.stringify(state.articulos));
          mas_articulos.articulos = [...mas_articulos.articulos, ...action.payload.results];
          mas_articulos.isLoading = false;
          mas_articulos.articulos_siguiente = action.payload.next;
          mas_articulos.articulos_anterior = action.payload.previous;
          return {
            ...state,
            articulos: mas_articulos,
          };
        case "pedido_habitual":
          mas_articulos = JSON.parse(JSON.stringify(state.pedido_habitual));
          mas_articulos.articulos = [...mas_articulos.articulos, ...action.payload.results];
          mas_articulos.isLoading = false;
          mas_articulos.articulos_siguiente = action.payload.next;
          mas_articulos.articulos_anterior = action.payload.previous;
          return {
            ...state,
            pedido_habitual: mas_articulos,
          };
        case "pedido_anterior":
          mas_articulos = JSON.parse(JSON.stringify(state.pedido_anterior));
          mas_articulos.articulos = [...mas_articulos.articulos, ...action.payload.results];
          mas_articulos.isLoading = false;
          mas_articulos.articulos_siguiente = action.payload.next;
          mas_articulos.articulos_anterior = action.payload.previous;
          return {
            ...state,
            pedido_anterior: mas_articulos,
          };
        case "novedades":
          mas_articulos = JSON.parse(JSON.stringify(state.novedades));
          mas_articulos.articulos = [...mas_articulos.articulos, ...action.payload.results];
          mas_articulos.isLoading = false;
          mas_articulos.articulos_siguiente = action.payload.next;
          mas_articulos.articulos_anterior = action.payload.previous;
          return {
            ...state,
            novedades: mas_articulos,
          };
        case "ofertas":
          mas_articulos = JSON.parse(JSON.stringify(state.ofertas));
          mas_articulos.articulos = [...mas_articulos.articulos, ...action.payload.results];
          mas_articulos.isLoading = false;
          mas_articulos.articulos_siguiente = action.payload.next;
          mas_articulos.articulos_anterior = action.payload.previous;
          return {
            ...state,
            ofertas: mas_articulos,
          };

        default:
          return {
            ...state,
          };
      }

    case SET_BUSQUEDA:
      return {
        ...state,
        articulos_search: action.payload,
      };

    case CHANGE_ARTICULOS_PAGINA:
      return {
        ...state,
        articulos_pagina: action.payload,
      };

    case CHANGE_ARTICULOS_ORDEN:
      return {
        ...state,
        articulos_orden: action.payload,
      };

    case GET_STOCK_ARTICULO:
      index = -1;

      articulos_nuevo = JSON.parse(JSON.stringify(state.articulos));

      index = articulos_nuevo.articulos.findIndex((articulo) => articulo.codigo_articulo === action.codigo_articulo);

      if (index !== -1) {
        articulos_nuevo.articulos[index]["info_stock"] = action.payload;
      }

      pedido_habitual_nuevo = JSON.parse(JSON.stringify(state.pedido_habitual));

      index = pedido_habitual_nuevo.articulos.findIndex((articulo) => articulo.codigo_articulo === action.codigo_articulo);

      if (index !== -1) {
        pedido_habitual_nuevo.articulos[index]["info_stock"] = action.payload;
      }

      pedido_anterior_nuevo = JSON.parse(JSON.stringify(state.pedido_anterior));

      index = pedido_anterior_nuevo.articulos.findIndex((articulo) => articulo.codigo_articulo === action.codigo_articulo);

      if (index !== -1) {
        pedido_anterior_nuevo.articulos[index]["info_stock"] = action.payload;
      }

      novedades_nuevo = JSON.parse(JSON.stringify(state.novedades));

      index = novedades_nuevo.articulos.findIndex((articulo) => articulo.codigo_articulo === action.codigo_articulo);

      if (index !== -1) {
        novedades_nuevo.articulos[index]["info_stock"] = action.payload;
      }
      ofertas_nuevo = JSON.parse(JSON.stringify(state.ofertas));

      index = ofertas_nuevo.articulos.findIndex((articulo) => articulo.codigo_articulo === action.codigo_articulo);

      if (index !== -1) {
        ofertas_nuevo.articulos[index]["info_stock"] = action.payload;
      }

      if (index !== -1) {
        ofertas_nuevo.articulos[index]["info_stock"] = action.payload;
      }

      relacionados_nuevo = JSON.parse(JSON.stringify(state.relacionados));

      index = relacionados_nuevo.articulos.findIndex((articulo) => articulo.codigo_articulo === action.codigo_articulo);
      if (index !== -1) {
        relacionados_nuevo.articulos[index]["info_stock"] = action.payload;
      }

      equivalentes_nuevo = JSON.parse(JSON.stringify(state.equivalentes));

      equivalentes_nuevo.equivalentes.forEach((element) => {
        index = element.articulos.findIndex((equivalente) => equivalente.codigo_articulo === action.codigo_articulo);
        if (index !== -1) {
          element.articulos[index]["info_stock"] = action.payload;
        }
      });

      return {
        ...state,
        articulos: articulos_nuevo,
        pedido_habitual: pedido_habitual_nuevo,
        pedido_anterior: pedido_anterior_nuevo,
        novedades: novedades_nuevo,
        ofertas: ofertas_nuevo,
        relacionados: relacionados_nuevo,
        equivalentes: equivalentes_nuevo,
        isLoading: false,
      };

    case SET_ARTICULO_EQUIVALENTES:
      equivalentes_nuevo = JSON.parse(JSON.stringify(state.equivalentes));
      equivalentes_nuevo.codigo_articulo = action.payload;

      return {
        ...state,
        equivalentes: equivalentes_nuevo,
        isLoading: false,
      };

    case SET_BUSQUEDA_AVANZADA:
      let new_articulos_advanced_search = JSON.parse(JSON.stringify(state.articulos_advanced_search));
      new_articulos_advanced_search.avanzada = action.payload.avanzada;
      new_articulos_advanced_search.codigo_articulo = action.payload.codigo_articulo;
      new_articulos_advanced_search.descripcion = action.payload.descripcion;
      new_articulos_advanced_search.codigo_barras = action.payload.codigo_barras;
      new_articulos_advanced_search.palabras_clave = action.payload.palabras_clave;
      new_articulos_advanced_search.equivalentes = action.payload.equivalentes;
      new_articulos_advanced_search.referencia_cliente = action.payload.referencia_cliente;
      new_articulos_advanced_search.codigo_sinonimo = action.payload.codigo_sinonimo;

      return {
        ...state,
        articulos_advanced_search: new_articulos_advanced_search,
      };

    case LIMPIAR_BUSQUEDA_AVANZADA:
      let clean_articulos_advanced_search = JSON.parse(JSON.stringify(state.articulos_advanced_search));
      clean_articulos_advanced_search.avanzada = "";
      clean_articulos_advanced_search.codigo_articulo = "";
      clean_articulos_advanced_search.descripcion = "";
      clean_articulos_advanced_search.codigo_barras = "";
      clean_articulos_advanced_search.palabras_clave = "";
      clean_articulos_advanced_search.equivalentes = "";
      clean_articulos_advanced_search.referencia_cliente = "";
      clean_articulos_advanced_search.codigo_sinonimo = "";

      return {
        ...state,
        articulos_advanced_search: clean_articulos_advanced_search,
      };

    case FETCH_STOCKS_ARTICULOS:
      listaArticulos = JSON.parse(JSON.stringify(state.articulos));
      pedido_habitual_nuevo = JSON.parse(JSON.stringify(state.pedido_habitual));
      pedido_anterior_nuevo = JSON.parse(JSON.stringify(state.pedido_anterior));
      novedades_nuevo = JSON.parse(JSON.stringify(state.novedades));
      ofertas_nuevo = JSON.parse(JSON.stringify(state.ofertas));
      relacionados_nuevo = JSON.parse(JSON.stringify(state.relacionados));
      equivalentes_nuevo = JSON.parse(JSON.stringify(state.equivalentes));

      switch (action.origen) {
        case "relacionados":
          for (i = 0; i < action.articulos_stock.length; i++) {
            for (x = 0; x < relacionados_nuevo.articulos.length; x++) {
              if (action.articulos_stock[i].codigo_articulo === relacionados_nuevo.articulos[x].codigo_articulo && relacionados_nuevo.articulos[x].info_stock === undefined) {
                relacionados_nuevo.articulos[x]["info_stock"] = {};
              }
            }
          }
          relacionados_nuevo.articulos.isLoading = true;
          break;
        case "equivalentes":
          for (i = 0; i < action.articulos_stock.length; i++) {
            for (x = 0; x < equivalentes_nuevo.equivalentes.length; x++) {
              if (action.articulos_stock[i].codigo_articulo === equivalentes_nuevo.equivalentes[x].codigo_articulo && equivalentes_nuevo.equivalentes[x].info_stock === undefined) {
                equivalentes_nuevo.equivalentes[x]["info_stock"] = {};
              }
            }
          }
          equivalentes_nuevo.equivalentes.isLoading = true;
          break;
        case "pedido_habitual":
          for (i = 0; i < action.articulos_stock.length; i++) {
            for (x = 0; x < pedido_habitual_nuevo.articulos.length; x++) {
              if (action.articulos_stock[i].codigo_articulo === pedido_habitual_nuevo.articulos[x].codigo_articulo && pedido_habitual_nuevo.articulos[x].info_stock === undefined) {
                pedido_habitual_nuevo.articulos[x]["info_stock"] = {};
              }
            }
          }
          pedido_habitual_nuevo.articulos.isLoading = true;
          break;
        case "pedido_anterior":
          for (i = 0; i < action.articulos_stock.length; i++) {
            for (x = 0; x < pedido_anterior_nuevo.articulos.length; x++) {
              if (action.articulos_stock[i].codigo_articulo === pedido_anterior_nuevo.articulos[x].codigo_articulo && pedido_anterior_nuevo.articulos[x].info_stock === undefined) {
                pedido_anterior_nuevo.articulos[x]["info_stock"] = {};
              }
            }
          }
          pedido_anterior_nuevo.articulos.isLoading = true;
          break;
        case "ofertas":
          for (i = 0; i < action.articulos_stock.length; i++) {
            for (x = 0; x < ofertas_nuevo.articulos.length; x++) {
              if (action.articulos_stock[i].codigo_articulo === ofertas_nuevo.articulos[x].codigo_articulo && ofertas_nuevo.articulos[x].info_stock === undefined) {
                ofertas_nuevo.articulos[x]["info_stock"] = {};
              }
            }
          }
          ofertas_nuevo.articulos.isLoading = true;
          break;
        case "novedades":
          for (i = 0; i < action.articulos_stock.length; i++) {
            for (x = 0; x < novedades_nuevo.articulos.length; x++) {
              if (action.articulos_stock[i].codigo_articulo === novedades_nuevo.articulos[x].codigo_articulo && novedades_nuevo.articulos[x].info_stock === undefined) {
                novedades_nuevo.articulos[x]["info_stock"] = {};
              }
            }
          }
          novedades_nuevo.articulos.isLoading = true;
          break;
        default:
          for (i = 0; i < action.articulos_stock.length; i++) {
            for (x = 0; x < listaArticulos.articulos.length; x++) {
              if (action.articulos_stock[i].codigo_articulo === listaArticulos.articulos[x].codigo_articulo && listaArticulos.articulos[x].info_stock === undefined) {
                listaArticulos.articulos[x]["info_stock"] = {};
              }
            }
          }
          listaArticulos.articulos.isLoading = true;
          break;
      }

      return {
        ...state,
        articulos: listaArticulos,
        pedido_habitual: pedido_habitual_nuevo,
        pedido_anterior: pedido_anterior_nuevo,
        novedades: novedades_nuevo,
        ofertas: ofertas_nuevo,
        relacionados: relacionados_nuevo,
        equivalentes: equivalentes_nuevo,
        isLoading: true,
      };

    case GET_STOCKS_ARTICULOS:
      articulos_nuevo = JSON.parse(JSON.stringify(state.articulos));
      pedido_habitual_nuevo = JSON.parse(JSON.stringify(state.pedido_habitual));
      pedido_anterior_nuevo = JSON.parse(JSON.stringify(state.pedido_anterior));
      novedades_nuevo = JSON.parse(JSON.stringify(state.novedades));
      ofertas_nuevo = JSON.parse(JSON.stringify(state.ofertas));
      relacionados_nuevo = JSON.parse(JSON.stringify(state.relacionados));
      equivalentes_nuevo = JSON.parse(JSON.stringify(state.equivalentes));

      for (i = 0; i < action.articulos_stock.length; i++) {
        index = -1;
        index = action.payload.findIndex((articulo) => articulo.codigo_articulo === action.articulos_stock[i].codigo_articulo);
        if (index !== -1) {
          index = articulos_nuevo.articulos.findIndex((articulo) => articulo.codigo_articulo === action.articulos_stock[i].codigo_articulo);

          if (index !== -1) {
            articulos_nuevo.articulos[index]["info_stock"] = action.payload[i];
            articulos_nuevo.articulos[index]["isLoading"] = false;
            articulos_nuevo.isLoading = false;
          }

          index = pedido_habitual_nuevo.articulos.findIndex((articulo) => articulo.codigo_articulo === action.articulos_stock[i].codigo_articulo);

          if (index !== -1) {
            pedido_habitual_nuevo.articulos[index]["info_stock"] = action.payload[i];
            pedido_habitual_nuevo.isLoading = false;
          }

          index = pedido_anterior_nuevo.articulos.findIndex((articulo) => articulo.codigo_articulo === action.articulos_stock[i].codigo_articulo);

          if (index !== -1) {
            pedido_anterior_nuevo.articulos[index]["info_stock"] = action.payload[i];
            pedido_anterior_nuevo.isLoading = false;
          }

          index = novedades_nuevo.articulos.findIndex((articulo) => articulo.codigo_articulo === action.articulos_stock[i].codigo_articulo);

          if (index !== -1) {
            novedades_nuevo.articulos[index]["info_stock"] = action.payload[i];
            novedades_nuevo.isLoading = false;
          }

          index = ofertas_nuevo.articulos.findIndex((articulo) => articulo.codigo_articulo === action.articulos_stock[i].codigo_articulo);

          if (index !== -1) {
            ofertas_nuevo.articulos[index]["info_stock"] = action.payload[i];
            ofertas_nuevo.isLoading = false;
          }

          index = relacionados_nuevo.articulos.findIndex((articulo) => articulo.codigo_articulo === action.articulos_stock[i].codigo_articulo);

          if (index !== -1) {
            relacionados_nuevo.articulos[index]["info_stock"] = action.payload[i];
            relacionados_nuevo.isLoading = false;
          }

          equivalentes_nuevo.equivalentes.forEach((element) => {
            index = element.articulos.findIndex((equivalente) => equivalente.codigo_articulo === action.articulos_stock[i].codigo_articulo);
            if (index !== -1) {
              element.articulos[index]["info_stock"] = action.payload[i];
              element.articulos.isLoading = false;
            }
          });
        }
      }
      return {
        ...state,
        articulos: articulos_nuevo,
        pedido_habitual: pedido_habitual_nuevo,
        pedido_anterior: pedido_anterior_nuevo,
        novedades: novedades_nuevo,
        ofertas: ofertas_nuevo,
        relacionados: relacionados_nuevo,
        equivalentes: equivalentes_nuevo,
        isLoading: false,
      };

    default:
      return state;
  }
}
