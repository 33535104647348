import verificarCarnet from "./comprobarCarnet";
import {} from "react-confirm-alert";
import addToCart, { CANTIDAD_NO_VALIDA, NO_ESTA_EL_PRECIO } from "./addToCart";
import { createMessage } from "../../../actions/messages";
import i18n from "../../../lang/idiomas";
/**
 *Añade al carrito una nueva linea para el articulo
 * @param {object} pedido_seleccionado El id del pedido al que se añadiran las lineas
 * @param {object}
 * @param {string} tipo Tipo de linea a crear
 * @param {string} codigo_plantilla El codigo de la plantilla
 * @param {Object} precio El objeto que contiene los precios de los articulos
 * @param {string} hash
 * @param {number} cantidad La cantidad del producto a añadir en la linea
 * @param {number} cantidadMinima La cantidad minima permitida del producto a añadir en la linea
 * @param {Object} usuario Los datos del usuario haciendo el pedido
 * @param {Object} subpresentacion Los datos de la subpresentacion
 * @param {Objedt} presentacion Los datos de la presentacion
 * @callback postLinea La funcion que hace el post de la linea a la BD
 * @callback comprobarCarnet Funcion para comprobar el carnet
 * @callback getObservaciones Obtener las observaciones para el articulo
 * @callback nuevaReceta Funcion que crea la nueva receta
 * @callback sendError
 * @callback togglePostLoading
 *
 * @typedef {Object} plantDet
 * @property {Object[]} articulos Arreglo con los datos de los articulos de la plantilla
 * @property {Object[]} plantilla_det Arreglo con los detalles de la plantilla para cada elemento
 *
 * @param {plantDet} detallesPlantilla
 * @param {Object<string, number>} cantidades
 *
 */
export default function createLines(
  state,
  detallesPlantilla,
  pedido_seleccionado,
  pedido_plantilla,
  tipo,
  codigo_plantilla,
  usuario,
  cantidades,
  hash,
  precio,
  presentacion,
  subpresentacion,
  postLinea,
  nuevaReceta,
  sendError,
  togglePostLoading
) {
  pedido_seleccionado = state.pedidos.pedido;
  pedido_plantilla = state.plantillas.pedido_creado_de_plantilla;
  let codigo_pedido = state.pedidos.id_pedido_seleccionado;
  precio = state.precio.precio;
  if (pedido_seleccionado.id_pedido === pedido_plantilla.id_pedido)
    detallesPlantilla.articulos.forEach(async (element, index) => {
      let skipIteration = false;
      if (!cantidades[element.codigo_articulo] || cantidades[element.codigo_articulo] < detallesPlantilla.plantilla_det[index].cantidadMinima) {
        togglePostLoading(true);
        sendError({
          articulo: element.codigo_articulo,
          message: CANTIDAD_NO_VALIDA + `${i18n.t("plantillas.pdelarti")} ` + element.descrip_comercial,
        });
        skipIteration = true;
        togglePostLoading(false);
      }

      if (skipIteration) return;

      await setTimeout(() => {
        const result = addToCart(
          element,
          codigo_pedido,
          tipo,
          codigo_plantilla,
          usuario,
          cantidades[element.codigo_articulo],
          detallesPlantilla.plantilla_det[index].cantidad_minima,
          hash,
          precio,
          presentacion,
          subpresentacion,
          postLinea,
          nuevaReceta
        );

        if (!result.completado) {
          sendError({
            articulo: element.codigo_articulo,
            message: result.mensaje,
          });
        }

        if (index === detallesPlantilla.articulos.length - 1) {
          togglePostLoading(false);
        }
        // togglePostLoading(false);
      }, index * 1700);
    });
  else {
    return;
  }
}
