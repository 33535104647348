/**
 *
 * @param {Object} articulo Los datos del articulo
 * @param {Object} linea Los datos de la linea
 * @param {string} comprobar_especificos Valor que determina si se comprobaran los especificos
 * @param {string} valida
 * @param {Object} usuario Los datos del usuario
 */
export default function comprobarCarnet(articulo, linea, comprobar_especificos, valida, usuario) {
  const condicionesDeCarnet = usuario.carnets && usuario.canrets.lineas.length > 0;
  if (!condicionesDeCarnet) return linea;

  let index = -1;

  if (comprobar_especificos === "S") {
    index = usuario.carnets.lineas.findIndex((carnet) => carnet.tipo_carnet == "00002");

    if (index !== -1) {
      if (valida === "otros") linea.valida_otros = "S";
      else linea.valida_receta = "S";
    } else {
      index = usuario.carnets.lineas.findIndex((carnet) => carnet.tipo_carnet == "00003");

      if (index !== -1) {
        if (valida === "otros") linea.valida_otros = "M";
        else linea.valida_receta = "M";
      }
    }
  } else {
    if (comprobar_especificos === "S") {
      if (valida === "receta") linea.valida_receta = "N";
      else linea.valida_carnet = "N";
    }
  }

  return linea;
}
