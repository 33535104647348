import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setPersonaPedido } from "../../actions/pedidos";
import i18n from "../../lang/idiomas";

export class PersonaPedido extends Component {
  static propTypes = {
    pedido: PropTypes.object.isRequired,
    setPersonaPedido: PropTypes.func.isRequired,
    pedido_cargando: PropTypes.bool.isRequired,
  };

  onchange = (e) => {
    if (e.target.value && !this.props.pedido_cargando) {
      this.props.setPersonaPedido(e.target.value);
    }
  };

  render() {
    return (
      <form className="form-default">
        <div className="form-group">
          <label htmlFor="persona_pedido">{i18n.t("pedido.ppersped")}</label>
          <input
            className="form-control"
            type="text"
            name="persona_pedido"
            id="persona_pedido"
            onChange={this.onchange}
            value={this.props.pedido && this.props.pedido.persona_pedido && this.props.pedido.persona_pedido !== "" ? this.props.pedido.persona_pedido : ""}
            placeholder={i18n.t("pedido.pperpemi")}
            disabled={this.props.pedidoGuardado}
          />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  pedido_cargando: state.pedidos.isLoading,
  pedido: state.pedidos.pedido,
});

export default connect(mapStateToProps, { setPersonaPedido })(PersonaPedido);
