import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "react-confirm-alert/src/react-confirm-alert.css";
import Precio from "./Precio";
import Descripcion from "./Descripcion";
import Spinner from "../layout/Spinner";
import { confirmAlert } from "react-confirm-alert"; // Import
import { getPrecios } from "../../actions/precio";
import { cargarParametros, comprobarCondiciones, cargarArrays } from "../../helpers/funciones";
import { getPedidosPendientes, setPedido } from "../../actions/pedidosPendientes";
import { getPedidoActivo, setIdPedidoSeleccionado, eliminarPedido, postLinea, postLineas } from "../../actions/pedidos";
import { ArrowLineLeft, Eye, Trash, Upload } from "phosphor-react";
import i18n from "../../lang/idiomas";

let articulos_aux = [];
let parametros_usados = ["mostrar_iva", "tipo_precio", "maxim_agrup"];
let parametros;

export class ListasCompra extends Component {
  static propTypes = {
    pedidos_pendientes: PropTypes.array.isRequired,
    pedido_pendiente_detalle: PropTypes.number.isRequired,
    lineas_pedido_pendiente_detalle: PropTypes.array.isRequired,
    getPedidosPendientes: PropTypes.func.isRequired,
    setPedido: PropTypes.func.isRequired,
    getPedidoActivo: PropTypes.func.isRequired,
    setIdPedidoSeleccionado: PropTypes.func.isRequired,
    getPrecios: PropTypes.func.isRequired,
  };

  state = {
    mostrar_lineas: false,
  };

  componentDidMount() {
    if (this.props.pedido.id_pedido === undefined && !this.props.pedido_loading) {
      this.props.getPedidoActivo("", "S");
    } else {
      this.props.getPedidosPendientes(this.props.pedido.id_pedido);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    window.wookie();
    if (this.props.id_pedido_seleccionado !== prevProps.id_pedido_seleccionado) {
      this.props.getPedidosPendientes(this.props.id_pedido_seleccionado);
    } else if (this.props.pedido.id_pedido !== prevProps.pedido.id_pedido) {
      this.props.getPedidosPendientes(this.props.pedido.id_pedido);
    }
    let ancla = window.document.getElementById("cabecera-principal");

    if (ancla) {
      ancla.scrollIntoView({ behavior: "smooth" });
    }
    if (comprobarCondiciones("pedidos_pendientes", this.props, prevProps, "S")) {
      articulos_aux = cargarArrays("pedidos_pendientes", this.props.lineas_pedido_pendiente_detalle, "precios", this.props.precio);
      if (articulos_aux && articulos_aux.length > 0) {
        //Solo llamamos a getPrecios si hai al menos un elemento en el array
        this.props.getPrecios(
          articulos_aux, //articulos_cantidades
          "pedidoPendiente", //origen
          null, //domicilio_envio
          parametros.maxim_agrup
        );
      }
    }
  }

  cargarPedido = (e) => {
    e.preventDefault();
    let _id_pedido = e.currentTarget.dataset.id_pedido;

    confirmAlert({
      title: i18n.t("pedido.precped"),
      message: i18n.t("pedido.pesrecp"),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: i18n.t("general.si"),
          onClick: () => {
            this.props.setIdPedidoSeleccionado(_id_pedido);
            this.props.getPedidoActivo("", "S", _id_pedido);
          },
        },
        {
          label: i18n.t("general.no"),
        },
      ],
    });
  };

  eliminarPedido = (e) => {
    e.preventDefault();
    let idPedido = e.currentTarget.dataset.id_pedido;
    confirmAlert({
      title: i18n.t("pedido.pelimped"),
      message: i18n.t("pedido.peselped"),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: i18n.t("general.si"),
          onClick: () => this.props.eliminarPedido(idPedido, "", idPedido, "pendientes"),
        },
        {
          label: i18n.t("general.no"),
        },
      ],
    });
  };

  lineas = (e) => {
    e.preventDefault();
    let _id_pedido = e.currentTarget.dataset.id_pedido;
    this.props.setPedido(_id_pedido);
    this.verLineas(e);
  };

  cerrarLineas = () => {
    this.setState({ mostrar_lineas: false });
  };

  verLineas = (e) => {
    e.preventDefault();
    this.setState({ mostrar_lineas: true });
  };

  render() {
    parametros = cargarParametros(parametros_usados, this.props.portal.parametros_ecom, "PEDP");
    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent" className="pedidos-pendientes">
            {this.state.mostrar_lineas === false ? (
              <div className="container-indent">
                <div className="container">
                  <h1 className="tt-title-subpages noborder">{i18n.t("pedido.ppedpend")}</h1>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <Spinner showSpinner={this.props.pedidos_pendientes_loading} />
                      {this.props.pedidos_pendientes.length === 0 && !this.props.pedidos_pendientes_loading ? (
                        <h5>{i18n.t("pedido.pnepedpe")}</h5>
                      ) : (
                        <Fragment>
                          <div className="tt-shopcart-table pedidos_pendientes_desctop row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                              {this.props.pedidos_pendientes.length > 0 ? (
                                <Fragment>
                                  <div className="row titulos">
                                    <div className="col-sm-12 col-md-4 col-lg-4">{i18n.t("pedido.pfechped")}</div>
                                    <div className="col-sm-12 col-md-4 col-lg-4">{i18n.t("pedido.pimprt")}</div>
                                    <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("pedido.paccns")}</div>
                                  </div>

                                  {this.props.pedidos_pendientes.map((pedido) => {
                                    return pedido.lineas === undefined || pedido.lineas.length === 0 ? (
                                      ""
                                    ) : (
                                      <div className="row pedidos-pendientes-row" key={pedido.id_pedido}>
                                        <div className="col-sm-12 col-md-4 col-lg-4">{new Date(Date.parse(pedido.fecha_pedido)).toLocaleDateString(this.props.locale)}</div>
                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                          {pedido.lineas === undefined || pedido.lineas.length === 0
                                            ? 0
                                            : Intl.NumberFormat(this.props.locale, {
                                                style: "currency",
                                                currency: this.props.moneda,
                                              }).format(
                                                Number.parseFloat(
                                                  pedido.lineas
                                                    .map((linea) => {
                                                      return Number.parseFloat(linea.precio_venta);
                                                    })
                                                    .reduce((accumulator, currentValue) => accumulator + currentValue)
                                                ).toFixed(2)
                                              )}
                                        </div>
                                        <div className="col-sm-12 col-md-1 col-lg-1">
                                          <button className="btn btn-primary" onClick={this.lineas} data-id_pedido={pedido.id_pedido}>
                                            <Eye size={22} weight={"regular"} />
                                          </button>
                                        </div>
                                        <div className="col-sm-12 col-md-1 col-lg-1">
                                          <button className="btn btn-primary" onClick={this.cargarPedido} data-id_pedido={pedido.id_pedido}>
                                            <Upload size={22} weight={"regular"} />
                                          </button>
                                        </div>
                                        <div className="col-sm-12 col-md-1 col-lg-1">
                                          <button className="btn btn-primary" onClick={this.eliminarPedido} data-id_pedido={pedido.id_pedido}>
                                            <Trash size={22} weight={"regular"} />
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </Fragment>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="tt-shopcart-table pedidos_pendientes_mobile row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                              {this.props.pedidos_pendientes.map((pedido) => {
                                return (
                                  <div className="row pedidos-pendientes-row" key={pedido.id_pedido}>
                                    <div className="col-12">
                                      <div className="row element-row">
                                        <div className="col-6 titulos">{i18n.t("pedido.pfechped")}</div>
                                        <div className="col-6">{new Date(Date.parse(pedido.fecha_pedido)).toLocaleDateString(this.props.locale)}</div>
                                      </div>
                                      <div className="row element-row">
                                        <div className="col-6 titulos">{i18n.t("pedido.pimprt")}</div>
                                        <div className="col-6">
                                          {pedido.lineas === undefined || pedido.lineas.length === 0
                                            ? 0
                                            : Intl.NumberFormat(this.props.locale, {
                                                style: "currency",
                                                currency: this.props.moneda,
                                              }).format(
                                                Number.parseFloat(
                                                  pedido.lineas
                                                    .map((linea) => {
                                                      return Number.parseFloat(linea.precio_venta);
                                                    })
                                                    .reduce((accumulator, currentValue) => accumulator + currentValue)
                                                ).toFixed(2)
                                              )}
                                        </div>
                                      </div>
                                      <div className="row element-row">
                                        <div className="col-6 titulos">{i18n.t("pedido.paccns")}</div>
                                        <div className="col-6">
                                          <div className="row">
                                            <div className="col-3">
                                              <button className="btn btn-primary pedidos-pendientes-accion" onClick={this.lineas} data-id_pedido={pedido.id_pedido}>
                                                <Eye size={22} weight={"regular"} />
                                              </button>
                                            </div>
                                            <div className="col-3">
                                              {pedido.id_pedido === "" ? (
                                                ""
                                              ) : (
                                                <button className="btn btn-primary" onClick={this.cargarPedido} data-id_pedido={pedido.id_pedido}>
                                                  <Upload size={22} weight={"regular"} />
                                                </button>
                                              )}
                                            </div>
                                            <div className="col-3">
                                              {pedido.id_pedido === "" ? (
                                                ""
                                              ) : (
                                                <button className="btn btn-primary" onClick={this.eliminarPedido} data-id_pedido={pedido.id_pedido}>
                                                  <Trash size={22} weight={"regular"} />
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {this.state.mostrar_lineas === true ? (
              <Fragment>
                {this.props.lineas_pedido_pendiente_detalle.length === 0 || this.props.pedido_pendiente_detalle === 0 ? (
                  <div className="container-indent">
                    <div className="container modal-body">
                      <h5 onClick={this.cerrarLineas} className="atras-listas">
                        <ArrowLineLeft size={22} weight={"bold"} /> {i18n.t("pedido.ppendmin")}
                      </h5>
                      <h1 className="tt-title-subpages noborder">{i18n.t("pedido.plineasp")}</h1>
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <p>{i18n.t("pedido.pnhlinep")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="container-indent">
                    <div className="container modal-body">
                      <h5 onClick={this.cerrarLineas} className="atras-listas">
                        <ArrowLineLeft size={22} weight={"bold"} /> {i18n.t("pedido.ppendmin")}
                      </h5>
                      <h1 className="tt-title-subpages noborder">{i18n.t("pedido.plineasp")}</h1>
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="tt-shopcart-table pedidos_pendientes_detalle_desctop row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                              <div className="row titulos">
                                <div className="col-sm-12 col-md-2 col-lg-2">{i18n.t("pedido.partcl")}</div>
                                <div className="col-sm-12 col-md-2 col-lg-4">{i18n.t("pedido.pdescr")}</div>
                                <div className="col-sm-12 col-md-1 col-lg-2">{i18n.t("pedido.prec")}</div>
                                <div className="col-sm-12 col-md-1 col-lg-2">{i18n.t("pedido.pcant")}</div>
                                <div className="col-sm-12 col-md-2 col-lg-2">{i18n.t("pedido.ppresnt")}</div>
                              </div>

                              {this.props.lineas_pedido_pendiente_detalle.map((linea) => {
                                return (
                                  <div className="row pedidos-pendientes-row" key={linea.id}>
                                    <div className="col-sm-12 col-md-2 col-lg-2">{linea.articulo}</div>
                                    <div className="col-sm-12 col-md-2 col-lg-4">
                                      <Descripcion codigo_articulo={linea.articulo} />
                                    </div>
                                    <div className="col-sm-12 col-md-1 col-lg-2">
                                      <Precio codigo_articulo={linea.articulo} mostrar_iva={parametros.mostrar_iva} tipo_precio={parametros.tipo_precio} />
                                    </div>
                                    <div className="col-sm-12 col-md-1 col-lg-2">{linea.cantidad_pedida}</div>
                                    <div className="col-sm-12 col-md-2 col-lg-2">{linea.presentacion_pedido}</div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <div className="tt-shopcart-table pedidos_pendientes_detalle_mobile row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                              {this.props.lineas_pedido_pendiente_detalle.map((linea) => {
                                return (
                                  <div className="row pedidos-pendientes-row">
                                    <div className="col-12">
                                      <div className="row element-row">
                                        <div className="col-6 titulos">{i18n.t("pedido.partcl")}</div>
                                        <div className="col-6">{linea.articulo}</div>
                                      </div>

                                      <div className="row element-row">
                                        <div className="col-6 titulos">{i18n.t("pedido.pdescr")}</div>
                                        <div className="col-6">
                                          <Descripcion codigo_articulo={linea.articulo} />
                                        </div>
                                      </div>

                                      <div className="row element-row">
                                        <div className="col-6 titulos">{i18n.t("pedido.pprec")}</div>
                                        <div className="col-6">
                                          <Precio codigo_articulo={linea.articulo} mostrar_iva={parametros.mostrar_iva} tipo_precio={parametros.tipo_precio} />
                                        </div>
                                      </div>

                                      <div className="row element-row">
                                        <div className="col-6 titulos">{i18n.t("pedido.pcant")}</div>
                                        <div className="col-6">{linea.cantidad_pedida}</div>
                                      </div>

                                      <div className="row element-row">
                                        <div className="col-6 titulos">{i18n.t("pedio.ppresnt")}</div>
                                        <div className="col-6">{linea.presentacion_pedido}</div>
                                      </div>

                                      <div className="row element-row">
                                        <div className="col-6 titulos">{i18n.t("pedido.pobsr")}</div>
                                        <div className="col-6">{linea.observaciones}</div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  portal: state.portal.portal,
  pedidos_pendientes: state.pedidosPendientes.pedidos_pendientes,
  pedido_pendiente_detalle: state.pedidosPendientes.pedido_pendiente_detalle,
  lineas_pedido_pendiente_detalle: state.pedidosPendientes.lineas_pedido_pendiente_detalle,
  precio: state.precio.precio,
  descripciones: state.descripcion.descripciones,
  locale: state.precio.locale,
  moneda: state.precio.moneda,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
  pedido: state.pedidos.pedido,
  pedido_loading: state.pedidos.isLoading,
  pedidos_pendientes_loading: state.pedidosPendientes.isLoading,
});

export default connect(mapStateToProps, {
  getPedidosPendientes,
  setPedido,
  postLinea,
  postLineas,
  getPedidoActivo,
  setIdPedidoSeleccionado,
  eliminarPedido,
  getPrecios,
})(ListasCompra);
