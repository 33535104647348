import {
  FETCH_ARTICULO,
  GET_ARTICULO,
  FETCH_ARTICULOS_DOC,
  GET_ARTICULOS_DOC,
  FETCH_ARTICULOS_AUX,
  GET_ARTICULOS_AUX,
  GET_STOCK_ARTICULO_FICHA,
  FETCH_CODIGOS_BARRAS,
  GET_CODIGOS_BARRAS,
  FETCH_CADENA_LOGISTICA,
  GET_CADENA_LOGISTICA,
  FETCH_STOCK_ARTICULO,
  FETCH_CARACTERISTICAS_ARTICULO,
  GET_CARACTERISTICAS_ARTICULO,
} from "../actions/types.js";

const initialState = {
  articulo: {},
  articulos_doc: [],
  articulos_aux: [],
  codigos_barras: [],
  cadena_logistica: {},
  caracteristicas: [],
  isLoading: false,
};

let articulo_nuevo;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ARTICULO:
      return {
        ...state,
        articulo: {},
        isLoading: true,
      };
    case GET_ARTICULO:
      return {
        ...state,
        isLoading: false,
        articulo: action.payload,
      };
    case FETCH_ARTICULOS_DOC:
      return {
        ...state,
        articulos_doc: [],
        isLoading: true,
      };
    case GET_ARTICULOS_DOC:
      return {
        ...state,
        articulos_doc: action.payload,
        isLoading: false,
      };
    case FETCH_ARTICULOS_AUX:
      return {
        ...state,
        articulos_aux: [],
        isLoading: true,
      };
    case GET_ARTICULOS_AUX:
      return {
        ...state,
        articulos_aux: action.payload,
        isLoading: false,
      };

    case FETCH_STOCK_ARTICULO:
      articulo_nuevo = JSON.parse(JSON.stringify(state.articulo));
      articulo_nuevo["info_stock"] = {};

      return {
        ...state,
        articulo: articulo_nuevo,
        isLoading: true,
      };

    case GET_STOCK_ARTICULO_FICHA:
      articulo_nuevo = JSON.parse(JSON.stringify(state.articulo));
      articulo_nuevo["info_stock"] = action.payload;

      return {
        ...state,
        articulo: articulo_nuevo,
        isLoading: false,
      };
    case FETCH_CODIGOS_BARRAS:
      return {
        ...state,
        codigos_barras: [],
        isLoading: true,
      };
    case GET_CODIGOS_BARRAS:
      return {
        ...state,
        isLoading: false,
        codigos_barras: action.payload,
      };

    case FETCH_CADENA_LOGISTICA:
      return {
        ...state,
        cadena_logistica: {},
        isLoading: true,
      };
    case GET_CADENA_LOGISTICA:
      return {
        ...state,
        isLoading: false,
        cadena_logistica: action.payload,
      };
    case FETCH_CARACTERISTICAS_ARTICULO:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CARACTERISTICAS_ARTICULO:
      let caracteristicas_aux = {
        articulo: action.articulo,
        caracteristicas_articulo: action.payload,
      };

      let caracteristicas_aux_filtradas = state.caracteristicas.filter((caract) => caract.articulo !== action.articulo);

      return {
        ...state,
        loading: false,
        caracteristicas: [...caracteristicas_aux_filtradas, caracteristicas_aux],
      };

    default:
      return state;
  }
}
