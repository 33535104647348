import React, { Component } from "react";
import Spinner from "../../layout/Spinner";
import { connect } from "react-redux";
import { ArrowLineLeft, ShoppingCart, X } from "phosphor-react";
import "../styles/plantillas.css";
import Precio from "../../ecommerce/Precio";
import { Link } from "react-router-dom";
//Importar Acciones
import { setPlantillaSeleccionada, toglePlantillaDetails, crearPedido, togglePostLoading } from "../actions/plantillas";
import { postLinea } from "../../../actions/pedidos";
import { getPrecio } from "../../../actions/precio";
import { cargarParametros } from "../../../helpers/funciones";
import { createMessage } from "../../../actions/messages";
import { nuevaReceta } from "../../../actions/modalGenerica";
import { limpiarPresentacion } from "../../../actions/presentaciones";
import createLines from "../functions/createLines";
import { CANTIDAD_NO_VALIDA, NO_ESTA_EL_PRECIO } from "../functions/addToCart";
import DatosPlantilla from "./DatosPlantilla";
import i18n from "../../../lang/idiomas";

let cantidades = {};
/**@var {string[]} estados */

class DetallesPlantilla extends Component {
  state = {
    responses: [],
  };

  sendResponse = (data) => {
    this.setState((state) => {
      return { ...state, responses: [...state.responses, data.message] };
    });
  };

  mapPropsToCreateLine(props, state) {
    this.props.togglePostLoading(true);
    this.setState((state) => {
      return { ...state, responses: [] };
    });
    cantidades = {};
    const articulos = props.plantilla_details.articulos;
    for (let i = 0; i < articulos.length; i++) {
      const input = document.querySelector("#in-" + articulos[i].codigo_articulo);
      cantidades[articulos[i].codigo_articulo] = input.value;
      if (!input.value) return;
    }

    createLines(
      state,
      props.plantilla_details,
      props.pedido,
      props.pedido_plantilla,
      "O",
      props.plantilla_details.plantilla_det[0].codigo_plantilla,
      props.usuario,
      cantidades,
      props.hash,
      props.precio,
      props.presentacion,
      props.subpresentacion,
      props.postLinea,
      props.nuevaReceta,
      this.sendResponse,
      this.props.togglePostLoading
    );
  }

  onCantidadChange(e, element) {
    cantidades[element.codigo_articulo] = e.target.value;
  }

  componentWillUnmount() {
    this.props.setPlantillaSeleccionada(null);
  }

  render() {
    return (
      <>
        <DatosPlantilla />
        <div className="tt-shopcart-table listas_compra_detalle_desctop row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="row titulos">
              <div className="col-sm-12 col-md-2 col-lg-3">{i18n.t("plantillas.parti")}</div>
              <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("plantillas.pdescrip")}</div>
              <div className="col-sm-12 col-md-2 col-lg-3">{i18n.t("plantillas.plprecio")}</div>
              <div className="col-sm-12 col-md-1 col-lg-3">{i18n.t("plantillas.pcantid")}</div>
            </div>
            <span style={{ display: "block", background: "#2b2b2b", opacity: 0.12, marginBottom: "10px", width: "100%", height: "2px", marginTop: "5px", borderRadius: "1000px" }}></span>
            {this.props.plantilla_details ? (
              this.props.plantilla_details.articulos.map((element, index) => {
                return (
                  <div key={`art${element.id}-l1`} className="row lista-plantilla-row">
                    <div className="col-sm-12 col-md-3 col-lg-3">
                      <Link
                        to={`/articulo/${element.codigo_articulo}/${
                          element.descrip_comercial ? element.descrip_comercial.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase() : ""
                        }`}
                      >
                        {element.codigo_articulo}
                      </Link>
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3">{element.descrip_comercial}</div>
                    <div className="col-sm-12 col-md-3 col-lg-3">
                      <Precio cantidad={10} codigo_articulo={element.codigo_articulo} origen="ficha" />
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3">
                      <input
                        className="quantity-input input-cantidad-listas prueba"
                        type="number"
                        tabIndex={1}
                        name="cantidad"
                        id={"in-" + element.codigo_articulo}
                        key={element.codigo_articulo}
                        // value={this.props.plantilla_details.plantilla_det[index].cantidad_minima ?? 0}
                        placeholder={this.props.plantilla_details.plantilla_det[index].cantidad_minima}
                        size="5"
                        // onChange={this.onChange}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>

        <div style={{ marginTop: "10px" }} className="tt-shopcart-table listas_compra_detalle_mobile row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            {this.props.plantilla_details ? (
              this.props.plantilla_details.articulos.map((element, index) => {
                return (
                  <div className="row lista-compra-row" key={"line" + element.descrip_comercial}>
                    <div className="col-12">
                      <div className="row element-row">
                        <div className="col-5 titulos">{i18n.t("plantillas.parti")}</div>
                        <div className="col-7">
                          <Link
                            to={`/articulo/${element.codigo_articulo}/${
                              element.descrip_comercial ? element.descrip_comercial.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase() : ""
                            }`}
                          >
                            {element.codigo_articulo}
                          </Link>
                        </div>
                      </div>

                      <div className="row element-row">
                        <div className="col-5 titulos">{i18n.t("plantillas.pdescrip")}</div>
                        <div className="col-7">{element.descrip_comercial}</div>
                      </div>

                      <div className="row element-row">
                        <div className="col-5 titulos">{i18n.t("plantillas.plprecio")}</div>
                        <Precio cantidad={10} codigo_articulo={element.codigo_articulo} origen="ficha" />
                      </div>

                      <div className="row element-row">
                        <div className="col-5 titulos">{i18n.t("plantillas.pcantid")}</div>
                        <div className="col-7">
                          <input
                            className="quantity-input input-cantidad-listas prueba"
                            type="number"
                            name="cantidad"
                            id={"in-" + element.codigo_articulo}
                            key={"elemInp" + element.codigo_articulo}
                            value={cantidades[element.codigo_articulo ?? null]}
                            placeholder={this.props.plantilla_details.plantilla_det[index].cantidad_minima}
                            size="5"
                            onChange={() => {}}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
        {this.props.plantilla_details?.articulos?.length === 0 ? (
          <span className="plantilla-vacia">{this.props.isLoading ? i18n.t("plantillas.pcardato") : i18n.t("plantillas.pnoartep")}</span>
        ) : (
          <></>
        )}
        <span style={{ marginTop: "40px", display: "block" }}></span>

        {this.state.responses.length > 0 ? (
          <div className="alert-holder container">
            <div className="row messages-titles-plantilla">
              <div className="col-11">{i18n.t("plantillas.pmsg")}</div>
              <div
                onClick={() => {
                  this.setState((state) => {
                    return {
                      ...state,
                      responses: [],
                    };
                  });
                }}
                className="col-1 exit-holder"
              >
                <X />
              </div>
            </div>
            {this.state.responses.map((element) => {
              return (
                <div key={"alert-error" + element} class="alert alert-danger" role="alert">
                  {element}
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}

        {this.props.loadingPost ? (
          <div className="spinner-holder">
            <Spinner showSpinner={this.props.loadingPost || this.props.isLoading || this.props.pedidos_loading} />
          </div>
        ) : (
          <></>
        )}
        <div className="row btn-holder">
          <button
            disabled={this.props.loadingPost || this.props.isLoading || this.props.plantilla_details?.articulos?.length === 0}
            onClick={() => {
              if (this.props.plantilla_details && this.props.plantilla_details.plantilla_det[0]) {
                this.setState((state) => {
                  return { ...state, responses: [] };
                });
                this.props.togglePostLoading(true);
                const articulos = this.props.plantilla_details.articulos;
                for (let i = 0; i < articulos.length; i++) {
                  let stop = false;
                  const input = document.querySelector("#in-" + articulos[i].codigo_articulo);
                  this.props.togglePostLoading(false);
                  if (!input.value || this.props.plantilla_details.plantilla_det[i].cantidad_minima > input.value) {
                    this.sendResponse({
                      articulo: articulos[i].codigo_articulo,
                      message: CANTIDAD_NO_VALIDA + `${i18n.t("plantillas.pdelarti")} ` + articulos[i].descrip_comercial,
                    });
                    stop = true;
                  }
                  const artEnPrecio = this.props.precio[articulos[i].codigo_articulo];
                  const EXISTE_PRECIO_PEDIDO = artEnPrecio && artEnPrecio.error === false && artEnPrecio.isLoading === false && artEnPrecio.data;
                  if (!EXISTE_PRECIO_PEDIDO) {
                    this.sendResponse({
                      articulo: articulos[i],
                      message: NO_ESTA_EL_PRECIO + `${i18n.t("plantillas.pdelarti")} ` + articulos[i].descrip_comercial,
                    });
                    stop = true;
                  }
                  if (i === articulos.length - 1 && stop) return;
                }
                this.props.crearPedido(this.props.plantilla_details.plantilla_det[0].codigo_plantilla, (state) => {
                  this.mapPropsToCreateLine(this.props, state);
                });
              }
            }}
            className="btn btn-primary btn-crear-pedido-plantilla col-md-2 col-lg-2 col-sm-12"
          >
            {this.props.loadingPost ? i18n.t("plantillas.pcreando") : i18n.t("plantillas.pcrear")}{" "}
          </button>
        </div>
        <span className="cart-warn-plantillas">{i18n.t("plantillas.pclcart")}</span>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  plantilla_details: state.plantillas.plantilla_details,
  precio: state.precio.precio,
  usuario: state.auth.user,
  subpresentacion: state.subpresentaciones.subpresentacion,
  presentacion: state.presentaciones.presentacion,
  pedido: state.pedidos.pedido,
  pedido_plantilla: state.plantillas.pedido_creado_plantilla,
  hash: state.auth.hash,
  pedidos_loading: state.pedidos.isLoading || state.pedidos.isLoadingLines,
  isLoading: state.plantillas.isLoading,
  loadingPost: state.plantillas.loadingPost,
});
export default connect(mapStateToProps, { toglePlantillaDetails, crearPedido, postLinea, nuevaReceta, getPrecio, limpiarPresentacion, createMessage, togglePostLoading, setPlantillaSeleccionada })(
  DetallesPlantilla
);
