import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import { setValorCampoExpediente } from "../../actions/expedientes";
import es from "date-fns/locale/es";
registerLocale("es", es);

export class InputFecha extends Component {
  state = {
    valor: "",
    fecha: "",
  };

  static propTypes = {
    setValorCampoExpediente: PropTypes.func.isRequired,
  };

  handleChange = (date) => {
    if (date) {
      let fecha = this.procesarFecha(date);
      this.setState({
        valor: date,
        fecha: fecha,
      });
      let date_proc = date.toJSON();
      this.props.setValorCampoExpediente(this.props.campo.columna, date_proc);
      this.props.handleBlur(this.props.campo.columna, date_proc);
      this.props.handleChange(this.props.campo.columna, date_proc);
    } else {
      this.setState({
        valor: date,
        fecha: date,
      });
    }
  };

  handleBlur = (e) => {
    this.setState({ codigo: e.value });
    this.props.handleBlur(this.props.campo.columna, e.value);
  };

  procesarFecha(date) {
    let fechaMuestra = date.toLocaleDateString();
    let hora = date.toTimeString();
    let longitud = hora.indexOf("GMT") - 1;
    hora = hora.substring(0, longitud);
    return fechaMuestra + " " + hora;
  }

  render() {
    const InputFechaDatepicker = ({ onClick }) => (
      <input
        id={this.props.campo.campo}
        className="form-control"
        type="text"
        value={this.state.fecha}
        name={this.props.campo.campo}
        placeholder={this.props.campo.literal}
        required={this.props.campo.obligatorio === "S"}
        onClick={onClick}
        readOnly
      />
    );

    return (
      <Fragment>
        <div className="form-group fecha" key={this.props.index}>
          <label htmlFor={this.props.campo.campo}>{this.props.campo.literal}</label>
          <DatePicker
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            isClearable
            selected={this.state.valor}
            dateFormat="dd/MM/yyyy HH:MM:ss"
            todayButton="Hoy"
            showTimeSelect={true}
            timeIntervals={15}
            locale="es"
            customInput={<InputFechaDatepicker />}
            className="form-control"
            name={this.props.campo.campo}
            placeholderText={this.props.campo.literal}
            required={this.props.campo.obligatorio === "S"}
          />
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  lov: state.expedientes.valores_lovs,
  tipos_expedientes: state.expedientes.tipos_expedientes,
  tipo_expediente_seleccionado: state.expedientes.tipo_expediente_seleccionado,
  auth: state.auth,
});
export default connect(mapStateToProps, { setValorCampoExpediente })(InputFecha);
