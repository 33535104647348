import React, { Component } from "react";
import { ArrowLineLeft, Eye, FloppyDiskBack, ShoppingCart, Trash } from "phosphor-react";
import { connect } from "react-redux";
import { setPlantillaSeleccionada, getDetallePlantila, toglePlantillaDetails } from "../actions/plantillas";
import i18n from "../../../lang/idiomas";
import axios from "../../../axiosConfig";

class ListaPlantillas extends Component {
  render() {
    const lista = this.props.lista_plantillas;
    return this.props.lista_plantillas ? (
      <>
        <div style={{ marginTop: "10px" }} className="tt-shopcart-table listas_compra_detalle_desctop row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="row titulos">
              <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("plantillas.pdescrip")}</div>
              <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("plantillas.pcod")}</div>
              <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("plantillas.pfin")}</div>
              <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("plantillas.paccnes")}</div>
            </div>
            <span style={{ display: "block", background: "#2b2b2b", opacity: 0.12, width: "100%", height: "2px", marginTop: "5px", borderRadius: "1000px" }}></span>
            {lista.map((element) => {
              return (
                <div key={`plantilla${element.id}`} className="row lista-compra-row">
                  <div className="col-sm-12 col-md-3 col-lg-3">{element.descripcion}</div>
                  <div className="col-sm-12 col-md-3 col-lg-3">{element.codigo_plantilla}</div>
                  <div className="col-sm-12 col-md-3 col-lg-3">{new Date(Date.parse(element.fecha_fin)).toLocaleDateString(this.props.locale)}</div>
                  <div className="col-sm-12 col-md-3 col-lg-3">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.props.getDetallePlantila(element.codigo_plantilla);
                            this.props.toglePlantillaDetails(true);
                            this.props.setPlantillaSeleccionada(element);
                          }}
                          data-codigo-lista={lista.codigo_lista}
                          disabled={this.props.plantillas_loading}
                        >
                          <Eye size={22} weight={"regular"} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div style={{ marginTop: "10px" }} className="tt-shopcart-table listas_compra_detalle_mobile row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            {lista.map((element) => {
              return (
                <div className="row lista-compra-row" key={element.descripcion}>
                  <div className="col-12">
                    <div className="row element-row">
                      <div className="col-5 titulos">{i18n.t("plantillas.pdescrip")}</div>
                      <div className="col-7">{element.descripcion}</div>
                    </div>

                    <div className="row element-row">
                      <div className="col-5 titulos">{i18n.t("plantillas.pcod")}</div>
                      <div className="col-7">{element.codigo_plantilla}</div>
                    </div>

                    <div className="row element-row">
                      <div className="col-5 titulos">{i18n.t("plantillas.pfin")}</div>
                      <div className="col-7">{new Date(Date.parse(element.fecha_fin)).toLocaleDateString(this.props.locale)}</div>
                    </div>

                    <div className="row element-row">
                      <div className="col-5 titulos">{i18n.t("plntillas.paccnes")}</div>
                      <div className="col-7">
                        <div className="row">
                          <button
                            className="btn btn-primary col-12"
                            onClick={() => {
                              this.props.getDetallePlantila(element.codigo_plantilla);
                              this.props.toglePlantillaDetails(true);
                              this.props.setPlantillaSeleccionada(element);
                            }}
                            data-codigo-lista={lista.codigo_lista}
                            disabled={this.props.plantillas_loading}
                          >
                            <Eye size={22} weight={"regular"} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    ) : (
      <></>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.precio.locale,
  portal: state.portal.portal,
  plantillas: state.plantillas,
  lista_plantillas: state.plantillas.lista_plantillas,
  plantillas_loading: state.plantillas.isLoading,
  plantilla_details: state.plantillas.plantilla_details,
});

export default connect(mapStateToProps, { getDetallePlantila, setPlantillaSeleccionada, toglePlantillaDetails })(ListaPlantillas);
