import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getPresentaciones, setPresentacion } from "../../../actions/presentaciones";
import { getPrecio } from "../../../actions/precio";
import i18n from "../../../lang/idiomas";

export class Presentaciones extends Component {
  static propTypes = {
    articulo: PropTypes.string.isRequired,
    presentaciones: PropTypes.object.isRequired,
    presentacion: PropTypes.string.isRequired,
    getPresentaciones: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.articulo) {
      this.props.getPresentaciones(this.props.articulo);
    }
  }

  componentDidUpdate(prevProps) {
    window.wookie();
  }

  /*componentDidUpdate(prevProps, prevState) {
    if (prevProps.articulo !== this.props.articulo) {
      this.setState({ articulo: this.props.articulo });
    }

  }*/

  onclick = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      const presentacion = this.props.presentaciones[this.props.articulo].filter((presentacion) => presentacion.presentacion === e.target.value);

      this.props.setPresentacion(presentacion[0]);
    }
  };

  render() {
    return (
      <Fragment>
        {this.props.presentaciones && this.props.presentaciones[this.props.articulo] && this.props.presentaciones[this.props.articulo].length > 0 ? (
          <div className="row presentaciones">
            <div className="col">
              {this.props.oculta_titulo && this.props.oculta_titulo === true ? "" : <div className="titulo-presentaciones">{i18n.t("eparciales.eppresen")}</div>}

              {this.props.presentaciones[this.props.articulo].map((presentacion, index) => {
                return (
                  <button
                    key={index}
                    className={
                      this.props.presentacion && presentacion.articulo === this.props.presentacion.articulo && presentacion.presentacion === this.props.presentacion.presentacion
                        ? "btn btn-small btn-primary"
                        : "btn btn-small btn-border"
                    }
                    name="presentacion"
                    value={presentacion.presentacion}
                    onClick={this.onclick}
                  >
                    {presentacion.presentacion}
                  </button>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  presentaciones: state.presentaciones.presentaciones,
  presentacion: state.presentaciones.presentacion,
});

export default connect(mapStateToProps, {
  getPresentaciones,
  getPrecio,
  setPresentacion,
})(Presentaciones);
