import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { enviarRegistro } from "../../actions/auth";
import { ordenarMenu } from "../../helpers/funciones";
import { createMessage } from "../../actions/messages";
import i18n from "../../lang/idiomas";

export class Registro extends Component {
  state = {
    nombre: "",
    cliente: "",
    mensaje: "",
    email: "",
    usuario_web: "",
    terminos_condiciones: false,
    politica_cookies: false,
    politica_privacidad: false,
  };

  static propTypes = {
    enviarRegistro: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    portal: PropTypes.object.isRequired,
    menus: PropTypes.array.isRequired,
    hash: PropTypes.string,
    invitado: PropTypes.bool,
    createMessage: PropTypes.func.isRequired,
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.terminos_condiciones) {
      this.props.createMessage(i18n.t("usuarios.udeaccon"), "warning");
    } else if (!this.state.politica_cookies) {
      this.props.createMessage(i18n.t("usuarios.udeaccoo"), "warning");
    } else if (!this.state.politica_privacidad) {
      this.props.createMessage(i18n.t("usuarios.udeacpop"), "warning");
    } else if (!this.state.usuario_web.match(/^([a-zA-Z0-9_-]+)$/)) {
      this.props.createMessage(i18n.t("usuarios.uelsolac"), "error");
    } else if (!this.state.email.match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i)) {
      this.props.createMessage(i18n.t("usuarios.uelemnov"), "error");
    } else if (!this.state.cliente.match(/^([a-zA-Z0-9]+)$/)) {
      this.props.createMessage(i18n.t("usuarios.uelcdcnv"), "error");
    } else {
      this.props.enviarRegistro(this.state.nombre, this.state.cliente, this.state.mensaje, this.state.email, this.state.usuario_web);
      this.setState({
        nombre: "",
        cliente: "",
        mensaje: "",
        email: "",
        usuario_web: "",
      });
    }
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { nombre, cliente, mensaje, email, usuario_web } = this.state;
    const { portal } = this.props;
    const menus = this.props.menus;

    if (menus && menus.length > 0) {
      menus.sort(ordenarMenu);

      if (this.props.isAuthenticated && this.props.invitado === false) {
        const irA = menus.find((menu) => menu.activo === "S" && menu.programa);

        if (irA) {
          return <Navigate to={"/" + irA.programa} />;
        } else {
          return "";
        }
      }
    }

    return (
      <div id="tt-pageContent" className="registro">
        <div class="container-indent">
          <div className="container">
            <a className="tt-logo" href="index.html">
              <img className="tt-retina" src="images/custom/logo.png" alt="" />
            </a>
            <h1 className="tt-title-subpages noborder">{portal.descripcion}</h1>
            <div class="tt-login-form">
              <div className="row	justify-content-center">
                <div className="col-md-5">
                  <div class="tt-item">
                    <h2 className="tt-title">{i18n.t("usuarios.uformreg")}</h2>
                    <div className="form-default justify-content-center">
                      <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                          <label htmlFor="input_nombre">{i18n.t("usuarios.unommin")}</label>
                          <input className="form-control" type="text" name="nombre" id="input_nombre" onChange={this.onChange} value={nombre} placeholder={i18n.t("usuarios.unoemes")} required />
                        </div>
                        <div className="form-group">
                          <label htmlFor="input_cliente">{i18n.t("usuarios.ucoclmin")}</label>
                          <input className="form-control" type="text" name="cliente" id="input_cliente" onChange={this.onChange} value={cliente} placeholder={i18n.t("usuarios.upoenalb")} required />
                        </div>
                        <div className="form-group">
                          <label htmlFor="input_email">{i18n.t("usuarios.uemlmin")}</label>
                          <input className="form-control" type="text" name="email" id="input_email" onChange={this.onChange} value={email} placeholder={i18n.t("usuarios.uemcont")} required />
                        </div>
                        <div className="form-group">
                          <label htmlFor="input_usuario_web">{i18n.t("usuarios.uusweb")}</label>
                          <input
                            className="form-control"
                            type="text"
                            name="usuario_web"
                            id="input_usuario_web"
                            onChange={this.onChange}
                            value={usuario_web}
                            placeholder={i18n.t("usuarios.uuwegus")}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="input_mensaje">{i18n.t("usuarios.umsj")}</label>
                          <textarea className="form-control" name="mensaje" id="input_mensaje" onChange={this.onChange} value={mensaje} placeholder={i18n.t("usuarios.uesumsj")} required />
                        </div>

                        <div class="form-check">
                          <input type="checkbox" class="form-check-input" id="terminos_condiciones" name="terminos_condiciones" onChange={this.onChange} checked={this.state.terminos_condiciones} />
                          <label class="form-check-label" for="terminos_condiciones">
                            {i18n.t("usuarios.uhelyac")}
                          </label>
                        </div>
                        <div class="form-check">
                          <input type="checkbox" class="form-check-input" id="politica_cookies" name="politica_cookies" onChange={this.onChange} checked={this.state.politica_cookies} />
                          <label class="form-check-label" for="politica_cookies">
                            {i18n.t("usuarios.uhlyacoo")}
                          </label>
                        </div>
                        <div class="form-check">
                          <input type="checkbox" class="form-check-input" id="politica_privacidad" name="politica_privacidad" onChange={this.onChange} checked={this.state.politica_privacidad} />
                          <label class="form-check-label" for="politica_privacidad">
                            {i18n.t("usuarios.uhlyapp")}
                          </label>
                        </div>

                        <div class="row">
                          <div class="col-auto mr-auto">
                            <div class="form-group">
                              <button type="submit" className="btn btn-primary">
                                {i18n.t("usuarios.uenvi")}
                              </button>
                            </div>
                          </div>
                        </div>
                        <p>
                          {i18n.t("usuarios.uyatiusr")} <Link to="/login">{i18n.t("usuarios.uaccsit")}</Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  hash: state.auth.hash,
  invitado: state.auth.invitado,
  portal: state.portal.portal,
  menus: state.portal.menus,
});

export default connect(mapStateToProps, { enviarRegistro, createMessage })(Registro);
