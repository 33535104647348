import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getDomEnvio, getEstado, getProvincia, setDireccion } from "../../actions/domiciliosEnvio";
import {
  setDireccionDomEnvioMod,
  setNombreDomEnvioMod,
  setEstadoDomEnvioMod,
  setProvinciaDomEnvioMod,
  setLocalidadDomEnvioMod,
  setCodPostalDomEnvioMod,
  setTipoPortesDomEnvioMod,
} from "../../actions/pedidos";
import { getProvincias } from "../../actions/provincias";
import { getEstados } from "../../actions/estados";
import i18n from "../../lang/idiomas";

export class DomiciliosEnvio extends Component {
  static propTypes = {
    getDomEnvio: PropTypes.func.isRequired,
    getEstado: PropTypes.func.isRequired,
    getProvincia: PropTypes.func.isRequired,
    setDireccion: PropTypes.func.isRequired,
    domicilios_envio: PropTypes.array.isRequired,
    domicilio: PropTypes.object.isRequired,
    estado: PropTypes.object.isRequired,
    provincia: PropTypes.object.isRequired,
    pedido_cargando: PropTypes.bool.isRequired,
    editable: PropTypes.bool.isRequired,
  };

  state = {
    domicilio: "",
    estado: "",
    provincia: "",
  };

  componentDidMount() {
    this.props.getDomEnvio();
    if (this.props.editable) {
      this.props.getEstados();
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.pedido_cargando !== this.props.pedido_cargando || prevProps.domicilios_envio !== this.props.domicilios_envio) && !this.props.pedido_cargando) {
      if (this.props.domicilios_envio && this.props.domicilios_envio.length === 1) {
        this.setState({ domicilio: this.props.domicilios_envio[0] });
        this.props.getEstado(this.props.domicilios_envio[0].estado);
        this.props.getProvincias(this.props.domicilios_envio[0].estado);
        this.props.getProvincia(this.props.domicilios_envio[0].estado, this.props.domicilios_envio[0].provincia);
        this.props.setDireccion(this.props.domicilios_envio[0]);
      }
      if (this.props.domicilios_envio && this.props.domicilios_envio.length > 1 && this.props.pedido.domicilio_envio && this.props.pedido.domicilio_envio !== "") {
        const dom_env = this.props.domicilios_envio.filter((domicilio) => domicilio.numero_direccion === parseInt(this.props.pedido.domicilio_envio));

        this.props.setDireccion(dom_env[0]);
      }
    }
  }

  onChange = (e) => {
    if (e.target.name === "domicilio" && e.target.value !== "") {
      const direccion = this.props.domicilios_envio.filter((dom) => dom.id === parseInt(e.target.value));
      this.setState({ [e.target.name]: direccion[0] });
      this.props.getEstado(direccion[0].estado);
      this.props.getProvincias(direccion[0].estado);
      this.props.getProvincia(direccion[0].estado, direccion[0].provincia);
      this.props.setDireccion(direccion[0]);
      this.props.setNombreDomEnvioMod(null);
      this.props.setDireccionDomEnvioMod(null);
      this.props.setLocalidadDomEnvioMod(null);
      this.props.setEstadoDomEnvioMod(null);
      this.props.setProvinciaDomEnvioMod(null);
      this.props.setCodPostalDomEnvioMod(null);
    } else {
      if (e.target.name === "estado") {
        this.props.getProvincias(e.target.value);
        this.props.setEstadoDomEnvioMod(e.target.value);
      } else if (e.target.name === "provincia") {
        this.props.setProvinciaDomEnvioMod(e.target.value);
      } else {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  render() {
    return (
      <Fragment>
        <div className="tt-shopcart-box domicilio-envio-box">
          <h4 className="tt-title">{i18n.t('domicilios.direnvio')}</h4>
          <p>{i18n.t('domicilios.seldiren')}</p>
          <form className="form-default">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="form-group">
                  <label htmlFor="input_domicilio">
                    {i18n.t('domicilios.domicil')} <sup>*</sup>
                  </label>
                  <select className="form-control" id="input_domicilio" name="domicilio" onChange={this.onChange} disabled={this.props.pedido_cargando || this.props.pedidoGuardado ? true : false}>
                    <option key="0" value="">{i18n.t('domicilios.seldoenv')}</option>
                    {this.props.domicilios_envio.map((dom) => {
                      if (dom.id === this.props.domicilio.id) {
                        return (
                          <option key={dom.id} value={dom.id} selected>
                            {dom.nombre}
                          </option>
                        );
                      } else {
                        return (
                          <option key={dom.id} value={dom.id}>
                            {dom.nombre}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="form-group">
                  <label htmlFor="input_nombre">{i18n.t('domicilios.nombre')}</label>
                  <input
                    type="text"
                    id="input_nombre"
                    name="nombre"
                    value={this.props.pedido.nombre_dom_envio ? this.props.pedido.nombre_dom_envio : this.props.domicilio.nombre !== "" ? this.props.domicilio.nombre : ""}
                    className="form-control"
                    placeholder="Nombre"
                    onChange={(e) => {
                      this.props.setNombreDomEnvioMod(e.target.value);
                    }}
                    readOnly={!this.props.editable}
                    disabled={this.props.pedido_cargando || this.props.pedidoGuardado ? true : false}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="form-group">
                  <label htmlFor="input_direccion">{i18n.t('domicilios.direc')}</label>
                  <input
                    type="text"
                    id="input_direccion"
                    name="direccion"
                    value={this.props.pedido.direccion_dom_envio ? this.props.pedido.direccion_dom_envio : this.props.domicilio.direccion !== "" ? this.props.domicilio.direccion : ""}
                    className="form-control"
                    placeholder="Dirección"
                    onChange={(e) => {
                      this.props.setDireccionDomEnvioMod(e.target.value);
                    }}
                    readOnly={!this.props.editable}
                    disabled={this.props.pedido_cargando || this.props.pedidoGuardado ? true : false}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="form-group">
                  <label htmlFor="input_estado">
                    {i18n.t('domicilios.estdomay')} <sup>*</sup>
                  </label>

                  {this.props.editable ? (
                    <select
                      className="form-control"
                      name="estado"
                      id="input_estado"
                      onChange={this.onChange}
                      value={this.props.pedido.estado_dom_envio ? this.props.pedido.estado_dom_envio : this.props.domicilio.estado || ""}
                      placeholder="Estado"
                      disabled={this.props.pedido_cargando || this.props.pedidoGuardado ? true : false}
                    >
                      <option value="">{i18n.t('domicilios.estdomin')}</option>
                      {this.props.estados && this.props.estados.length > 0
                        ? this.props.estados.map((estado, index) => {
                            let est = this.props.pedido.estado_dom_envio ? this.props.pedido.estado_dom_envio : this.props.domicilio.estado || "";

                            if (estado.codigo === est) {
                              return (
                                <option key={index} value={estado.codigo} selected>
                                  {estado.nombre}
                                </option>
                              );
                            } else {
                              return (
                                <option key={index} value={estado.codigo}>
                                  {estado.nombre}
                                </option>
                              );
                            }
                          })
                        : ""}
                    </select>
                  ) : (
                    <input
                      type="text"
                      id="input_estado"
                      name="estado"
                      value={this.props.estado.nombre || ""}
                      className="form-control"
                      placeholder={i18n.t('domicilios.estdomin')}
                      onChange={this.onChange}
                      readOnly={!this.props.editable}
                      disabled={this.props.pedido_cargando || this.props.pedidoGuardado ? true : false}
                    />
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="form-group">
                  <label htmlFor="input_provincia">
                    {i18n.t('domicilios.prov')} <sup>*</sup>
                  </label>
                  {this.props.editable ? (
                    <select
                      className="form-control"
                      name="provincia"
                      id="input_provincia"
                      onChange={this.onChange}
                      value={this.props.pedido.provincia_dom_envio ? this.props.pedido.provincia_dom_envio : this.props.domicilio.provincia || ""}
                      placeholder="Provincia"
                      disabled={this.props.pedido_cargando || this.props.pedidoGuardado ? true : false}
                    >
                      <option value="">{i18n.t('domicilios.provmin')}</option>
                      {this.props.provincias && this.props.provincias.length > 0
                        ? this.props.provincias.map((provincia, index) => {
                            let prov = this.props.pedido.provincia_dom_envio ? this.props.pedido.provincia_dom_envio : this.props.domicilio.provincia || "";

                            if (provincia.provincia === prov) {
                              return (
                                <option key={index} value={provincia.provincia} selected>
                                  {provincia.nombre}
                                </option>
                              );
                            } else {
                              return (
                                <option key={index} value={provincia.provincia}>
                                  {provincia.nombre}
                                </option>
                              );
                            }
                          })
                        : ""}
                    </select>
                  ) : (
                    <input
                      type="text"
                      name="provincia"
                      id="input_provincia"
                      value={this.props.provincia.nombre || ""}
                      className="form-control"
                      placeholder= {i18n.t('domicilios.provmin')}
                      onChange={this.onChange}
                      readOnly={!this.props.editable}
                      disabled={this.props.pedido_cargando || this.props.pedidoGuardado ? true : false}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="input_localidad">{i18n.t('domicilios.local')}</label>
                  <input
                    type="text"
                    name="localidad"
                    id="input_localidad"
                    value={this.props.pedido.localidad_dom_envio ? this.props.pedido.localidad_dom_envio : this.props.domicilio.localidad !== "" ? this.props.domicilio.localidad : ""}
                    className="form-control"
                    placeholder={i18n.t('domicilios.localmin')}
                    onChange={(e) => {
                      this.props.setLocalidadDomEnvioMod(e.target.value);
                    }}
                    readOnly={!this.props.editable}
                    disabled={this.props.pedido_cargando || this.props.pedidoGuardado ? true : false}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="input_cod_postal">{i18n.t('domicilios.codpstal')}</label>
                  <input
                    type="text"
                    id="input_cod_postal"
                    name="cod_postal"
                    value={this.props.pedido.cod_postal_dom_envio ? this.props.pedido.cod_postal_dom_envio : this.props.domicilio.cod_postal !== "" ? this.props.domicilio.cod_postal : ""}
                    className="form-control"
                    placeholder={i18n.t('domicilios.codposmi')}
                    onChange={(e) => {
                      this.props.setCodPostalDomEnvioMod(e.target.value);
                    }}
                    readOnly={!this.props.editable}
                    disabled={this.props.pedido_cargando || this.props.pedidoGuardado ? true : false}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="form-group tipo-portes-group">
                  <label htmlFor="input_tipo_portes">{i18n.t('domicilios.tipport')}</label>
                  <input
                    type="checkbox"
                    id="input_tipo_portes"
                    name="tipo_portes"
                    className="form-control"
                    value={this.props.pedido.tipo_portes_dom_envio && this.props.pedido.tipo_portes_dom_envio === "S" ? true : false}
                    placeholder={i18n.t('domicilios.tippormi')}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        this.props.setTipoPortesDomEnvioMod("S");
                      } else {
                        this.props.setTipoPortesDomEnvioMod("N");
                      }
                    }}
                    readOnly={!this.props.editable}
                    disabled={this.props.pedido_cargando || this.props.pedidoGuardado ? true : false}
                    checked={this.props.pedido.tipo_portes_dom_envio && this.props.pedido.tipo_portes_dom_envio === "S" ? true : false}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  domicilios_envio: state.domiciliosEnvio.domicilios_envio,
  domicilio: state.domiciliosEnvio.domicilio,
  estado: state.domiciliosEnvio.estado,
  provincia: state.domiciliosEnvio.provincia,
  pedido_cargando: state.pedidos.isLoading,
  provincias: state.provincias.provincias,
  estados: state.estados.estados,
  pedido: state.pedidos.pedido,
});

export default connect(mapStateToProps, {
  getDomEnvio,
  getEstado,
  getProvincia,
  setDireccion,
  getEstados,
  getProvincias,
  setDireccionDomEnvioMod,
  setNombreDomEnvioMod,
  setEstadoDomEnvioMod,
  setProvinciaDomEnvioMod,
  setLocalidadDomEnvioMod,
  setCodPostalDomEnvioMod,
  setTipoPortesDomEnvioMod,
})(DomiciliosEnvio);
