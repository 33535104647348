import informes from "../translations/informes/en_US.json";
import general from "../translations/general/en_US.json";
import articulos from "../translations/articulos/en_US.json";
import resumen from "../translations/resumen/en_US.json";
import subscripciones from "../translations/subscripciones/en_US.json";
import carrito from "../translations/carrito/en_US.json";
import buscador from "../translations/buscador/en_US.json";
import expedientes from "../translations/expedientes/en_US.json";
import login from "../translations/login/en_US.json";

export default {
  translation: {
    login,
    informes,
    general,
    articulos,
    resumen,
    subscripciones,
    carrito,
    buscador,
    expedientes,
  },
};
