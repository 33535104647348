import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getExpedientes } from "../../actions/expedientes";
import { dynamicSort } from "../../helpers/funciones";
import Spinner from "../layout/Spinner";
import i18n from "../../lang/idiomas";

export class ExpedientesCreados extends Component {
  static propTypes = {
    expedientes: PropTypes.array.isRequired,
    getExpedientes: PropTypes.func.isRequired,
  };
  componentDidMount() {
    if (this.props.expedientes === undefined || this.props.expedientes.length === 0) {
      this.props.getExpedientes(this.props.tipo_expediente);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.tipo_expediente !== prevProps.tipo_expediente) {
      this.props.getExpedientes(this.props.tipo_expediente);
    } else if (this.props.expedientes !== prevProps.expedientes && this.props.isLoading !== true) {
      if (prevProps.expedientes > 0) {
        this.props.getExpedientes(this.props.tipo_expediente);
      }
    }
  }

  render() {
    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent">
            <div className="container-indent">
              <div className="container">
                <h1 className="tt-title-subpages noborder">{i18n.t("expedientes.exliexcr")}</h1>

                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    {this.props.expedientes && this.props.expedientes.length > 0 ? (
                      <Fragment>
                        <div className="tt-shopcart-table tipos_expedientes_desctop row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row titulos">
                              <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("expedientes.exusweb")}</div>
                              <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("expedientes.extiexp")}</div>
                              <div className="col-sm-12 col-md-6 col-lg-6">{i18n.t("expedientes.exfecalt")}</div>
                            </div>
                            {this.props.expedientes.sort(dynamicSort("-fecha_alta")).map((expediente) => {
                              return (
                                <div className="row" key={expediente.id}>
                                  <div className="col-sm-12 col-md-3 col-lg-3">{expediente.usuario_web}</div>
                                  <div className="col-sm-12 col-md-3 col-lg-3">{expediente.tipo_expediente}</div>
                                  <div className="col-sm-12 col-md-6 col-lg-6">{expediente.fecha_alta}</div>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="tt-shopcart-table tipos_expedientes_mobile row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            {this.props.expedientes.sort(dynamicSort("-fecha_alta")).map((expediente) => {
                              return (
                                <div className="row" key={expediente.id}>
                                  <div className="col-12">
                                    <div className="row ">
                                      <div className="col-6 titulos">{i18n.t("expedientes.exusweb")}</div>
                                      <div className="col-6">{expediente.usuario_web}</div>
                                    </div>

                                    <div className="row ">
                                      <div className="col-6 titulos">{i18n.t("expedientes.extiexp")}</div>
                                      <div className="col-6">{expediente.tipo_expediente}</div>
                                    </div>
                                    <div className="row ">
                                      <div className="col-6 titulos">{i18n.t("expedientes.exfecalt")}</div>
                                      <div className="col-6">{expediente.fecha_alta}</div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      <Spinner showSpinner={this.props.isLoading} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  expedientes: state.expedientes.expedientes,
  isLoading: state.expedientes.isLoading,
});

export default connect(mapStateToProps, {
  getExpedientes,
})(ExpedientesCreados);
