import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getImagen } from "../../actions/imagen";
import Spinner from "../layout/Spinner";
import { cargarParametros } from "../../helpers/funciones";

let src_pers = "";
let parametros_usados = ["img_cargadas"];
let parametros;

export class Imagen extends Component {
  static propTypes = {
    codigo_articulo: PropTypes.string.isRequired,
    numero_imagen: PropTypes.number,
    principal: PropTypes.string,
    getImagen: PropTypes.func.isRequired,
    imagenes: PropTypes.object.isRequired,
    tipo_imagen: PropTypes.string,
  };

  componentDidMount() {
    this.props.getImagen(this.props.codigo_articulo, this.props.numero_imagen ? this.props.numero_imagen : 1, this.props.principal ? this.props.principal : "S", parametros.img_cargadas);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.codigo_articulo !== this.props.codigo_articulo || this.props.numero_imagen !== prevProps.numero_imagen) {
      if (
        this.props.imagenes[this.props.codigo_articulo] === undefined ||
        this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen] === undefined ||
        this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen].error
      ) {
        this.props.getImagen(this.props.codigo_articulo, this.props.numero_imagen ? this.props.numero_imagen : 1, this.props.principal ? this.props.principal : "S", parametros.img_cargadas);
      }
    }
  }

  cargarSRCImagenes() {
    if (
      this.props.imagenes[this.props.codigo_articulo] &&
      this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen] &&
      this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen].articulo_referencia &&
      this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen].articulo_referencia !== ""
    ) {
      //Imagen de articulo referencia
      src_pers =
        "/assets/img/" +
        this.props.portal.parametros_ecom[0].codigo_empresa +
        "-" +
        this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen].articulo_referencia +
        "/" +
        this.props.portal.parametros_ecom[0].codigo_empresa +
        "-" +
        this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen].articulo_referencia +
        "-" +
        this.props.numero_imagen +
        (this.props.tipo_imagen ? "-" + this.props.tipo_imagen : "") +
        "." +
        (this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen].extension ? this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen].extension : "jpg");
    } else if (
      //Imagen propia
      this.props.imagenes[this.props.codigo_articulo] &&
      this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen] &&
      (this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen].articulo_referencia ||
        this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen].articulo_referencia === "")
    ) {
      src_pers =
        "/assets/img/" +
        this.props.portal.parametros_ecom[0].codigo_empresa +
        "-" +
        this.props.codigo_articulo +
        "/" +
        this.props.portal.parametros_ecom[0].codigo_empresa +
        "-" +
        this.props.codigo_articulo +
        "-" +
        this.props.numero_imagen +
        (this.props.tipo_imagen ? "-" + this.props.tipo_imagen : "") +
        "." +
        (this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen].extension ? this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen].extension : "jpg");
    } else {
      //No encuentra imagen
      src_pers = "/assets/img/placeholder.jpg";
    }
  }

  render() {
    parametros = cargarParametros(parametros_usados, this.props.portal.parametros_ecom, "GEN");
    if (parametros && parametros.img_cargadas === "S") {
      if (
        this.props.imagenes[this.props.codigo_articulo] &&
        this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen] &&
        this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen].isLoading
      ) {
        return <Spinner showSpinner={true} />;
      } else {
        this.cargarSRCImagenes();
        return (
          <Fragment>
            <img
              src={src_pers}
              data-src={src_pers}
              alt=""
              onError={(event) => {
                event.target.src = "/assets/img/placeholder.jpg";
                event.onerror = "null";
              }}
            />
          </Fragment>
        );
      }
    } else if (
      this.props.imagenes[this.props.codigo_articulo] &&
      this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen] &&
      this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen].isLoading
    ) {
      return <Spinner showSpinner={true} />;
    } else if (
      this.props.imagenes[this.props.codigo_articulo] &&
      this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen] &&
      this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen].imagen !== ""
    ) {
      return (
        <Fragment>
          <img
            src={"data:image/png;base64, " + this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen].imagen}
            data-src={"data:image/png;base64, " + this.props.imagenes[this.props.codigo_articulo][this.props.numero_imagen].imagen}
            alt=""
          />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <img src="/assets/img/placeholder.jpg" data-src="/assets/img/placeholder.jpg" alt="" />
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  imagenes: state.imagen.imagenes,
  portal: state.portal.portal,
});

export default connect(mapStateToProps, {
  getImagen,
})(Imagen);
