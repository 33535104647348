import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import store from "../../store";
import { CLEAR_MESSAGE } from "../../actions/types";

export class Alerts extends Component {
  static propTypes = {
    message: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    const options = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    };
    const { message } = this.props;

    if (message && message !== prevProps.message) {
      if (message.msg !== "") {
        switch (message.status) {
          case "warning":
            toast.warning(message.msg, options);
            break;
          case "info":
            toast.info(message.msg, options);
            break;
          case "success":
            toast.success(message.msg, options);
            break;
          case "error":
          default:
            toast.error(message.msg, options);
            break;
        }
      }
    }
  }

  render() {
    return <ToastContainer />;
  }
}

const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.messages,
});

export default connect(mapStateToProps)(Alerts);
