import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import { ordenarMenu } from "../../helpers/funciones";
import { getPedidoActivo } from "../../actions/pedidos";
import { getMenus } from "../../actions/portal";
import i18n from "../../lang/idiomas";
//import { withTranslation } from 'react-i18next';

let menu_registro;
let direccion_registro = "";
export class Login extends Component {
  state = {
    username: "",
    password: "",
    remember: false,
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    portal: PropTypes.object.isRequired,
    menus: PropTypes.array.isRequired,
    hash: PropTypes.string,
    invitado: PropTypes.bool,
    getPedidoActivo: PropTypes.func.isRequired,
  };

  componentDidMount() {
    window.wookie();
    if (this.props.P && this.props.P !== "" && this.props.rememberDate && this.props.rememberDate !== "") {
      if (Date.now() - this.props.rememberDate > 1296000000) {
        localStorage.removeItem("remember");
        localStorage.removeItem("P");
        localStorage.removeItem("rememberDate");
      } else {
        this.setState({ username: atob(this.props.P).split(":")[0] });
        this.setState({ password: atob(this.props.P).split(":")[1] });
        this.setState({ remember: this.props.remember });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isAuthenticated !== this.props.isAuthenticated || prevProps.invitado !== this.props.invitado) {
      this.props.getMenus();

      let findPos = (elem) => elem.programa === "articulos";
      if (this.props.menus) {
        let pos = this.props.menus.findIndex(findPos);
        if (pos !== -1) {
          this.props.getPedidoActivo(this.props.hash != null ? this.props.hash : "", "S", this.props.id_pedido_seleccionado);
        }
      }
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password, this.props.hash, this.state.remember);
  };

  onChange = (e) => {
    if (e.target.name !== "remember") {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      this.setState({ [e.target.name]: e.target.checked });
    }
  };

  render() {
    const { username, password, remember } = this.state;
    const { portal } = this.props;

    if (!this.props.isAuthenticated || this.props.invitado){
        return (
        <div id="tt-pageContent" className="login">
          <img className="bg_login" src="https://www.gesuga.com/wp-content/uploads/2019/06/sedecentral2.jpg?t=1668700563" />
          <div className="container-indent">
            <div className="container">
              <a className="tt-logo" href="index.html">
                <img className="tt-retina" src="images/custom/logo.png" alt="" />
              </a>
              <h1 className="tt-title-subpages noborder">{portal.descripcion}</h1>
              <div className="tt-login-form">
                <div className="row	justify-content-center">
                  <div className="col-md-5" style={{ backgroundColor: "white" }}>
                    <div className="tt-item">
                      <h2 className="tt-title">{i18n.t("login.title")}</h2>
                      <div className="form-default justify-content-center">
                        <form onSubmit={this.onSubmit}>
                          <div className="form-group">
                            <input className="form-control" type="text" name="username" onChange={this.onChange} value={username} placeholder={i18n.t("general.username")} />
                          </div>
                          <div className="form-group">
                            <input className="form-control" type="password" name="password" onChange={this.onChange} value={password} placeholder={i18n.t("general.password")} />
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" onChange={this.onChange} checked={remember} name="remember" id="remember" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                              {i18n.t("login.remember")}
                            </label>
                          </div>
                          <div className="row">
                            <div className="col-auto mr-auto">
                              <div className="form-group">
                                <button type="submit" className="btn btn-primary">
                                  {i18n.t("login.login")}
                                </button>
                              </div>
                            </div>
                          </div>
                          <p>
                            {i18n.t("login.remembp")}{" "}
                            <Link to="/recuperar" className="link-rec-pass">
                              {i18n.t("login.recpass")}
                            </Link>
                          </p>
                          <p>
                            {i18n.t("login.nocuenta")} {" "}
                            <Link to={{ pathname: "/contacto", search: "new" }} className="link-rec-pass">
                            {i18n.t("login.regform")}
                            </Link>
                          </p>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      } else {
        return <Navigate to={"/"}/>;
      }
      
    }
  
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  hash: state.auth.hash,
  invitado: state.auth.invitado,
  portal: state.portal.portal,
  menus: state.portal.menus,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
  P: state.auth.P,
  rememberDate: state.auth.rememberDate,
  remember: state.auth.remember,
  language: state.languageSelector.language,
});
export default connect(mapStateToProps, {
  login,
  getMenus,
  getPedidoActivo,
})(Login);
