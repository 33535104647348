import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setArticulo, setLineas } from "../../actions/botonanadirlista";
import { HeartStraight, ListBullets } from "phosphor-react";
import SubscripcionesStock from "../usuarios/SubscripcionesStock";
import i18n from "../../lang/idiomas";
import ArticuloNecesidades from "./ArticuloNecesidades";

export class BotonAnadirLista extends Component {
  static propTypes = {
    menus: PropTypes.array.isRequired,
    setArticulo: PropTypes.func.isRequired,
    setLineas: PropTypes.func.isRequired,
    listas_compra: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  openModal = (e) => {
    e.preventDefault();
    if (this.props.codigo_articulo) {
      this.props.setArticulo(this.props.codigo_articulo, "listas");
    } else if (this.props.lineas) {
      this.props.setLineas(this.props.lineas);
    }
  };

  comprobarMenu() {
    let existe_menu = false;
    if (this.props.menus) {
      existe_menu = this.props.menus.some((menu) => {
        if (menu.hijos && menu.hijos.length > 0) {
          return menu.hijos.some((hijo) => {
            return !!(hijo.programa && hijo.programa !== "" && hijo.programa === "listasCompra");
          });
        } else {
          return !!(menu.programa && menu.programa !== "" && menu.programa === "listasCompra");
        }
      });
    }
    return existe_menu;
  }

  cargarArticulo() {
    let articulo_aux;

    let existeArticulo = -1;
    switch (this.props.origen) {
      case "articulo":
        if (this.props.articulo) {
          articulo_aux = this.props.articulo;
        }
        break;
      case "articulos":
        if (this.props.articulos && this.props.articulos.length > 0) {
          existeArticulo = this.props.articulos.findIndex((articulo) => articulo.codigo_articulo === this.props.codigo_articulo);

          if (existeArticulo !== -1) {
            articulo_aux = this.props.articulos[existeArticulo];
          }
        }
        break;
      case "relacionados":
        if (this.props.relacionados && this.props.relacionados.length > 0) {
          existeArticulo = this.props.relacionados.findIndex((articulo) => articulo.codigo_articulo === this.props.codigo_articulo);

          if (existeArticulo !== -1) {
            articulo_aux = this.props.relacionados[existeArticulo];
          }
        }
        break;
      case "equivalentes":
        if (this.props.equivalentes && this.props.equivalentes.length > 0) {
          existeArticulo = this.props.equivalentes.findIndex((articulo) => articulo.codigo_articulo === this.props.codigo_articulo);

          if (existeArticulo !== -1) {
            articulo_aux = this.props.equivalentes[existeArticulo];
          }
        }
        break;
      case "pedido_habitual":
        if (this.props.pedido_habitual && this.props.pedido_habitual.length > 0) {
          existeArticulo = this.props.pedido_habitual.findIndex((articulo) => articulo.codigo_articulo === this.props.codigo_articulo);

          if (existeArticulo !== -1) {
            articulo_aux = this.props.pedido_habitual[existeArticulo];
          }
        }
        break;
      case "ofertas":
        if (this.props.ofertas && this.props.ofertas.length > 0) {
          existeArticulo = this.props.ofertas.findIndex((articulo) => articulo.codigo_articulo === this.props.codigo_articulo);

          if (existeArticulo !== -1) {
            articulo_aux = this.props.ofertas[existeArticulo];
          }
        }
        break;
      case "novedades":
        if (this.props.novedades && this.props.novedades.length > 0) {
          existeArticulo = this.props.novedades.findIndex((articulo) => articulo.codigo_articulo === this.props.codigo_articulo);

          if (existeArticulo !== -1) {
            articulo_aux = this.props.novedades[existeArticulo];
          }
        }
        break;
      default:
        break;
    }

    return articulo_aux;
  }

  render() {
    let anadir_lista = this.comprobarMenu();
    let articulo = this.cargarArticulo();

    if (anadir_lista) {
      switch (this.props.origen) {
        case "articulos":
        case "pedido_habitual":
        case "ofertas":
        case "novedades":
        case "equivalentes":
        case "relacionados":
          return (
            <Fragment>
              <button className="tt-btn-wishlist" data-tposition="left" onClick={this.openModal}>
                <HeartStraight size={20} weight="regular" alt={i18n.t("general.altaddwl")} />
              </button>
              {this.props.btn_subs === "S" ? <SubscripcionesStock origen={this.props.origen} codigo_articulo={this.props.codigo_articulo} /> : ""}
              {articulo ? <ArticuloNecesidades articulo={articulo} origen={this.props.origen} /> : ""}
            </Fragment>
          );
        case "articulos_mobile":
          return (
            <Fragment>
              {" "}
              <button className="tt-btn-wishlist" onClick={this.openModal}>
                <span className="ocultar">{i18n.t("general.wishlist")}</span>
              </button>
            </Fragment>
          );
        case "articulo":
          return (
            <Fragment>
              {articulo ? <ArticuloNecesidades articulo={articulo} origen={this.props.origen} /> : ""}
              <div className="iconos-funcion">
                <button className="btn list" onClick={this.openModal}>
                  <HeartStraight size={30} alt={i18n.t("general.altaddwl")} /> {i18n.t("general.altaddwl").toUpperCase()}
                </button>
                {this.props.btn_subs === "S" ? <SubscripcionesStock origen={this.props.origen} codigo_articulo={this.props.codigo_articulo} /> : ""}
              </div>
            </Fragment>
          );
        case "tabla":
          return (
            <Fragment>
              <button
                className="btn btn-primary"
                //data-tooltip="Añadir a lista"
                value="O"
                onClick={this.openModal}
                tabIndex={this.props.tabIndex}
              >
                {this.props.mostrar_texto_btn === "S" ? i18n.t("general.lista").toUpperCase() : <HeartStraight size={20} alt={i18n.t("general.altaddwl")} />}
              </button>
            </Fragment>
          );
        case "carrito":
          return (
            <Fragment>
              <div className="tt-cart-btn">
                <div className="tt-item">
                  <button onClick={this.openModal} className="btn">
                    {i18n.t("general.saveaswl").toUpperCase()}
                  </button>
                </div>
              </div>
            </Fragment>
          );
        case "resumen":
          return (
            <Fragment>
              <button className="btn-link" onClick={this.openModal}>
                <ListBullets size={20} weight="regular" /> <text>{i18n.t("general.saveaswl").toUpperCase()}</text>
              </button>
            </Fragment>
          );
        default:
          break;
      }
    } else {
      return "";
    }
  }
}
const mapStateToProps = (state) => ({
  menus: state.portal.menus,
  listas_compra: state.listasCompra.listas_compra,
  articulos: state.listadoArticulos.articulos.articulos,
  articulo: state.articulo.articulo,
  pedido_habitual: state.listadoArticulos.pedido_habitual.articulos,
  ofertas: state.listadoArticulos.ofertas.articulos,
  novedades: state.listadoArticulos.novedades.articulos,
  relacionados: state.listadoArticulos.relacionados.articulos,
  equivalentes: state.listadoArticulos.equivalentes.equivalentes,
  isLoading: state.listasCompra.isLoading,
  usuario: state.auth.user,
});

export default connect(mapStateToProps, {
  setArticulo,
  setLineas,
})(BotonAnadirLista);
