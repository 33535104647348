import axios from "../axiosConfig";
import { createMessage } from "./messages";
import { tokenConfig } from "./auth";
import {
  BORRA_HASH,
  CERRAR_PEDIDO,
  DELETE_PEDIDO,
  DELETE_PEDIDO_PENDIENTE,
  FETCH_LINEAS_PEDIDO_ACTIVO,
  FETCH_LINEAS_PEDIDO_ACTIVO_FAIL,
  FETCH_PEDIDO_ACTIVO,
  FETCH_PEDIDO_ACTIVO_FAIL,
  FETCH_TEXTOS_VENTAS,
  GET_PEDIDO_ACTIVO,
  GET_TEXTOS_VENTAS,
  INTEGRANDO_PEDIDO,
  INTEGRAR_PEDIDO,
  INTEGRAR_PEDIDO_FAIL,
  POST_PEDIDO,
  SET_COD_POSTAL_DOM_ENVIO_MOD,
  SET_DIRECCION_DOM_ENVIO_MOD,
  SET_EMAIL_PEDIDO,
  SET_ESTADO_DOM_ENVIO_MOD,
  SET_FECHA_ENTREGA,
  SET_ID_PEDIDO_SELECCIONADO,
  SET_LOCALIDAD_DOM_ENVIO_MOD,
  SET_NOMBRE_DOM_ENVIO_MOD,
  SET_NUMERO_PEDIDO_CLIENTE,
  SET_OBSERVACIONES_PEDIDO,
  SET_PERSONA_PEDIDO,
  SET_PROVINCIA_DOM_ENVIO_MOD,
  SET_TIPO_PORTES_DOM_ENVIO_MOD,
} from "./types";

// GET PEDIDO ACTIVO
export const getPedidoActivo =
  (hash_invitado = "", recalcular_pedido = "S", id_pedido_seleccionado = "") =>
  (dispatch, getState) => {
    if (recalcular_pedido === "S") {
      dispatch({
        type: FETCH_LINEAS_PEDIDO_ACTIVO,
      });
    } else {
      dispatch({
        type: FETCH_PEDIDO_ACTIVO,
      });
    }
    axios
      .get(`/pedidos/?activo=S&inv=${hash_invitado}&recalcular=${recalcular_pedido}&id_pedido_seleccionado=${id_pedido_seleccionado}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_PEDIDO_ACTIVO,
          payload: res.data,
        });

        const invitado = getState().auth.invitado;
        if (!invitado) {
          dispatch({ type: BORRA_HASH });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: FETCH_PEDIDO_ACTIVO_FAIL,
        });
        //
        // if (err?.response?.status === 401) {
        //   refrescar();
        // }
      });
  };

// ELIMINAR EL PEDIDO
export const eliminarPedido =
  (id, hash_invitado = "", id_pedido_seleccionado = "", origen = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_PEDIDO_ACTIVO,
    });
    if (origen === "") {
      axios
        .delete(`/pedidos/${id}/?inv=${hash_invitado}&id_pedido_seleccionado=${id_pedido_seleccionado}`, tokenConfig(getState))
        .then((res) => {
          dispatch(createMessage("Carrito Eliminado", "success"));
          dispatch({
            type: DELETE_PEDIDO,
            payload: id,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch(createMessage(err.response.data !== undefined ? err.response.data : "No se ha podido eliminar el carrito en este momento.", err.response.status));
          dispatch({
            type: FETCH_PEDIDO_ACTIVO_FAIL,
          });
        });
    } else if ((origen = "pendientes")) {
      axios
        .delete(`/pedidos/${id}/?inv=${hash_invitado}&id_pedido_seleccionado=${id_pedido_seleccionado}`, tokenConfig(getState))
        .then((res) => {
          dispatch(createMessage("Carrito Eliminado", "success"));
          dispatch({
            type: DELETE_PEDIDO_PENDIENTE,
            payload: id,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch(createMessage(err.response.data !== undefined ? err.response.data : "No se ha podido eliminar el carrito en este momento.", err.response.status));
          dispatch({
            type: FETCH_PEDIDO_ACTIVO_FAIL,
          });
        });
    }
  };

// INSERTAR LINEA AL PEDIDO
export const postLinea =
  (linea, hash_invitado = "", id_pedido_seleccionado = "", actualizar = "N", cantidad_prev = 0) =>
  (dispatch, getState) => {
    if (linea.valida_carnet && linea.valida_carnet === "N") {
      dispatch(createMessage("Pendiente de validación manual de carnet", "warning"));
    }

    if ((linea.valida_receta && linea.valida_receta !== "S") || (linea.valida_otros && linea.valida_otros !== "S")) {
      dispatch(createMessage("El producto añadido requiere autorización para su venta, en breve nos pondremos en contacto con usted para verificarla", "info"));
    }
    if (actualizar === "S") {
      dispatch({
        type: FETCH_LINEAS_PEDIDO_ACTIVO,
      });
    } else {
      dispatch({
        type: FETCH_PEDIDO_ACTIVO,
      });
    }
    const body = JSON.stringify(linea);
    axios
      .post(`/lineas/?inv=${hash_invitado}&id_pedido_seleccionado=${id_pedido_seleccionado}&actualizar=${actualizar}`, body, tokenConfig(getState))
      .then((res) => {
        if (actualizar === "S") {
          dispatch(createMessage("Linea actualizada correctamente", "success"));
        } else {
          dispatch(createMessage("Se ha añadido el artículo a su carrito", "success"));
        }
        dispatch({
          type: GET_PEDIDO_ACTIVO,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (actualizar === "S") {
          dispatch({
            type: FETCH_LINEAS_PEDIDO_ACTIVO_FAIL,
            payload: {
              linea: linea,
              cantidad_prev: cantidad_prev,
            },
          });
        } else {
          dispatch({
            type: FETCH_PEDIDO_ACTIVO_FAIL,
          });
        }
        dispatch(createMessage(err.response.data !== undefined ? err.response.data : "No se ha podido añadir el artículo a su carrito en este momento.", err.response.status));
        console.log(err);
      });
  };

export const postLineas =
  (_lineas, hash_invitado = "", id_pedido_seleccionado = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_PEDIDO_ACTIVO,
    });
    const body = { lineas: _lineas };

    axios
      .post(`/lineas/createmany/?inv=${hash_invitado}&id_pedido_seleccionado=${id_pedido_seleccionado}`, body, tokenConfig(getState))
      .then((res) => {
        dispatch(createMessage("Se han añadido los artículos a su carrito", "success"));
        dispatch({
          type: GET_PEDIDO_ACTIVO,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(createMessage("No se han podido añadir los artículos a su carrito en este momento.", err.response.status));
        dispatch({
          type: FETCH_PEDIDO_ACTIVO_FAIL,
        });
        console.log(err);
      });
  };

// DELETE LINEA
export const deleteLinea =
  (id, hash_invitado = "", id_pedido_seleccionado = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_PEDIDO_ACTIVO,
    });
    axios
      .delete(`/lineas/${id}/?inv=${hash_invitado}&id_pedido_seleccionado=${id_pedido_seleccionado}`, tokenConfig(getState))
      .then((res) => {
        dispatch(createMessage("Línea Eliminada", "success"));
        dispatch({
          type: GET_PEDIDO_ACTIVO,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(createMessage("No se ha podido eliminar la línea de su carrito en este momento.", err.response.status));
        dispatch({
          type: FETCH_PEDIDO_ACTIVO_FAIL,
        });
        console.log(err);
      });
  };

// INTEGRAR PEDIDO
export const integraPedido =
  (
    domicilio,
    observaciones,
    forma_envio,
    almacen_recogida,
    ruta,
    forma_pago,
    pedido,
    recalcular_pedido = "N",
    id_pedido_seleccionado = "",
    fecha_entrega = "",
    persona_pedido = "",
    pedido_cliente = "",
    domicilio_envio_mod = {
      nombre_dom_envio: "",
      direccion_dom_envio: "",
      localidad_dom_envio: "",
      estado_dom_envio: "",
      provincia_dom_envio: "",
      cod_postal_dom_envio: "",
      tipo_portes_dom_envio: "",
    },
    email_pedido = "",
    transportista = {}
  ) =>
  (dispatch, getState) => {
    dispatch({
      type: INTEGRANDO_PEDIDO,
    });

    const body = JSON.stringify({
      domicilio,
      observaciones,
      forma_envio,
      almacen_recogida,
      ruta,
      forma_pago,
      pedido,
      fecha_entrega,
      persona_pedido,
      pedido_cliente,
      domicilio_envio_mod,
      email_pedido,
      transportista,
    });

    axios
      .post(`/integrapedido/?recalcular=${recalcular_pedido}&id_pedido_seleccionado=${id_pedido_seleccionado}`, body, tokenConfig(getState))
      .then((res) => {
        if (res.data.estado_pedido === "D") {
          dispatch({
            type: CERRAR_PEDIDO,
            payload: res.data,
          });
        } else {
          dispatch({
            type: INTEGRAR_PEDIDO,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          dispatch(createMessage("El carrito ha sufrido cambios, por favor, revíselo e inténtelo de nuevo", err.response.status));
          dispatch({
            type: GET_PEDIDO_ACTIVO,
            payload: err.response.data,
          });
          console.log(err);
        } else if (err?.response?.status === 403) {
          dispatch(createMessage(err.response.data, err.response.status));
          dispatch({
            type: GET_PEDIDO_ACTIVO,
            payload: err.response.data,
          });
          console.log(err);
        } else if (err?.response?.status === 418) {
          dispatch(createMessage(err.response.data.message, err.response.status));
          dispatch({
            type: GET_PEDIDO_ACTIVO,
            payload: err.response.data.pedido,
          });
          console.log({ err });
        } else {
          dispatch(createMessage("No se ha podido finalizar el carrito, por favor intentelo de nuevo más tarde o contacte con nosotros", err.response.status));
          dispatch({
            type: INTEGRAR_PEDIDO_FAIL,
          });
          dispatch({
            type: GET_PEDIDO_ACTIVO,
            payload: err.response.data,
          });
          console.log(err);
        }
      });
  };

export const actualizaPedido =
  (
    domicilio,
    forma_envio,
    almacen_recogida,
    ruta,
    hash_invitado = "",
    tipo_pedido = {},
    id_pedido_seleccionado = "",
    fecha_entrega = "",
    observaciones = "",
    persona_pedido = "",
    pedido_cliente = "",
    domicilio_envio_mod = {
      nombre_dom_envio: "",
      direccion_dom_envio: "",
      localidad_dom_envio: "",
      estado_dom_envio: "",
      provincia_dom_envio: "",
      cod_postal_dom_envio: "",
      tipo_portes_dom_envio: "",
    },
    email_pedido = "",
    transportista = {},
    forma_pago = {}
  ) =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_PEDIDO_ACTIVO,
    });

    const body = JSON.stringify({
      domicilio,
      forma_envio,
      almacen_recogida,
      ruta,
      tipo_pedido,
      fecha_entrega,
      observaciones,
      persona_pedido,
      pedido_cliente,
      domicilio_envio_mod,
      email_pedido,
      transportista,
      forma_pago,
    });

    axios
      .post(`/actualizapedido/?inv=${hash_invitado}&id_pedido_seleccionado=${id_pedido_seleccionado}`, body, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_PEDIDO_ACTIVO,
          payload: res.data,
        });
        dispatch(createMessage("Carrito actualizado", "success"));
      })
      .catch((err) => {
        dispatch(createMessage(err.response.data, err.response.status));
        dispatch({
          type: FETCH_PEDIDO_ACTIVO_FAIL,
        });
        console.log(err);
      });
  };

export const cerrarPedido = (pedido) => (dispatch, getState) => {
  dispatch({
    type: FETCH_PEDIDO_ACTIVO,
  });
  const body = JSON.stringify({
    pedido,
  });

  axios
    .post("/actualizapedido/cierrapedido/", body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: CERRAR_PEDIDO,
        payload: pedido,
      });
    })
    .catch((err) => {
      dispatch(createMessage(err.response.data, err.response.status));
      dispatch({
        type: FETCH_PEDIDO_ACTIVO_FAIL,
      });
      console.log(err);
    });
};

export const calculaPortesPedido =
  (hash_invitado = "", id_pedido_seleccionado = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_PEDIDO_ACTIVO,
    });

    axios
      .get((hash_invitado = "", id_pedido_seleccionado = "") => `/pedidos/calculaportes/?inv=${hash_invitado}&id_pedido_seleccionado${id_pedido_seleccionado}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_PEDIDO_ACTIVO,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(createMessage(err.response.data, err.response.status));
        dispatch({
          type: FETCH_PEDIDO_ACTIVO_FAIL,
        });
        console.log(err);
      });
  };

export const getTextosVentas = (organizacion_comercial, presupuesto, idioma) => (dispatch, getState) => {
  dispatch({
    type: FETCH_TEXTOS_VENTAS,
  });
  axios
    .get(`/textosventas/?organizacion_comercial=${organizacion_comercial}&presupuesto=${presupuesto}&idioma=${idioma}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_TEXTOS_VENTAS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const setIdPedidoSeleccionado = (id_pedido_seleccionado) => (dispatch, getState) => {
  dispatch({
    type: SET_ID_PEDIDO_SELECCIONADO,
    payload: id_pedido_seleccionado,
  });
};

export const setObservacionesPedido = (observaciones) => (dispatch, getState) => {
  dispatch({
    type: SET_OBSERVACIONES_PEDIDO,
    payload: observaciones,
  });
};

export const setFechaEntregaPedido = (fecha) => (dispatch, getState) => {
  dispatch({
    type: SET_FECHA_ENTREGA,
    payload: fecha,
  });
};
export const setNumeroPedidoCliente = (num_pedido_cliente) => (dispatch, getState) => {
  dispatch({
    type: SET_NUMERO_PEDIDO_CLIENTE,
    payload: num_pedido_cliente,
  });
};
export const setPersonaPedido = (persona_pedido) => (dispatch, getState) => {
  dispatch({
    type: SET_PERSONA_PEDIDO,
    payload: persona_pedido,
  });
};

export const nuevoPedido =
  (codigo_plantilla = null) =>
  (dispatch, getState) => {
    axios
      .post(`/pedidos/`, { codigo_plantilla }, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: POST_PEDIDO,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const setDireccionDomEnvioMod = (direccion) => (dispatch) => {
  dispatch({
    type: SET_DIRECCION_DOM_ENVIO_MOD,
    payload: direccion,
  });
};

export const setNombreDomEnvioMod = (nombre) => (dispatch) => {
  dispatch({
    type: SET_NOMBRE_DOM_ENVIO_MOD,
    payload: nombre,
  });
};
export const setEstadoDomEnvioMod = (estado) => (dispatch) => {
  dispatch({
    type: SET_ESTADO_DOM_ENVIO_MOD,
    payload: estado,
  });
};
export const setProvinciaDomEnvioMod = (provincia) => (dispatch) => {
  dispatch({
    type: SET_PROVINCIA_DOM_ENVIO_MOD,
    payload: provincia,
  });
};
export const setLocalidadDomEnvioMod = (localidad) => (dispatch) => {
  dispatch({
    type: SET_LOCALIDAD_DOM_ENVIO_MOD,
    payload: localidad,
  });
};
export const setCodPostalDomEnvioMod = (cod_postal) => (dispatch) => {
  dispatch({
    type: SET_COD_POSTAL_DOM_ENVIO_MOD,
    payload: cod_postal,
  });
};

export const setTipoPortesDomEnvioMod = (tipo_portes) => (dispatch) => {
  dispatch({
    type: SET_TIPO_PORTES_DOM_ENVIO_MOD,
    payload: tipo_portes,
  });
};

export const setEmailPedido = (email) => (dispatch) => {
  dispatch({
    type: SET_EMAIL_PEDIDO,
    payload: email,
  });
};

export const getInfoPedido = (email) => (dispatch) => {
  dispatch({
    type: SET_EMAIL_PEDIDO,
    payload: email,
  });
};
