import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setAceptacionCookies } from "../../actions/portal";
import i18n from "../../lang/idiomas";

export class ConfigCookies extends Component {
  static propTypes = {
    setAceptacionCookies: PropTypes.func.isRequired,
    portal: PropTypes.object.isRequired,
  };

  state = {
    acepta_cookies: true,
    fecha_consentimiento: Date.now(),
    publicitarias: false,
    analiticas: false,
    basicas: true,
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.setAceptacionCookies(this.state.acepta_cookies, this.state.fecha_consentimiento, this.state.publicitarias, this.state.analiticas);

    window.history.pushState({}, "", "/");
    window.history.go();
  };

  onChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.checked });
  };

  render() {
    return (
      <Fragment>
        <div id="tt-pageContent" className="mb-5">
          <div className="container-indent">
            <div className="container container-fluid-custom-mobile-padding">
              <div className="row">
                <div className="col">
                  <p>{i18n.t("common.ccooklon")}</p>
                  <p>{i18n.t("common.ccookltw")}</p>
                  <p>{i18n.t("common.ccooklth")}</p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p>
                    {i18n.t("common.cmarqcon")} {i18n.t("common.cmarqctw")}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <form className="contact-form form-default" onSubmit={this.onSubmit}>
                    <div className="form-check">
                      <input type="checkbox" name="basicas" id="basicas" checked={this.state.basicas ? this.state.basicas : false} className="form-check-input" readOnly disabled />
                      <label className="form-check-label" for="basicas">
                        {i18n.t("common.ccookbas")}
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="publicitarias"
                        id="publicitarias"
                        checked={this.state.publicitarias ? this.state.publicitarias : false}
                        className="form-check-input"
                        onChange={this.onChange}
                      />
                      <label className="form-check-label" for="publicitarias">
                        {i18n.t("common.ccookpub")}
                      </label>
                    </div>
                    <div className="form-check">
                      <input type="checkbox" name="analiticas" id="analiticas" checked={this.state.analiticas ? this.state.analiticas : false} className="form-check-input" onChange={this.onChange} />
                      <label className="form-check-label" for="analiticas">
                        {i18n.t("common.ccookana")}
                      </label>
                    </div>
                    <div className="text-center">
                      <button type="submit" className="btn">
                        {i18n.t("common.cguarcon")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  portal: state.portal.portal,
});

export default connect(mapStateToProps, { setAceptacionCookies })(ConfigCookies);
