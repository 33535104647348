import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getMasArticulos, setBusqueda } from "../../actions/articulos";
import { getFiltros, limpiarFiltros, añadirFiltro } from "../../actions/filtros";
import Buscador from "./Buscador";
import FamiliasMenu from "./FamiliasMenu";
import ArticulosFiltros from "./ArticulosFiltros";
import TablaArticulos from "./TablaArticulos";
import ListadoArticulos from "./ListadoArticulos";
import { setFamiliaSeleccionada } from "../../actions/familias";
import BuscadorAvanzado from "./BuscadorAvanzado";
import { cargarParametros } from "../../helpers/funciones";

let parametros_usados = ["bus_avanz"];
let parametros;
let estadistico_url;
let familia_url;
let descrip_url;

export class Articulos extends Component {
  static propTypes = {
    articulos_pagina: PropTypes.number.isRequired,
    getFiltros: PropTypes.func.isRequired,
    limpiarFiltros: PropTypes.func.isRequired,
    setBusqueda: PropTypes.func.isRequired,
    setFamiliaSeleccionada: PropTypes.func.isRequired,
    añadirFiltro: PropTypes.func.isRequired,
  };

  state = {
    buscador: "basico",
  };

  componentDidMount() {
    if (estadistico_url && familia_url && descrip_url) {
      this.props.limpiarFiltros();

      this.props.setBusqueda("");

      this.props.setFamiliaSeleccionada(estadistico_url, familia_url, "");

      this.props.añadirFiltro({
        estadistico: estadistico_url,
        familia: familia_url,
        nombre: "",
        tipo: "estad",
      });
    }

    if (
      this.props.filtros === undefined ||
      this.props.filtros.filtros === undefined ||
      this.props.filtros.filtros.length === 0 ||
      this.props.filtros_claves_estadisticas === undefined ||
      this.props.filtros_claves_estadisticas.filtros_claves_estadisticas === undefined ||
      this.props.filtros_claves_estadisticas.filtros_claves_estadisticas.length === 0
    ) {
      this.props.getFiltros();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    window.wookie();
    let ancla = window.document.getElementById("cabecera-principal");

    if (ancla) {
      ancla.scrollIntoView({ behavior: "smooth" });
    }
  }

  cambiarBuscador = () => {
    if (this.state.buscador === "basico") {
      this.setState({ buscador: "avanzado" });
    } else {
      this.setState({ buscador: "basico" });
    }
  };

  render() {
    parametros = cargarParametros(parametros_usados, this.props.portal.parametros_ecom, "ART");
    // Partimos la URL
    let url = window.location.pathname;
    let splittedURL = url.split("/");
    // Cogemos el codigo_articulo
    if (splittedURL[2] && splittedURL[3] && splittedURL[4]) {
      estadistico_url = splittedURL[2];
      familia_url = splittedURL[3];
      descrip_url = splittedURL[4];
    }
    return (
      <Fragment>
        <FamiliasMenu />
        {parametros && parametros.bus_avanz ? (
          this.state.buscador === "basico" ? (
            <Buscador cambiarBuscador={this.cambiarBuscador} mostrar_avanzado={parametros.bus_avanz} />
          ) : (
            <BuscadorAvanzado cambiarBuscador={this.cambiarBuscador} />
          )
        ) : (
          <Buscador cambiarBuscador={this.cambiarBuscador} mostrar_avanzado={"N"} />
        )}
        <div className="container">
          <div id="tt-pageContent" className="articulos">
            <div className="container-indent">
              <div className="container-fluid-custom container-fluid-custom-mobile-padding">
                <div className="row">
                  <ArticulosFiltros />
                  <div className="col-md-12 col-lg-9 col-xl-9">
                    <FamiliasMenu usar_imagenes={true} />
                    <TablaArticulos
                      origen="articulos"
                      filtro_url={
                        estadistico_url && familia_url && descrip_url
                          ? {
                              estadistico: estadistico_url,
                              familia: familia_url,
                              nombre: descrip_url,
                            }
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  portal: state.portal.portal,
  articulos_pagina: state.listadoArticulos.articulos_pagina,
  filtros: state.articulos.filtros,
  filtros_claves_estadisticas: state.articulos.filtros_claves_estadisticas,
  filtros_aplicados: state.articulos.filtros_aplicados,
});

export default connect(mapStateToProps, {
  getFiltros,
  getMasArticulos,
  setBusqueda,
  limpiarFiltros,
  añadirFiltro,
  setFamiliaSeleccionada,
})(Articulos);
