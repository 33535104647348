import React, { Component, Fragment } from "react";
import { cargarParametros } from "../../../helpers/funciones";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AnadirCarrito from "../AnadirCarrito";
import Precio from "../Precio";
import Subpresentaciones from "./Subpresentaciones";
import i18n from "../../../lang/idiomas";
import { getAgrupacionHijos, getAgrupacionCondiciones, setClaveSeleccionada, setHijoSeleccionado, limpiarClavesSeleccionadas, limpiarHijoSeleccionado } from "../../../actions/agrupaciones";

let parametros_usados = ["mostrar_iva", "tipo_precio"];
let parametros;

export class AgrupacionFiltros extends Component {
  state = {};

  static propTypes = {
    articulo: PropTypes.object.isRequired,
    hijos: PropTypes.array.isRequired,
    condiciones: PropTypes.array.isRequired,
    getAgrupacionHijos: PropTypes.func.isRequired,
    getAgrupacionCondiciones: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const codigo_articulo = this.props.codigo_articulo;
    this.props.getAgrupacionHijos(codigo_articulo);
    this.props.getAgrupacionCondiciones(codigo_articulo);
    this.props.limpiarClavesSeleccionadas();
    this.props.limpiarHijoSeleccionado();
  }

  componentDidUpdate(prevProps) {
    window.wookie();
  }

  onClick = (e) => {
    if (e.target.value && e.target.value !== "") {
      this.props.limpiarHijoSeleccionado();
      this.props.setClaveSeleccionada({
        clave: e.target.name,
        valor_clave: e.target.value,
      });
    }
  };

  seleccionHijo = (e) => {
    if (e.target.value && e.target.value !== "") {
      let hijo = this.props.hijos.filter((hijo) => hijo.codigo_articulo === e.target.value);

      if (hijo) {
        this.props.setHijoSeleccionado(hijo[0]);
      }
    }
  };

  filtrar_hijos = () => {
    const hijos_filtrados = this.props.hijos.filter((hijo) => {
      return hijo.claves.every((clave) => {
        let clave_aplicada = this.props.claves_seleccionadas.filter((cla) => cla.clave === clave.clave && cla.valor_clave === clave.valor_clave);

        return !!(clave_aplicada && clave_aplicada.length > 0);
      });
    });

    return hijos_filtrados;
  };

  render() {
    parametros = cargarParametros(parametros_usados, this.props.portal.parametros_ecom, "AGFIL");
    const hijos_filtrados = this.filtrar_hijos();
    return (
      <div className="agrupacion_articulos">
        {this.props.condiciones.map((condicion) => (
          <div key={condicion.id} className="condicion_agrupacion">
            <div className="titulo_agrupacion">{condicion.clave.nombre}</div>
            {condicion.valores
              .filter((valor) => {
                return this.props.hijos.some((hijo) => {
                  return hijo.claves.some((clave_hijo) => valor.valor_clave === clave_hijo.valor_clave);
                });
              })
              .map((valor) => {
                return (
                  <button
                    key={valor.id}
                    className={
                      this.props.claves_seleccionadas.find((cla) => cla.clave === valor.clave && cla.valor_clave === valor.valor_clave) ? "btn btn-small btn-primary" : "btn btn-small btn-border"
                    }
                    name={valor.clave}
                    value={valor.valor_clave}
                    onClick={this.onClick}
                  >
                    {valor.nombre}
                  </button>
                );
              })}
          </div>
        ))}
        {Object.keys(this.props.condiciones).length === Object.keys(this.props.claves_seleccionadas).length ? (
          hijos_filtrados.length > 0 ? (
            <Fragment>
              <div className="tabla_agrupacion">
                <div className="col-12">
                  <div className="row titulo_agrupacion_fila">
                    <h5>{i18n.t("eparciales.epseluar")}</h5>
                  </div>
                  {hijos_filtrados.map((hijo) => (
                    <div key={hijo.id} className="row tabla_agrupacion_fila">
                      <div className=" col-sm-6 col-md-4 col-lg-4 tabla_agrupacion_columna columna_descripcion" style={{ heigth: "100%" }}>
                        <div className="descrip-articulo-hijo-cont">
                          <span className="descrip-articulo-hijo">{hijo.descrip_comercial}</span>
                        </div>
                      </div>

                      {this.props.hijo_seleccionado.codigo_articulo === hijo.codigo_articulo ? (
                        <Fragment>
                          <div className="col-sm-6 col-md-2 col-lg-2 tabla_agrupacion_columna columna_precio">
                            <div className="tt-price">
                              <Precio
                                codigo_articulo={this.props.hijo_seleccionado.codigo_articulo ? this.props.hijo_seleccionado.codigo_articulo : this.props.articulo.codigo_articulo}
                                mostrar_iva={parametros.mostrar_iva}
                                tipo_precio={parametros.tipo_precio}
                                forzar_peticion="S"
                              />
                            </div>
                          </div>

                          <div className=" col-sm-12 col-md-12 col-lg-12 tabla_agrupacion_columna columna_botonera">
                            {this.props.hijo_seleccionado && this.props.hijo_seleccionado !== "" ? <AnadirCarrito articulo={this.props.hijo_seleccionado} mostrar_texto_btn="S" origen="ficha" /> : ""}
                          </div>
                        </Fragment>
                      ) : (
                        <div className="col-sm-6 col-md-4 col-lg-4 tabla_agrupacion_columna columna_descripcion">
                          <button
                            onClick={this.seleccionHijo}
                            value={hijo.codigo_articulo}
                            className={this.props.hijo_seleccionado.codigo_articulo === hijo.codigo_articulo ? "btn btn-small btn-primary" : "btn btn-small btn-border"}
                          >
                            {this.props.hijo_seleccionado.codigo_articulo === hijo.codigo_articulo ? i18n.t("eparciales.epseldo") : i18n.t("eparciales.epselar")}
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </Fragment>
          ) : (
            i18n.t("eparciales.epnsenar")
          )
        ) : (
          i18n.t("eparciales.epuselco")
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  portal: state.portal.portal,
  articulo: state.articulo.articulo,
  hijos: state.agrupaciones.hijos,
  condiciones: state.agrupaciones.condiciones,
  claves_seleccionadas: state.agrupaciones.claves_seleccionadas,
  hijo_seleccionado: state.agrupaciones.hijo_seleccionado,
});

export default connect(mapStateToProps, {
  getAgrupacionHijos,
  getAgrupacionCondiciones,
  setClaveSeleccionada,
  setHijoSeleccionado,
  limpiarClavesSeleccionadas,
  limpiarHijoSeleccionado,
})(AgrupacionFiltros);
