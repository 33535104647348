import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import i18n from "../../lang/idiomas";
import { getArticulos, setBusqueda, getArticulosByFamily, setBusquedaAvanzada, limpiarBusquedaAvanzada } from "../../actions/articulos";
import { withRouter } from "../../helpers/funciones";
import AnalyticsEdisa from "../common/AnalyticsEdisa";
import { MagnifyingGlass } from "phosphor-react";

export class BuscadorAvanzado extends Component {
  state = {
    avanzada: "S",
    codigo_articulo: "",
    codigo_barras: "",
    descripcion: "",
    palabras_clave: "",
    equivalentes: "",
    referencia_cliente: "",
    codigo_sinonimo: "",
  };

  static propTypes = {
    articulos_pagina: PropTypes.number.isRequired,
    articulos_orden: PropTypes.string.isRequired,
    filtros_aplicados: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
    getArticulos: PropTypes.func.isRequired,
    getArticulosByFamily: PropTypes.func.isRequired,
  };

  onChange = (e) => {
    if (e.target.name !== "equivalentes") {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      this.setState({ [e.target.name]: e.target.checked });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { avanzada, palabras_clave, descripcion, codigo_articulo, codigo_barras, equivalentes, referencia_cliente, codigo_sinonimo } = this.state;

    let analytics = new AnalyticsEdisa();
    analytics.registraEvento({
      modulo: "Articulos",
      tipo: "Search",
      valor: codigo_articulo + "-" + descripcion + "-" + palabras_clave + "-" + codigo_barras + "-" + equivalentes + "-" + referencia_cliente + "-" + codigo_sinonimo,
    });

    this.props.setBusquedaAvanzada(avanzada, palabras_clave, descripcion, codigo_articulo, codigo_barras, equivalentes === true ? "S" : "", referencia_cliente, codigo_sinonimo);

    if (this.props.filtros_aplicados.length > 0) {
      this.props.getArticulosByFamily(
        this.props.filtros_aplicados,
        this.props.articulos_pagina,
        this.props.articulos_orden,
        "",
        this.props.pedido.domicilio_envio ? this.props.pedido.domicilio_envio : "",
        avanzada,
        codigo_articulo,
        codigo_barras,
        descripcion,
        palabras_clave,
        equivalentes === true ? "S" : "",
        referencia_cliente,
        codigo_sinonimo
      );
    } else {
      this.props.getArticulos(
        "",
        this.props.articulos_pagina,
        this.props.articulos_orden,
        this.props.pedido.domicilio_envio ? this.props.pedido.domicilio_envio : "",
        avanzada,
        codigo_articulo,
        codigo_barras,
        descripcion,
        palabras_clave,
        equivalentes === true ? "S" : "",
        referencia_cliente,
        codigo_sinonimo
      );
    }

    this.setState({
      avanzada: "S",
      codigo_articulo: "",
      codigo_barras: "",
      descripcion: "",
      palabras_clave: "",
      equivalentes: "",
      referencia_cliente: "",
      codigo_sinonimo: "",
    });

    if (this.props.router && this.props.router.location.pathname !== "/articulos") {
      this.props.router.navigate("/articulos");
    }
  };

  render() {
    const { palabras_clave, descripcion, codigo_articulo, codigo_barras, equivalentes, referencia_cliente, codigo_sinonimo } = this.state;
    return (
      <div className="container">
        <div className="row buscador-avanzado justify-content-center">
          <div className="col-md-10">
            <form onSubmit={this.onSubmit} className=" form-default justify-content-end pr-3">
              <div className="row align-items-center">
                <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="codigo_articulo">{i18n.t("general.sortbyca")}</label>
                    <input className="form-control" type="text" name="codigo_articulo" placeholder="Código artículo" onChange={this.onChange} value={codigo_articulo} />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="palabras_clave">{i18n.t("general.sortbyrc")}</label>
                    <input
                      className="form-control"
                      type="text"
                      name="referencia_cliente"
                      id="referencia_cliente"
                      placeholder="Referencia cliente"
                      onChange={this.onChange}
                      value={referencia_cliente}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="codigo_barras">{i18n.t("general.sortbycb")}</label>
                    <input className="form-control" type="text" name="codigo_barras" id="codigo_barras" placeholder="Código de barras" onChange={this.onChange} value={codigo_barras} />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="descripcion">{i18n.t("general.sortbyds")}</label>
                    <input className="form-control" type="text" name="descripcion" id="descripcion" placeholder="Descripción" onChange={this.onChange} value={descripcion} />
                  </div>
                </div>
              </div>

              <div className="row align-items-center">
                <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="palabras_clave">{i18n.t("general.sortbypc")}</label>
                    <input
                      className="form-control"
                      type="text"
                      name="palabras_clave"
                      id="palabras_clave"
                      placeholder="Palabras separadas por guiones"
                      onChange={this.onChange}
                      value={palabras_clave}
                    />
                  </div>
                </div>

                <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="palabras_clave">{i18n.t("general.sortbycs")}</label>
                    <input className="form-control" type="text" name="codigo_sinonimo" id="codigo_sinonimo" placeholder="Codigo sinónimo" onChange={this.onChange} value={codigo_sinonimo} />
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-check">
                    <input type="checkbox" name="equivalentes" id="equivalentes" checked={equivalentes ? equivalentes : false} className="form-check-input" onChange={this.onChange} />
                    <label className="form-check-label" for="equivalentes">
                      {i18n.t("general.sortbyeq")}
                    </label>
                  </div>
                </div>
                <div className="col-4 botones">
                  <div className="form-group">
                    <button type="submit" className="btn btn-primary">
                      <MagnifyingGlass size={20} weight={"bold"} />
                      {i18n.t("general.buscar")}
                    </button>
                  </div>
                  <div className="enlace-busqueda-simple">
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.limpiarBusquedaAvanzada();
                        this.props.cambiarBuscador();
                      }}
                      href="#"
                    >
                      {i18n.t("general.bussmtit")}
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  articulos_pagina: state.listadoArticulos.articulos_pagina,
  articulos_orden: state.listadoArticulos.articulos_orden,
  filtros_aplicados: state.articulos.filtros_aplicados,
  portal: state.portal.portal,
  cookies: state.portal.cookies,
  pedido: state.pedidos.pedido,
});

export default withRouter(
  connect(mapStateToProps, {
    getArticulos,
    setBusqueda,
    getArticulosByFamily,
    setBusquedaAvanzada,
    limpiarBusquedaAvanzada,
  })(BuscadorAvanzado)
);
