import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import i18n from "../../lang/idiomas";
import { getArticulos, setBusqueda, getArticulosByFamily } from "../../actions/articulos";
import { withRouter } from "../../helpers/funciones";
import AnalyticsEdisa from "../common/AnalyticsEdisa";
import { MagnifyingGlass } from "phosphor-react";

export class Buscador extends Component {
  state = {
    valor: "",
  };

  static propTypes = {
    articulos_pagina: PropTypes.number.isRequired,
    articulos_orden: PropTypes.string.isRequired,
    filtros_aplicados: PropTypes.array.isRequired,
    auth: PropTypes.object,
    getArticulos: PropTypes.func.isRequired,
    getArticulosByFamily: PropTypes.func.isRequired,
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { valor } = this.state;
    let analytics = new AnalyticsEdisa();
    analytics.registraEvento({
      modulo: "Articulos",
      tipo: "Search",
      valor: valor,
    });

    this.props.setBusqueda(valor);

    if (this.props.filtros_aplicados.length > 0) {
      this.props.getArticulosByFamily(
        this.props.filtros_aplicados,
        this.props.articulos_pagina,
        this.props.articulos_orden,
        valor,
        this.props.pedido.domicilio_envio ? this.props.pedido.domicilio_envio : ""
      );
    } else {
      this.props.getArticulos(valor, this.props.articulos_pagina, this.props.articulos_orden, this.props.pedido.domicilio_envio ? this.props.pedido.domicilio_envio : "");
    }

    this.setState({
      valor: "",
    });

    if (this.props.router && this.props.router.location.pathname !== "/articulos") {
      this.props.router.navigate("/articulos");
    }
  };

  render() {
    const { valor } = this.state;
    return (
      <div className="container">
        <div className="row buscador justify-content-end">
          <div className="col-md-6">
            <form onSubmit={this.onSubmit} className="form-inline form-default justify-content-end pr-3">
              <div className="input-group">
                <input className="form-control" type="text" name="valor" placeholder="Buscar" onChange={this.onChange} value={valor} />
                <div className="input-group-append">
                  <button type="submit" className="btn btn-primary">
                    <MagnifyingGlass size={20} weight="bold" />
                    {i18n.t("general.buscar")}
                  </button>
                </div>
              </div>
              {this.props.mostrar_avanzado === "S" ? (
                <div className="enlace-busqueda-avanzada">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.setBusqueda("");
                      this.props.cambiarBuscador();
                    }}
                    href="#"
                  >
                    {i18n.t("general.busavtit")}
                  </a>
                </div>
              ) : (
                ""
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  articulos_pagina: state.listadoArticulos.articulos_pagina,
  articulos_orden: state.listadoArticulos.articulos_orden,
  filtros_aplicados: state.articulos.filtros_aplicados,
  portal: state.portal.portal,
  cookies: state.portal.cookies,
  pedido: state.pedidos.pedido,
});

export default withRouter(connect(mapStateToProps, { getArticulos, setBusqueda, getArticulosByFamily })(Buscador));
