import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "react-confirm-alert/src/react-confirm-alert.css";
import { postLinea, postLineas } from "../../actions/pedidos";
import Precio from "./Precio";
import { getPrecios } from "../../actions/precio";
import Descripcion from "./Descripcion";
import Spinner from "../layout/Spinner";
import AnadirCarrito from "./AnadirCarrito";
import { confirmAlert } from "react-confirm-alert"; // Import
import { getStocksArticulos } from "../../actions/articulos";
import { comprobarCondiciones, cargarArrays, cargarParametros } from "../../helpers/funciones";
import { getPedidosAnteriores, getLineasPedido, generarPedidoPedidoAnterior, getDatosLineasPedido, limpiarDatosLineasPedido } from "../../actions/pedidosAnteriores";
import { ArrowLineLeft, Eye, ShoppingCart } from "phosphor-react";
import i18n from "../../lang/idiomas";

let articulos_aux = [];
let parametros_usados = ["stock_cantidad", "mostrar_iva", "tipo_precio", "maxim_agrup"];
let parametros;

export class ListasCompra extends Component {
  static propTypes = {
    pedidos_anteriores: PropTypes.array.isRequired,
    pedido_anterior_detalle: PropTypes.number.isRequired,
    lineas_pedido_anterior_detalle: PropTypes.array.isRequired,
    getPedidosAnteriores: PropTypes.func.isRequired,
    getLineasPedido: PropTypes.func.isRequired,
    generarPedidoPedidoAnterior: PropTypes.func.isRequired,
    getPrecios: PropTypes.func.isRequired,
  };

  state = {
    mostrar_lineas: false,
  };

  componentDidMount() {
    this.props.getPedidosAnteriores();
  }

  componentDidUpdate(prevProps, prevState) {
    window.wookie();
    let ancla = window.document.getElementById("cabecera-principal");

    if (ancla) {
      ancla.scrollIntoView({ behavior: "smooth" });
    }
    //Comprobamos si solicitan precios grupalmente
    if (comprobarCondiciones("pedidos_anteriores", this.props, prevProps, "S")) {
      articulos_aux = cargarArrays("pedidos_anteriores", this.props.lineas_pedido_anterior_detalle, "precios", this.props.precio);
      if (articulos_aux && articulos_aux.length > 0) {
        //Solo llamamos a getPrecios si hai al menos un elemento en el array
        this.props.getPrecios(
          articulos_aux, //articulos_cantidades
          "pedidoAnterior", //origen
          null, //domicilio_envio
          parametros.maxim_agrup
        );
      }
    }
    //Comprobamos si solicitan stocks grupalmente
    if (comprobarCondiciones("pedidos_anteriores", this.props, prevProps, "S")) {
      articulos_aux = cargarArrays("pedidos_anteriores", this.props.lineas_pedido_anterior_detalle, "stocks", this.props.pedido);
      if (articulos_aux && articulos_aux.length > 0) {
        //Solo llamamos a getStocks si hai al menos 1 elemento en el array
        this.props.getStocksArticulos(
          articulos_aux, //articulos_stock
          "pedidoAnterior", //origen
          parametros.stock_cantidad,
          null, //domicilio_envio
          parametros.maxim_agrup
        );
      }
    }
  }

  anadirCarrito = (e) => {
    e.preventDefault();
    let _id_pedido = e.currentTarget.dataset.id_pedido;
    this.props.getLineasPedido(_id_pedido);

    confirmAlert({
      title: i18n.t("pedido.paaped"),
      message: i18n.t("pedido.pesatlpa"),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: i18n.t("general.si"),
          onClick: () => {
            this.props.generarPedidoPedidoAnterior(_id_pedido);
          },
        },
        {
          label: i18n.t("general.no"),
        },
      ],
    });
  };

  anadirLineaCarrito = (e) => {
    e.preventDefault();
    let _id = parseInt(e.currentTarget.dataset.id);

    this.props.lineas_pedido_anterior_detalle
      .filter((linea) => linea.id === _id && linea.tipo_linea === "P")
      .forEach((linea_detalle) => {
        const linea = {
          articulo: linea_detalle.articulo,
          descripcion: this.props.descripciones[linea_detalle.articulo].data.descrip_comercial,
          precio_venta: parseFloat(this.props.precios[linea_detalle.articulo].data.precio_neto),
          observaciones: linea_detalle.observaciones,
          estado_linea: "B",
          cantidad_pedida: Number.parseFloat(linea_detalle.cantidad_pedida),
          presentacion_pedido: linea_detalle.presentacion_pedido,
          tipo_linea: "P",
          sub_pres: linea_detalle.sub_pres,
          sub_pres_cant: linea_detalle.sub_pres_cant !== "" ? Number.parseInt(linea_detalle.sub_pres_cant) : "",
          sub_pres_fc: linea_detalle.sub_pres_fc !== "" ? Number.parseFloat(linea_detalle.sub_pres_fc) : "",
          pres_fc: linea_detalle.pres_fc !== "" ? Number.parseFloat(linea_detalle.pres_fc) : "",
          presentacion_escogida: linea_detalle.presentacion_escogida !== "" ? Number.parseFloat(linea_detalle.presentacion_escogida) : "",
        };

        this.props.postLinea(linea, "", this.props.id_pedido_seleccionado);
      });
  };

  lineas = (e) => {
    e.preventDefault();
    let _id_pedido = e.currentTarget.dataset.id_pedido;
    this.props.getLineasPedido(_id_pedido);
    let index = -1;
    index = this.props.pedidos_anteriores.findIndex((pedido) => pedido.id_pedido === Number(_id_pedido));

    if (index !== 1) {
      this.props.getDatosLineasPedido(this.props.pedidos_anteriores[index]);
    }

    this.verLineas(e);
  };

  cerrarLineas = () => {
    this.props.limpiarDatosLineasPedido();
    this.props.getLineasPedido(-0);
    this.setState({ mostrar_lineas: false });
  };

  verLineas = (e) => {
    e.preventDefault();
    this.setState({ mostrar_lineas: true });
  };

  cargaBotonera(linea, anadir_lista) {
    let index = -1;

    if (this.props.listado_pedido_anterior && this.props.listado_pedido_anterior.articulos && this.props.listado_pedido_anterior.articulos.length > 0) {
      index = this.props.listado_pedido_anterior.articulos.findIndex((articulo) => linea.articulo === articulo.codigo_articulo);
    }
    if (this.props.listado_pedido_anterior && this.props.listado_pedido_anterior.isLoading === false) {
      if (index !== -1) {
        return <AnadirCarrito articulo={this.props.listado_pedido_anterior.articulos[index]} datos={linea} origen="tabla" anadir_lista={anadir_lista} index={index} />;
      } else {
        return <AnadirCarrito articulo={linea.datos_articulo[0]} origen="tabla" datos={linea} anadir_lista={anadir_lista} index={index} />;
      }
    } else {
      <Spinner showSpinner={this.props.listado_pedido_anterior.isLoading} />;
    }
  }

  render() {
    parametros = cargarParametros(parametros_usados, this.props.portal.parametros_ecom, "PEDA");
    let anadir_lista = false;
    if (this.props.menus) {
      anadir_lista = this.props.menus.some((menu) => {
        if (menu.hijos && menu.hijos.length > 0) {
          return menu.hijos.some((hijo) => {
            return !!(hijo.programa && hijo.programa !== "" && hijo.programa === "listasCompra");
          });
        } else {
          return !!(menu.programa && menu.programa !== "" && menu.programa === "listasCompra");
        }
      });
    }
    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent" className="pedidos-anteriores">
            {this.state.mostrar_lineas === false ? (
              <div className="container-indent">
                <div className="container">
                  <h1 className="tt-title-subpages noborder" onClick={this.cerrarLineas}>
                    {i18n.t("pedido.pedantr")}
                  </h1>

                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <Spinner showSpinner={this.props.pedidos_anteriores_loading} />

                      {this.props.pedidos_anteriores.length === 0 &&
                      this.props.pedidos_anteriores.filter((pedido) => {
                        return pedido.lineas && pedido.lineas.filter((linea) => linea.tipo_linea === "P").length > 0;
                      }) &&
                      !this.props.pedidos_anteriores_loading ? (
                        <h5>{i18n.t("pedido.pnexpeda")}</h5>
                      ) : (
                        <Fragment>
                          <div className="tt-shopcart-table pedidos_anteriores_desctop row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                              {this.props.pedidos_anteriores.length > 0 ? (
                                <Fragment>
                                  <div className="row titulos">
                                    <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("pedido.pserped")}</div>
                                    <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("pedido.pfechped")}</div>
                                    <div className="col-sm-12 col-md-2 col-lg-2">{i18n.t("pedido.pimprt")}</div>
                                    <div className="col-sm-12 col-md-4 col-lg-4">{i18n.t("pedido.paccns")}</div>
                                  </div>
                                  {this.props.pedidos_anteriores
                                    .filter((pedido) => {
                                      return pedido.lineas && pedido.lineas.filter((linea) => linea.tipo_linea === "P").length > 0;
                                    })
                                    .map((pedido) => {
                                      return (
                                        <div className="row pedidos-anteriores-row" key={pedido.id_pedido}>
                                          <div className="col-sm-12 col-md-3 col-lg-3">
                                            {pedido.numero_serie ? pedido.numero_serie + "/" + pedido.numero_pedido : pedido.numero_serie_pres + "/" + pedido.numero_pres}
                                          </div>
                                          <div className="col-sm-12 col-md-3 col-lg-3">{new Date(Date.parse(pedido.fecha_valor)).toLocaleDateString(this.props.locale)}</div>
                                          <div className="col-sm-12 col-md-2 col-lg-2">
                                            {new Intl.NumberFormat(this.props.locale, {
                                              style: "currency",
                                              currency: this.props.moneda,
                                            }).format(Number.parseFloat(pedido.importe_cobrado).toFixed(2))}
                                          </div>
                                          <div className="col-sm-12 col-md-2 col-lg-2">
                                            <button className="btn btn-primary" onClick={this.lineas} data-id_pedido={pedido.id_pedido}>
                                              <Eye size={22} weight={"regular"} />
                                            </button>
                                          </div>
                                          <div className="col-sm-12 col-md-2 col-lg-2">
                                            <button className="btn btn-primary" onClick={this.anadirCarrito} data-id_pedido={pedido.id_pedido}>
                                              <ShoppingCart size={22} weight={"regular"} />
                                            </button>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </Fragment>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="tt-shopcart-table pedidos_anteriores_mobile row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                              {this.props.pedidos_anteriores
                                .filter((pedido) => {
                                  return pedido.lineas && pedido.lineas.filter((linea) => linea.tipo_linea === "P").length > 0;
                                })
                                .map((pedido) => {
                                  return (
                                    <div className="row pedidos-anteriores-row" key={pedido.id_pedido}>
                                      <div className="col-12">
                                        <div className="row ">
                                          <div className="col-6 titulos">{i18n.t("pedido.pserped")}</div>
                                          <div className="col-6">{pedido.numero_serie ? pedido.numero_serie + "/" + pedido.numero_pedido : pedido.numero_serie_pres + "/" + pedido.numero_pres}</div>
                                        </div>
                                        <div className="row ">
                                          <div className="col-6 titulos">{i18n.t("pedido.pfechped")}</div>
                                          <div className="col-6">{new Date(Date.parse(pedido.fecha_valor)).toLocaleDateString(this.props.locale)}</div>
                                        </div>
                                        <div className="row ">
                                          <div className="col-6 titulos">{i18n.t("pedido.pimprt")}</div>
                                          <div className="col-6">
                                            {new Intl.NumberFormat(this.props.locale, {
                                              style: "currency",
                                              currency: this.props.moneda,
                                            }).format(Number.parseFloat(pedido.importe_cobrado).toFixed(2))}
                                          </div>
                                        </div>
                                        <div className="row ">
                                          <div className="col-6 titulos">{i18n.t("pedido.paccns")}</div>
                                          <div className="col-6">
                                            <div className="row">
                                              <div className="col-6">
                                                <button className="btn btn-primary pedidos-anteriores-accion" onClick={this.lineas} data-id_pedido={pedido.id_pedido}>
                                                  <Eye size={22} weight={"regular"} />
                                                </button>
                                              </div>
                                              <div className="col-6">
                                                <button className="btn btn-primary pedidos-anteriores-accion" onClick={this.anadirCarrito} data-id_pedido={pedido.id_pedido}>
                                                  <ShoppingCart size={22} weight={"regular"} />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {this.state.mostrar_lineas === true ? (
              <Fragment>
                {this.props.lineas_pedido_anterior_detalle.filter((linea) => linea.tipo_linea === "P").length === 0 || this.props.pedido_anterior_detalle === 0 ? (
                  <div className="container-indent">
                    <div className="container modal-body">
                      <h5 onClick={this.cerrarLineas} className="atras-listas">
                        <ArrowLineLeft size={22} weight={"regular"} /> {i18n.t("pedido.ppanmin")}
                      </h5>
                      <h1 className="tt-title-subpages noborder">{i18n.t("pedido.plineasp")}</h1>
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <p>{i18n.t("pedido.pnhlinep")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="container-indent">
                    <div className="container modal-body">
                      <h5 onClick={this.cerrarLineas} className="atras-pedido">
                        <ArrowLineLeft size={22} weight={"regular"} /> {i18n.t("pedido.ppanmin")}
                      </h5>
                      <h1 className="tt-title-subpages noborder">{i18n.t("pedido.plineasp")}</h1>
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="tt-shopcart-table pedidos_anteriores_detalle_desctop row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                              <div className="row titulos">
                                <div className="col-sm-12 col-md-2 col-lg-2">{i18n.t("pedido.partcl")}</div>
                                <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("pedido.pdescr")}</div>
                                <div className="col-sm-12 col-md-2 col-lg-2">{i18n.t("pedido.pprec")}</div>
                                <div className="col-sm-12 col-md-1 col-lg-1">{i18n.t("pedido.pcant")}</div>
                                <div className="col-sm-12 col-md-2 col-lg-2">{i18n.t("pedido.ppresnt")}</div>
                                <div className="col-sm-12 col-md-2 col-lg-2">{i18n.t("pedido.paccns")}</div>
                              </div>

                              {this.props.lineas_pedido_anterior_detalle
                                .filter((linea) => linea.tipo_linea === "P")
                                .map((linea) => {
                                  return (
                                    <div className="row pedidos-anteriores-row" key={linea.id}>
                                      <div className="col-sm-12 col-md-2 col-lg-2">
                                        <Link
                                          to={
                                            "/articulo/" +
                                            linea.articulo +
                                            "/" +
                                            (linea.datos_articulo && linea.datos_articulo[0] && linea.datos_articulo[0].descrip_comercial
                                              ? linea.datos_articulo[0].descrip_comercial.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase()
                                              : "")
                                          }
                                        >
                                          {linea.articulo}
                                        </Link>
                                      </div>
                                      <div className="col-sm-12 col-md-3 col-lg-3">
                                        <Descripcion codigo_articulo={linea.articulo} />
                                      </div>
                                      <div className="col-sm-12 col-md-2 col-lg-2">
                                        <Precio codigo_articulo={linea.articulo} mostrar_iva={parametros.mostrar_iva} tipo_precio={parametros.tipo_precio} />
                                      </div>
                                      <div className="col-sm-12 col-md-1 col-lg-1">{Number(linea.cantidad_pedida).toFixed(0)}</div>
                                      <div className="col-sm-12 col-md-2 col-lg-2">{linea.presentacion_pedido}</div>
                                      <div className="col-sm-12 col-md-2 col-lg-2">{this.cargaBotonera(linea, anadir_lista)}</div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>

                          <div className="tt-shopcart-table pedidos_anteriores_detalle_mobile row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                              {this.props.lineas_pedido_anterior_detalle
                                .filter((linea) => linea.tipo_linea === "P")
                                .map((linea, index) => {
                                  return (
                                    <div className="row pedidos-anteriores-row" key={index}>
                                      <div className="col-12">
                                        <div className="row element-row">
                                          <div className="col-6 titulos">{i18n.t("pedido.partcl")}</div>
                                          <div className="col-6">{linea.articulo}</div>
                                        </div>

                                        <div className="row element-row">
                                          <div className="col-6 titulos">{i18n.t("pedido.pdescr")}</div>
                                          <div className="col-6">
                                            <Descripcion codigo_articulo={linea.articulo} />
                                          </div>
                                        </div>

                                        <div className="row element-row">
                                          <div className="col-6 titulos">{i18n.t("pedido.pprec")}</div>
                                          <div className="col-6">
                                            <Precio codigo_articulo={linea.articulo} mostrar_iva={parametros.mostrar_iva} tipo_precio={parametros.tipo_precio} />
                                          </div>
                                        </div>

                                        <div className="row element-row">
                                          <div className="col-6 titulos">{i18n.t("pedido.pcant")}</div>
                                          <div className="col-6">{Number(linea.cantidad_pedida).toFixed(0)}</div>
                                        </div>

                                        <div className="row element-row">
                                          <div className="col-6 titulos">{i18n.t("pedido.ppresnt")}</div>
                                          <div className="col-6">{linea.presentacion_pedido}</div>
                                        </div>

                                        <div className="row element-row">
                                          <div className="col-6 titulos">{i18n.t("pedido.paccns")}</div>
                                          <div className="col-6">
                                            <button className="btn btn-primary pedidos-anteriores-accion" onClick={this.anadirLineaCarrito} data-id={linea.id}>
                                              <ShoppingCart size={22} weight={"regular"} />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  portal: state.portal.portal,
  menus: state.portal.menus,
  pedidos_anteriores: state.pedidosAnteriores.pedidos_anteriores,
  pedido_anterior_detalle: state.pedidosAnteriores.pedido_anterior_detalle,
  lineas_pedido_anterior_detalle: state.pedidosAnteriores.lineas_pedido_anterior_detalle,
  precio: state.precio.precio,
  descripciones: state.descripcion.descripciones,
  locale: state.precio.locale,
  moneda: state.precio.moneda,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
  pedidos_anteriores_loading: state.pedidosAnteriores.isLoading,
  pedido: state.pedidos.pedido,
  listado_pedido_anterior: state.listadoArticulos.pedido_anterior,
});

export default connect(mapStateToProps, {
  getPedidosAnteriores,
  getLineasPedido,
  postLinea,
  postLineas,
  generarPedidoPedidoAnterior,
  getPrecios,
  getStocksArticulos,
  getDatosLineasPedido,
  limpiarDatosLineasPedido,
})(ListasCompra);
