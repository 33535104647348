import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import BuscadorRapido from "./BuscadorRapido";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { ArrowLineLeft, Eye, FloppyDiskBack, ShoppingCart, Trash } from "phosphor-react";
import Precio from "./Precio";
import { getPrecios } from "../../actions/precio";
import Descripcion from "./Descripcion";
import { getStocksArticulos } from "../../actions/articulos";
import Spinner from "../layout/Spinner";
import { postLinea, postLineas } from "../../actions/pedidos";
import { ordenarLista, comprobarCondiciones, cargarArrays, cargarParametros } from "../../helpers/funciones";
import { eliminarLista, getListasCompra, getLineasListaCompra, eliminarLineaLista, crearLista, generarPedidoListaCompra, actualizaLineaLista } from "../../actions/listasCompra";
import i18n from "../../lang/idiomas";

let articulos_aux = [];
let parametros_usados = ["tipo_precio", "mostrar_iva", "busc_rapido", "andr_a", "maxim_agrup", "stock_cantidad"];
let parametros;
export class ListasCompra extends Component {
  static propTypes = {
    listas_compra: PropTypes.array.isRequired,
    lista_detalle: PropTypes.number.isRequired,
    lineas_lista_detalle: PropTypes.array.isRequired,
    getListasCompra: PropTypes.func.isRequired,
    eliminarLista: PropTypes.func.isRequired,
    crearLista: PropTypes.func.isRequired,
    getLineasListaCompra: PropTypes.func.isRequired,
    eliminarLineaLista: PropTypes.func.isRequired,
    postLinea: PropTypes.func.isRequired,
    postLineas: PropTypes.func.isRequired,
    precios: PropTypes.object.isRequired,
    descripciones: PropTypes.object.isRequired,
    getPrecios: PropTypes.func.isRequired,
    getStocksArticulos: PropTypes.func.isRequired,
  };

  state = {
    nombre_lista: "",
    cantidad_linea: { articulo: "", cantidad: 0 },
    mostrar_lineas: false,
  };

  componentDidMount() {
    this.props.getListasCompra();
  }

  componentDidUpdate(prevProps, prevState) {
    window.wookie();
    if (comprobarCondiciones("listasCompra", this.props.lista, prevProps.lista, "S", this.props.lineas_lista_detalle)) {
      articulos_aux = cargarArrays("listasCompra", this.props.lista, "precios", this.props.precios);
      if (articulos_aux && articulos_aux.length > 0) {
        //Solo llamamos a getPrecios si hai al menos un elemento en el array
        this.props.getPrecios(
          articulos_aux, //articulos_cantidades
          "listasCompra", //origen
          null, //domicilio_envio
          parametros.maxim_agrup
        );
      }
    }
    if (comprobarCondiciones("listasCompra", this.props.lista, prevProps.lista, "S", this.props.lineas_lista_detalle)) {
      articulos_aux = cargarArrays("listasCompra", this.props.lista, "stocks", this.props.pedido);
      if (articulos_aux && articulos_aux.length > 0) {
        //Solo llamamos a getStocksArticulos si hai al menos un elemento en el array
        this.props.getStocksArticulos(
          articulos_aux, //articulos_stock
          "ListasCompra", //origen
          parametros.stock_cantidad,
          this.props.lista_detalle, // identificador de la lista a cargar
          parametros.maxim_agrup
        );
      }
    }
  }

  deleteLista = (e) => {
    e.preventDefault();
    let _codigo_lista = e.currentTarget.dataset.codigoLista;

    confirmAlert({
      title: i18n.t("lista.elilisco"),
      message: i18n.t("lista.esegelli"),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: i18n.t("general.si"),
          onClick: (e) => {
            this.props.eliminarLista(_codigo_lista);
          },
        },
        {
          label: i18n.t("general.no"),
        },
      ],
    });
  };

  deleteLinea = (e) => {
    e.preventDefault();
    let _id = e.currentTarget.dataset.id;

    confirmAlert({
      title: i18n.t("lista.elimline"),
      message: i18n.t("lista.esegelin"),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: i18n.t("general.si"),
          onClick: (e) => {
            this.props.eliminarLineaLista(_id);
          },
        },
        {
          label: i18n.t("general.no"),
        },
      ],
    });
  };

  anadirCarrito = (e) => {
    e.preventDefault();

    let _codigo_lista = e.currentTarget.dataset.codigoLista;

    confirmAlert({
      title: i18n.t("lista.anlist"),
      message: i18n.t("lista.esegalic"),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: i18n.t("general.si"),
          onClick: (e) => {
            this.props.generarPedidoListaCompra(_codigo_lista);
          },
        },
        {
          label: i18n.t("general.no"),
        },
      ],
    });
  };

  actualizarLineaLista = (e) => {
    e.preventDefault();
    let _id = parseInt(e.currentTarget.dataset.id);

    //Buscamos la linea
    let linea_detalle = this.props.lineas_lista_detalle.filter((linea) => linea.id === _id)[0];

    if (linea_detalle) {
      //Comprobamos estado
      if (Number.parseFloat(linea_detalle.cantidad_pedida).toFixed(0) !== Number.parseFloat(this.state.cantidad_linea.articulo).toFixed(0)) {
        let input = document.getElementById("in-" + linea_detalle.codigo_articulo).value;
        let input_mob = document.getElementById("im-" + linea_detalle.codigo_articulo).value;

        // Comprobamos el valor defectivo
        if (
          Number.parseFloat(linea_detalle.cantidad_pedida).toFixed(0) !== Number.parseFloat(input).toFixed(0) ||
          Number.parseFloat(linea_detalle.cantidad_pedida).toFixed(0) !== Number.parseFloat(input_mob).toFixed(0)
        ) {
          linea_detalle.cantidad_pedida = Number.parseFloat(linea_detalle.codigo_articulo === this.state.cantidad_linea.articulo ? this.state.cantidad_linea.cantidad : linea_detalle.cantidad_pedida);

          // Actualizamos
          this.props.actualizaLineaLista(linea_detalle);
        }
      }
    }
  };

  anadirLineaCarrito = (e) => {
    e.preventDefault();
    let _id = parseInt(e.currentTarget.dataset.id);

    this.props.lineas_lista_detalle
      .filter((linea) => linea.id === _id)
      .forEach((linea_detalle) => {
        const linea = {
          articulo: linea_detalle.codigo_articulo,
          descripcion: this.props.descripciones[linea_detalle.codigo_articulo].data.descrip_comercial,
          precio_venta: parseFloat(this.props.precios[linea_detalle.codigo_articulo].data.precio_neto),
          observaciones: linea_detalle.observaciones,
          estado_linea: "B",
          cantidad_pedida: Number.parseFloat(linea_detalle.codigo_articulo === this.state.cantidad_linea.articulo ? this.state.cantidad_linea.cantidad : linea_detalle.cantidad_pedida),
          presentacion_pedido: linea_detalle.presentacion_pedido,
          tipo_linea: "P",
          sub_pres: linea_detalle.sub_pres,
          sub_pres_cant: linea_detalle.sub_pres_cant !== "" ? Number.parseInt(linea_detalle.sub_pres_cant) : "",
          sub_pres_fc: linea_detalle.sub_pres_fc !== "" ? Number.parseFloat(linea_detalle.sub_pres_fc) : "",
          pres_fc: linea_detalle.pres_fc !== "" ? Number.parseFloat(linea_detalle.pres_fc) : "",
          presentacion_escogida: linea_detalle.presentacion_escogida !== "" ? Number.parseFloat(linea_detalle.presentacion_escogida) : "",
        };

        this.props.postLinea(linea, "", this.props.id_pedido_seleccionado);
      });
  };

  nuevaLista = (e) => {
    e.preventDefault();
    if (this.state.nombre_lista !== "") {
      this.props.crearLista(this.state.nombre_lista);
      this.setState({ nombre_lista: "" });
    }
  };

  onChange = (e) => {
    if (e.target.name !== "cantidad") {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      let cantidad = e.target.value;
      let articulo = e.currentTarget.id.slice(3);

      if (cantidad < 1) {
        this.setState({
          cantidad_linea: {
            articulo: articulo,
            cantidad: "",
          },
        });
      } else {
        this.setState({
          cantidad_linea: {
            articulo: articulo,
            cantidad: cantidad,
          },
        });
      }
    }
  };

  lineas = (e) => {
    e.preventDefault();
    let _codigo_lista = e.currentTarget.dataset.codigoLista;
    this.props.getLineasListaCompra(_codigo_lista);
    this.verLineas(e);
  };

  cerrarLineas = () => {
    this.setState({ mostrar_lineas: false });
  };

  verLineas = (e) => {
    e.preventDefault();
    this.setState({ mostrar_lineas: true });
  };

  comprobarStock(linea) {
    if (linea && linea.stock_articulo) {
      return !!(linea.stock_articulo[0].stock === "S" || linea.stock_articulo[0].stock > 0);
    } else {
      return false;
    }
  }

  cargarLineas() {
    if ((this.props.lineas_lista_detalle.length === 0 || this.props.lista_detalle === 0) && parametros) {
      return (
        <div className="container-indent">
          <div className="container modal-body">
            <h5 onClick={this.cerrarLineas} className="atras-listas">
              <ArrowLineLeft size={22} weight={"regular"} /> {i18n.t("lista.listas")}
            </h5>
            <h1 className="tt-title-subpages noborder">{i18n.t("lista.detlinea")}</h1>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <p>{i18n.t("lista.noartali")}</p>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container-indent">
          <div className="container modal-body">
            <h5 onClick={this.cerrarLineas} className="atras-listas">
              <ArrowLineLeft size={22} weight={"regular"} /> {i18n.t("lista.listas")}
            </h5>
            <h1 className="tt-title-subpages noborder">{i18n.t("lista.detlinea")}</h1>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="tt-shopcart-table listas_compra_detalle_desctop row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="row titulos">
                      <div className="col-sm-12 col-md-2 col-lg-2">{i18n.t("lista.artic")}</div>
                      <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("lista.descr")}</div>
                      <div className="col-sm-12 col-md-2 col-lg-2">{i18n.t("lista.prec")}</div>
                      <div className="col-sm-12 col-md-1 col-lg-1">{i18n.t("lista.cantid")}</div>
                      <div className="col-sm-12 col-md-1 col-lg-1">{i18n.t("lista.presntn")}</div>
                      <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("lista.accnes")}</div>
                    </div>

                    {this.props.lineas_lista_detalle.map((linea) => {
                      return (
                        <div className="row lista-compra-row" key={linea.id}>
                          <div className="col-sm-12 col-md-2 col-lg-2">
                            <span>{linea.codigo_articulo}</span>
                          </div>
                          <div className="col-sm-12 col-md-3 col-lg-3">
                            <Descripcion codigo_articulo={linea.codigo_articulo} />
                          </div>
                          <div className="col-sm-12 col-md-2 col-lg-2">
                            <Precio codigo_articulo={linea.codigo_articulo} tipo_precio={parametros.tipo_precio} mostrar_iva={parametros.mostrar_iva} />
                          </div>
                          <div className="col-sm-12 col-md-1 col-lg-1">
                            <input
                              className="quantity-input input-cantidad-listas"
                              type="number"
                              name="cantidad"
                              id={"in-" + linea.codigo_articulo}
                              key={linea.codigo_articulo}
                              value={
                                linea.codigo_articulo === this.state.cantidad_linea.articulo
                                  ? this.state.cantidad_linea.cantidad
                                  : linea.sub_pres && linea.sub_pres !== ""
                                  ? linea.sub_pres_cant + " X " + linea.sub_pres
                                  : Number.parseFloat(linea.cantidad).toFixed(0)
                              }
                              size="5"
                              onChange={this.onChange}
                            />
                          </div>
                          <div className="col-sm-12 col-md-1 col-lg-1">
                            <span>{linea.presentacion_pedido}</span>
                          </div>

                          <div className="col-sm-12 col-md-3 col-lg-3">
                            <div className="row">
                              <div className="col-sm-12 col-md-4 col-lg-4">
                                <button
                                  className="btn btn-primary lista-compra-accion"
                                  //data-tooltip="Actualizar linea"
                                  onClick={this.actualizarLineaLista}
                                  data-id={linea.id}
                                  disabled={this.props.listas_compra_loading ? true : false}
                                >
                                  <FloppyDiskBack size={22} weight={"regular"} />
                                </button>
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-4">
                                <button
                                  className="btn btn-primary lista-compra-accion"
                                  //data-tooltip="Añadir linea a carrito"
                                  onClick={this.anadirLineaCarrito}
                                  data-id={linea.id}
                                  disabled={this.props.pedido_cargando || this.props.listas_compra_loading || !this.comprobarStock(linea) ? true : false}
                                >
                                  <ShoppingCart size={22} weight={"regular"} />
                                </button>
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-4">
                                <button
                                  className="btn btn-primary lista-compra-accion"
                                  //data-tooltip="Eliminar linea"
                                  onClick={this.deleteLinea}
                                  data-id={linea.id}
                                  disabled={this.props.listas_compra_loading ? true : false}
                                >
                                  <Trash size={22} weight={"regular"} />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {parametros.busc_rapido && parametros.busc_rapido !== "N" ? (
                      <div className="buscador">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <BuscadorRapido añadir_a={parametros.andr_a} />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="tt-shopcart-table listas_compra_detalle_mobile row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    {this.props.lineas_lista_detalle.map((linea) => {
                      return (
                        <div className="row lista-compra-row" key={linea.codigo_articulo}>
                          <div className="col-12">
                            <div className="row element-row">
                              <div className="col-5 titulos">{i18n.t("lista.artic")}</div>
                              <div className="col-7">{linea.codigo_articulo}</div>
                            </div>

                            <div className="row element-row">
                              <div className="col-5 titulos">{i18n.t("lista.descr")}</div>
                              <div className="col-7">
                                <Descripcion codigo_articulo={linea.codigo_articulo} />
                              </div>
                            </div>

                            <div className="row element-row">
                              <div className="col-5 titulos">{i18n.t("lista.prec")}</div>
                              <div className="col-7">
                                <Precio codigo_articulo={linea.codigo_articulo} tipo_precio={parametros.tipo_precio} mostrar_iva={parametros.mostrar_iva} />
                              </div>
                            </div>

                            <div className="row element-row">
                              <div className="col-5 titulos">{i18n.t("lista.cantid")}</div>
                              <div className="col-7">
                                <input
                                  className="quantity-input input-cantidad-listas"
                                  type="number"
                                  name="cantidad"
                                  id={"im-" + linea.codigo_articulo}
                                  key={linea.codigo_articulo}
                                  value={
                                    linea.codigo_articulo === this.state.cantidad_linea.articulo
                                      ? this.state.cantidad_linea.cantidad
                                      : linea.sub_pres && linea.sub_pres !== ""
                                      ? linea.sub_pres_cant + " X " + linea.sub_pres
                                      : Number.parseFloat(linea.cantidad).toFixed(0)
                                  }
                                  size="5"
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>

                            <div className="row element-row">
                              <div className="col-5 titulos">{i18n.t("lista.presntn")}</div>
                              <div className="col-7">{linea.presentacion}</div>
                            </div>
                            <div className="row element-row">
                              <div className="col-5 titulos">{i18n.t("lista.dispon")}</div>
                              <div className="col-7">{this.comprobarStock(linea) ? i18n.t("lista.disponmi") : i18n.t("lista.agotmin")}</div>
                            </div>
                            <div className="row element-row">
                              <div className="col-5 titulos">{i18n.t("lista.accnes")}</div>
                              <div className="col-7">
                                <div className="row">
                                  <div className="col-6">
                                    <button className="btn btn-primary lista-compra-accion" onClick={this.anadirLineaCarrito} data-id={linea.id}>
                                      <ShoppingCart size={22} weight={"regular"} />
                                    </button>
                                  </div>

                                  <div className="col-6">
                                    <button className="btn btn-primary lista-compra-accion" onClick={this.deleteLinea} data-id={linea.id}>
                                      <Trash size={22} weight={"regular"} />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    parametros = cargarParametros(parametros_usados, this.props.portal.parametros_ecom, "LIS");
    const listas_compra = this.props.listas_compra;

    if (listas_compra && listas_compra.length > 0) {
      listas_compra.sort(ordenarLista);
    }

    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent" className="listas-compra">
            {this.state.mostrar_lineas === false ? (
              <div className="container-indent">
                <div className="container">
                  <Fragment>
                    <h1 className="tt-title-subpages noborder" onClick={this.cerrarLineas}>
                      {i18n.t("lista.liscomma")}
                    </h1>

                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <form className="form-inline" id="nueva_lista" onSubmit={this.nuevaLista}>
                          <div className="form-group">
                            <label htmlFor="nombre_lista" className="sr-only">
                              {i18n.t("lista.nommin")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="nombre_lista"
                              aria-describedby="nombre_lista_help"
                              placeholder={i18n.t("lista.nomllism")}
                              onChange={this.onChange}
                              name="nombre_lista"
                            />
                          </div>
                          <button type="submit" className="btn btn-primary">
                            {i18n.t("lista.nuevmin")}
                          </button>
                        </form>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <Spinner showSpinner={this.props.listas_compra_loading} />
                        {this.props.listas_compra.length === 0 ? (
                          ""
                        ) : (
                          <Fragment>
                            <div className="tt-shopcart-table listas_compra_desctop row">
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="row titulos">
                                  <div className="col-sm-12 col-md-4 col-lg-4">{i18n.t("lista.nombr")}</div>
                                  <div className="col-sm-12 col-md-4 col-lg-4">{i18n.t("lista.fechcre")}</div>
                                  <div className="col-sm-12 col-md-4 col-lg-4">{i18n.t("lista.accnes")}</div>
                                </div>

                                {this.props.listas_compra.map((lista) => {
                                  return (
                                    <div className="row lista-compra-row" key={lista.codigo_lista}>
                                      <div className="col-sm-12 col-md-4 col-lg-4">{lista.nombre_lista}</div>
                                      <div className="col-sm-12 col-md-4 col-lg-4">{new Date(Date.parse(lista.fecha_alta)).toLocaleDateString(this.props.locale)}</div>
                                      <div className="col-sm-12 col-md-4 col-lg-4">
                                        <div className="row">
                                          <div className="col-sm-12 col-md-4 col-lg-4">
                                            <button
                                              className="btn btn-primary"
                                              //data-tooltip="Ver lista"
                                              onClick={this.lineas}
                                              data-codigo-lista={lista.codigo_lista}
                                              disabled={this.props.listas_compra_loading || lista.tiene_lineas === 0 || lista.tiene_lineas === null || lista.tiene_lineas === undefined ? true : false}
                                            >
                                              <Eye size={22} weight={"regular"} />
                                            </button>
                                          </div>

                                          <div className="col-sm-12 col-md-4 col-lg-4">
                                            {lista.tiene_lineas === 0 || lista.tiene_lineas === null || lista.tiene_lineas === undefined ? (
                                              ""
                                            ) : (
                                              <button
                                                className="btn btn-primary"
                                                //data-tooltip="Añadir a carrito"
                                                onClick={this.anadirCarrito}
                                                data-codigo-lista={lista.codigo_lista}
                                                disabled={this.props.listas_compra_loading || this.props.pedido_cargando ? true : false}
                                              >
                                                <ShoppingCart size={22} weight={"regular"} />
                                              </button>
                                            )}
                                          </div>
                                          <div className="col-sm-12 col-md-4 col-lg-4">
                                            <button
                                              className="btn btn-primary"
                                              //data-tooltip="Eliminar lista"
                                              onClick={this.deleteLista}
                                              data-codigo-lista={lista.codigo_lista}
                                              disabled={this.props.listas_compra_loading ? true : false}
                                            >
                                              <Trash size={22} weight={"regular"} />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="tt-shopcart-table listas_compra_mobile row">
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                {this.props.listas_compra.map((lista) => {
                                  return (
                                    <div className="row lista-compra-row" key={lista.codigo_lista}>
                                      <div className="col-12">
                                        <div className="row element-row">
                                          <div className="col-6 titulos">{i18n.t("lista.nombr")}</div>
                                          <div className="col-6">{lista.nombre_lista}</div>
                                        </div>

                                        <div className="row element-row">
                                          <div className="col-6 titulos">{i18n.t("lista.fechcre")}</div>
                                          <div className="col-6">{new Date(Date.parse(lista.fecha_alta)).toLocaleDateString(this.props.locale)}</div>
                                        </div>
                                        <div className="row element-row">
                                          <div className="col-6 titulos">{i18n.t("lista.accnes")}</div>
                                          <div className="col-6">
                                            <div className="row">
                                              <div className="col-4">
                                                <button
                                                  className="btn btn-primary lista-compra-accion"
                                                  //data-tooltip="Ver lista"
                                                  onClick={this.lineas}
                                                  data-codigo-lista={lista.codigo_lista}
                                                  disabled={this.props.listas_compra_loading ? true : false}
                                                >
                                                  <Eye size={22} weight={"regular"} />
                                                </button>
                                              </div>

                                              <div className="col-4">
                                                {lista.codigo_lista !== this.props.lista_detalle ? (
                                                  ""
                                                ) : (
                                                  <button
                                                    className="btn btn-primary lista-compra-accion"
                                                    //data-tooltip="Añadir a carrito"
                                                    onClick={this.anadirCarrito}
                                                    data-codigo-lista={lista.codigo_lista}
                                                    disabled={this.props.listas_compra_loading || this.props.pedido_cargando ? true : false}
                                                  >
                                                    <ShoppingCart size={22} weight={"regular"} />
                                                  </button>
                                                )}
                                              </div>

                                              <div className="col-4">
                                                <button
                                                  className="btn btn-primary lista-compra-accion"
                                                  //data-tooltip="Eliminar lista"
                                                  onClick={this.deleteLista}
                                                  data-codigo-lista={lista.codigo_lista}
                                                  disabled={this.props.listas_compra_loading ? true : false}
                                                >
                                                  <Trash size={22} weight={"regular"} />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </Fragment>
                </div>
              </div>
            ) : (
              ""
            )}

            {this.state.mostrar_lineas === true ? this.cargarLineas() : ""}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  portal: state.portal.portal,
  lista: state.listasCompra,
  listas_compra: state.listasCompra.listas_compra,
  lista_detalle: state.listasCompra.lista_detalle,
  lineas_lista_detalle: state.listasCompra.lineas_lista_detalle,
  precios: state.precio.precio,
  descripciones: state.descripcion.descripciones,
  locale: state.precio.locale,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
  articulo: state.articulo,
  pedido: state.pedidos.pedido,
  pedido_cargando: state.pedidos.isLoading,
  listas_compra_loading: state.listasCompra.isLoading,
});

export default connect(mapStateToProps, {
  eliminarLista,
  getListasCompra,
  getLineasListaCompra,
  eliminarLineaLista,
  crearLista,
  postLinea,
  postLineas,
  generarPedidoListaCompra,
  actualizaLineaLista,
  getPrecios,
  getStocksArticulos,
})(ListasCompra);
