import {
  FETCH_LISTAS_COMPRA,
  GET_LISTAS_COMPRA,
  GET_LISTAS_COMPRA_FAIL,
  DELETE_LISTA_COMPRA,
  DELETE_LISTA_COMPRA_SUCCESS,
  CREATE_LISTA_COMPRA,
  CREATE_LISTA_COMPRA_SUCCESS,
  SET_VER_LISTA,
  DELETE_LINEA_LISTA_COMPRA,
  DELETE_LINEA_LISTA_COMPRA_SUCCESS,
  GET_STOCK_ARTICULO_LISTA,
  ACTUALIZAR_LINEA_LISTA,
  ACTUALIZAR_LINEA_LISTA_SUCCESS,
  GENERA_PEDIDO_LISTA,
  GENERA_PEDIDO_LISTA_SUCCESS,
  GENERA_PEDIDO_LISTA_FAIL,
  SET_VER_LISTA_SUCCESS,
  GET_STOCKS_ARTICULOS_LISTA,
  FETCH_STOCKS_ARTICULOS_LISTA,
} from "../actions/types";

const initialState = {
  listas_compra: [],
  lista_detalle: 0,
  lineas_lista_detalle: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_LISTAS_COMPRA_FAIL:
    case GENERA_PEDIDO_LISTA_FAIL:
    case GENERA_PEDIDO_LISTA_SUCCESS:
    case ACTUALIZAR_LINEA_LISTA:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_LISTAS_COMPRA:
      return {
        ...state,
        listas_compra: [],
        lista_detalle: 0,
        lineas_lista_detalle: [],
        isLoading: true,
      };

    case DELETE_LISTA_COMPRA:
    case CREATE_LISTA_COMPRA:
    case GENERA_PEDIDO_LISTA:
    case SET_VER_LISTA:
    case DELETE_LINEA_LISTA_COMPRA:
    case FETCH_STOCKS_ARTICULOS_LISTA:
      return {
        ...state,
        isLoading: true,
      };

    case SET_VER_LISTA_SUCCESS:
      return {
        ...state,
        lista_detalle: parseInt(action.payload.codigo_lista),
        lineas_lista_detalle: action.payload.lineas,
        isLoading: false,
      };

    case GET_LISTAS_COMPRA:
      return {
        ...state,
        isLoading: false,
        listas_compra: action.payload,
      };

    case DELETE_LISTA_COMPRA_SUCCESS:
      let listas = state.listas_compra.filter((lista) => lista.codigo_lista !== parseInt(action.payload));
      return {
        ...state,
        listas_compra: listas,
        isLoading: false,
      };

    case CREATE_LISTA_COMPRA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listas_compra: [...state.listas_compra, action.payload],
      };

    case DELETE_LINEA_LISTA_COMPRA_SUCCESS:
      let lineas = state.lineas_lista_detalle.filter((linea) => linea.id !== parseInt(action.payload));
      return {
        ...state,
        lineas_lista_detalle: lineas,
        isLoading: false,
      };
    case GET_STOCK_ARTICULO_LISTA:
      let lista = state.listas_compra.filter((lista) => lista.codigo_lista === parseInt(action.payload.lista_compra))[0];
      let listas_sin_calculada = state.listas_compra.filter((lista) => lista.codigo_lista !== parseInt(action.payload.lista_compra));

      if (lista) {
        if (lista["stock_articulo"]) {
          lista["stock_articulo"] = [
            ...lista.stock_articulo,
            {
              codigo_articulo: action.payload.articulo,
              stock: action.payload.stock,
            },
          ];
        } else {
          lista["stock_articulo"] = [
            {
              codigo_articulo: action.payload.articulo,
              stock: action.payload.stock,
            },
          ];
        }
        return {
          ...state,
          isLoading: false,
          listas_compra: [...listas_sin_calculada, lista],
        };
      } else {
        return {
          ...state,
          isLoading: false,
        };
      }
    case GET_STOCKS_ARTICULOS_LISTA:
      let lista_compra = state.listas_compra.filter((lista) => lista.codigo_lista === parseInt(action.lista_compra))[0];
      let lineas_lista_detalle_nuevo = state.lineas_lista_detalle;

      if (lista_compra && lineas_lista_detalle_nuevo && lineas_lista_detalle_nuevo.length > 0) {
        for (let actual of action.payload) {
          let index = -1;
          index = lineas_lista_detalle_nuevo.findIndex((linea) => linea.codigo_articulo === actual.codigo_articulo);

          if (index !== -1) {
            if (lineas_lista_detalle_nuevo[index]["stock_articulo"]) {
              lineas_lista_detalle_nuevo[index]["stock_articulo"].push(actual);
            } else {
              lineas_lista_detalle_nuevo[index]["stock_articulo"] = [];
              lineas_lista_detalle_nuevo[index]["stock_articulo"].push(actual);
            }
          }
        }
        return {
          ...state,
          isLoading: false,
          lineas_lista_detalle: lineas_lista_detalle_nuevo,
        };
      } else {
        return {
          ...state,
          isLoading: false,
        };
      }

    case ACTUALIZAR_LINEA_LISTA_SUCCESS:
      let lineas_lista = state.lineas_lista_detalle.filter((linea) => linea.id !== parseInt(action.payload.linea.id));

      return {
        ...state,
        lineas_lista_detalle: [...lineas_lista, action.payload.linea],
        isLoading: false,
      };
    default:
      return state;
  }
}
