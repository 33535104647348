import {
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  TOKEN_FAIL,
  TOKEN_REFRESH,
  SEND_CAMBIO_PASSWORD,
  CAMBIO_PASSWORD_SUCCESS,
  CAMBIO_PASSWORD_FAIL,
  RECOVER_PASS,
  RECOVER_PASS_FAIL,
  RECOVER_PASS_SUCCESS,
  COMPROBAR_TOKEN,
  COMPROBAR_TOKEN_SUCCESS,
  COMPROBAR_TOKEN_FAIL,
  REINICIAR_PASSWORD,
  REINICIAR_PASSWORD_SUCCESS,
  REINICIAR_PASSWORD_FAIL,
  ENVIAR_REGISTRO,
  ENVIAR_REGISTRO_SUCCESS,
  ENVIAR_REGISTRO_FAIL,
  BORRA_HASH,
  ACTUALIZAR_RESERVADO_USUARIO,
  ACTUALIZAR_RESERVADO_USUARIO_SUCCESS,
  ACTUALIZAR_RESERVADO_USUARIO_FAIL,
  FETCH_SUBSCRIPCIONES_STOCK,
  GET_SUBSCRIPCIONES_STOCK,
  GET_SUBSCRIPCIONES_STOCK_SUCCESS,
  GET_SUBSCRIPCIONES_STOCK_FAIL,
  ACTUALIZA_SUB_STOCK,
  ACTUALIZA_SUB_STOCK_SUCCESS,
  ACTUALIZA_SUB_STOCK_FAIL,
  CREAR_SUB_STOCK,
  CREAR_SUB_STOCK_SUCCESS,
  CREAR_SUB_STOCK_FAIL,
  FETCH_CARNETS,
  GET_CARNETS,
  GET_CARNETS_SUCCESS,
  GET_CARNETS_FAIL,
} from "../actions/types";

import jwtDecode from "jwt-decode";

const initialState = {
  token: localStorage.getItem("token"),
  refresh: localStorage.getItem("refresh"),
  isAuthenticated: null,
  isLoading: false,
  user: {
    subscripciones: { lineas: [], isLoading: false, hasErrors: false },
    carnets: { lineas: [], isLoading: false, hasErrors: false },
  },
  portal: null,
  token_info: null,
  irAInicio: false,
  hash: localStorage.getItem("hash"),
  invitado: false,
  hijos: [],
  P: localStorage.getItem("P"),
  rememberDate: localStorage.getItem("rememberDate"),
  remember: localStorage.getItem("remember"),
};

export default function reducer(state = initialState, action) {
  let prevUser = { ...state.user };
  switch (action.type) {
    case USER_LOADING:
    case SEND_CAMBIO_PASSWORD:
    case RECOVER_PASS:
    case REINICIAR_PASSWORD:
    case ENVIAR_REGISTRO:
    case ACTUALIZAR_RESERVADO_USUARIO:
      return {
        ...state,
        isLoading: true,
      };
    case CAMBIO_PASSWORD_SUCCESS:
    case CAMBIO_PASSWORD_FAIL:
    case RECOVER_PASS_SUCCESS:
    case RECOVER_PASS_FAIL:
    case COMPROBAR_TOKEN_FAIL:
    case REINICIAR_PASSWORD_FAIL:
    case ENVIAR_REGISTRO_FAIL:
    case ACTUALIZAR_RESERVADO_USUARIO_FAIL:
    case ACTUALIZA_SUB_STOCK_FAIL:
    case CREAR_SUB_STOCK_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case COMPROBAR_TOKEN:
    case ENVIAR_REGISTRO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        irAInicio: false,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      };

    case COMPROBAR_TOKEN_SUCCESS:
      if (prevUser.id === action.payload.id && prevUser.subscripciones && prevUser.subscripciones.lineas.length > 0) {
        action.payload.subscripciones = prevUser.subscripciones;
      }
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        user: action.payload,
      };
    case TOKEN_REFRESH:
      localStorage.setItem("token", action.payload);
      return {
        ...state,
        token: action.payload,
        token_info: jwtDecode(action.payload),
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("refresh", action.payload.refresh);
      localStorage.setItem("hash", action.payload.hash);

      if (action.remember === true) {
        localStorage.setItem("remember", action.remember);
        localStorage.setItem("rememberDate", Date.now());

        if (action.remember && action.remember !== false) {
          var P = btoa(action.username + ":" + action.password);
          localStorage.setItem("P", P);
        }
      } else if (action.remember === false) {
        localStorage.removeItem("P");
        localStorage.removeItem("rememberDate");
      }
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
        token_info: jwtDecode(action.payload.token),
        hash: action.payload.hash,
        invitado: action.payload.invitado,
      };
    case TOKEN_FAIL:
      if (!state.isLoading) {
        localStorage.removeItem("token");
        return {
          ...state,
          token: null,
          user: null,
          isAuthenticated: false,
          isLoading: false,
        };
      } else {
        return state;
      }

    case LOGOUT_SUCCESS:
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      localStorage.removeItem("hash");
      return {
        ...state,
        token: null,
        refresh: null,
        token_info: null,
        user: {
          subscripciones: { lineas: [], isLoading: false, hasErrors: false },
          carnets: { lineas: [], isLoading: false, hasErrors: false },
        },
        isAuthenticated: false,
        isLoading: false,
        hash: null,
      };

    case AUTH_ERROR:
    case LOGIN_FAIL:
    case REGISTER_FAIL:
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      return {
        ...state,
        token: null,
        refresh: null,
        token_info: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    case REINICIAR_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        irAInicio: true,
      };
    case BORRA_HASH:
      localStorage.removeItem("hash");
      return {
        ...state,
        hash: null,
      };
    case ACTUALIZAR_RESERVADO_USUARIO_SUCCESS:
      if (prevUser.id === action.payload.id && prevUser.subscripciones && prevUser.subscripciones.lineas.length > 0) {
        action.payload.subscripciones = prevUser.subscripciones;
      }
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    case GET_SUBSCRIPCIONES_STOCK:
    case ACTUALIZA_SUB_STOCK:
    case CREAR_SUB_STOCK:
      return {
        ...state,
        user: {
          ...state.user,
          subscripciones: {
            ...state.user.subscripciones,
            isLoading: true,
            hasErrors: false,
          },
        },
      };
    case FETCH_SUBSCRIPCIONES_STOCK:
      return {
        ...state,
        user: {
          ...state.user,
          subscripciones: {
            lineas: [],
            isLoading: false,
            hasErrors: false,
          },
        },
      };
    case GET_CARNETS:
      return {
        ...state,
        user: {
          ...state.user,
          carnets: {
            ...state.user.carnets,
            isLoading: true,
            hasErrors: false,
          },
        },
      };
    case FETCH_CARNETS:
      return {
        ...state,
        user: {
          ...state.user,
          carnets: {
            lineas: [],
            isLoading: false,
            hasErrors: false,
          },
        },
      };
    case GET_SUBSCRIPCIONES_STOCK_SUCCESS:
      let subs_ordenadas = action.payload.sort((a, b) => {
        let fa = a.codigo_articulo.toLowerCase(),
          fb = b.codigo_articulo.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      return {
        ...state,
        user: {
          ...state.user,
          subscripciones: {
            lineas: subs_ordenadas,
            isLoading: false,
            hasErrors: false,
          },
        },
      };
    case GET_CARNETS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          carnets: {
            lineas: action.payload,
            isLoading: false,
            hasErrors: false,
          },
        },
      };
    case GET_SUBSCRIPCIONES_STOCK_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          subscripciones: { lineas: [], isLoading: false, hasErrors: true },
        },
      };
    case GET_CARNETS_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          carnets: { lineas: [], isLoading: false, hasErrors: true },
        },
      };
    case ACTUALIZA_SUB_STOCK_SUCCESS:
    case CREAR_SUB_STOCK_SUCCESS:
      let sub_actualizada = action.payload;

      let subs_filtradas = state.user.subscripciones.lineas.filter((sub) => sub.codigo_articulo !== action.payload.codigo_articulo);
      subs_filtradas.sort((a, b) => {
        let fa = a.codigo_articulo.toLowerCase(),
          fb = b.codigo_articulo.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      if (sub_actualizada.status !== "ACT" && sub_actualizada.status !== "DES") {
        return {
          ...state,
          user: {
            ...state.user,
            subscripciones: {
              lineas: [...subs_filtradas],
              isLoading: false,
              hasErrors: false,
            },
          },
        };
      } else {
        subs_filtradas.push(sub_actualizada);
        subs_filtradas.sort((a, b) => {
          let fa = a.codigo_articulo.toLowerCase(),
            fb = b.codigo_articulo.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        return {
          ...state,
          user: {
            ...state.user,
            subscripciones: {
              lineas: subs_filtradas,
              isLoading: false,
              hasErrors: false,
            },
          },
        };
      }

    default:
      return state;
  }
}
