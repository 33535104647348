import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getRutas, setRutaReparto } from "../../actions/rutas";
import i18n from "../../lang/idiomas";

export class Rutas extends Component {
  static propTypes = {
    getRutas: PropTypes.func.isRequired,
    setRutaReparto: PropTypes.func.isRequired,
    rutas: PropTypes.array.isRequired,
    ruta: PropTypes.object.isRequired,
    pedido_cargando: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.getRutas();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.pedido_cargando !== this.props.pedido_cargando || JSON.stringify(prevProps.rutas) !== JSON.stringify(this.props.rutas)) && !this.props.pedido_cargando) {
      if (this.props.rutas && this.props.rutas.length === 1) {
        this.props.setRutaReparto(this.props.rutas[0]);
      }
    }
  }

  onchange = (e) => {
    if (e.target.value !== "") {
      const ruta_reparto = this.props.rutas.filter((ruta) => ruta.ruta === e.target.value);

      this.props.setRutaReparto(ruta_reparto[0]);
    }
  };

  render() {
    return (
      <Fragment>
        <div className="tt-shopcart-box">
          <h4 className="tt-title">{i18n.t("rutas.rrutdrep")}</h4>
          <p>{i18n.t("rutas.rselrure")}</p>
          <form className="form-default">
            <div className="form-group">
              <label htmlFor="ruta">
                {i18n.t("rutas.rrutrep")}
                <sup>*</sup>
              </label>
              {this.props.rutas.length > 0 ? (
                <select className="form-control" name="ruta" onChange={this.onchange} disabled={this.props.pedido_cargando || this.props.pedidoGuardado ? true : false}>
                  <option key="0" value="">
                    {i18n.t("rutas.rselruen")}
                  </option>
                  {this.props.rutas.map((ruta, index) => {
                    if (ruta.ruta === this.props.ruta.ruta) {
                      return (
                        <option key={index} value={ruta.ruta} selected>
                          {ruta.descripcion}
                        </option>
                      );
                    } else {
                      return (
                        <option key={index} value={ruta.ruta}>
                          {ruta.descripcion}
                        </option>
                      );
                    }
                  })}
                </select>
              ) : (
                <p>{i18n.t("rutas.rnexruen")}</p>
              )}
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  rutas: state.rutas.rutas,
  ruta: state.rutas.ruta,
  pedido_cargando: state.pedidos.isLoading,
  pedido: state.pedidos.pedido,
});

export default connect(mapStateToProps, { getRutas, setRutaReparto })(Rutas);
