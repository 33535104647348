import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AnadirCarrito from "./AnadirCarrito";
import Precio from "./Precio";
import ArticulosEquivalentes from "./ArticulosEquivalentes";
import ArticuloNecesidades from "./ArticuloNecesidades";
import { getPedidoHabitual } from "../../actions/pedidoHabitual";
import { getArticulosRelacionados } from "../../actions/articulosRelacionados";
import { getArticulosEquivalentes } from "../../actions/articulosEquivalentes";
import { getOfertas } from "../../actions/ofertas";
import { getNovedades } from "../../actions/novedades";
import { setPresentacion } from "../../actions/presentaciones";
import { getPrecios } from "../../actions/precio";
import Spinner from "../layout/Spinner";
import { postLinea } from "../../actions/pedidos";
import i18n from "../../lang/idiomas";
import { comprobarCondiciones, cargarArrays, cargarParametros } from "../../helpers/funciones";
import { List, Note, XCircle } from "phosphor-react";
import {
  getArticulosByFamily,
  setBusqueda,
  cambiaArticulosOrden,
  getArticulos,
  getMasArticulos,
  cambiaArticulosPagina,
  setArticuloEquivalentes,
  getStocksArticulos,
  limpiarBusquedaAvanzada,
} from "../../actions/articulos";
import Imagen from "./Imagen";

let articulos_aux = [];

let parametros_usados = ["maxim_agrup", "mostrar_iva", "tipo_precio", "stock_cantidad"];
let parametros;

export class TablaArticulos extends Component {
  static propTypes = {
    articulos: PropTypes.object.isRequired,
    pedido_habitual: PropTypes.object.isRequired,
    ofertas: PropTypes.object.isRequired,
    novedades: PropTypes.object.isRequired,
    relacionados: PropTypes.object.isRequired,
    equivalentes: PropTypes.object.isRequired,
    articulos_pagina: PropTypes.number,
    articulos_search: PropTypes.string,
    articulos_orden: PropTypes.string,
    filtros_aplicados: PropTypes.array.isRequired,
    menus: PropTypes.array.isRequired,
    getArticulos: PropTypes.func.isRequired,
    getMasArticulos: PropTypes.func.isRequired,
    getPedidoHabitual: PropTypes.func.isRequired,
    getArticulosRelacionados: PropTypes.func.isRequired,
    getArticulosEquivalentes: PropTypes.func.isRequired,
    getNovedades: PropTypes.func.isRequired,
    getOfertas: PropTypes.func.isRequired,
    cambiaArticulosPagina: PropTypes.func.isRequired,
    postLinea: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.setPresentacion("");
    switch (this.props.origen) {
      case "relacionados":
        this.props.getArticulosRelacionados(this.props.id_articulo, this.props.pedido.domicilio_envio);
        break;

      case "equivalentes":
        this.props.getArticulosEquivalentes(this.props.id_articulo, this.props.pedido.domicilio_envio);
        break;

      case "pedido_habitual":
        this.props.getPedidoHabitual(this.props.pedido.domicilio_envio);
        break;

      case "ofertas":
        this.props.getOfertas(this.props.pedido.domicilio_envio);
        break;

      case "novedades":
        this.props.getNovedades(this.props.pedido.domicilio_envio);
        break;

      case "articulos":
        if (this.props.filtro_url) {
          ///////////////////////////////////////////////////////////////////////////////////////////////////
          ///////////////////////////////////////////////////////////////////////////////////////////////////
          //si hay un filtro de url, no hacemos peticion, ya se ocuparan los filtros de lanzar la busqueda///
          ///////////////////////////////////////////////////////////////////////////////////////////////////
          break;
        } else {
          if (this.props.filtros_aplicados.length > 0) {
            this.props.getArticulosByFamily(
              this.props.filtros_aplicados,
              this.props.articulos_pagina,
              this.props.articulos_orden,
              this.props.articulos_search,
              this.props.pedido.domicilio_envio,
              this.props.busqueda_avanzada.avanzada,
              this.props.busqueda_avanzada.codigo_articulo,
              this.props.busqueda_avanzada.codigo_barras,
              this.props.busqueda_avanzada.descripcion,
              this.props.busqueda_avanzada.palabras_clave,
              this.props.busqueda_avanzada.equivalentes,
              this.props.busqueda_avanzada.referencia_cliente,
              this.props.busqueda_avanzada.codigo_sinonimo
            );
          } else {
            this.props.getArticulos(
              this.props.articulos_search,
              this.props.articulos_pagina,
              this.props.articulos_orden,
              this.props.pedido.domicilio_envio,
              this.props.busqueda_avanzada.avanzada,
              this.props.busqueda_avanzada.codigo_articulo,
              this.props.busqueda_avanzada.codigo_barras,
              this.props.busqueda_avanzada.descripcion,
              this.props.busqueda_avanzada.palabras_clave,
              this.props.busqueda_avanzada.equivalentes,
              this.props.busqueda_avanzada.referencia_cliente,
              this.props.busqueda_avanzada.codigo_sinonimo
            );
          }
        }

        break;
      default:
        if (this.props.filtros_aplicados.length > 0) {
          this.props.getArticulosByFamily(
            this.props.filtros_aplicados,
            this.props.articulos_pagina,
            this.props.articulos_orden,
            this.props.articulos_search,
            this.props.pedido.domicilio_envio,
            this.props.busqueda_avanzada.avanzada,
            this.props.busqueda_avanzada.codigo_articulo,
            this.props.busqueda_avanzada.codigo_barras,
            this.props.busqueda_avanzada.descripcion,
            this.props.busqueda_avanzada.palabras_clave,
            this.props.busqueda_avanzada.equivalentes,
            this.props.busqueda_avanzada.referencia_cliente,
            this.props.busqueda_avanzada.codigo_sinonimo
          );
        } else {
          this.props.getArticulos(
            this.props.articulos_search,
            this.props.articulos_pagina,
            this.props.articulos_orden,
            this.props.pedido.domicilio_envio,
            this.props.busqueda_avanzada.avanzada,
            this.props.busqueda_avanzada.codigo_articulo,
            this.props.busqueda_avanzada.codigo_barras,
            this.props.busqueda_avanzada.descripcion,
            this.props.busqueda_avanzada.palabras_clave,
            this.props.busqueda_avanzada.equivalentes,
            this.props.busqueda_avanzada.referencia_cliente,
            this.props.busqueda_avanzada.codigo_sinonimo
          );
        }
        break;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    window.wookie();
    switch (this.props.origen) {
      case "relacionados":
        if (prevProps.id_articulo !== this.props.id_articulo) {
          this.props.getArticulosRelacionados(this.props.id_articulo, this.props.pedido.domicilio_envio);
        }
        //Comprobamos si solicitan precios grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.relacionados, prevProps.relacionados, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.relacionados, "precios", this.props.precio);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getPrecios si hai al menos un elemento en el array
            this.props.getPrecios(
              articulos_aux, //articulos_cantidades
              this.props.origen, //origen
              null, //domicilio_envio
              parametros.maxim_agrup
            );
          }
        }
        //Comprobamos si solicitan stocks grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.relacionados, prevProps.relacionados, "S")) {
          articulos_aux = this.cargarArrays(this.props.origen, this.props.relacionados, "stocks", this.props.pedido);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getStocks si hai al menos 1 elemento en el array
            this.props.getStocksArticulos(
              articulos_aux, //articulos_stock
              this.props.origen, //origen
              parametros.stock_cantidad,
              null, //domicilio_envio
              parametros.maxim_agrup
            );
          }
        }
        break;
      case "equivalentes":
        //Comprobamos si solicitan precios grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.equivalentes, prevProps.equivalentes, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.equivalentes, "precios", this.props.precio);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getPrecios si hai al menos un elemento en el array
            this.props.getPrecios(
              articulos_aux, //articulos_cantidades
              this.props.origen, //origen
              null, //domicilio_envio
              parametros.maxim_agrup
            );
          }
        }
        //Comprobamos si solicitan stocks grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.equivalentes, prevProps.equivalentes, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.equivalentes, "stocks", this.props.pedido);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getStocks si hai al menos 1 elemento en el array
            this.props.getStocksArticulos(
              articulos_aux, //articulos_stock
              this.props.origen, //origen
              parametros.stock_cantidad,
              null, //domicilio_envio
              parametros.maxim_agrup
            );
          }
        }
        break;
      case "pedido_habitual":
        //Comprobamos si solicitan precios grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.pedido_habitual, prevProps.pedido_habitual, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.pedido_habitual, "precios", this.props.precio);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getPrecios si hai al menos un elemento en el array
            this.props.getPrecios(
              articulos_aux, //articulos_cantidades
              this.props.origen, //origen
              null, //domicilio_envio
              parametros.maxim_agrup
            );
          }
        }
        //Comprobamos si solicitan stocks grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.pedido_habitual, prevProps.pedido_habitual, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.pedido_habitual, "stocks", this.props.pedido);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getStocks si hai al menos 1 elemento en el array
            this.props.getStocksArticulos(
              articulos_aux, //articulos_stock
              this.props.origen, //origen
              parametros.stock_cantidad,
              null, //domicilio_envio
              parametros.maxim_agrup
            );
          }
        }
        break;
      case "ofertas":
        //Comprobamos si solicitan precios grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.ofertas, prevProps.ofertas, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.ofertas, "precios", this.props.precio);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getPrecios si hai al menos un elemento en el array
            this.props.getPrecios(
              articulos_aux, //articulos_cantidades
              this.props.origen, //origen
              null, //domicilio_envio
              parametros.maxim_agrup
            );
          }
        }
        //Comprobamos si solicitan stocks grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.ofertas, prevProps.ofertas, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.ofertas, "stocks", this.props.pedido);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getStocks si hai al menos 1 elemento en el array
            this.props.getStocksArticulos(
              articulos_aux, //articulos_stock
              this.props.origen, //origen
              parametros.stock_cantidad,
              null, //domicilio_envio
              parametros.maxim_agrup
            );
          }
        }
        break;
      case "novedades":
        //Comprobamos si solicitan precios grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.novedades, prevProps.novedades, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.novedades, "precios", this.props.precio);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getPrecios si hai al menos un elemento en el array
            this.props.getPrecios(
              articulos_aux, //articulos_cantidades
              this.props.origen, //origen
              null, //domicilio_envio
              parametros.maxim_agrup
            );
          }
        }
        //Comprobamos si solicitan stocks grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.novedades, prevProps.novedades, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.novedades, "stocks", this.props.pedido);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getStocks si hai al menos 1 elemento en el array
            this.props.getStocksArticulos(
              articulos_aux, //articulos_stock
              this.props.origen, //origen
              parametros.stock_cantidad,
              null, //domicilio_envio
              parametros.maxim_agrup
            );
          }
        }

        break;
      default:
        //Comprobamos si solicitan precios grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.articulos, prevProps.articulos, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.articulos, "precios", this.props.precio);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getPrecios si hai al menos un elemento en el array
            this.props.getPrecios(
              articulos_aux, //articulos_cantidades
              this.props.origen, //origen
              null, //domicilio_envio
              parametros.maxim_agrup
            );
          }
        }
        //Comprobamos si solicitan stocks grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.articulos, prevProps.articulos, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.articulos, "stocks", this.props.pedido);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getStocks si hai al menos 1 elemento en el array
            this.props.getStocksArticulos(
              articulos_aux, //articulos_stock
              this.props.origen, //origen
              parametros.stock_cantidad,
              null, //domicilio_envio
              parametros.maxim_agrup
            );
          }
        }
        for (const articulo of articulos_aux) {
          let index = this.buscarLineaPedido(articulo.codigo_articulo);
          if (index != null) {
            // Comprobamos si las observaciones tienen valor
            if (
              this.props.pedido &&
              this.props.pedido.lineas &&
              this.props.pedido.lineas[index] &&
              this.props.pedido.lineas[index].observaciones &&
              this.props.pedido.lineas[index].observaciones !== ""
            ) {
              // Buscamos los elementos a tratar
              let text_obs = document.getElementById("observaciones-" + articulo.codigo_articulo);
              let text_obs_mob = document.getElementById("observaciones-mob-" + articulo.codigo_articulo);
              if (text_obs) {
                this.modificarObs(articulo.codigo_articulo);
                this.desactivarBoton(articulo.codigo_articulo);
                text_obs.value = this.props.pedido.lineas[index].observaciones;
              }
              if (text_obs_mob) {
                text_obs_mob.value = this.props.pedido.lineas[index].observaciones;
              }
            }
          }
        }
        break;
    }
  }

  masArticulos = (e) => {
    e.preventDefault();
    let articulos_siguiente = "";
    switch (this.props.origen) {
      case "relacionados":
        articulos_siguiente = this.props.relacionados.articulos_siguiente;
        break;
      case "equivalentes":
        articulos_siguiente = this.props.equivalentes.articulos_siguiente;
        break;
      case "pedido_habitual":
        articulos_siguiente = this.props.pedido_habitual.articulos_siguiente;
        break;
      case "ofertas":
        articulos_siguiente = this.props.ofertas.articulos_siguiente;
        break;
      case "novedades":
        articulos_siguiente = this.props.novedades.articulos_siguiente;
        break;
      case "articulos":
        articulos_siguiente = this.props.articulos.articulos_siguiente;
        break;
      default:
        articulos_siguiente = this.props.articulos.articulos_siguiente;
        break;
    }

    this.props.getMasArticulos(articulos_siguiente, this.props.filtros_aplicados, this.props.origen);
  };

  verEquivalentes = (codigo_articulo) => {
    if (this.props.equivalentes.codigo_articulo !== codigo_articulo) {
      this.props.setArticuloEquivalentes(codigo_articulo);
    } else {
      this.props.setArticuloEquivalentes("");
    }
  };

  limpiarBusqueda = (e) => {
    e.preventDefault();
    this.props.setBusqueda("");
    this.props.limpiarBusquedaAvanzada();

    if (this.props.filtros_aplicados.length > 0) {
      this.props.getArticulosByFamily(this.props.filtros_aplicados, this.props.articulos_pagina, this.props.articulos_orden, "", this.props.pedido.domicilio_envio, "N", "", "", "", "", "", "", "");
    } else {
      this.props.getArticulos("", this.props.articulos_pagina, this.props.articulos_orden, this.props.pedido.domicilio_envio, "N", "", "", "", "", "", "", "");
    }
  };

  onChange = (e) => {
    this.props.cambiaArticulosOrden(e.target.value);

    if (this.props.origen === "articulos") {
      if (this.props.filtros_aplicados.length > 0) {
        this.props.getArticulosByFamily(
          this.props.filtros_aplicados,
          this.props.articulos_pagina,
          e.target.value,
          this.props.articulos_search,
          this.props.pedido.domicilio_envio,
          this.props.busqueda_avanzada.avanzada,
          this.props.busqueda_avanzada.codigo_articulo,
          this.props.busqueda_avanzada.codigo_barras,
          this.props.busqueda_avanzada.descripcion,
          this.props.busqueda_avanzada.palabras_clave,
          this.props.busqueda_avanzada.equivalentes,
          this.props.busqueda_avanzada.referencia_cliente,
          this.props.busqueda_avanzada.codigo_sinonimo
        );
      } else {
        this.props.getArticulos(
          this.props.articulos_search,
          this.props.articulos_pagina,
          e.target.value,
          this.props.pedido.domicilio_envio,
          this.props.busqueda_avanzada.avanzada,
          this.props.busqueda_avanzada.codigo_articulo,
          this.props.busqueda_avanzada.codigo_barras,
          this.props.busqueda_avanzada.descripcion,
          this.props.busqueda_avanzada.palabras_clave,
          this.props.busqueda_avanzada.equivalentes,
          this.props.busqueda_avanzada.referencia_cliente,
          this.props.busqueda_avanzada.codigo_sinonimo
        );
      }
    }
  };

  changeArtPag = (e) => {
    e.preventDefault();

    this.props.cambiaArticulosPagina(e.target.value);

    if (this.props.filtros_aplicados.length > 0) {
      this.props.getArticulosByFamily(
        this.props.filtros_aplicados,
        e.target.value,
        this.props.articulos_orden,
        this.props.articulos_search,
        this.props.pedido.domicilio_envio,
        this.props.busqueda_avanzada.avanzada,
        this.props.busqueda_avanzada.codigo_articulo,
        this.props.busqueda_avanzada.codigo_barras,
        this.props.busqueda_avanzada.descripcion,
        this.props.busqueda_avanzada.palabras_clave,
        this.props.busqueda_avanzada.equivalentes,
        this.props.busqueda_avanzada.referencia_cliente,
        this.props.busqueda_avanzada.codigo_sinonimo
      );
    } else {
      this.props.getArticulos(
        this.props.articulos_search,
        e.target.value,
        this.props.articulos_orden,
        this.props.pedido.domicilio_envio,
        this.props.busqueda_avanzada.avanzada,
        this.props.busqueda_avanzada.codigo_articulo,
        this.props.busqueda_avanzada.codigo_barras,
        this.props.busqueda_avanzada.descripcion,
        this.props.busqueda_avanzada.palabras_clave,
        this.props.busqueda_avanzada.equivalentes,
        this.props.busqueda_avanzada.referencia_cliente,
        this.props.busqueda_avanzada.codigo_sinonimo
      );
    }
  };

  desactivarBoton(articulo) {
    // Recuperamos los elementos a tratar
    let boton = document.getElementById("boton-obs-" + articulo);
    let text_area = document.getElementById("observaciones-" + articulo);
    // Comprobamos si el boton existe
    if (boton) {
      // Comprobamos si el textarea existe y tiene valor
      if (text_area.value === undefined || text_area.value === null || text_area.value === "") {
        boton.disabled = false;
      } else {
        boton.disabled = true;
      }
    }
  }

  modificarObs(articulo) {
    // Recuperamos los elementos a tratar
    let contenedor = document.getElementById("obs-desp-" + articulo);
    let text_area = document.getElementById("observaciones-" + articulo);
    // Comprobamos si el text_area tiene valor
    if (text_area.value === undefined || text_area.value === null || text_area.value === "") {
      if (contenedor.hidden) {
        contenedor.hidden = false;
      } else {
        contenedor.hidden = true;
      }
    }
  }

  getObservaciones(articulo) {
    if (document.getElementById("observaciones-" + articulo) && document.getElementById("observaciones-" + articulo).value && document.getElementById("observaciones-" + articulo).value !== "") {
      return document.getElementById("observaciones-" + articulo).value;
    } else {
      return "";
    }
  }

  setObservaciones(articulo) {
    //Obtenemos el elemento
    let elem = document.getElementById("observaciones-" + articulo);
    if (elem && elem.value && elem.value !== "") {
      //Comprobamos si la linea existe en el pedido
      let index = this.buscarLineaPedido(articulo);

      if (index != null) {
        // Si el valor no es el mismo actualizamos
        if (elem.value !== this.props.pedido.lineas[index].observaciones) {
          this.props.pedido.lineas[index].observaciones = elem.value;
          this.props.postLinea(this.props.pedido.lineas[index], "", "", "S");
        }
      }
    }
  }

  buscarLineaPedido(articulo) {
    if (this.props.pedido && this.props.pedido.lineas && this.props.pedido.lineas.length > 0) {
      let index = -1;
      // Buscamos la linea en el pedido
      index = this.props.pedido.lineas.findIndex((linea) => linea.articulo === articulo && linea.tipo_linea === "P");
      if (index !== -1) {
        return index;
      } else {
        return null;
      }
    }
  }

  render() {
    parametros = cargarParametros(parametros_usados, this.props.portal.parametros_ecom, "TART");

    let anadir_lista = false;
    if (this.props.menus) {
      anadir_lista = this.props.menus.some((menu) => {
        if (menu.hijos && menu.hijos.length > 0) {
          return menu.hijos.some((hijo) => {
            return !!(hijo.programa && hijo.programa !== "" && hijo.programa === "listasCompra");
          });
        } else {
          return !!(menu.programa && menu.programa !== "" && menu.programa === "listasCompra");
        }
      });
    }
    let articulos_render;
    switch (this.props.origen) {
      case "relacionados":
        articulos_render = this.props.relacionados;
        break;
      case "equivalentes":
        let equivalentes_articulo = this.props.equivalentes.equivalentes.filter((equivalente) => equivalente.codigo_articulo === this.props.id_articulo);
        if (equivalentes_articulo[0]) {
          articulos_render = equivalentes_articulo[0];
        } else {
          articulos_render = {
            codigo_articulo: "",
            articulos: [],
            articulos_total: 0,
            articulos_siguiente: "",
            articulos_anterior: "",
          };
        }
        break;
      case "pedido_habitual":
        articulos_render = this.props.pedido_habitual;
        break;
      case "ofertas":
        articulos_render = this.props.ofertas;
        break;
      case "novedades":
        articulos_render = this.props.novedades;
        break;
      case "articulos":
        articulos_render = this.props.articulos;
        break;
      default:
        articulos_render = this.props.articulos;
        break;
    }

    return (
      <Fragment>
        <div className="tabla-articulos">
          <div className="content-indent">
            <div className="tt-filters-options">
              {this.props.origen === "articulos" ? (
                <h1 className="tt-title">
                  {i18n.t("articulos.tittart")} <span className="tt-title-total">({this.props.articulos.articulos_total})</span>
                </h1>
              ) : (
                ""
              )}
              {this.props.origen === "novedades" ? (
                <h1 className="tt-title">
                  {i18n.t("articulos.titnov")} <span className="tt-title-total">({this.props.novedades.articulos_total})</span>
                </h1>
              ) : (
                ""
              )}
              {this.props.origen === "ofertas" ? (
                <h1 className="tt-title">
                  {i18n.t("articulos.titofe")} <span className="tt-title-total">({this.props.ofertas.articulos_total})</span>
                </h1>
              ) : (
                ""
              )}
              {this.props.origen === "pedido_habitual" ? (
                <h1 className="tt-title">
                  {i18n.t("articulos.titarthb")} <span className="tt-title-total">({this.props.pedido_habitual.articulos_total})</span>
                </h1>
              ) : (
                ""
              )}
              {this.props.origen === "relacionados" && articulos_render.articulos.length > 0 ? (
                <h1 className="tt-title">
                  {i18n.t("articulos.titartrl")} <span className="tt-title-total">({this.props.relacionados.articulos_total})</span>
                </h1>
              ) : (
                ""
              )}
              {this.props.origen === "articulos" ? (
                <div className="tt-btn-toggle">
                  <a href="#">{i18n.t("general.filtros")}</a>
                </div>
              ) : (
                ""
              )}
              <div className="tt-sort">
                {this.props.articulos_search && this.props.origen !== "equivalentes" ? (
                  <a href="#" onClick={this.limpiarBusqueda} className="">
                    <XCircle size={20} /> {this.props.articulos_search}
                  </a>
                ) : (
                  ""
                )}
                {this.props.busqueda_avanzada && this.props.busqueda_avanzada.avanzada && this.props.busqueda_avanzada.avanzada !== "" ? (
                  <a href="#" onClick={this.limpiarBusqueda} className="">
                    <XCircle size={20} />{" "}
                    {this.props.busqueda_avanzada.codigo_articulo && this.props.busqueda_avanzada.codigo_articulo !== ""
                      ? i18n.t("articulos.busavca") + " " + this.props.busqueda_avanzada.codigo_articulo + " "
                      : ""}
                    {this.props.busqueda_avanzada.codigo_barras && this.props.busqueda_avanzada.codigo_barras !== ""
                      ? i18n.t("articulos.busavcb") + " " + this.props.busqueda_avanzada.codigo_barras + " "
                      : ""}
                    {this.props.busqueda_avanzada.descripcion && this.props.busqueda_avanzada.descripcion !== ""
                      ? i18n.t("articulos.busavds") + " " + this.props.busqueda_avanzada.descripcion + " "
                      : ""}
                    {this.props.busqueda_avanzada.palabras_clave && this.props.busqueda_avanzada.palabras_clave !== ""
                      ? i18n.t("articulos.busavpc") + " " + this.props.busqueda_avanzada.palabras_clave + " "
                      : ""}
                    {this.props.busqueda_avanzada.equivalentes && this.props.busqueda_avanzada.equivalentes !== ""
                      ? i18n.t("articulos.busaveq") + " " + this.props.busqueda_avanzada.equivalentes + " "
                      : ""}
                    {this.props.busqueda_avanzada.referencia_cliente && this.props.busqueda_avanzada.referencia_cliente !== ""
                      ? i18n.t("articulos.busavrc") + " " + this.props.busqueda_avanzada.referencia_cliente + " "
                      : ""}
                    {this.props.busqueda_avanzada.codigo_sinonimo && this.props.busqueda_avanzada.codigo_sinonimo !== ""
                      ? i18n.t("articulos.busavcs") + " " + this.props.busqueda_avanzada.codigo_sinonimo + " "
                      : ""}
                  </a>
                ) : (
                  ""
                )}
                {this.props.origen === "articulos" ? (
                  <select name="orden" onChange={this.onChange}>
                    <option value="Default Sorting">{i18n.t("general.sortby")}</option>
                    <option value="codigo">{i18n.t("general.sortbyca")}</option>
                    <option value="descripcion">{i18n.t("general.sortbyds")}</option>
                  </select>
                ) : (
                  ""
                )}
                {this.props.origen === "articulos" ? (
                  <select name="resultados" onChange={this.changeArtPag}>
                    <option value="">{i18n.t("general.mostrar")}</option>
                    <option value="12">12</option>
                    <option value="24">24</option>
                    <option value="36">36</option>
                  </select>
                ) : (
                  ""
                )}
              </div>
              {/* <div className="tt-quantity">
              <a href="#" className="tt-show" data-value="4">
                <FontAwesomeIcon icon={faTh} />
              </a>
              <a href="#" className="tt-show" data-value="6">
                <FontAwesomeIcon icon={faThLarge} />
              </a>
            </div> */}
            </div>
          </div>
          {/*----------------------Tabla desctop----------------------*/}
          <div className="tt-article-table tabla_articulos_desctop row">
            <div className="col-sm-12 col-md-12 col-lg-12 tabla_articulos_desctop_int">
              {this.props.origen !== "equivalentes" ? (
                <Fragment>
                  <div className="row titulos">
                    <div className="col-sm-1 col-md-1 col-lg-1">{i18n.t("general.imagen")}</div>
                    <div className="col-sm-1 col-md-1 col-lg-1">{i18n.t("general.articulo")}</div>
                    <div className="col-sm-3 col-md-3 col-lg-3">{i18n.t("general.descrip")}</div>
                    <div className="col-sm-1 col-md-1 col-lg-1 titulo_precio">{i18n.t("general.pvp")}</div>
                    <div className="col-sm-1 col-md-1 col-lg-1 titulo_impuesto">{i18n.t("general.impuesto")}</div>
                    <div className="col-sm-1 col-md-1 col-lg-1 titulo_descuento">{i18n.t("general.descuent")}</div>
                    <div className="col-sm-1 col-md-1 col-lg-1">{i18n.t("general.info")}</div>
                    <div className="col-sm-1 col-md-1 col-lg-1">{i18n.t("general.comment")}</div>
                    <div className="col-sm-2 col-md-2 col-lg-2">{i18n.t("general.actions")}</div>
                  </div>
                </Fragment>
              ) : (
                <Fragment></Fragment>
              )}
              {articulos_render.isLoading || parametros === undefined || parametros === null ? (
                <Spinner showSpinner={articulos_render.isLoading}></Spinner>
              ) : (
                articulos_render.articulos.map((articulo, index) => (
                  <Fragment key={articulo.codigo_articulo + index}>
                    <div className="row registro-articulos" key={articulo.codigo_articulo + index}>
                      <div className={"col-12 registro-" + index}>
                        <div className="row datos-registro">
                          <div className="col-sm-1 col-md-1 col-lg-1 imagen">
                            <Link
                              to={
                                "/articulo/" +
                                articulo.codigo_articulo +
                                "/" +
                                (articulo.descrip_comercial ? articulo.descrip_comercial.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase() : "")
                              }
                            >
                              <div className="tt-item-img">
                                <Imagen codigo_articulo={articulo.codigo_articulo} principal="S" numero_imagen={1} />
                              </div>
                            </Link>
                          </div>
                          <div className="col-sm-1 col-md-1 col-lg-1 articulo">
                            <div className="link-articulo">
                              <Link
                                to={
                                  "/articulo/" +
                                  articulo.codigo_articulo +
                                  "/" +
                                  (articulo.descrip_comercial ? articulo.descrip_comercial.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase() : "")
                                }
                              >
                                {articulo.codigo_articulo}
                              </Link>
                            </div>
                          </div>
                          <div className="col-sm-3 col-md-3 col-lg-3">
                            {this.props.origen !== "equivalentes" && articulo.tiene_equivalentes === "S" ? (
                              <Fragment>
                                <div className="row desc_articulo_equ">
                                  <button
                                    className="boton-articulos-equ"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.verEquivalentes(articulo.codigo_articulo);
                                    }}
                                  >
                                    <List size={18} alt={i18n.t("articulos.altmoseq")} />
                                  </button>
                                  <div className="descripcion">{articulo.descrip_comercial}</div>
                                </div>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <div className="row desc_articulo">
                                  <div className="descripcion">{articulo.descrip_comercial}</div>
                                </div>
                              </Fragment>
                            )}
                          </div>
                          <div className="col-sm-1 col-md-1 col-lg-1 precio PVP">
                            <Precio codigo_articulo={articulo.codigo_articulo} mostrar_iva={parametros.mostrar_iva} tipo_precio={parametros.tipo_precio} />
                          </div>
                          <div className="col-sm-1 col-md-1 col-lg-1">
                            {/*IVA*/}
                            <Precio codigo_articulo={articulo.codigo_articulo} mostrar_iva={parametros.mostrar_iva} tipo_precio={"impuesto"} />
                          </div>
                          <div className="col-sm-1 col-md-1 col-lg-1">
                            {/*Bonif*/}
                            <Precio codigo_articulo={articulo.codigo_articulo} mostrar_iva={parametros.mostrar_iva} tipo_precio={"descuento"} />
                          </div>
                          <div className="col-sm-1 col-md-1 col-lg-1">
                            {/*Necesidades Articulo*/}
                            <ArticuloNecesidades articulo={articulo} origen="tabla" />
                          </div>
                          <div className="col-sm-1 col-md-1 col-lg-1">
                            {/*Observaciones*/}
                            <div className="botonera-mod">
                              <button className="btn modificar-obs" id={"boton-obs-" + articulo.codigo_articulo} onClick={() => this.modificarObs(articulo.codigo_articulo)}>
                                <Note size={20} alt={i18n.t("general.altaddob")} />
                              </button>
                            </div>
                          </div>
                          <div className="col-sm-2 col-md-2 col-lg-2">
                            <AnadirCarrito articulo={articulo} getObservaciones={this.getObservaciones} origen="tabla" anadir_lista={anadir_lista} index={index} />
                          </div>
                        </div>
                        <div className="row datos-desplegables">
                          <div className={"col-12 desplegables-" + articulo.codigo_articulo}>
                            <div className="row observaciones-desplegables" hidden={true} id={"obs-desp-" + articulo.codigo_articulo}>
                              <textarea
                                className="form-control form-observaciones-desplegables"
                                rows="2"
                                name="observaciones"
                                id={"observaciones-" + articulo.codigo_articulo}
                                onChange={(e) => {
                                  e.preventDefault();
                                  this.desactivarBoton(articulo.codigo_articulo);
                                }}
                                onBlur={(e) => {
                                  e.preventDefault();
                                  this.setObservaciones(articulo.codigo_articulo);
                                }}
                              />
                            </div>
                            {this.props.equivalentes && this.props.equivalentes.codigo_articulo !== "" && this.props.equivalentes.codigo_articulo === articulo.codigo_articulo ? (
                              <div className="row tt-article-table tabla_equivalentes_desctop">
                                <ArticulosEquivalentes id_articulo={articulo.codigo_articulo} origen="tabla" />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))
              )}
            </div>
          </div>
          <div className="tt-article-table tabla_articulos_mobile row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              {articulos_render.isLoading || parametros === undefined || parametros === null ? (
                <Spinner showSpinner={articulos_render.isLoading}></Spinner>
              ) : (
                articulos_render.articulos.map((articulo, index) => (
                  <div className="row" key={articulo.codigo_articulo}>
                    <div className="col-12">
                      <div className="row ">
                        <div className="col-6 titulos">{i18n.t("general.articulo")}</div>
                        <div className="col-6">{articulo.codigo_articulo}</div>
                      </div>

                      <div className="row ">
                        <div className="col-12 titulos">{i18n.t("general.descrip")}</div>
                        <div className="col-12">
                          {this.props.origen !== "equivalentes" && articulo.tiene_equivalentes === "S" ? (
                            <Fragment>
                              <div className="row">
                                <button
                                  className="boton-articulos-equ"
                                  //data-tooltip="Mostrar artículos equivalentes"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.verEquivalentes(articulo.codigo_articulo);
                                  }}
                                >
                                  <List size={20} />
                                </button>
                                <div className="descripcion">
                                  <textarea className="form-control" rows="2" name="observaciones" id={"observaciones-mob-" + articulo.codigo_articulo} />
                                </div>
                              </div>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <div className="row">
                                <div className="descripcion">{articulo.descrip_comercial}</div>
                              </div>
                            </Fragment>
                          )}
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-6 titulos">{i18n.t("general.precio")}</div>
                        <div className="col-6">
                          <Precio codigo_articulo={articulo.codigo_articulo} mostrar_iva={parametros.mostrar_iva} tipo_precio={parametros.tipo_precio} />
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-12 titulos">{i18n.t("general.actions")}</div>
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12">
                              <AnadirCarrito
                                articulo={articulo}
                                origen="tabla"
                                anadir_lista={anadir_lista}
                                index={index}
                                observaciones={
                                  document.getElementById("observaciones-mob-" + articulo.codigo_articulo) && document.getElementById("observaciones-mob-" + articulo.codigo_articulo).value
                                    ? document.getElementById("observaciones-mob-" + articulo.codigo_articulo).value
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.props.equivalentes && this.props.equivalentes.codigo_articulo !== "" && this.props.equivalentes.codigo_articulo === articulo.codigo_articulo ? (
                        <div className="row ">
                          <div className="col-12 titulos" id="tit_equ_mob">
                            {i18n.t("articulos.titarteq")}
                          </div>
                          <div className="tt-article-table tabla_equivalentes_mobile row">
                            <ArticulosEquivalentes id_articulo={articulo.codigo_articulo} origen="tabla" />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          {this.props.origen !== "relacionados" && this.props.origen !== "equivalentes" && articulos_render.isLoading !== true ? (
            <div className="text-center mt-5">
              {articulos_render.articulos_siguiente && articulos_render.articulos_siguiente !== "" ? (
                <button onClick={this.masArticulos} className="btn btn-border">
                  {i18n.t("articulos.mosmasar")}
                </button>
              ) : (
                <div className="text-center mt-5">
                  <button className="btn btn-border01">{i18n.t("articulos.nomasar")}</button>
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  portal: state.portal.portal,
  precio: state.precio.precio,
  articulos: state.listadoArticulos.articulos,
  pedido_habitual: state.listadoArticulos.pedido_habitual,
  ofertas: state.listadoArticulos.ofertas,
  novedades: state.listadoArticulos.novedades,
  relacionados: state.listadoArticulos.relacionados,
  equivalentes: state.listadoArticulos.equivalentes,
  articulos_pagina: state.listadoArticulos.articulos_pagina,
  articulos_search: state.listadoArticulos.articulos_search,
  articulos_orden: state.listadoArticulos.articulos_orden,
  filtros_aplicados: state.articulos.filtros_aplicados,
  menus: state.portal.menus,
  pedido: state.pedidos.pedido,
  busqueda_avanzada: state.listadoArticulos.articulos_advanced_search,
});

export default connect(mapStateToProps, {
  getArticulos,
  getMasArticulos,
  getPedidoHabitual,
  getNovedades,
  getOfertas,
  getArticulosRelacionados,
  getArticulosEquivalentes,
  cambiaArticulosPagina,
  getArticulosByFamily,
  setBusqueda,
  cambiaArticulosOrden,
  setPresentacion,
  setArticuloEquivalentes,
  getPrecios,
  getStocksArticulos,
  postLinea,
  limpiarBusquedaAvanzada,
})(TablaArticulos);
