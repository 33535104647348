import { connect } from "react-redux";
import PropTypes from "prop-types";
import InputFecha from "./InputFecha";
import Selector from "./Selector";
import { confirmAlert } from "react-confirm-alert";
import Spinner from "../layout/Spinner";
import React, { Component, Fragment } from "react";
import i18n from "../../lang/idiomas";
import { getTiposExpedientesCampos, nuevoExpediente, setTipoExpediente, validaCampoExpediente, setValorCampoExpediente, reiniciaLV, setExpediente } from "../../actions/expedientes";

let elemento;
let valores = {};

export class NuevoExpediente extends Component {
  static propTypes = {
    tipos_expedientes_campos: PropTypes.array.isRequired,
    nuevoExpediente: PropTypes.func.isRequired,
    getTiposExpedientesCampos: PropTypes.func.isRequired,
    setTipoExpediente: PropTypes.func.isRequired,
    validaCampoExpediente: PropTypes.func.isRequired,
    setValorCampoExpediente: PropTypes.func.isRequired,
    setExpediente: PropTypes.func.isRequired,
    reiniciaLV: PropTypes.func.isRequired,
  };

  state = {
    filesAux: [],
    fileInput: React.createRef(),
  };

  componentDidMount() {
    // Cargamos los campos relativos al expediente
    if (this.props.tipos_expedientes_campos && this.props.tipos_expedientes_campos.length === 0 && !this.props.expedientes_loading) {
      this.props.getTiposExpedientesCampos(this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado]);
    } else {
      // Reiniciamos los valores de los campos
      for (let i = 0; i < this.props.tipos_expedientes_campos.length; i++) {
        if (this.props.tipos_expedientes_campos[i].valor && this.props.tipos_expedientes_campos[i].valor !== "") {
          this.props.setValorCampoExpediente(this.props.tipos_expedientes_campos[i].columna, "");
          this.setState({ [this.props.tipos_expedientes_campos[i].columna]: "" });
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Comprobamos si se ha modificado el tipo expediente seleccionado
    if (this.props.tipo_expediente_seleccionado !== prevProps.tipo_expediente_seleccionado && !this.props.expedientes_loading) {
      this.props.getTiposExpedientesCampos(this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado]);
    }
  }

  reiniciaValoresPosteriores(campo) {
    let index = -1;
    index = this.props.tipos_expedientes_campos.findIndex((campo_prop) => campo_prop.columna === campo);
    if (index !== -1) {
      for (let i = index + 1; i < this.props.tipos_expedientes_campos.length; i++) {
        if (this.props.tipos_expedientes_campos[i].valor && this.props.tipos_expedientes_campos[i].valor !== "") {
          this.props.setValorCampoExpediente(this.props.tipos_expedientes_campos[i].columna, "");
          if (this.props.tipos_expedientes_campos[i].tipo_input === 'lv'){
            this.props.reiniciaLV(this.props.tipos_expedientes_campos[i].codigo_lov);
          }
          this.setState({ [this.props.tipos_expedientes_campos[i].columna]: "" });
        }
      }
    }
  }

  onChange = (e) => {
    this.props.setValorCampoExpediente(e.target.name, e.target.value);
    this.reiniciaValoresPosteriores(e.target.name);
    this.setState({[e.target.name]: e.target.value});
    elemento = document.getElementById(e.target.name);
    elemento.style.border = "unset";
  };

  onChangeFecha = (nombre_campo, valor_campo) => {
    this.reiniciaValoresPosteriores(nombre_campo);
    this.setState({[nombre_campo]: valor_campo});
    elemento = document.getElementById(nombre_campo);
    elemento.style.border = "unset";
  };

  onChangeLOV = (nombre_campo, valor_campo) => {
    this.reiniciaValoresPosteriores(nombre_campo);
    this.setState({[nombre_campo]: valor_campo});
    elemento = document.getElementById(nombre_campo);
    elemento.style.border = "unset";
  };

  handleClick = () => {
    this.state.fileInput.current.click();
  };

  onBlur = (e) => {
    let variables = [];
    let index = -1;
    index = this.props.tipos_expedientes_campos.findIndex((campo) => campo.columna === e.currentTarget.id);

    if (index !== -1) {
      if (this.props.tipos_expedientes_campos[index].validacion === "S") {
        for (let campo of this.props.tipos_expedientes_campos) {
          variables.push({ campo: campo.columna, valor: campo.valor ? campo.valor : "" });
        }
        this.props.validaCampoExpediente(
          this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].programa,
          e.currentTarget.id,
          e.currentTarget.value,
          this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].id_pers,
          variables
        );
      }
    }
  };

  handleBlur = (nombre_campo, valor_campo) => {
    let variables = [];
    let index = -1;
    index = this.props.tipos_expedientes_campos.findIndex((campo) => campo.columna === nombre_campo);

    if (index !== -1) {
      if (this.props.tipos_expedientes_campos[index].validacion === "S") {
        for (let campo of this.props.tipos_expedientes_campos) {
          variables.push({ campo: campo.columna, valor: campo.valor ? campo.valor : "" });
        }
        this.props.validaCampoExpediente(
          this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].programa,
          nombre_campo,
          valor_campo,
          this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].id_pers,
          variables
        );
      }
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    let falta_campo = true;
    let contador_campos = 0;
    let id_campo = [];
    this.props.tipos_expedientes_campos.forEach((campo_prop) => {
      if (campo_prop.obligatorio === "S") {
        falta_campo = true;
        if (campo_prop.tipo_input === "file") {
          if (this.state.filesAux && this.state.filesAux?.length > 0) {
            falta_campo = false;
          }
        } else {
          if (campo_prop.valor && campo_prop.valor !== "") {
            falta_campo = false;
          }
        }

        if (falta_campo === true) {
          contador_campos = contador_campos + 1;
          id_campo.push(campo_prop.columna);
        }
      }
    });
    if (contador_campos === 0) {
      let readers = [];
      let fileArray = [];

      const readFile = (file) => {
        return new Promise(function (resolve, reject) {
          let fr = new FileReader();

          fr.onload = function () {
            resolve(fr.result);
          };

          fr.onerror = function () {
            reject(fr);
          };

          fr.readAsBinaryString(file);
        });
      };

      if (this.state.filesAux) {
        for (let index = 0; index < this.state.filesAux?.length; index++) {
          let selectedFile = this.state.filesAux?.item(index);
          if (selectedFile) {
            readers.push(readFile(selectedFile));
          }
        }
        Promise.all(readers).then((values) => {
          for (let index = 0; index < this.state.filesAux.length; index++) {
            let selectedFile = this.state.filesAux.item(index);
            if (selectedFile) {
              let fileName = selectedFile?.name;
              let fileType = selectedFile?.type;
              let contenido = btoa(values[index]);
              fileArray.push({
                nombre: fileName,
                tipo: fileType,
                contenido: contenido,
              });
            }
          }

          valores["id_personalizacion"] = this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].id_pers;
          valores["tipo_expediente"] = this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].tipo_expediente;
          valores["ficheros"] = fileArray?.length > 0 ? fileArray : [];

          //Convertimos las keys a minusculas para evitar problemas en el back
          let val_aux = Object.keys(valores);
          val_aux.map((valor) => {
            let val_aux_Upper = valor; // Nombre campo original (Puede estar en mayusculas)
            let val_aux_Lower = val_aux_Upper.toLowerCase(); // Nombre campo minusculas
            if (val_aux_Upper !== val_aux_Lower) {
              Object.defineProperty(valores, val_aux_Lower, Object.getOwnPropertyDescriptor(valores, val_aux_Upper));
              delete valores[val_aux_Upper];
            }
            return valores;
          });
          this.props.nuevoExpediente(valores);
          this.props.setTipoExpediente(this.props.tipo_expediente_seleccionado);
        });
      }
    } else {
      confirmAlert({
        title: i18n.t("expedientes.exfadat"),
        message: i18n.t("expedientes.exfacuca"),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: i18n.t("general.aceptar"),
            onClick: () => {
              "";
            },
          },
        ],
        willUnmount: () => {
          for (const element of id_campo) {
            elemento = document.getElementById(element);
            elemento.style.border = "1px solid #FF0000";
          }
        },
      });
    }
  };

  onSelectFile = () => {
    if (this.state.fileInput.current.files.length && this.state.fileInput.current.files.length > 0) {
      this.setState({ filesAux: this.state.fileInput.current.files });
    }
  };

  render() {
    let origen = this.props.origen ? (i18n.t("general.nuevo") + ' '+ this.props.origen).toUpperCase() : i18n.t("expedientes.exnuexp") ;
    this.props.tipos_expedientes_campos.forEach((campo_prop) => {
      valores[campo_prop.columna] = campo_prop.valor ? campo_prop.valor : "";
    });
    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent">
            <div className="container-indent">
              <div className="container">
                <h1 className="tt-title-subpages noborder">{origen}</h1>
                <div className="row justify-content-center">
                  <div className="col form-body">
                    <div className="form-default justify-content-center">
                      {this.props.isLoading !== true ? (
                        <form onSubmit={this.onSubmit}>
                          {this.props.tipos_expedientes_campos !== undefined && this.props.tipos_expedientes_campos.length > 0
                            ? this.props.tipos_expedientes_campos.map((campo, index) => {
                                switch (campo.tipo_input) {
                                  case "text":
                                    return (
                                      <div className="form-group text" key={index}>
                                        <label htmlFor={campo.columna} hidden={campo.oculto !== "S" ? false : true}>
                                          {campo.literal}
                                        </label>
                                        <input
                                          id={campo.columna}
                                          className="form-control"
                                          type="text"
                                          hidden={campo.oculto !== "S" ? false : true}
                                          name={campo.columna}
                                          placeholder={campo.literal}
                                          onChange={this.onChange}
                                          onBlur={this.onBlur}
                                          value={campo.valor}
                                        />
                                      </div>
                                    );

                                  case "number":
                                    return (
                                      <div className="form-group number" key={index}>
                                        <label htmlFor={campo.columna} hidden={campo.oculto !== "S" ? false : true}>
                                          {campo.literal}
                                        </label>
                                        <input
                                          id={campo.columna}
                                          className="form-control"
                                          type="number"
                                          hidden={campo.oculto !== "S" ? false : true}
                                          name={campo.columna}
                                          placeholder={campo.literal}
                                          onChange={this.onChange}
                                          onBlur={this.onBlur}
                                          value={campo.valor}
                                        />
                                      </div>
                                    );

                                  case "file":
                                    return (
                                      <div className="form-group file" key={index}>
                                        <label htmlFor={campo.columna} hidden={campo.oculto !== "S" ? false : true}>
                                          {campo.literal}
                                        </label>
                                        <input
                                          id={campo.columna}
                                          ref={this.state.fileInput}
                                          type="file"
                                          hidden={campo.oculto !== "S" ? false : true}
                                          multiple={true}
                                          onChange={(ev) => {
                                            this.onSelectFile(ev.nativeEvent.target);
                                          }}
                                        />
                                      </div>
                                    );

                                  case "date":
                                    return <InputFecha campo={campo} index={index} handleChange={this.onChangeFecha} handleBlur={this.handleBlur} />;

                                  case "lv":
                                    return <Selector campo={campo} index={index} handleChange={this.onChangeLOV} handleBlur={this.handleBlur} filtros={this.state} />;

                                  default:
                                    return (
                                      <div className="form-group" key={index}>
                                        <label htmlFor={campo.columna} hidden={campo.oculto !== "S" ? false : true}>
                                          {campo.literal}
                                        </label>
                                        <input
                                          id={campo.columna}
                                          className="form-control"
                                          type="text"
                                          hidden={campo.oculto !== "S" ? false : true}
                                          name={campo.columna}
                                          placeholder={campo.literal}
                                          onChange={this.onChange}
                                          onBlur={this.onBlur}
                                          value={campo.valor}
                                        />
                                      </div>
                                    );
                                }
                              })
                            : "No se han encontrado campos"}
                          <div className="row">
                            <div className="col-auto mr-auto">
                              <div className="form-group">
                                <button type="submit" className="btn btn-primary">
                                  {i18n.t("expedientes.exenv")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      ) : (
                        <Spinner showSpinner={this.props.isLoading} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  tipos_expedientes: state.expedientes.tipos_expedientes,
  tipo_expediente_seleccionado: state.expedientes.tipo_expediente_seleccionado,
  tipos_expedientes_campos: state.expedientes.tipos_expedientes_campos,
  expedientes_loading: state.expedientes.isLoading,
  isLoading: state.expedientes.isLoading,
});

export default connect(mapStateToProps, {
  nuevoExpediente,
  setTipoExpediente,
  getTiposExpedientesCampos,
  validaCampoExpediente,
  setValorCampoExpediente,
  reiniciaLV,
  setExpediente
})(NuevoExpediente);
