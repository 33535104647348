import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import i18n from "../../lang/idiomas";
import { Link, Navigate } from "react-router-dom";
import { createMessage } from "../../actions/messages";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import BotonAnadirLista from "./BotonAnadirLista";
import DomiciliosEnvio from "./DomiciliosEnvio";
import BuscadorRapido from "./BuscadorRapido";
import FormasEnvio from "./FormasEnvio";
import Almacenes from "./Almacenes";
import Rutas from "./Rutas";
import Imagen from "./Imagen";
import Descripcion from "./Descripcion";
import FormasPago from "./FormasPago";
import Precio from "./Precio";
import TiposPedido from "./TiposPedido";
import AnalyticsEdisa from "../common/AnalyticsEdisa";
import ObservacionesPedido from "./ObservacionesPedido";
import NumeroPedidoCliente from "./NumeroPedidoCliente";
import PersonaPedido from "./PersonaPedido";
import Transportistas from "./Transportistas";
import EmailPedido from "./EmailPedido";
import Spinner from "../layout/Spinner";
import { cargarParametros, dynamicSort } from "../../helpers/funciones";
import TarifasEnvioPortes from "./TarifasEnvioPortes";
import { setLinea, setCarnetProfesional } from "../../actions/modalGenerica";
import { ArticuloNecesidades } from "./ArticuloNecesidades";
import { Trash, ArrowFatLineLeft, Note, CaretDown, CaretUp } from "phosphor-react";
import { integraPedido, eliminarPedido, getTextosVentas, getPedidoActivo, calculaPortesPedido, actualizaPedido, deleteLinea, postLinea } from "../../actions/pedidos";

let parametros_usados = ["mostrar_iva", "tipo_precio", "andr_desc", "busc_rapido", "andr_a", "msg_tar_env", "tipo_msg_tar_env", "resumen_simple"];
let parametros;

export class Resumen extends Component {
  state = {
    volver: "",
    articulos_carrito: [],
    importe_pedido: null,
    moneda: null,
    guardado: false,
    ver_resumen: true,
    modificado: false,
  };

  static propTypes = {
    pedido: PropTypes.object.isRequired,
    num_pedido: PropTypes.string.isRequired,
    serie_pedido: PropTypes.string.isRequired,
    ejercicio: PropTypes.string.isRequired,
    numero_pres: PropTypes.string.isRequired,
    numero_serie_pres: PropTypes.string.isRequired,
    eliminarPedido: PropTypes.func.isRequired,
    deleteLinea: PropTypes.func.isRequired,
    domicilio_envio: PropTypes.object.isRequired,
    forma_envio: PropTypes.object.isRequired,
    almacen_recogida: PropTypes.object.isRequired,
    ruta: PropTypes.object.isRequired,
    forma_pago: PropTypes.object.isRequired,
    precio: PropTypes.object.isRequired,
    postLinea: PropTypes.func.isRequired,
    setLinea: PropTypes.func.isRequired,
    hash: PropTypes.string,
    locale: PropTypes.string.isRequired,
    moneda: PropTypes.string.isRequired,
    formas_pago: PropTypes.array,
    getPedidoActivo: PropTypes.func.isRequired,
    calculaPortesPedido: PropTypes.func.isRequired,
    getTarifasEnvioPortes: PropTypes.func,
    pedido_cargando: PropTypes.bool.isRequired,
    tipo_pedido: PropTypes.object.isRequired,
    getTextosVentas: PropTypes.func.isRequired,
    subscripciones: PropTypes.object,
    carnets: PropTypes.object.isRequired,
    setCarnetProfesional: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getTextosVentas(this.props.user["organizacion_comercial"], "S", "01");
    if (!this.props.pedido_cargando && !this.props.lineas_pedido_cargando) {
      this.props.getPedidoActivo("", "S", this.props.id_pedido_seleccionado);
    }
    this.setState({ guardado: false });
  }

  componentDidUpdate(prevProps, prevState) {
    window.wookie();
    if (!this.props.pedido_cargando && !this.props.lineas_pedido_cargando) {
      if (prevProps.pedido && prevProps.pedido.lineas && this.props.pedido && this.props.pedido.lineas && prevProps.pedido.lineas.length !== this.props.pedido.lineas.length) {
        //Si no coincide el tamaño de los arrays
        this.props.getPedidoActivo("", "S", this.props.id_pedido_seleccionado);
      } else {
        if (this.props.pedido && this.props.pedido.lineas && this.props.pedido.lineas.length > 0) {
          for (let linea of this.props.pedido.lineas) {
            //Si coincide buscamos que los articulos encajen
            let index = -1;
            index = prevProps.pedido.lineas.findIndex((linea_prev) => linea_prev.articulo === linea.articulo && linea_prev.tipo_linea === linea.tipo_linea);

            if (index !== -1) {
              //Si coincide la linea
              if (linea.cantidad_pedida && prevProps.pedido.lineas[index].cantidad_pedida && linea.cantidad_pedida !== prevProps.pedido.lineas[index].cantidad_pedida) {
                //Pedimos si no coincide la cantidad
                this.props.getPedidoActivo("", "S", this.props.id_pedido_seleccionado);
              }
            } else {
              //Pedimos si no se encuentra el articulo previo
              this.props.getPedidoActivo("", "S", this.props.id_pedido_seleccionado);
            }
          }
        }
      }
    }
  }

  changeQuantity = (e, linea) => {
    // Recuperamos el input
    let input = e.target.parentNode.getElementsByClassName("quantity-input")[0];

    let count = 0;
    //Comprobamos si es input mobil o no
    if (e.currentTarget.className !== "plus-btn" && e.currentTarget.className !== "minus-btn") {
      count = parseInt(input.value);
    } else {
      count = parseInt(input.value, 10) + parseInt(e.currentTarget.className === "plus-btn" ? 1 : -1, 10);
    }

    let value = Math.max(count, 1);
    if (parseInt(linea.cantidad_pedida) !== value) {
      let cantidad_prev = linea.cantidad_pedida;
      linea.cantidad_pedida = value;
      this.props.postLinea(linea, this.props.hash, this.props.id_pedido_seleccionado, "S", cantidad_prev);
    }
  };

  onChange = (e) => {
    this.setState({ observaciones: e.target.value });
  };

  pedirConfirmacion = (e) => {
    e.preventDefault();
    let mensaje = "";
    let titulo = "";

    if (this.props.pedido.lineas && this.props.pedido.lineas.some((linea) => linea.tipo_linea === "O") && this.props.pedido.lineas.some((linea) => linea.tipo_linea === "P")) {
      titulo = i18n.t("resumen.resfnppt").toLocaleUpperCase();
      mensaje = i18n.t("resumen.resfnppm");
    } else if (this.props.pedido.lineas && this.props.pedido.lineas.every((linea) => linea.tipo_linea === "O" || linea.tipo_linea === "R")) {
      titulo = i18n.t("resumen.resfnprt").toLocaleUpperCase();
      mensaje = i18n.t("resumen.resfnprm");
    } else if (this.props.pedido.lineas && this.props.pedido.lineas.every((linea) => linea.tipo_linea === "P" || linea.tipo_linea === "R")) {
      titulo = i18n.t("resumen.resfnpdt").toLocaleUpperCase();
      mensaje = i18n.t("resumen.resfnpdm");
    }

    confirmAlert({
      title: titulo,
      message: mensaje,
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          label: i18n.t("general.aceptar"),
          onClick: () => {
            this.integrarPedido();
          },
        },
        {
          label: i18n.t("general.cancelar"),
          onClick: () => {},
        },
      ],
    });
  };

  integrarPedido = () => {
    if (this.comprobarPedido()) {
      this.props.integraPedido(
        this.props.domicilio_envio,
        this.props.pedido.observaciones,
        this.props.forma_envio,
        this.props.almacen_recogida,
        this.props.ruta,
        this.props.forma_pago,
        this.props.pedido,
        "N",
        this.props.id_pedido_seleccionado,
        "",
        this.props.pedido.persona_pedido,
        this.props.pedido.pedido_cliente,
        {
          nombre_dom_envio: this.props.pedido.nombre_dom_envio,
          direccion_dom_envio: this.props.pedido.direccion_dom_envio,
          localidad_dom_envio: this.props.pedido.localidad_dom_envio,
          estado_dom_envio: this.props.pedido.estado_dom_envio,
          provincia_dom_envio: this.props.pedido.provincia_dom_envio,
          cod_postal_dom_envio: this.props.pedido.cod_postal_dom_envio,
          tipo_portes_dom_envio: this.props.pedido.tipo_portes_dom_envio,
        },
        this.props.pedido.email,
        this.props.transportista
      );

      if (Object.keys(this.props.portal.parametros_seo).length > 0 && this.props.cookies.acepta_cookies && this.props.cookies.analiticas) {
        let articulos_carrito = [];
        this.props.pedido.lineas.forEach((linea) => {
          articulos_carrito.push(linea.articulo);
        });

        let importe_pedido = Number.parseFloat(
          this.props.pedido.lineas
            .map((linea) => {
              let impuesto_articulo = 0;

              if (this.props.precio[linea.articulo] && this.props.precio[linea.articulo].data.impuesto_articulo && !Array.isArray(this.props.precio[linea.articulo].data.impuesto_articulo)) {
                impuesto_articulo = Number.parseInt(this.props.precio[linea.articulo].data.impuesto_articulo) / 100;
              }

              return linea.precio_venta * (1 + impuesto_articulo);
            })
            .reduce((accumulator, currentValue) => accumulator + currentValue)
        ).toFixed(this.props.precio.decimales_pvp);

        let moneda = this.props.precio.moneda;

        this.setState({ articulos_carrito: articulos_carrito });
        this.setState({ importe_pedido: importe_pedido });
        this.setState({ moneda: moneda });

        let analytics = new AnalyticsEdisa();
        analytics.registraEvento({
          modulo: "Pedido",
          tipo: "InitiateCheckout",
          valor: importe_pedido,
          params: {
            articulo: articulos_carrito,
            divisa: moneda,
            precio: importe_pedido,
          },
        });
      }
    }
  };

  comprobarPedido = () => {
    // Comprobamos si el pedido está cargando
    if (!this.props.pedido_cargando) {
      //Comprobamos la forma de envio
      if (this.props.forma_envio.tipo === undefined || this.props.forma_envio.tipo === null) {
        this.props.createMessage(i18n.t("resumen.resfalfe"), "error");
      } else if (
        // Comprobamos el domicilio de envio
        this.props.forma_envio.tipo === "D" &&
        (this.props.domicilio_envio.numero_direccion === undefined || this.props.domicilio_envio.numero_direccion === null)
      ) {
        this.props.createMessage(i18n.t("resumen.resfalde"), "error");
      } else if (
        //Comprobamos el lugar de recogida
        this.props.forma_envio.tipo === "T" &&
        (this.props.almacen_recogida.almacen === undefined || this.props.almacen_recogida.almacen === null)
      ) {
        this.props.createMessage(i18n.t("resumen.resfallr"), "error");
      } else if (
        //Comprobamos la ruta
        this.props.forma_envio.tipo === "R" &&
        (this.props.ruta.almacen === undefined || this.props.ruta.almacen === null)
      ) {
        this.props.createMessage(i18n.t("resumen.resfalrr"), "error");
      } else if (
        // Comprobamos la forma de pago
        this.props.formas_pago.length > 0 &&
        (this.props.forma_pago.codigo_forma_pago_web === undefined || this.props.forma_pago.codigo_forma_pago_web === null)
      ) {
        this.props.createMessage(i18n.t("resumen.resfalfp"), "error");
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  eliminarPedido = (e) => {
    e.preventDefault();
    if (!this.props.pedido_cargando) {
      confirmAlert({
        title: i18n.t("carrito.crrbort").toUpperCase(),
        message: i18n.t("carrito.crrborm"),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: i18n.t("general.si"),
            onClick: () => this.props.eliminarPedido(this.props.pedido.id_pedido, "", this.props.id_pedido_seleccionado),
          },
          {
            label: i18n.t("general.no"),
          },
        ],
      });
    }
  };

  eliminarLinea = (e) => {
    e.preventDefault();
    if (!this.props.pedido_cargando && !this.state.guardado) {
      const linea_seleccionada = e.currentTarget.dataset.linea;

      confirmAlert({
        title: i18n.t("resumen.reselmat").toUpperCase(),
        message: i18n.t("resumen.reselmam"),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: i18n.t("general.si"),
            onClick: () => this.props.deleteLinea(linea_seleccionada, this.props.hash, this.props.id_pedido_seleccionado),
          },
          {
            label: i18n.t("general.no"),
          },
        ],
      });
    }
  };

  mostrar_resumen = (e) => {
    e.preventDefault();
    this.state.ver_resumen ? this.setState({ ver_resumen: false, modificado: true }) : this.setState({ ver_resumen: true, modificado: true });
  };

  recargar = (e) => {
    e.preventDefault();
    this.forceUpdate();
  };

  finalizar = () => {
    const info_pedido = this.props.serie_pedido && this.props.serie_pedido !== "" && this.props.num_pedido && this.props.num_pedido !== "" ? this.props.serie_pedido + "/" + this.props.num_pedido : "";

    const info_pres =
      this.props.numero_serie_pres && this.props.numero_serie_pres !== "" && this.props.numero_pres && this.props.numero_pres !== "" ? this.props.numero_serie_pres + "/" + this.props.numero_pres : "";

    let mensaje = "";
    let titulo = "";
    if (info_pedido !== "" && info_pres !== "") {
      mensaje = i18n.t("resumen.resmokf1") + info_pedido + i18n.t("resumen.resmokf2") + info_pres + i18n.t("resumen.resmokf3") + "\n" + i18n.t("resumen.resmokf4");
      titulo = i18n.t("resumen.restokf").toLocaleUpperCase();
    } else if (info_pedido !== "" && info_pres === "") {
      mensaje = i18n.t("resumen.resmokf1") + info_pedido + i18n.t("resumen.resmokf3") + "\n" + i18n.t("resumen.resmokf4");
      titulo = i18n.t("resumen.restokf1").toLocaleUpperCase();
    } else if (info_pedido === "" && info_pres !== "") {
      mensaje = i18n.t("resumen.resmokf0") + info_pres + i18n.t("resumen.resmokf3") + "\n" + i18n.t("resumen.resmokf4");
      titulo = i18n.t("resumen.restokf2").toLocaleUpperCase();
    }

    confirmAlert({
      title: titulo,
      message: mensaje,
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          label: i18n.t("general.aceptar"),
          onClick: () => {
            let analytics = new AnalyticsEdisa();
            analytics.registraEvento({
              modulo: "Pedido",
              tipo: "Purchase",
              valor: this.state.importe_pedido,
              params: {
                articulos: this.state.articulos_carrito,
                divisa: this.state.moneda,
                precio: this.state.importe_pedido,
              },
            });
            this.setState({ volver: "S" });
          },
        },
      ],
    });
  };

  actualizarPedido = (e) => {
    e.preventDefault();

    if (this.comprobarPedido()) {
      this.props.actualizaPedido(
        this.props.domicilio_envio,
        this.props.forma_envio,
        this.props.almacen_recogida,
        this.props.ruta,
        this.props.hash,
        this.props.tipo_pedido,
        this.props.id_pedido_seleccionado,
        "",
        this.props.pedido.observaciones,
        this.props.pedido.persona_pedido,
        this.props.pedido.pedido_cliente,
        {
          nombre_dom_envio: this.props.pedido.nombre_dom_envio,
          direccion_dom_envio: this.props.pedido.direccion_dom_envio,
          localidad_dom_envio: this.props.pedido.localidad_dom_envio,
          estado_dom_envio: this.props.pedido.estado_dom_envio,
          provincia_dom_envio: this.props.pedido.provincia_dom_envio,
          cod_postal_dom_envio: this.props.pedido.cod_postal_dom_envio,
          tipo_portes_dom_envio: this.props.pedido.tipo_portes_dom_envio,
        },
        this.props.pedido.email,
        this.props.transportista,
        this.props.forma_pago
      );
      this.setState({ guardado: true });
    }
  };

  modificarPedido = (e) => {
    e.preventDefault();
    this.setState({ guardado: false, ver_resumen: true, modificado: true });
  };

  modificarObs = (linea, origen) => {
    this.props.setLinea(linea, origen);
  };

  calcularIndiceInfoLinea = (linea) => {
    if (this.props.informacion_pedido && this.props.informacion_pedido.articulos && this.props.informacion_pedido.articulos.length > 0) {
      let index = -1;

      index = this.props.informacion_pedido.articulos.findIndex((art) => art.codigo_articulo === linea.articulo);

      if (index !== -1) {
        return index;
      }
    }
    return null;
  };

  devuelveLin(tipo_linea) {
    return (
      <Fragment>
        <div className="row titulo-lineas-pedido">
          <div className="col">
            <h1 className="tt-title-subpages noborder">{tipo_linea === "presupuesto" ? i18n.t("general.presup") : i18n.t("general.pedido")}</h1>
          </div>
        </div>
        <div className={"lineas-" + tipo_linea + " row tt-shopcart-table"}>
          <div className="col">
            {this.props.lineas_pedido_cargando ? (
              <Spinner showSpinner={this.props.lineas_pedido_cargando} />
            ) : this.props.pedido.lineas === undefined || this.props.pedido.lineas.length === 0 || parametros === undefined || parametros === null ? (
              ""
            ) : (
              this.props.pedido.lineas
                .sort(dynamicSort("articulo"))
                .filter((linea) => {
                  if (tipo_linea === "presupuesto") {
                    return linea.tipo_linea === "O";
                  } else if (linea.tipo_linea === "P") {
                    return true;
                  } else if (linea.tipo_linea === "R") {
                    return this.props.pedido.lineas.some((linea_padre) => (linea_padre.numero_linea = linea.numero_linea_origen && linea_padre.tipo_linea === "P"));
                  } else return false;
                })
                .map((linea) => (
                  <div className="row linea-carrito" key={linea.id}>
                    <div className="col-sm-12 col-md-2 col-lg-2 imagen">
                      {linea.tipo_articulo !== "F" ? (
                        <Link to={"/articulo/" + linea.articulo + "/" + (linea.descripcion ? linea.descripcion.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase() : "")}>
                          <div className="tt-product-img">
                            <Imagen codigo_articulo={linea.articulo} principal="S" numero_imagen={1} />
                          </div>
                        </Link>
                      ) : (
                        <div className="tt-product-img">
                          <Imagen codigo_articulo={linea.articulo} principal="S" numero_imagen={1} />
                        </div>
                      )}
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3 descripcion">
                      <div className="row desc-cod">
                        {linea.tipo_articulo !== "F" ? (
                          <Link to={"/articulo/" + linea.articulo + "/" + (linea.descripcion ? linea.descripcion.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase() : "")}>
                            {linea.articulo}
                          </Link>
                        ) : (
                          linea.articulo
                        )}
                      </div>
                      <div className="row desc-des">
                        {linea.tipo_articulo !== "F" ? (
                          <Link to={"/articulo/" + linea.articulo + "/" + (linea.descripcion ? linea.descripcion.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase() : "")}>
                            <Descripcion codigo_articulo={linea.articulo} />
                          </Link>
                        ) : (
                          <Descripcion codigo_articulo={linea.articulo} />
                        )}
                      </div>
                      <div className="row des-nec">
                        {linea.datos_articulo ? (
                          <ArticuloNecesidades
                            articulo={linea.datos_articulo[0]}
                            origen="resumen"
                            carnets={this.props.carnets}
                            user={this.props.user}
                            setCarnetProfesional={this.props.setCarnetProfesional}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2 col-lg-2 info-lin">
                      {linea.sub_pres && linea.sub_pres !== "" ? (
                        linea.sub_pres_cant + " X " + linea.sub_pres
                      ) : (
                        <div className="detach-quantity-desctope info-cantidad">
                          <div className="tt-input-counter style-01 div-cantidad-resumen">
                            <input
                              className="quantity-input input-cantidad-resumen"
                              type="text"
                              defaultValue={Number.parseFloat(linea.cantidad_pedida).toFixed(0)}
                              onBlur={(e) => {
                                e.preventDefault();
                                this.changeQuantity(e, linea);
                              }}
                              size="5"
                              data-articulo={linea.articulo}
                              data-presentacion_pedido={linea.presentacion_pedido}
                              data-descripcion={linea.descripcion}
                              readOnly={linea.tipo_articulo !== "R" && linea.tipo_articulo !== "T" && linea.tipo_articulo !== "F" && this.state.guardado !== true ? false : true}
                            />
                          </div>
                        </div>
                      )}
                      {linea.tipo_articulo !== "R" && linea.tipo_articulo !== "T" && linea.tipo_articulo !== "F" && this.state.guardado !== true ? (
                        <div className="botonera-mod">
                          <button className="btn modificar-obs" onClick={() => this.modificarObs(linea, "lin-ped-obs")}>
                            <Note size={20} alt={i18n.t("general.altaddob")} />
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-sm-12 col-md-2 col-lg-2 precio">
                      <span>
                        {linea.sub_pres && linea.sub_pres !== ""
                          ? Number.parseFloat(linea.sub_pres_cant).toFixed(0) + " X " + linea.sub_pres + " X "
                          : Number.parseFloat(linea.cantidad_pedida).toFixed(0) + " (" + linea.presentacion_pedido + ") X "}

                        {linea.tipo_linea !== "R" ? (
                          linea.precio_manual === "S" ? (
                            <Precio codigo_articulo={linea.articulo} precio_manual={linea.precio_venta} mostrar_iva={parametros.mostrar_iva} />
                          ) : (
                            <span>{<Precio codigo_articulo={linea.articulo} mostrar_iva={parametros.mostrar_iva} tipo_precio={parametros.tipo_precio} añadir_descuento={parametros.andr_desc} />}</span>
                          )
                        ) : (
                          <Precio codigo_articulo={linea.articulo} precio_manual={0} mostrar_iva={parametros.mostrar_iva} />
                        )}
                      </span>
                    </div>
                    <div className="col-sm-12 col-md-2 col-lg-2 total">
                      {tipo_linea === "presupuesto" ? (
                        ""
                      ) : (
                        <span className="tt-price subtotal">
                          {linea.tipo_linea !== "R" ? (
                            <Precio codigo_articulo={linea.articulo} precio_manual={Number.parseFloat(linea.precio_venta)} mostrar_iva={parametros.mostrar_iva} />
                          ) : (
                            <Precio codigo_articulo={linea.articulo} precio_manual={0} mostrar_iva={parametros.mostrar_iva} />
                          )}
                        </span>
                      )}
                    </div>
                    <div className="col-sm-12 col-md-1 col-lg-1 eliminar-lin">
                      {linea.tipo_articulo !== "R" && linea.tipo_articulo !== "T" && linea.tipo_articulo !== "F" && this.state.guardado !== true ? (
                        <button className="btn-borrar-linea" data-linea={linea.id} onClick={this.eliminarLinea}>
                          <Trash size={20} />
                          <span className="ocultar">{i18n.t("general.cerrar")}</span>
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    parametros = cargarParametros(parametros_usados, this.props.portal.parametros_ecom, "RES");
    let es_pedido = true;
    let es_presupuesto = false;
    if (this.props.pedido && Object.keys(this.props.pedido).length > 0 && this.props.pedido.lineas && this.props.pedido.lineas.some((linea) => linea.tipo_linea === "P")) {
      es_pedido = true;
    } else {
      es_pedido = false;
    }

    if (this.props.pedido && Object.keys(this.props.pedido).length > 0 && this.props.pedido.lineas && this.props.pedido.lineas.some((linea) => linea.tipo_linea === "O")) {
      es_presupuesto = true;
    } else {
      es_presupuesto = false;
    }

    if (parametros.resumen_simple === "S" && this.state.modificado === false) {
      if (this.state.guardado !== true || this.state.ver_resumen !== true) {
        // Si el parametro es S saltamos el 1er paso
        this.setState({ guardado: true, ver_resumen: true });
      }
    }
    const { volver } = this.state;

    if (
      (Object.keys(this.props.pedido).length === 0 && this.props.num_pedido === "" && this.props.serie_pedido === "" && this.props.numero_pres === "" && this.props.numero_serie_pres === "") ||
      volver === "S"
    ) {
      return <Navigate to="/" />;
    } else if (
      Object.keys(this.props.pedido).length === 0 &&
      ((this.props.num_pedido !== "" && this.props.serie_pedido !== "") || (this.props.numero_pres !== "" && this.props.numero_serie_pres !== ""))
    ) {
      this.finalizar();
    } else if (Object.keys(this.props.pedido).length !== 0 && this.props.num_pedido !== "" && this.props.serie_pedido !== "") {
      if (this.props.forma_pago.pasarela && this.props.forma_pago.pasarela !== "") {
        switch (this.props.forma_pago.pasarela) {
          case "WOMPI":
            return (
              <Navigate
                to={{
                  pathname: "/wompi",
                  state: {
                    articulos_carrito: this.state.articulos_carrito,
                    moneda: this.state.moneda,
                    importe_pedido: this.state.importe_pedido,
                  },
                }}
              />
            );
          case "CARDNET":
            return (
              <Navigate
                to={{
                  pathname: "/cardnet",
                  state: {
                    articulos_carrito: this.state.articulos_carrito,
                    moneda: this.state.moneda,
                    importe_pedido: this.state.importe_pedido,
                  },
                }}
              />
            );

          default:
            break;
        }
      }
    }

    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent">
            <div className="container-indent">
              {this.props.pedido_cargando || parametros === undefined || parametros === null ? (
                <Spinner showSpinner={this.props.pedido_cargando} />
              ) : (
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      {this.props.pedido && Object.keys(this.props.pedido).length > 0 && this.props.pedido.lineas ? (
                        this.props.pedido.lineas.some((linea) => linea.tipo_linea === "P") ? (
                          <Fragment>
                            {this.props.pedido && !this.state.guardado && parametros.busc_rapido && parametros.busc_rapido !== "N" ? (
                              <div className="row buscador-carrito">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                  <BuscadorRapido añadir_a={parametros.andr_a} />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.devuelveLin("pedido")}
                            {this.props.pedido_cargando || this.props.lineas_pedido_cargando ? (
                              ""
                            ) : (
                              <div className="row totales">
                                <div className="col">
                                  <div className="row">
                                    <div className="col-7"></div>
                                    <div className="col-2 titulo">{i18n.t("general.total").toUpperCase()}</div>
                                    <div className="col-2 total">
                                      {this.props.pedido.lineas === undefined || this.props.pedido.lineas.length === 0
                                        ? 0
                                        : new Intl.NumberFormat(this.props.locale, {
                                            style: "currency",
                                            currency: this.props.moneda,
                                          }).format(
                                            Number.parseFloat(
                                              this.props.pedido.lineas
                                                .filter((linea) => linea.tipo_linea === "P")
                                                .map((linea) => {
                                                  return Number.parseFloat(linea.precio_venta);
                                                })
                                                .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                                            ).toFixed(this.props.precio.decimales_pvp)
                                          )}
                                    </div>
                                    <div className="col-1"></div>
                                  </div>
                                  <div className="row">
                                    <div className="col-7"></div>
                                    <div className="col-2 titulo">{i18n.t("general.total").toUpperCase() + " + " + i18n.t("general.impuesto")}</div>
                                    <div className="col-2 total">
                                      {this.props.pedido.lineas === undefined || this.props.pedido.lineas.length === 0
                                        ? 0
                                        : new Intl.NumberFormat(this.props.locale, {
                                            style: "currency",
                                            currency: this.props.moneda,
                                          }).format(
                                            Number.parseFloat(
                                              this.props.pedido.lineas
                                                .filter((linea) => linea.tipo_linea === "P")
                                                .map((linea) => {
                                                  let impuesto_articulo = 0;

                                                  if (
                                                    this.props.precio[linea.articulo] &&
                                                    this.props.precio[linea.articulo].data.impuesto_articulo &&
                                                    !Array.isArray(this.props.precio[linea.articulo].data.impuesto_articulo)
                                                  ) {
                                                    impuesto_articulo = Number.parseInt(this.props.precio[linea.articulo].data.impuesto_articulo) / 100;
                                                  }

                                                  return linea.precio_venta * (1 + impuesto_articulo);
                                                })
                                                .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                                            ).toFixed(this.props.precio.decimales_pvp)
                                          )}
                                    </div>
                                    <div className="col-1"></div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <TarifasEnvioPortes mostrar_mensaje={parametros.msg_tar_env} tipo_tarifa={parametros.tipo_msg_tar_env} />
                          </Fragment>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}

                      {this.props.pedido && Object.keys(this.props.pedido).length > 0 && this.props.pedido.lineas ? (
                        this.props.pedido.lineas.some((linea) => linea.tipo_linea === "O") ? (
                          <Fragment>{this.devuelveLin("presupuesto")}</Fragment>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row tt-shopcart-btn">
                    <div className="col-sm-12 col-md-4 col-lg-4 continuar">
                      <Link to="/articulos" className="btn-link">
                        <ArrowFatLineLeft size={20} />
                        <text>{i18n.t("general.contcomp").toUpperCase()}</text>
                      </Link>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 borrar">
                      <button className="btn-link" onClick={this.eliminarPedido}>
                        <Trash size={20} />
                        <text>{i18n.t("carrito.crrbort").toUpperCase()}</text>
                      </button>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 añadir">
                      {this.props.pedido.lineas && this.props.pedido.lineas.filter((linea) => linea.tipo_linea === "P").length > 0 ? (
                        <BotonAnadirLista origen="resumen" lineas={this.props.pedido.lineas.filter((linea) => linea.tipo_linea === "P")} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                      <div className="tt-shopcart-wrapper">
                        <div className="row mostrar_resumen_btn" style={this.state.guardado ? { display: "block" } : { display: "none" }}>
                          {this.state.ver_resumen ? (
                            <button className="btn-link mostrar-res" onClick={this.mostrar_resumen}>
                              <text>{i18n.t("resumen.resocurs")}</text>
                              <CaretUp size={18} weight="bold" />
                            </button>
                          ) : (
                            <button className="btn-link mostrar-res" onClick={this.mostrar_resumen}>
                              <text>{i18n.t("resumen.resmosrs")}</text>
                              <CaretDown size={18} weight="bold" />
                            </button>
                          )}
                        </div>
                        <div className="row" style={this.state.guardado && !this.state.ver_resumen ? { display: "none" } : { display: "block" }}>
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <TiposPedido pedidoGuardado={this.state.guardado} />
                          </div>
                        </div>
                        <div className="row" style={this.state.guardado && !this.state.ver_resumen ? { display: "none" } : { display: "block" }}>
                          <div className="col-sm-12 col-md-6 col-lg-6">
                            <FormasEnvio pedidoGuardado={this.state.guardado} />
                          </div>

                          {this.props.forma_envio.tipo && this.props.forma_envio.tipo === "D" ? (
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <Transportistas pedidoGuardado={this.state.guardado} />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="row" style={this.state.guardado && !this.state.ver_resumen ? { display: "none" } : { display: "block" }}>
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            {this.props.forma_envio.tipo && this.props.forma_envio.tipo === "D" ? <DomiciliosEnvio editable={true} pedidoGuardado={this.state.guardado} /> : ""}

                            {this.props.forma_envio.tipo && this.props.forma_envio.tipo === "T" ? <Almacenes pedidoGuardado={this.state.guardado} /> : ""}
                            {this.props.forma_envio.tipo && this.props.forma_envio.tipo === "R" ? <Rutas pedidoGuardado={this.state.guardado} /> : ""}
                          </div>
                        </div>

                        {/*
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <FechaEntrega pedidoGuardado={this.state.guardado} hora_limite_entrega_dia_siguiente="20"/>
                          </div>
                        </div>
                        */}

                        <div className="row" style={this.state.guardado && !this.state.ver_resumen ? { display: "none" } : { display: "block" }}>
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <FormasPago pedidoGuardado={this.state.guardado} />
                          </div>
                        </div>

                        {this.props.pedido && Object.keys(this.props.pedido).length > 0 ? (
                          <div className="row" style={this.state.guardado && !this.state.ver_resumen ? { display: "none" } : { display: "block" }}>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                              <div className="tt-shopcart-box">
                                <h4 className="tt-title">{i18n.t("resumen.resdat")}</h4>
                                <p>{i18n.t("resumen.resdam")}</p>
                                <div className="row" style={this.state.guardado && !this.state.ver_resumen ? { display: "none" } : { display: "block" }}>
                                  <div className="col-sm-12 col-md-4 col-lg-4">
                                    <PersonaPedido pedidoGuardado={this.state.guardado} />
                                  </div>
                                  <div className="col-sm-12 col-md-4 col-lg-4">
                                    <NumeroPedidoCliente pedidoGuardado={this.state.guardado} />
                                  </div>
                                  <div className="col-sm-12 col-md-4 col-lg-4">
                                    <EmailPedido pedidoGuardado={this.state.guardado} />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-12 col-md-12 col-lg-12">
                                    <ObservacionesPedido pedidoGuardado={this.state.guardado} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="tt-shopcart-box tt-boredr-large">
                          {this.props.pedido_cargando ? (
                            <Spinner showSpinner={this.props.pedido_cargando} />
                          ) : (
                            <>
                              {this.state.guardado ? (
                                <Fragment>
                                  <button className="btn btn-lg boton-pedido" onClick={this.modificarPedido}>
                                    <span className="icon icon-check_circle"></span>
                                    {i18n.t("general.modificr").toUpperCase()}{" "}
                                    {es_pedido && es_presupuesto
                                      ? i18n.t("general.pedyprp").toUpperCase()
                                      : es_pedido && !es_presupuesto
                                      ? i18n.t("general.pedido").toUpperCase()
                                      : !es_pedido && es_presupuesto
                                      ? i18n.t("general.presup").toUpperCase()
                                      : i18n.t("general.carrito").toUpperCase()}
                                  </button>

                                  <button className="btn btn-lg boton-pedido" onClick={this.pedirConfirmacion}>
                                    <span className="icon icon-check_circle"></span>
                                    {i18n.t("general.finalizr").toUpperCase()}{" "}
                                    {es_pedido && es_presupuesto
                                      ? i18n.t("general.pedyprp").toUpperCase()
                                      : es_pedido && !es_presupuesto
                                      ? i18n.t("general.pedido").toUpperCase()
                                      : !es_pedido && es_presupuesto
                                      ? i18n.t("general.presup").toUpperCase()
                                      : i18n.t("general.carrito").toUpperCase()}
                                  </button>
                                </Fragment>
                              ) : (
                                <button className="btn btn-lg boton-pedido" onClick={this.actualizarPedido}>
                                  <span className="icon icon-check_circle"></span>
                                  {i18n.t("general.guarcamb").toUpperCase()}
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  pedido: state.pedidos.pedido,
  domicilio_envio: state.domiciliosEnvio.domicilio,
  almacen_recogida: state.almacenes.almacen,
  forma_envio: state.formasEnvio.forma_envio,
  formas_pago: state.formasPago.formas_pago,
  forma_pago: state.formasPago.forma_pago,
  num_pedido: state.pedidos.num_pedido,
  serie_pedido: state.pedidos.serie_pedido,
  ejercicio: state.pedidos.ejercicio,
  numero_pres: state.pedidos.numero_pres,
  numero_serie_pres: state.pedidos.numero_serie_pres,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
  ruta: state.rutas.ruta,
  precio: state.precio.precio,
  hash: state.auth.hash,
  locale: state.precio.locale,
  moneda: state.precio.moneda,
  pedido_cargando: state.pedidos.isLoading,
  lineas_pedido_cargando: state.pedidos.isLoadingLines,
  tipo_pedido: state.tiposPedido.tipo_pedido,
  portal: state.portal.portal,
  cookies: state.portal.cookies,
  user: state.auth.user,
  texto_venta: state.pedidos.texto_venta,
  transportista: state.transportistas.transportista,
  subscripciones: state.auth.user.subscripciones,
  carnets: state.auth.user.carnets,
});

export default connect(mapStateToProps, {
  integraPedido,
  createMessage,
  eliminarPedido,
  deleteLinea,
  postLinea,
  setLinea,
  getPedidoActivo,
  calculaPortesPedido,
  actualizaPedido,
  getTextosVentas,
  setCarnetProfesional,
  dynamicSort,
})(Resumen);
