import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { closeModal } from "../../actions/modalGenerica";
import ReactModal from "react-modal";
import AnalyticsEdisa from "../common/AnalyticsEdisa";
import { confirmAlert } from "react-confirm-alert";
import "../../css/modal.css";
import { postLinea } from "../../actions/pedidos";
import { postLineaLista, postLineasLista, crearLista, getListasCompra } from "../../actions/listasCompra";
import i18n from "../../lang/idiomas";

ReactModal.setAppElement("#root");

export class ModalGenerica extends Component {
  state = {
    lista_seleccionada: "",
    nueva_lista: false,
    nombre_lista: "",
    valor: "",
    filesAux: [],
    fileInput: React.createRef(),
  };

  static propTypes = {
    getListasCompra: PropTypes.func.isRequired,
    postLineaLista: PropTypes.func.isRequired,
    postLineasLista: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    codigo_articulo: PropTypes.string.isRequired,
    origen: PropTypes.string.isRequired,
    lineas_carrito: PropTypes.array.isRequired,
    listas_compra: PropTypes.array.isRequired,
    menus: PropTypes.array.isRequired,
    crearLista: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (!this.props.origen && this.props.origen !== "") {
      this.props.getListasCompra();
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.props.origen && this.props.origen !== "") {
      switch (this.props.origen) {
        case "lin-ped-obs":
          if (this.props.pedido.lineas && this.props.pedido.lineas.length > 0) {
            let index = -1;
            index = this.props.pedido.lineas.findIndex((linea) => linea.id === this.props.lineas_carrito.id);
            if (index !== -1) {
              this.props.pedido.lineas[index].observaciones = document.getElementById("modificar-observaciones").value;
              this.props.postLinea(this.props.pedido.lineas[index], "", "", "S");
            }
          }
          break;
        case "carnet-articulo":
          //Futuro codigo de insercion de carnet profesional
          break;
        case "receta":
          if (this.state.checkNoRc || this.state.checkRE || this.state.checkRP || (this.state.filesAux && this.state.filesAux?.length > 0)) {
            if (this.state.checkNoRc === true || this.state.checkRE === true || this.state.checkRP === true || (this.state.filesAux && this.state.filesAux?.length > 0)) {
              if (this.state.checkNoRc === true) {
                //No receta
                this.props.lineas_carrito.valida_receta = "M1";
              } else if (this.state.checkRE === true) {
                //Receta Electronica
                this.props.lineas_carrito.valida_receta = "M2";
              } else if (this.state.checkRP === true) {
                //Receta Papel
                this.props.lineas_carrito.valida_receta = "M3";
              } else {
                //Añade receta
                this.props.lineas_carrito.valida_receta = "M";
              }

              //Reiniciamos estado
              this.setState({
                lista_seleccionada: "",
                nueva_lista: false,
                nombre_lista: "",
                valor: "",
                filesAux: [],
                fileInput: React.createRef(),
                checkNoRc: false,
                checkRE: false,
                checkRP: false,
              });

              this.props.postLinea(this.props.lineas_carrito, this.props.hash, this.props.id_pedido_seleccionado);
            }
          } else {
            confirmAlert({
              title: i18n.t("modal.faltdat"),
              message: i18n.t("modal.debarec"),
              closeOnEscape: true,
              closeOnClickOutside: true,
              buttons: [
                {
                  label: i18n.t("general.aceptar"),
                  onClick: () => {
                    "";
                  },
                },
              ],
            });
          }
          break;
        default:
          break;
      }
    } else {
      if (this.state.nueva_lista) {
        // Crear nueva lista
        if (this.state.nombre_lista !== "") {
          this.props.crearLista(this.state.nombre_lista);
          this.setState({ nombre_lista: "" });
          this.setState({ nueva_lista: false });
          return;
        }
      }

      if (this.props.codigo_articulo && this.props.codigo_articulo !== "") {
        const linea = {
          codigo_lista: this.state.lista_seleccionada,
          codigo_articulo: this.props.codigo_articulo,
        };

        this.props.postLineaLista(linea, "", this.props.id_pedido_seleccionado);

        let analytics = new AnalyticsEdisa();

        analytics.registraEvento({
          modulo: "Articulos",
          tipo: "AddToWishlist",
          valor: this.props.codigo_articulo,
          params: {
            codigo_articulo: this.props.codigo_articulo,
          },
        });
      } else if (this.props.lineas_carrito && this.props.lineas_carrito.length > 0) {
        let lineas = [];

        this.props.lineas_carrito.forEach((linea_carrito) => {
          let lin = {
            codigo_lista: this.state.lista_seleccionada,
            codigo_articulo: linea_carrito.articulo,
            cantidad: linea_carrito.cantidad_pedida,
            cantidad_pedida: Number.parseFloat(linea_carrito.cantidad_pedida),
            presentacion_pedido: linea_carrito.presentacion_pedido,
            observaciones: linea_carrito.observaciones,
            sub_pres: linea_carrito.sub_pres,
            sub_pres_cant: linea_carrito.sub_pres_cant !== "" ? Number.parseInt(linea_carrito.sub_pres_cant) : linea_carrito.sub_pres_cant,
            sub_pres_fc: linea_carrito.sub_pres_fc !== "" ? Number.parseFloat(linea_carrito.sub_pres_fc) : "",
            pres_fc: linea_carrito.pres_fc !== "" ? Number.parseFloat(linea_carrito.pres_fc) : "",
            presentacion_escogida: linea_carrito.presentacion_escogida !== "" ? linea_carrito.presentacion_escogida : "",
          };
          lineas.push(lin);
        });

        this.props.postLineasLista(lineas, "", this.props.id_pedido_seleccionado);
      }
    }
    this.props.closeModal();
  };

  onChange = (e) => {
    if (e.target.type !== "checkbox") {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      //Seteamos todas las checks a false
      this.setState({ checkNoRc: false, checkRE: false, checkRP: false });
      //Modificamos el valor a tratar
      this.setState({ [e.target.name]: e.target.checked });
    }
  };

  onSelectFile = () => {
    if (this.state.fileInput.current.files.length && this.state.fileInput.current.files.length > 0) {
      this.setState({ filesAux: this.state.fileInput.current.files });
    }
  };

  cerrarModal = (e) => {
    e.preventDefault();

    this.setState({
      lista_seleccionada: "",
      nueva_lista: false,
      nombre_lista: "",
      valor: "",
      filesAux: [],
      fileInput: React.createRef(),
      checkNoRc: false,
    });

    this.props.closeModal();
  };

  modalNuevaLista = (e) => {
    e.preventDefault();
    this.setState({ nueva_lista: true });
  };

  render() {
    let customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };
    if (this.props.origen && this.props.origen !== "") {
      switch (this.props.origen) {
        case "lin-ped-obs":
          customStyles.content.width = "50%";
          return (
            <Fragment>
              <ReactModal isOpen={this.props.isOpen} onRequestClose={this.cerrarModal} style={customStyles}>
                <div>
                  <h5>{i18n.t("modal.obsrcns")}</h5>
                </div>
                <div>
                  <form id="modificarObservaciones" onSubmit={this.onSubmit}>
                    <textarea className="form-control" rows={5} name="valor" id={"modificar-observaciones"} defaultValue={this.props.lineas_carrito.observaciones} onChange={this.onChange} />
                    <div className="botonera-modal">
                      <button type="submit" form="modificarObservaciones" className="btn btn-primary">
                        {i18n.t("modal.ogrdr")}
                      </button>
                      <button type="button" className="btn btn-secondary" onClick={this.cerrarModal}>
                        {i18n.t("modal.ocrr")}
                      </button>
                    </div>
                  </form>
                </div>
              </ReactModal>
            </Fragment>
          );
        case "carnet-articulo":
          customStyles.content.width = "50%";
          return (
            <Fragment>
              <ReactModal isOpen={this.props.isOpen} onRequestClose={this.cerrarModal} style={customStyles}>
                <div>
                  <h5>{i18n.t("modal.mcarpro")}</h5>
                </div>
                <div className="modal-carnet-profesional">
                  <div className="datos-carnet">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-5">
                              <h6>{i18n.t("modal.mnumcar")}</h6>
                            </div>
                            <div className="col-7">
                              <input className="txt_numero_carnet" value={this.props.lineas_carrito.numero_carnet} disabled={true} />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-5">
                              <h6>{i18n.t("modal.mcaduc")}</h6>
                            </div>
                            <div className="col-7">
                              <input className="txt_fecha_caducidad" value={this.props.lineas_carrito.fecha_caducidad} disabled={true} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-5">
                              <h6>{i18n.t("modal.mnivel")}</h6>
                            </div>
                            <div className="col-7">
                              <input className="txt_nivel" value={this.props.lineas_carrito.nivel} disabled={true} />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-5">
                              <h6>{i18n.t("modal.mdescr")}</h6>
                            </div>
                            <div className="col-7">
                              <input className="txt_descripcion" value={this.props.lineas_carrito.descripcion} disabled={true} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="datos-persona-fisica">
                    <h6>{i18n.t("modal.dtitul")}</h6>
                    <div className="datos-personales">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-2">
                            <h6>{i18n.t("modal.monombr")}</h6>
                          </div>
                          <div className="col-4">
                            <input className="txt_nombre" value={this.props.lineas_carrito.nombre + " " + this.props.lineas_carrito.apellidos} disabled={true} />
                          </div>
                          <div className="col-2">
                            <h6>{i18n.t("modal.modni")}</h6>
                          </div>
                          <div className="col-4">
                            <input className="txt_dni" value={this.props.lineas_carrito.dni} disabled={true} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ReactModal>
            </Fragment>
          );
        case "receta":
          return (
            <Fragment>
              <ReactModal isOpen={this.props.isOpen} onRequestClose={this.cerrarModal} style={customStyles}>
                <div>
                  <h5>{i18n.t("modal.mselopc")}</h5>
                </div>
                <form id="añadirReceta" className="modal-receta" onSubmit={this.onSubmit}>
                  <div className="col12">
                    <div className="row labels">
                      <div className="col-4">
                        <label htmlFor={"checkNoRc"}>{"No tengo receta: "}</label>
                      </div>
                      <div className="col-4">
                        <label htmlFor={"checkRE"}>{"Dispongo de receta electronica: "}</label>
                      </div>
                      <div className="col-4">
                        <label htmlFor={"checkRP"}>{"Dispongo de receta en papel: "}</label>
                      </div>
                    </div>
                    <div className="row checks">
                      <div className="col-4">
                        <input
                          id={"checkNoRc"}
                          type="checkbox"
                          name="checkNoRc"
                          checked={this.state.checkNoRc ? this.state.checkNoRc : false}
                          disabled={
                            this.state.fileInput &&
                            this.state.fileInput.current &&
                            this.state.fileInput.current.files &&
                            this.state.fileInput.current.files.length &&
                            this.state.fileInput.current.files.length > 0
                              ? true
                              : false
                          }
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="col-4">
                        <input
                          id={"checkRE"}
                          type="checkbox"
                          name="checkRE"
                          checked={this.state.checkRE ? this.state.checkRE : false}
                          disabled={
                            this.state.fileInput &&
                            this.state.fileInput.current &&
                            this.state.fileInput.current.files &&
                            this.state.fileInput.current.files.length &&
                            this.state.fileInput.current.files.length > 0
                              ? true
                              : false
                          }
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="col-4">
                        <input
                          id={"checkRP"}
                          type="checkbox"
                          name="checkRP"
                          checked={this.state.checkRP ? this.state.checkRP : false}
                          disabled={
                            this.state.fileInput &&
                            this.state.fileInput.current &&
                            this.state.fileInput.current.files &&
                            this.state.fileInput.current.files.length &&
                            this.state.fileInput.current.files.length > 0
                              ? true
                              : false
                          }
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    {/* <div className="row file-input">
                      <label htmlFor={"selectRecipe"}>
                        {"Seleccione su receta: "}
                      </label>
                      <input
                        id={"selectRecipe"}
                        ref={this.state.fileInput}
                        type="file"
                        multiple={false}
                        disabled={this.state.checkNoRc ? true : false}
                        onChange={(ev) => {
                          this.onSelectFile(ev.nativeEvent.target);
                        }}
                      />
                    </div> */}
                  </div>
                  <div className="botonera-modal">
                    <button type="submit" form="añadirReceta" className="btn btn-primary" disabled={!this.state.checkNoRc && !this.state.checkRE && !this.state.checkRP ? true : false}>
                      {i18n.t("modal.o.ocrr")}
                    </button>
                    <button type="button" className="btn btn-secondary" onClick={this.cerrarModal}>
                      {i18n.t("modal.ogrdr")}
                    </button>
                  </div>
                </form>
              </ReactModal>
            </Fragment>
          );
        default:
          break;
      }
    } else {
      let findPos = (elem) => elem.nombre === "LISTAS";
      let findPos2 = (elem) => elem.programa === "listasCompra";
      let pos;
      let pos2;
      let pos3;
      if (this.props.menus) {
        // BUSCAMOS SI ESTÁ EN EL MENÚ PRINCIPAL
        // Y NO ES PARTE DEL SUBMENÚ
        pos3 = this.props.menus.findIndex(findPos2);
        if (pos3 !== -1) {
          pos2 = pos3;
        } else {
          pos = this.props.menus.findIndex(findPos);
          if (pos !== -1) {
            pos2 = this.props.menus[pos].hijos.findIndex(findPos2);
          } else {
            pos2 = -1;
          }
        }
      } else {
        pos = -1;
        pos2 = -1;
      }

      if (pos2 !== -1 && (this.props.codigo_articulo || this.props.lineas_carrito)) {
        return (
          <Fragment>
            <ReactModal isOpen={this.props.isOpen} onRequestClose={this.cerrarModal} style={customStyles}>
              <div>
                <div>
                  <div>
                    <div>{this.state.nueva_lista ? <h5>{i18n.t("modal.mnuelist")}</h5> : <h5>{i18n.t("modal.manlist")}</h5>}</div>
                    <div>
                      <form id="anadirLista" onSubmit={this.onSubmit}>
                        <div className="form-group">
                          {!this.state.nueva_lista ? (
                            <>
                              <label htmlFor="lista_compra">{i18n.t("modal.molist")}</label>
                              <select className="form-control" id="lista_compra" name="lista_seleccionada" onChange={this.onChange}>
                                <option value="">{i18n.t("modal.mosellist")}</option>
                                {this.props.listas_compra
                                  ? this.props.listas_compra.map((lista, index) => {
                                      return (
                                        <option key={index} value={lista.codigo_lista}>
                                          {lista.nombre_lista}
                                        </option>
                                      );
                                    })
                                  : ""}
                              </select>
                            </>
                          ) : (
                            <div className="form-group">
                              <label for="nombre_lista">{i18n.t("modal.mnuelist")}</label>
                              <input
                                type="text"
                                className="form-control"
                                id="nombre_lista"
                                aria-describedby="nombre_lista_help"
                                placeholder={i18n.t("modal.monomlis")}
                                onChange={this.onChange}
                                name="nombre_lista"
                              />
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                    <div>
                      <button type="submit" form="anadirLista" className="btn btn-primary">
                        {i18n.t("modal.ogrdr")}
                      </button>
                      <button type="button" className="btn btn-secondary" onClick={this.cerrarModal}>
                        {i18n.t("modal.ocrr")}
                      </button>
                      {!this.state.nueva_lista ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "1rem",
                          }}
                        >
                          <button type="button" className="btn btn-secondary" onClick={this.modalNuevaLista}>
                            {i18n.t("modal.mnuelist")}
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </ReactModal>
          </Fragment>
        );
      } else {
        return "";
      }
    }
  }
}

const mapStateToProps = (state) => ({
  isOpen: state.modalGenerica.isOpen,
  codigo_articulo: state.modalGenerica.codigo_articulo,
  lineas_carrito: state.modalGenerica.lineas,
  origen: state.modalGenerica.origen,
  listas_compra: state.listasCompra.listas_compra,
  menus: state.portal.menus,
  portal: state.portal.portal,
  cookies: state.portal.cookies,
  pedido: state.pedidos.pedido,
  tipos_expedientes_campos: state.expedientes.tipos_expedientes_campos,
  hash: state.auth.hash,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
});

export default connect(mapStateToProps, {
  postLinea,
  postLineaLista,
  postLineasLista,
  closeModal,
  crearLista,
  getListasCompra,
})(ModalGenerica);
