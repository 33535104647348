import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { registerLocale } from "react-datepicker";
import { getLOVExpediente, setValorCampoExpediente } from "../../actions/expedientes";
import Select from "react-select";

import es from "date-fns/locale/es";
import i18n from "../../lang/idiomas";

registerLocale("es", es);

let filtros_procesados = [];
let prev_filtros_proc;
let valores;
let filtros_keys;
let lista_filtrada = {};
let indice = -1;

export class Selector extends Component {
  static propTypes = {
    lov: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
    getLOVExpediente: PropTypes.func.isRequired,
    setValorCampoExpediente: PropTypes.func.isRequired,
  };

  componentDidMount() {}

  procesaDatos = (e) => {
    // Funcion que carga datos al entrar en el campo
    e.preventDefault();
    // Comprobamos que se ha seteado el valor previamente
    if (valores && valores.length > 0) {
      // Cargamos los valores actuales en las variables
      this.procesarValores();
      if (prev_filtros_proc && filtros_procesados) {
        let pedir = false;
        if (prev_filtros_proc.length !== filtros_procesados.length) {
          pedir = true;
        } else {
          for (let i = 0; i < prev_filtros_proc.length; i++) {
            if (prev_filtros_proc[i].valor !== filtros_procesados[i].valor) {
              pedir = true;
            }
          }
        }
        if (pedir) {
          // Llamamos a LISA para cargar los datos
          this.cargaDatos();
        }
      }
    } else {
      // Cargamos los valores actuales en las variables
      this.procesarValores();
      // Llamamos a LISA para cargar los datos
      this.cargaDatos();
    }
  };

  procesarValores = () => {
    //Limpiamos el array
    filtros_procesados = [];
    valores = { ...this.props.filtros };

    //Eliminamos  valores innecesarios
    delete valores["filesAux"];
    delete valores["fileInput"];

    filtros_keys = Object.keys(valores);

    // Cargamos el array de filtros
    filtros_keys.forEach((codigo) => {
      filtros_procesados.push({
        codigo: codigo,
        valor: this.props.filtros[codigo] ? this.props.filtros[codigo] : "",
      });
    });
  };

  cargaDatos = () => {
    // Cargamos los valores actuales a la variable de previos
    prev_filtros_proc = filtros_procesados;
    this.props.getLOVExpediente(this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].tipo_expediente, this.props.campo.codigo_lov, this.props.campo.columna, filtros_procesados);
  };

  handleBlur = () => {
    // Cargamos el valor guardado en el estado local al redux
    if (this.state.selected && this.state.selected !== this.props.tipos_expedientes_campos[indice].valor) {
      this.props.setValorCampoExpediente(this.props.campo.columna, this.state.selected);
      this.props.handleChange(this.props.campo.columna, this.state.selected);
    }
  };

  handleChange = (e) => {
    // Cargamos estado local
    this.setState({ selected: e.value });
    // Cargamos estado del redux
    this.props.setValorCampoExpediente(this.props.campo.columna, e.value);
    // Cargamos estado padra ( Completar el array de filtros del resto de campos )
    this.props.handleChange(this.props.campo.columna, e.value);
  };

  render() {
    if (this.props.lov && this.props.lov.length > 0) {
      lista_filtrada = this.props.lov.filter(
        (lista_valores) =>
          lista_valores.tipo_expediente === this.props.tipos_expedientes[this.props.tipo_expediente_seleccionado].tipo_expediente && lista_valores.codigo_lov === this.props.campo.codigo_lov
      )[0];
    }
    if (lista_filtrada && this.props.campo.valor && this.props.campo.valor !== "") {
      indice = lista_filtrada.data.findIndex((linea) => linea.value === this.props.campo.valor);
    }

    return (
      <Fragment>
        <div className="form-group selector" key={this.props.campo.codigo_lov}>
          <label htmlFor={this.props.campo.columna}>{this.props.campo.literal}</label>
          {lista_filtrada && lista_filtrada.data && lista_filtrada.data.length > 0 ? (
            <Select
              id={this.props.campo.columna}
              className={"selector-rapido"}
              onFocus={this.procesaDatos}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              options={lista_filtrada && lista_filtrada.data ? lista_filtrada.data : []}
              isLoading={lista_filtrada && lista_filtrada.loading ? lista_filtrada.loading : ""}
              loadingMessage={() => i18n.t("expedientes.excardat")}
              noOptionsMessage={() => i18n.t("expedientes.exnoseen")}
              placeholder={i18n.t("expedientes.exselna")}
            />
          ) : (
            <Select
              id={this.props.campo.columna}
              className={"selector-rapido"}
              onFocus={this.procesaDatos}
              options={[]}
              isLoading={lista_filtrada && lista_filtrada.loading ? lista_filtrada.loading : ""}
              loadingMessage={() => i18n.t("expedientes.excardat")}
              noOptionsMessage={() => i18n.t("expedientes.exnoseen")}
              placeholder={i18n.t("expedientes.exselna")}
              value={i18n.t("expedientes.exselna")}
            />
          )}
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  tipos_expedientes: state.expedientes.tipos_expedientes,
  tipo_expediente_seleccionado: state.expedientes.tipo_expediente_seleccionado,
  tipos_expedientes_campos: state.expedientes.tipos_expedientes_campos,
  lov: state.expedientes.valores_lovs,
  auth: state.auth,
});

export default connect(mapStateToProps, { getLOVExpediente, setValorCampoExpediente })(Selector);
