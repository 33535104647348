import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getTarifasEnvioPortes } from "../../actions/tarifasEnvioPortes";
import i18n from "../../lang/idiomas";

export class TarifasEnvioPortes extends Component {
  state = {};

  static propTypes = {
    getTarifasEnvioPortes: PropTypes.func.isRequired,
    tarifas: PropTypes.array.isRequired,
    pedido: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    moneda: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.props.getTarifasEnvioPortes(this.props.id_pedido_seleccionado);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.pedido.domicilio_envio !== this.props.pedido.domicilio_envio ||
      prevProps.pedido.estado_dom_envio !== this.props.pedido.estado_dom_envio ||
      prevProps.pedido.provincia_dom_envio !== this.props.pedido.provincia_dom_envio
    ) {
      this.props.getTarifasEnvioPortes(this.props.id_pedido_seleccionado);
    }
  }

  render() {
    return this.props.tarifas && this.props.mostrar_mensaje === "S"
      ? this.props.tarifas.map((tarifa, index) =>
          this.props.tipo_tarifa && this.props.tipo_tarifa === "mixto" ? (
            <div className="row tarifa-envio-portes" key={index}>
              {`${i18n.t("tarifa.tppasiis")} ` +
                new Intl.NumberFormat(this.props.locale, {
                  style: "currency",
                  currency: this.props.moneda,
                }).format(Number.parseFloat(tarifa.importe_min_pagados).toFixed(2)) +
                ` ${i18n.t("tarifa.typesped")} ` +
                Number.parseFloat(tarifa.peso_max_pagados).toFixed(2) +
                " Kg"}
            </div>
          ) : this.props.tipo_tarifa && this.props.tipo_tarifa === "texto_peso" ? (
            <div className="row tarifa-envio-portes" key={index}>
              {`${i18n.t("tarifa.tppsipes")} ` + Number.parseFloat(tarifa.peso_max_pagados).toFixed(2)}
            </div>
          ) : (
            <div className="row tarifa-envio-portes" key={index}>
              {`${i18n.t("tarifa.tppasiis")} ` +
                new Intl.NumberFormat(this.props.locale, {
                  style: "currency",
                  currency: this.props.moneda,
                }).format(Number.parseFloat(tarifa.importe_min_pagados).toFixed(2))}
            </div>
          )
        )
      : "";
  }
}

const mapStateToProps = (state) => ({
  tarifas: state.tarifas.tarifas,
  pedido: state.pedidos.pedido,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
  locale: state.precio.locale,
  moneda: state.precio.moneda,
});

export default connect(mapStateToProps, { getTarifasEnvioPortes })(TarifasEnvioPortes);
