import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getArticulosDoc, getArticulosAux } from "../../../actions/articulo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import i18n from "../../../lang/idiomas";

export class InfoAdicional extends Component {
  static propTypes = {
    codigo_articulo: PropTypes.string.isRequired,
    articulos_doc: PropTypes.array.isRequired,
    articulos_aux: PropTypes.array.isRequired,
    getArticulosDoc: PropTypes.func.isRequired,
    getArticulosAux: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.codigo_articulo) {
      this.props.getArticulosDoc(this.props.codigo_articulo);
      this.props.getArticulosAux(this.props.codigo_articulo);
    }
  }

  componentDidUpdate(prevProps) {
    window.wookie();
  }

  onClickArchivo = (e) => {
    e.preventDefault();
    let codigo_articulo = e.currentTarget.dataset["articulo"];
    let numero_doc = Number.parseInt(e.currentTarget.dataset["doc"]);
    let fichero = this.props.articulos_doc.filter((articulo_doc) => articulo_doc.codigo_articulo === codigo_articulo && articulo_doc.numero_doc === numero_doc)[0];

    const binary = atob(fichero.archivo.replace(/\s/g, ""));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < len; i += 1) {
      view[i] = binary.charCodeAt(i);
    }
    // create the blob object with content-type "application/pdf"
    const blob = new Blob([view], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = fichero.nombre_fichero;

    a.click();
  };

  render() {
    return (
      <Fragment>
        {this.props.opiniones || (this.props.articulos_doc && this.props.articulos_doc.length > 0) || (this.props.articulos_aux && this.props.articulos_aux.length > 0) ? (
          <div className="content-indent" id="informacion-adicional">
            <div className="container container-fluid-mobile">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="nav nav-tabs" id="infoAdicionalTab" role="tablist">
                    {this.props.articulos_aux && this.props.articulos_aux.length > 0 ? (
                      <li className="nav-item">
                        <a className="nav-link active" id="auxiliares-tab" data-toggle="tab" href="#auxiliares" role="tab" aria-controls="auxiliares" aria-selected="true">
                          {i18n.t("eparciales.epavisos")}
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {this.props.articulos_doc && this.props.articulos_doc.length > 0 ? (
                      <li className="nav-item">
                        <a className="nav-link" id="documentos-tab" data-toggle="tab" href="#documentos" role="tab" aria-controls="documentos" aria-selected="false">
                          {i18n.t("eparciales.epdocmin")}
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {this.props.opiniones ? (
                      <li className="nav-item">
                        <a className="nav-link" id="opiniones-tab" data-toggle="tab" href="#opiniones" role="tab" aria-controls="opiniones" aria-selected="false">
                          {i18n.t("eparciales.epopmin")}
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                  <div className="tab-content" id="infoAdicionalContent">
                    {this.props.articulos_aux && this.props.articulos_aux.length > 0 ? (
                      <div className="tab-pane fade show active" id="auxiliares" role="tabpanel" aria-labelledby="auxiliares-tab">
                        <div className="row listaAuxiliares">
                          <div className="col">
                            {this.props.articulos_aux.map((articulo_aux) => {
                              return (
                                <Fragment>
                                  <div className="row auxiliares" key={articulo_aux.codigo_articulo}>
                                    <div className="col-6">
                                      <span>{i18n.t("eparciales.epauxil")} 1</span>
                                    </div>
                                    <div className="col-6">
                                      <span>{articulo_aux.auxiliar_1}</span>
                                    </div>
                                  </div>
                                  <div className="row auxiliares" key={articulo_aux.codigo_articulo}>
                                    <div className="col-6">
                                      <span>{i18n.t("eparciales.epauxil")} 2</span>
                                    </div>
                                    <div className="col-6">
                                      <span>{articulo_aux.auxiliar_2}</span>
                                    </div>
                                  </div>
                                </Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.props.articulos_doc && this.props.articulos_doc.length > 0 ? (
                      <div className="tab-pane fade" id="documentos" role="tabpanel" aria-labelledby="documentos-tab">
                        <ul className="listaDocumentos">
                          {this.props.articulos_doc.map((articulo_doc) => {
                            return (
                              <li key={articulo_doc.numero_doc}>
                                <a data-articulo={articulo_doc.codigo_articulo} data-doc={articulo_doc.numero_doc} onClick={this.onClickArchivo}>
                                  {articulo_doc.descripcion}
                                  <FontAwesomeIcon icon={faDownload} className="ml-1" />
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.props.opiniones ? <div className="tab-pane fade" id="opiniones" role="tabpanel" aria-labelledby="opiniones-tab"></div> : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  articulos_doc: state.articulo.articulos_doc,
  articulos_aux: state.articulo.articulos_aux,
});

export default connect(mapStateToProps, { getArticulosDoc, getArticulosAux })(InfoAdicional);
