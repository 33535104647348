import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setNumeroPedidoCliente } from "../../actions/pedidos";
import i18n from "../../lang/idiomas";

export class NumeroPedidoCliente extends Component {
  static propTypes = {
    pedido: PropTypes.object.isRequired,
    setNumeroPedidoCliente: PropTypes.func.isRequired,
    pedido_cargando: PropTypes.bool.isRequired,
  };

  onchange = (e) => {
    if (e.target.value && !this.props.pedido_cargando) {
      this.props.setNumeroPedidoCliente(e.target.value);
    }
  };

  render() {
    return (
      <form className="form-default">
        <div className="form-group">
          <label htmlFor="pedido_cliente">{i18n.t("pedido.numpedcl")}</label>
          <input
            className="form-control"
            type="text"
            name="pedido_cliente"
            id="pedido_cliente"
            onChange={this.onchange}
            value={this.props.pedido && this.props.pedido.pedido_cliente && this.props.pedido.pedido_cliente !== "" ? this.props.pedido.pedido_cliente : ""}
            placeholder={i18n.t("pedido.numpclmi")}
            disabled={this.props.pedidoGuardado}
          />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  pedido_cargando: state.pedidos.isLoading,
  pedido: state.pedidos.pedido,
});

export default connect(mapStateToProps, { setNumeroPedidoCliente })(NumeroPedidoCliente);
