import axios from "../axiosConfig";

import { GET_IMAGEN, GET_IMAGEN_FAIL, GET_IMAGEN_SUCCESS } from "./types";
import { tokenConfig } from "./auth";

// GET AVISOS
export const getImagen =
  (codigo_articulo, numero_imagen = 1, principal = "S", subidas) =>
  (dispatch, getState) => {
    dispatch({
      type: GET_IMAGEN,
      codigo_articulo: codigo_articulo,
      numero_imagen: numero_imagen,
    });

    axios
      .get(`/imagen/?codigo_articulo=${codigo_articulo}&principal=${principal}&numero_imagen=${numero_imagen}&subidas=${subidas}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_IMAGEN_SUCCESS,
          payload: res.data,
          codigo_articulo: codigo_articulo,
          numero_imagen: numero_imagen,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_IMAGEN_FAIL,
          payload: err.data,
          codigo_articulo: codigo_articulo,
          numero_imagen: numero_imagen,
        });
      });
  };
