import { tokenConfig } from "../../../actions/auth";
import {
  GET_PLANTILLAS,
  FETCH_PLANTILLAS,
  TOGGLE_PLANTILLAS_DETAILS,
  ERROR_FETCHING_PLANTILLA_DETAIL,
  FETCH_PLANTILLA_DETAIL,
  PLANTILLAS_STOP_LOADING,
  GET_PLANTILLAS_DETAILS,
  POST_PEDIDO_PLANTILLA,
  GET_PEDIDO_ACTIVO,
  PLANTILLA_POST_LOADING,
  SET_PLANTILLA_SELECCIONADA,
} from "../../../actions/types";
import axios from "../../../axiosConfig";

export const getListaPlantillas = () => (dispatch, getState) => {
  dispatch({ type: FETCH_PLANTILLAS });
  axios
    .get("plantillas", tokenConfig(getState))
    .then((res) => {
      dispatch({ type: GET_PLANTILLAS, payload: res.data });
    })
    .catch((err) => {
      console.error({ err });
      dispatch({ type: PLANTILLAS_STOP_LOADING });
    });
};

export const getDetallePlantila = (codigo_plantilla) => (dispatch, getState) => {
  // dispatch({ type: FETCH_PLANTILLAS });
  dispatch({ type: FETCH_PLANTILLA_DETAIL });
  axios
    .post("plantillas/details/", { codigo_plantilla })
    .then((res) => {
      dispatch({ type: GET_PLANTILLAS_DETAILS, payload: res.data });
      // dispatch({ type: TOGGLE_PLANTILLAS_DETAILS, payload: true });
    })
    .catch((_) => dispatch({ type: ERROR_FETCHING_PLANTILLA_DETAIL }));
};

export const toglePlantillaDetails = (new_state) => (dispatch) => {
  dispatch({ type: TOGGLE_PLANTILLAS_DETAILS, payload: new_state });
};

export const crearPedido =
  (codigo_plantilla = null, callback) =>
  (dispatch, getState) => {
    axios
      .post("/pedidos/", { codigo_plantilla }, tokenConfig(getState))
      .then((res) => {
        console.log({ res });
        dispatch({ type: POST_PEDIDO_PLANTILLA, payload: res.data });
        // dispatch({ type: POST_PEDIDO });
        dispatch({ type: GET_PEDIDO_ACTIVO, payload: res.data });
        callback(getState());
      })
      .catch((err) => console.error(err));
  };

/**
 *
 * @param {boolean} state
 * @returns
 */
export const togglePostLoading = (state) => (dispatch, getState) => {
  dispatch({ type: PLANTILLA_POST_LOADING, payload: state });
};

export const setPlantillaSeleccionada =
  (value = null) =>
  (dispatch) => {
    dispatch({ type: SET_PLANTILLA_SELECCIONADA, payload: value });
  };
