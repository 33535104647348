import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AnadirCarrito from "./AnadirCarrito";
import Precio from "./Precio";
import Imagen from "./Imagen";
import BotonAnadirLista from "./BotonAnadirLista";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { getPedidoHabitual } from "../../actions/pedidoHabitual";
import { getArticulosRelacionados } from "../../actions/articulosRelacionados";
import { getArticulosEquivalentes } from "../../actions/articulosEquivalentes";
import { getPrecios } from "../../actions/precio";
import { getOfertas } from "../../actions/ofertas";
import { getNovedades } from "../../actions/novedades";
import { setPresentacion } from "../../actions/presentaciones";
import i18n from "../../lang/idiomas";
import Spinner from "../layout/Spinner";
import { cargarArrays, comprobarCondiciones, cargarParametros } from "../../helpers/funciones";
import { getArticulosByFamily, setBusqueda, cambiaArticulosOrden, limpiarBusquedaAvanzada, getArticulos, getMasArticulos, cambiaArticulosPagina, getStocksArticulos } from "../../actions/articulos";

let articulos_aux = [];
let parametros_usados = ["mostrar_iva", "tipo_precio", "maxim_agrup", "stock_cantidad", "btn_subs"];
let parametros;

export class ListadoArticulos extends Component {
  static propTypes = {
    articulos: PropTypes.object.isRequired,
    pedido_habitual: PropTypes.object.isRequired,
    ofertas: PropTypes.object.isRequired,
    novedades: PropTypes.object.isRequired,
    relacionados: PropTypes.object.isRequired,
    equivalentes: PropTypes.object.isRequired,
    articulos_pagina: PropTypes.number,
    articulos_search: PropTypes.string,
    articulos_orden: PropTypes.string,
    filtros_aplicados: PropTypes.array.isRequired,
    menus: PropTypes.array.isRequired,
    getArticulos: PropTypes.func.isRequired,
    getMasArticulos: PropTypes.func.isRequired,
    getPedidoHabitual: PropTypes.func.isRequired,
    getArticulosRelacionados: PropTypes.func.isRequired,
    getArticulosEquivalentes: PropTypes.func.isRequired,
    getNovedades: PropTypes.func.isRequired,
    getOfertas: PropTypes.func.isRequired,
    cambiaArticulosPagina: PropTypes.func.isRequired,
    getPrecios: PropTypes.func.isRequired,
    getStocksArticulos: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.setPresentacion("");
    switch (this.props.origen) {
      case "relacionados":
        this.props.getArticulosRelacionados(this.props.id_articulo, this.props.pedido.domicilio_envio);
        break;
      case "equivalentes":
        break;
      case "pedido_habitual":
        this.props.getPedidoHabitual(this.props.pedido.domicilio_envio);
        break;
      case "ofertas":
        this.props.getOfertas(this.props.pedido.domicilio_envio);
        break;
      case "novedades":
        this.props.getNovedades(this.props.pedido.domicilio_envio);
        break;
      case "articulos":
        if (this.props.filtro_url) {
          ///////////////////////////////////////////////////////////////////////////////////////////////////
          //si hay un filtro de url, no hacemos peticion, ya se ocuparan los filtros de lanzar la busqueda///
          ///////////////////////////////////////////////////////////////////////////////////////////////////
          break;
        } else {
          if (this.props.filtros_aplicados.length > 0) {
            this.props.getArticulosByFamily(
              this.props.filtros_aplicados,
              this.props.articulos_pagina,
              this.props.articulos_orden,
              this.props.articulos_search,
              this.props.pedido.domicilio_envio,
              this.props.busqueda_avanzada.avanzada,
              this.props.busqueda_avanzada.codigo_articulo,
              this.props.busqueda_avanzada.codigo_barras,
              this.props.busqueda_avanzada.descripcion,
              this.props.busqueda_avanzada.palabras_clave,
              this.props.busqueda_avanzada.equivalentes,
              this.props.busqueda_avanzada.referencia_cliente,
              this.props.busqueda_avanzada.codigo_sinonimo
            );
          } else {
            this.props.getArticulos(
              this.props.articulos_search,
              this.props.articulos_pagina,
              this.props.articulos_orden,
              this.props.pedido.domicilio_envio,
              this.props.busqueda_avanzada.avanzada,
              this.props.busqueda_avanzada.codigo_articulo,
              this.props.busqueda_avanzada.codigo_barras,
              this.props.busqueda_avanzada.descripcion,
              this.props.busqueda_avanzada.palabras_clave,
              this.props.busqueda_avanzada.equivalentes,
              this.props.busqueda_avanzada.referencia_cliente,
              this.props.busqueda_avanzada.codigo_sinonimo
            );
          }
        }

        break;
      default:
        if (this.props.filtros_aplicados.length > 0) {
          this.props.getArticulosByFamily(
            this.props.filtros_aplicados,
            this.props.articulos_pagina,
            this.props.articulos_orden,
            this.props.articulos_search,
            this.props.pedido.domicilio_envio,
            this.props.busqueda_avanzada.avanzada,
            this.props.busqueda_avanzada.codigo_articulo,
            this.props.busqueda_avanzada.codigo_barras,
            this.props.busqueda_avanzada.descripcion,
            this.props.busqueda_avanzada.palabras_clave,
            this.props.busqueda_avanzada.equivalentes,
            this.props.busqueda_avanzada.referencia_cliente,
            this.props.busqueda_avanzada.codigo_sinonimo
          );
        } else {
          this.props.getArticulos(
            this.props.articulos_search,
            this.props.articulos_pagina,
            this.props.articulos_orden,
            this.props.pedido.domicilio_envio,
            this.props.busqueda_avanzada.avanzada,
            this.props.busqueda_avanzada.codigo_articulo,
            this.props.busqueda_avanzada.codigo_barras,
            this.props.busqueda_avanzada.descripcion,
            this.props.busqueda_avanzada.palabras_clave,
            this.props.busqueda_avanzada.equivalentes,
            this.props.busqueda_avanzada.referencia_cliente,
            this.props.busqueda_avanzada.codigo_sinonimo
          );
        }
        break;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    window.wookie();
    switch (this.props.origen) {
      case "relacionados":
        if (prevProps.id_articulo !== this.props.id_articulo) {
          this.props.getArticulosRelacionados(this.props.id_articulo, this.props.pedido.domicilio_envio);
        }
        //Comprobamos si solicitan precios grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.relacionados, prevProps.relacionados, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.relacionados, "precios", this.props.precio);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getPrecios si hai al menos un elemento en el array
            this.props.getPrecios(
              articulos_aux, //articulos_cantidades
              this.props.origen, //origen
              null, //domicilio_envio
              parametros.maxim_agrup
            );
          }
        }
        //Comprobamos si solicitan stocks grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.relacionados, prevProps.relacionados, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.relacionados, "stocks", this.props.pedido);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getStocks si hai al menos 1 elemento en el array
            this.props.getStocksArticulos(
              articulos_aux, //articulos_stock
              this.props.origen, //origen
              parametros.stock_cantidad,
              null, //lista_compra
              parametros.maxim_agrup //maximo peticiones agrupadas
            );
          }
        }
        break;
      case "equivalentes":
        //Comprobamos si solicitan precios grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.equivalentes, prevProps.equivalentes, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.equivalentes, "precios", this.props.precio);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getPrecios si hai al menos un elemento en el array
            this.props.getPrecios(
              articulos_aux, //articulos_cantidades
              this.props.origen, //origen
              null, //domicilio_envio
              parametros.maxim_agrup
            );
          }
        }
        //Comprobamos si solicitan stocks grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.equivalentes, prevProps.equivalentes, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.equivalentes, "stocks", this.props.pedido);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getStocks si hai al menos 1 elemento en el array
            this.props.getStocksArticulos(
              articulos_aux, //articulos_stock
              this.props.origen, //origen
              parametros.stock_cantidad,
              null, //domicilio_envio
              parametros.maxim_agrup // Maximo de peticiones agrupadas
            );
          }
        }
        break;
      case "pedido_habitual":
        //Comprobamos si solicitan precios grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.pedido_habitual, prevProps.pedido_habitual, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.pedido_habitual, "precios", this.props.precio);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getPrecios si hai al menos un elemento en el array
            this.props.getPrecios(
              articulos_aux, //articulos_cantidades
              this.props.origen, //origen
              null, //domicilio_envio
              parametros.maxim_agrup // Maximo de peticiones agrupadas
            );
          }
        }
        //Comprobamos si solicitan stocks grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.pedido_habitual, prevProps.pedido_habitual, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.pedido_habitual, "stocks", this.props.pedido);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getStocks si hai al menos 1 elemento en el array
            this.props.getStocksArticulos(
              articulos_aux, //articulos_stock
              this.props.origen, //origen
              parametros.stock_cantidad,
              null, //domicilio_envio
              parametros.maxim_agrup // Maximo de peticiones agrupadas
            );
          }
        }
        break;
      case "ofertas":
        //Comprobamos si solicitan precios grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.ofertas, prevProps.ofertas, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.ofertas, "precios", this.props.precio);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getPrecios si hai al menos un elemento en el array
            this.props.getPrecios(
              articulos_aux, //articulos_cantidades
              this.props.origen, //origen
              null, //domicilio_envio
              parametros.maxim_agrup // Maximo de peticiones agrupadas
            );
          }
        }
        //Comprobamos si solicitan stocks grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.ofertas, prevProps.ofertas, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.ofertas, "stocks", this.props.pedido);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getStocks si hai al menos 1 elemento en el array
            this.props.getStocksArticulos(
              articulos_aux, //articulos_stock
              this.props.origen, //origen
              parametros.stock_cantidad,
              null, //domicilio_envio
              parametros.maxim_agrup // Maximo de peticiones agrupadas
            );
          }
        }
        break;
      case "novedades":
        //Comprobamos si solicitan precios grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.novedades, prevProps.novedades, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.novedades, "precios", this.props.precio);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getPrecios si hai al menos un elemento en el array
            this.props.getPrecios(
              articulos_aux, //articulos_cantidades
              this.props.origen, //origen
              null, //domicilio_envio
              parametros.maxim_agrup // Maximo de peticiones agrupadas
            );
          }
        }
        //Comprobamos si solicitan stocks grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.novedades, prevProps.novedades, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.novedades, "stocks", this.props.pedido);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getStocks si hai al menos 1 elemento en el array
            this.props.getStocksArticulos(
              articulos_aux, //articulos_stock
              this.props.origen, //origen
              parametros.stock_cantidad,
              null, //domicilio_envio
              parametros.maxim_agrup // Maximo de peticiones agrupadas
            );
          }
        }
        break;
      default:
        //Comprobamos si solicitan precios grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.articulos, prevProps.articulos, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.articulos, "precios", this.props.precio);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getPrecios si hai al menos un elemento en el array
            this.props.getPrecios(
              articulos_aux, //articulos_cantidades
              this.props.origen, //origen
              null, //domicilio_envio
              parametros.maxim_agrup // Maximo de peticiones agrupadas
            );
          }
        }
        //Comprobamos si solicitan stocks grupalmente
        if (comprobarCondiciones(this.props.origen, this.props.articulos, prevProps.articulos, "S")) {
          articulos_aux = cargarArrays(this.props.origen, this.props.articulos, "stocks", this.props.pedido);
          if (articulos_aux && articulos_aux.length > 0) {
            //Solo llamamos a getStocks si hai al menos 1 elemento en el array
            this.props.getStocksArticulos(
              articulos_aux, //articulos_stock
              this.props.origen, //origen
              parametros.stock_cantidad,
              null, //domicilio_envio
              parametros.maxim_agrup // Maximo de peticiones agrupadas
            );
          }
        }
        break;
    }
  }

  masArticulos = (e) => {
    e.preventDefault();
    let articulos_siguiente = "";
    switch (this.props.origen) {
      case "relacionados":
        articulos_siguiente = this.props.relacionados.articulos_siguiente;
        break;
      case "equivalentes":
        articulos_siguiente = this.props.equivalentes.articulos_siguiente;
        break;
      case "pedido_habitual":
        articulos_siguiente = this.props.pedido_habitual.articulos_siguiente;
        break;
      case "ofertas":
        articulos_siguiente = this.props.ofertas.articulos_siguiente;
        break;
      case "novedades":
        articulos_siguiente = this.props.novedades.articulos_siguiente;
        break;
      case "articulos":
        articulos_siguiente = this.props.articulos.articulos_siguiente;
        break;
      default:
        articulos_siguiente = this.props.articulos.articulos_siguiente;
        break;
    }

    this.props.getMasArticulos(articulos_siguiente, this.props.filtros_aplicados, this.props.origen);
  };

  limpiarBusqueda = (e) => {
    e.preventDefault();
    this.props.setBusqueda("");
    this.props.limpiarBusquedaAvanzada();

    if (this.props.filtros_aplicados.length > 0) {
      this.props.getArticulosByFamily(this.props.filtros_aplicados, this.props.articulos_pagina, this.props.articulos_orden, "", this.props.pedido.domicilio_envio, "N", "", "", "", "", "", "", "");
    } else {
      this.props.getArticulos("", this.props.articulos_pagina, this.props.articulos_orden, this.props.pedido.domicilio_envio, "N", "", "", "", "", "", "", "");
    }
  };

  onChange = (e) => {
    this.props.cambiaArticulosOrden(e.target.value);

    if (this.props.origen === "articulos") {
      if (this.props.filtros_aplicados.length > 0) {
        this.props.getArticulosByFamily(
          this.props.filtros_aplicados,
          this.props.articulos_pagina,
          e.target.value,
          this.props.articulos_search,
          this.props.pedido.domicilio_envio,
          this.props.busqueda_avanzada.avanzada,
          this.props.busqueda_avanzada.codigo_articulo,
          this.props.busqueda_avanzada.codigo_barras,
          this.props.busqueda_avanzada.descripcion,
          this.props.busqueda_avanzada.palabras_clave,
          this.props.busqueda_avanzada.equivalentes,
          this.props.busqueda_avanzada.referencia_cliente,
          this.props.busqueda_avanzada.codigo_sinonimo
        );
      } else {
        this.props.getArticulos(
          this.props.articulos_search,
          this.props.articulos_pagina,
          e.target.value,
          this.props.pedido.domicilio_envio,
          this.props.busqueda_avanzada.avanzada,
          this.props.busqueda_avanzada.codigo_articulo,
          this.props.busqueda_avanzada.codigo_barras,
          this.props.busqueda_avanzada.descripcion,
          this.props.busqueda_avanzada.palabras_clave,
          this.props.busqueda_avanzada.equivalentes,
          this.props.busqueda_avanzada.referencia_cliente,
          this.props.busqueda_avanzada.codigo_sinonimo
        );
      }
    }
  };

  changeArtPag = (e) => {
    e.preventDefault();

    this.props.cambiaArticulosPagina(e.target.value);

    if (this.props.filtros_aplicados.length > 0) {
      this.props.getArticulosByFamily(
        this.props.filtros_aplicados,
        e.target.value,
        this.props.articulos_orden,
        this.props.articulos_search,
        this.props.pedido.domicilio_envio,
        this.props.busqueda_avanzada.avanzada,
        this.props.busqueda_avanzada.codigo_articulo,
        this.props.busqueda_avanzada.codigo_barras,
        this.props.busqueda_avanzada.descripcion,
        this.props.busqueda_avanzada.palabras_clave,
        this.props.busqueda_avanzada.equivalentes,
        this.props.busqueda_avanzada.referencia_cliente,
        this.props.busqueda_avanzada.codigo_sinonimo
      );
    } else {
      this.props.getArticulos(
        this.props.articulos_search,
        e.target.value,
        this.props.articulos_orden,
        this.props.pedido.domicilio_envio,
        this.props.busqueda_avanzada.avanzada,
        this.props.busqueda_avanzada.codigo_articulo,
        this.props.busqueda_avanzada.codigo_barras,
        this.props.busqueda_avanzada.descripcion,
        this.props.busqueda_avanzada.palabras_clave,
        this.props.busqueda_avanzada.equivalentes,
        this.props.busqueda_avanzada.referencia_cliente,
        this.props.busqueda_avanzada.codigo_sinonimo
      );
    }
  };

  comprobarMenu() {
    let existe_menu = false;
    if (this.props.menus) {
      existe_menu = this.props.menus.some((menu) => {
        if (menu.hijos && menu.hijos.length > 0) {
          return menu.hijos.some((hijo) => {
            return !!(hijo.programa && hijo.programa !== "" && hijo.programa === "listasCompra");
          });
        } else {
          return !!(menu.programa && menu.programa !== "" && menu.programa === "listasCompra");
        }
      });
    }
    return existe_menu;
  }

  render() {
    parametros = cargarParametros(parametros_usados, this.props.portal.parametros_ecom, "LART");

    let anadir_lista = this.comprobarMenu();

    let articulos_render;
    switch (this.props.origen) {
      case "relacionados":
        articulos_render = this.props.relacionados;
        break;
      case "equivalentes":
        let equivalentes_articulo = this.props.equivalentes.equivalentes.filter((equivalente) => equivalente.codigo_articulo === this.props.id_articulo);
        if (equivalentes_articulo[0]) {
          articulos_render = equivalentes_articulo[0];
        } else {
          articulos_render = {
            codigo_articulo: "",
            articulos: [],
            articulos_total: 0,
            articulos_siguiente: "",
            articulos_anterior: "",
          };
        }
        break;
      case "pedido_habitual":
        articulos_render = this.props.pedido_habitual;
        break;
      case "ofertas":
        articulos_render = this.props.ofertas;
        break;
      case "novedades":
        articulos_render = this.props.novedades;
        break;
      case "articulos":
        articulos_render = this.props.articulos;
        break;
      default:
        articulos_render = this.props.articulos;
        break;
    }

    let cNContentIndent;
    {
      this.props.origen && (this.props.origen === "relacionados" || this.props.origen === "equivalentes")
        ? (cNContentIndent = "content-indent " + this.props.origen)
        : (cNContentIndent = "content-indent");
    }

    return (
      <Fragment>
        <div className={cNContentIndent}>
          <div className="tt-filters-options">
            {this.props.origen === "articulos" ? (
              <h1 className="tt-title">
                {i18n.t("articulos.tittart")} <span className="tt-title-total">({this.props.articulos.articulos_total})</span>
              </h1>
            ) : (
              ""
            )}
            {this.props.origen === "novedades" ? (
              <h1 className="tt-title">
                {i18n.t("articulos.titnov")} <span className="tt-title-total">({this.props.novedades.articulos_total})</span>
              </h1>
            ) : (
              ""
            )}
            {this.props.origen === "ofertas" ? (
              <h1 className="tt-title">
                {i18n.t("articulos.titofe")} <span className="tt-title-total">({this.props.ofertas.articulos_total})</span>
              </h1>
            ) : (
              ""
            )}
            {this.props.origen === "pedido_habitual" ? (
              <h1 className="tt-title">
                {i18n.t("articulos.titarthb")} <span className="tt-title-total">({this.props.pedido_habitual.articulos_total})</span>
              </h1>
            ) : (
              ""
            )}
            {this.props.origen === "relacionados" && articulos_render.articulos.length > 0 ? (
              <h1 className="tt-title">
                {i18n.t("articulos.titartrl")} <span className="tt-title-total">({this.props.relacionados.articulos_total})</span>
              </h1>
            ) : (
              ""
            )}
            {this.props.origen === "equivalentes" && articulos_render.articulos.length > 0 ? (
              <h1 className="tt-title">
                {i18n.t("articulos.titarteq")} <span className="tt-title-total">({articulos_render.articulos_total})</span>
              </h1>
            ) : (
              ""
            )}
            {this.props.origen === "articulos" ? (
              <div className="tt-btn-toggle">
                <a href="#">{i18n.t("general.filtros")}</a>
              </div>
            ) : (
              ""
            )}
            <div className="tt-sort">
              {this.props.articulos_search ? (
                <a href="#" onClick={this.limpiarBusqueda} className="">
                  <FontAwesomeIcon icon={faTimes} /> {this.props.articulos_search}
                </a>
              ) : (
                ""
              )}
              {this.props.busqueda_avanzada && this.props.busqueda_avanzada.avanzada && this.props.busqueda_avanzada.avanzada !== "" ? (
                <a href="#" onClick={this.limpiarBusqueda} className="">
                  <FontAwesomeIcon icon={faTimes} />{" "}
                  {this.props.busqueda_avanzada.codigo_articulo && this.props.busqueda_avanzada.codigo_articulo !== ""
                    ? i18n.t("articulos.busavca") + " " + this.props.busqueda_avanzada.codigo_articulo + " "
                    : ""}
                  {this.props.busqueda_avanzada.codigo_barras && this.props.busqueda_avanzada.codigo_barras !== ""
                    ? i18n.t("articulos.busavcb") + " " + this.props.busqueda_avanzada.codigo_barras + " "
                    : ""}
                  {this.props.busqueda_avanzada.descripcion && this.props.busqueda_avanzada.descripcion !== ""
                    ? i18n.t("articulos.busavds") + " " + this.props.busqueda_avanzada.descripcion + " "
                    : ""}
                  {this.props.busqueda_avanzada.palabras_clave && this.props.busqueda_avanzada.palabras_clave !== ""
                    ? i18n.t("articulos.busavpc") + " " + this.props.busqueda_avanzada.palabras_clave + " "
                    : ""}
                  {this.props.busqueda_avanzada.codigo_articulo && this.props.busqueda_avanzada.equivalentes !== ""
                    ? i18n.t("articulos.busaveq") + " " + this.props.busqueda_avanzada.equivalentes + " "
                    : ""}
                  {this.props.busqueda_avanzada.referencia_cliente && this.props.busqueda_avanzada.referencia_cliente !== ""
                    ? i18n.t("articulos.busavrc") + " " + this.props.busqueda_avanzada.referencia_cliente + " "
                    : ""}
                  {this.props.busqueda_avanzada.codigo_sinonimo && this.props.busqueda_avanzada.codigo_sinonimo !== ""
                    ? i18n.t("articulos.busavcs") + " " + this.props.busqueda_avanzada.codigo_sinonimo + " "
                    : ""}
                </a>
              ) : (
                ""
              )}
              {this.props.origen === "articulos" ? (
                <select name="orden" onChange={this.onChange}>
                  <option value="Default Sorting">{i18n.t("general.sortby")}</option>
                  <option value="codigo">{i18n.t("general.sortbyca")}</option>
                  <option value="descripcion">{i18n.t("general.sortbyds")}</option>
                </select>
              ) : (
                ""
              )}
              {this.props.origen === "articulos" ? (
                <select name="resultados" onChange={this.changeArtPag}>
                  <option value="">{i18n.t("general.mostrar")}</option>
                  <option value="12">12</option>
                  <option value="24">24</option>
                  <option value="36">36</option>
                </select>
              ) : (
                ""
              )}
            </div>
            {/* <div className="tt-quantity">
              <a href="#" className="tt-show" data-value="4">
                <FontAwesomeIcon icon={faTh} />
              </a>
              <a href="#" className="tt-show" data-value="6">
                <FontAwesomeIcon icon={faThLarge} />
              </a>
            </div> */}
          </div>

          <div className="tt-product-listing row" itemScope itemType="https://schema.org/ItemList">
            {articulos_render.isLoading || parametros === undefined || parametros === null ? (
              <Spinner showSpinner={articulos_render.isLoading}></Spinner>
            ) : (
              articulos_render.articulos.map((articulo, index) => (
                <div key={articulo.id} className="col-6 col-md-4 col-lg-3 col-xl-3 tt-col-item" itemProp="itemListElement" itemScope itemType="https://schema.org/Product">
                  <meta itemProp="sku" content={articulo.codigo_articulo} />
                  <meta
                    itemProp="url"
                    content={
                      "/articulo/" + articulo.id + "/" + (articulo.descrip_comercial ? articulo.descrip_comercial.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase() : "")
                    }
                  />
                  <div className="tt-product thumbprod-center">
                    <div className="tt-image-box">
                      {anadir_lista === true ? <BotonAnadirLista origen={this.props.origen} btn_subs={parametros.btn_subs} codigo_articulo={articulo.codigo_articulo} /> : ""}
                      <Link
                        to={
                          "/articulo/" +
                          articulo.codigo_articulo +
                          "/" +
                          (articulo.descrip_comercial ? articulo.descrip_comercial.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase() : "")
                        }
                      >
                        <span className="tt-img" itemScope itemType="https://schema.org/ImageObject">
                          <Imagen codigo_articulo={articulo.codigo_articulo} numero_imagen={1} principal="S" tipo_imagen="min" />
                        </span>
                      </Link>
                    </div>
                    <div className="tt-description">
                      <div className="tt-row">
                        <ul className="tt-add-info">
                          <li>
                            <Link
                              to={
                                "/articulo/" +
                                articulo.codigo_articulo +
                                "/" +
                                (articulo.descrip_comercial ? articulo.descrip_comercial.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase() : "")
                              }
                            >
                              {articulo.codigo_articulo}
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <h2 className="tt-title">
                        <Link
                          to={
                            "/articulo/" +
                            articulo.codigo_articulo +
                            "/" +
                            (articulo.descrip_comercial ? articulo.descrip_comercial.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase() : "")
                          }
                          itemProp="name"
                        >
                          {articulo.descrip_comercial}
                        </Link>
                      </h2>

                      <div className="tt-price">
                        <Precio codigo_articulo={articulo.codigo_articulo} mostrar_iva={parametros.mostrar_iva} tipo_precio={parametros.tipo_precio} />
                      </div>

                      <div className="tt-product-inside-hover">
                        <div className="tt-row-btn">{articulo.tiene_agrupaciones !== "S" ? <AnadirCarrito articulo={articulo} origen="lista" index={index} /> : ""}</div>
                        <div className="tt-row-btn">
                          {/* <a
                          href="/#"
                          className="tt-btn-quickview"
                          data-toggle="modal"
                          data-target="#ModalquickView"
                        >
                          <span className="ocultar">Cerrar</span>
                        </a> */}
                          {anadir_lista === true ? <BotonAnadirLista origen="articulos_mobile" codigo_articulo={articulo.codigo_articulo} /> : ""}

                          {/* <a href="/#" className="tt-btn-compare">
                          <span className="ocultar">Cerrar</span>
                        </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        {this.props.origen !== "relacionados" && this.props.origen !== "equivalentes" && articulos_render.isLoading !== true ? (
          <div className="text-center mt-5">
            {articulos_render.articulos_siguiente && articulos_render.articulos_siguiente !== "" ? (
              <button onClick={this.masArticulos} className="btn btn-border">
                {i18n.t("articulos.mosmasar")}
              </button>
            ) : (
              <div className="text-center mt-5">
                <button className="btn btn-border01">{i18n.t("articulos.nomasar")}</button>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  precio: state.precio.precio,
  portal: state.portal.portal,
  articulos: state.listadoArticulos.articulos,
  pedido_habitual: state.listadoArticulos.pedido_habitual,
  ofertas: state.listadoArticulos.ofertas,
  novedades: state.listadoArticulos.novedades,
  relacionados: state.listadoArticulos.relacionados,
  equivalentes: state.listadoArticulos.equivalentes,
  articulos_pagina: state.listadoArticulos.articulos_pagina,
  articulos_search: state.listadoArticulos.articulos_search,
  articulos_orden: state.listadoArticulos.articulos_orden,
  filtros_aplicados: state.articulos.filtros_aplicados,
  menus: state.portal.menus,
  pedido: state.pedidos.pedido,
  busqueda_avanzada: state.listadoArticulos.articulos_advanced_search,
});

export default connect(mapStateToProps, {
  getArticulos,
  getMasArticulos,
  getPedidoHabitual,
  getNovedades,
  getOfertas,
  getArticulosRelacionados,
  getArticulosEquivalentes,
  cambiaArticulosPagina,
  getArticulosByFamily,
  setBusqueda,
  cambiaArticulosOrden,
  setPresentacion,
  limpiarBusquedaAvanzada,
  getPrecios,
  getStocksArticulos,
})(ListadoArticulos);
