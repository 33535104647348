import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getArticulo, getStockArticulo } from "../../actions/articulo";
import AnadirCarrito from "./AnadirCarrito";
import Precio from "./Precio";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { cargarParametros } from "../../helpers/funciones";
import BotonAnadirLista from "./BotonAnadirLista";
import ArticulosRelacionados from "./ArticulosRelacionados";
import ArticulosEquivalentes from "./ArticulosEquivalentes";
import Galeria from "./Galeria";
import FamiliasMenu from "./FamiliasMenu";
import Migas from "./Migas";
import AgrupacionFiltros from "./parciales/AgrupacionFiltros";
import InfoAdicional from "./parciales/InfoAdicional";
import Subpresentaciones from "./parciales/Subpresentaciones";
import AnalyticsEdisa from "../common/AnalyticsEdisa";
import Presentaciones from "./parciales/Presentaciones";
import OfertasMultiples from "./parciales/OfertasMultiples";
import InfoClavesEstadisticas from "./parciales/InfoClavesEstadisticas";
import i18n from "../../lang/idiomas";

let parametros_usados = ["mostrar_iva", "tipo_precio", "stock_cantidad", "btn_subs"];
let parametros;
let id_articulo;

export class Articulo extends Component {
  static propTypes = {
    articulo: PropTypes.object.isRequired,
    menus: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
    getArticulo: PropTypes.func.isRequired,
    pedido: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.getArticulo(id_articulo, this.props.auth.hash != null ? this.props.auth.hash : "");
  }

  componentDidUpdate(prevProps) {
    window.wookie();

    let ancla = window.document.getElementById("cabecera-principal");

    if (ancla) {
      ancla.scrollIntoView({ behavior: "smooth" });
    }

    if (this.props.articulo.id && this.props.articulo.id !== Number.parseInt(id_articulo) && id_articulo !== this.props.articulo.codigo_articulo) {
      this.props.getArticulo(id_articulo, this.props.auth.hash != null ? this.props.auth.hash : "");

      this.props.getStockArticulo(this.props.articulo.codigo_articulo, this.props.articulo.presentacion_web, this.props.pedido.almacen, this.props.id_pedido_seleccionado, parametros.stock_cantidad);
    }

    let analytics = new AnalyticsEdisa();

    analytics.registraEvento({
      modulo: "Articulos",
      tipo: "ViewContent",
      valor: this.props.articulo.codigo_articulo,
      params: {
        codigo_articulo: this.props.articulo.codigo_articulo,
        descrip_comercial: this.props.articulo.descrip_comercial,
        divisa: this.props.precio[this.props.codigo_articulo] ? this.props.precio[this.props.articulo.codigo_articulo].data.divisa_precio : null,
        precio: this.props.precio[this.props.codigo_articulo]
          ? parseFloat(this.props.precio[this.props.articulo.codigo_articulo].data.precio_neto / this.props.precio[this.props.articulo.codigo_articulo].data.cantidad_precio)
          : null,
      },
    });
  }

  render() {
    parametros = cargarParametros(parametros_usados, this.props.portal.parametros_ecom, "ART");
    let anadir_lista = false;
    // Partimos la URL
    let url = window.location.pathname;
    let splittedURL = url.split("/");
    // Cogemos el codigo_articulo
    id_articulo = splittedURL[2];

    if (this.props.menus) {
      anadir_lista = this.props.menus.some((menu) => {
        if (menu.hijos && menu.hijos.length > 0) {
          return menu.hijos.some((hijo) => {
            return !!(hijo.programa && hijo.programa !== "" && hijo.programa === "listasCompra");
          });
        } else {
          return !!(menu.programa && menu.programa !== "" && menu.programa === "listasCompra");
        }
      });
    }
    if (this.props.articulo.codigo_articulo && this.props.articulo.info_stock === undefined) {
      this.props.getStockArticulo(
        this.props.articulo.codigo_articulo,
        this.props.articulo.presentacion_web,
        this.props.pedido.almacen,
        "S",
        this.props.id_pedido_seleccionado,
        null,
        parametros.stock_cantidad
      );
    }

    return (
      <Fragment>
        <FamiliasMenu />
        <div className="container">
          <Migas id_articulo={id_articulo} />
          <div id="tt-pageContent" className="articulo" itemScope itemType="https://schema.org/Product">
            <meta itemProp="url" content={"/articulo/" + id_articulo + "/" + this.props.articulo.descrip_comercial} />
            <meta itemProp="sku" content={this.props.articulo.codigo_articulo} />
            <div className="container-indent">
              {/* <div className="tt-mobile-product-layout visible-xs">
                 <div className="tt-mobile-product-slider arrow-location-center slick-animated-show-js">
                  {this.props.galeria.map((imagen) => (
                    <div key={"div_img_" + imagen.id}>
                      <Imagen
                        codigo_articulo={imagen.articulo}
                        principal="N"
                        numero_imagen={imagen.numero_imagen}
                      />
                    </div>
                  ))}
                </div> 
              </div> */}
              <div className="container container-fluid-mobile">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">{this.props.articulo.codigo_articulo ? <Galeria codigo_articulo={this.props.articulo.codigo_articulo} origen="desktop" /> : ""}</div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="tt-product-single-info">
                      <div className="tt-add-info">
                        <ul>
                          <li itemProp="productID" content={this.props.articulo.codigo_articulo}>
                            <span>{i18n.t("general.codart")}</span> {this.props.articulo.codigo_articulo}
                          </li>
                        </ul>
                      </div>
                      <h1 className="tt-title" itemProp="name">
                        {this.props.articulo.descrip_comercial}
                      </h1>
                      {this.props.articulo.descrip_web_reducida && this.props.articulo.descrip_web_reducida !== "" ? (
                        <div className="tt-wrapper">
                          <span className="descrip_web_reducida">{this.props.articulo.descrip_web_reducida}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.props.articulo.codigo_articulo && anadir_lista === true ? (
                        <div className="tt-wrapper">
                          <ul className="tt-list-btn">
                            <li className="list-btn-art">
                              {this.props.articulo.codigo_articulo && anadir_lista === true ? (
                                <BotonAnadirLista origen="articulo" btn_subs={parametros.btn_subs} codigo_articulo={this.props.articulo.codigo_articulo} />
                              ) : (
                                ""
                              )}
                            </li>
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.props.articulo.tiene_agrupaciones === "S" ? (
                        <Fragment>{this.props.articulo.codigo_articulo ? <AgrupacionFiltros codigo_articulo={this.props.articulo.codigo_articulo} /> : ""}</Fragment>
                      ) : (
                        <Fragment>
                          <div className="tt-price">
                            <span className="new-price">
                              {this.props.articulo.codigo_articulo ? (
                                <Precio codigo_articulo={this.props.articulo.codigo_articulo} mostrar_iva={parametros.mostrar_iva} tipo_precio={parametros.tipo_precio} origen="ficha" />
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                          <div className="tt-wrapper">
                            {this.props.articulo.codigo_articulo ? (
                              <Fragment>
                                <Subpresentaciones codigo_articulo={this.props.articulo.codigo_articulo} />
                                <Presentaciones articulo={this.props.articulo.codigo_articulo} />
                                <AnadirCarrito articulo={this.props.articulo} mostrar_texto_btn="S" origen="ficha" />
                              </Fragment>
                            ) : (
                              ""
                            )}
                          </div>
                          {this.props.articulo.codigo_articulo ? <OfertasMultiples articulo={this.props.articulo.codigo_articulo} /> : ""}
                          <div className="tt-collapse-block">
                            <div className="tt-item active">
                              <div className="tt-collapse-title">{i18n.t("general.descrip").toUpperCase()}</div>
                              <div
                                className="tt-collapse-content"
                                style={{ display: "block" }}
                                dangerouslySetInnerHTML={
                                  this.props.articulo.descrip_web_ampliada
                                    ? {
                                        __html: this.props.articulo.descrip_web_ampliada.replaceAll("\n", "<br>"),
                                      }
                                    : { __html: "" }
                                }
                                itemProp="description"
                              ></div>
                            </div>
                            {/* <div className="tt-item">
                          <div className="tt-collapse-title">
                            ADDITIONAL INFORMATION
                          </div>
                          <div className="tt-collapse-content">
                            <table className="tt-table-03">
                              <tbody>
                                <tr>
                                  <td>Color:</td>
                                  <td>Blue, Purple, White</td>
                                </tr>
                                <tr>
                                  <td>Size:</td>
                                  <td>20, 24</td>
                                </tr>
                                <tr>
                                  <td>Material:</td>
                                  <td>100% Polyester</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div> */}
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.props.articulo.tiene_agrupaciones === "S" ? (
              <div className="container-indent">
                <div className="container container-fluid-mobile">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="tt-collapse-block">
                        <div className="tt-item active">
                          <div className="tt-collapse-title">{i18n.t("general.descrip").toUpperCase()}</div>
                          <div
                            className="tt-collapse-content"
                            style={{ display: "block" }}
                            dangerouslySetInnerHTML={
                              this.props.articulo.descrip_web_ampliada
                                ? {
                                    __html: this.props.articulo.descrip_web_ampliada.replaceAll("\n", "<br>"),
                                  }
                                : { __html: "" }
                            }
                          ></div>
                        </div>
                        {/* <div className="tt-item">
                          <div className="tt-collapse-title">
                            ADDITIONAL INFORMATION
                          </div>
                          <div className="tt-collapse-content">
                            <table className="tt-table-03">
                              <tbody>
                                <tr>
                                  <td>Color:</td>
                                  <td>Blue, Purple, White</td>
                                </tr>
                                <tr>
                                  <td>Size:</td>
                                  <td>20, 24</td>
                                </tr>
                                <tr>
                                  <td>Material:</td>
                                  <td>100% Polyester</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                {this.props.articulo.codigo_articulo ? <InfoClavesEstadisticas codigo_articulo={this.props.articulo.codigo_articulo} /> : ""}
              </div>
            ) : (
              ""
            )}

            {this.props.articulo.codigo_articulo ? <InfoAdicional codigo_articulo={this.props.articulo.codigo_articulo} /> : ""}

            {this.props.articulo.id ? <ArticulosRelacionados id_articulo={this.props.articulo.id} /> : ""}

            {this.props.articulo.id ? <ArticulosEquivalentes id_articulo={this.props.articulo.id} origen="ficha" /> : ""}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  portal: state.portal.portal,
  articulo: state.articulo.articulo,
  menus: state.portal.menus,
  auth: state.auth,
  pedido: state.pedidos.pedido,
  precio: state.precio.precio,
  cookies: state.portal.cookies,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
});

export default connect(mapStateToProps, {
  getArticulo,
  getStockArticulo,
})(Articulo);
