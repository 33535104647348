import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Carrito from "../ecommerce/Carrito";
import { getPedidoActivo } from "../../actions/pedidos";
import { getTiras } from "../../actions/tiras";
import { getOfertas } from "../../actions/ofertas";
import { getNovedades } from "../../actions/novedades";
import AnalyticsEdisa from "../common/AnalyticsEdisa";
import { ordenarMenu } from "../../helpers/funciones";
import LanguageSelector from "./LanguageSelector";
import { getMenus } from "../../actions/portal";
import { limpiarInformeSeleccionado } from "../../actions/informes";
import { ShoppingCart, SignOut, User, X , Files, Info} from "@phosphor-icons/react";
import { clearMessage } from "../../actions/messages";
import i18n from "../../lang/idiomas";
import store from "../../store";

export class Header extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    pedido: PropTypes.object.isRequired,
    portal: PropTypes.object.isRequired,
    menus: PropTypes.array.isRequired,
    getPedidoActivo: PropTypes.func.isRequired,
    getOfertas: PropTypes.func.isRequired,
    getNovedades: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    getMenus: PropTypes.func.isRequired,
    limpiarInformeSeleccionado: PropTypes.func.isRequired,
    clearMessage: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.language !== prevProps.language || this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
      this.props.getMenus();
    }
    if (typeof window.wookie !== undefined) {
      window.wookie();
    } else {
      console.log("No se encuentra librería wookie");
    }

    if (prevProps.menus !== this.props.menus || prevProps.portal !== this.props.portal) {
      //si los menus cambian
      let findPos = (elem) => elem.programa === "articulos";
      if (this.props.menus && this.props.portal.descripcion) {
        if (this.props.menus.some((menu) => menu.programa === "novedadesOfertas")) {
          if (this.props.ofertas.articulos === undefined) {
            this.props.getOfertas();
          }
          if (this.props.novedades.articulos === undefined) {
            this.props.getNovedades();
          }
        }
        let analytics = new AnalyticsEdisa();
        analytics.registraPagina("/");

        let pos = this.props.menus.findIndex(findPos);
        if (pos !== -1) {
          this.props.getPedidoActivo(this.props.auth.hash != null ? this.props.auth.hash : "", "N", this.props.id_pedido_seleccionado);
          this.props.getTiras();
        }
      }
    }
  }

  ocultarMenu = (e) => {
    document.getElementsByClassName("mobile-main-menu")[0].classList.remove("mmitemopen");
  };

  cargarIcono(menu) {
    if (menu.programa) {
      switch (menu.programa) {
        case "login":
          return <User size={22} weight="regular" />
        case "informes":
          return <Files size={22} weight="regular" />
        case "contacto":
          return <Info size={22} weight="regular" />
        case "cuenta":
          return <User size={20} weight="fill" />;
        case "logout":
          return <SignOut size={20} weight="fill" />;
        default:
          return "";
      }
    } else if (menu.nombre && (menu.nombre === "USUARIO" || menu.nombre === "CARRITO")) {
      if (menu.nombre === "USUARIO") {
        return <User size={22} weight="regular" />;
      } else {
        return <ShoppingCart size={22} weight="regular" />;
      }
    }
  }

  onClick = (e) => {
    e.preventDefault();

    let invitado = store.getState().auth.invitado;
    let autenticado = store.getState().auth.isAuthenticated;
      if (!invitado && autenticado) {
      this.props.clearMessage();
      this.props.limpiarInformeSeleccionado();
      window.location.replace("/informes");
    } else {
      window.location.replace("/");
    }
    
  };

  render() {
    const { isAuthenticated, user } = this.props.auth;
    const { lineas } = this.props.pedido;
    const { portal } = this.props;
    let ofertas = this.props.ofertas.articulos_total;
    let novedades = this.props.novedades.articulos_total;
    let menus = this.props.menus;
    const invitado = this.props.auth.invitado;

    if (menus && menus.length > 0) {
      menus.sort(ordenarMenu);
      if (this.props.auth.isAuthenticated && !invitado) {
        menus = menus.filter((menu) => {
          return menu.programa !== "login" && menu.programa !== "registro";
        });
      }
      if (this.props.auth.user && this.props.auth.user.puede_crear_usuarios !== "S") {
        menus = menus.filter((menu) => {
          return menu.programa !== "registroHijos";
        });
      }
    }

    const desktoLinks = (
      <div className="tt-obj-menu">
        <div className="container" style={{ maxWidth: '1920px' }}>
          <div className="row justify-content-md-start no-gutters" >
            <div className="tt-desctop-parent-menu tt-parent-box">
              <div className="tt-desctop-menu">
                <nav>
                  <ul>
                    {menus && menus.length > 0
                      ? menus.map((menu) => {
                          menu.hijos.sort(ordenarMenu);
                          return menu.visible === "S" && menu.objeto_programa !== "CARRITO" && menu.objeto_programa !== "USUARIO" ? (
                            <li key={menu.id} className="dropdown  tt-megamenu-col-01">
                              {menu.programa && menu.programa.substring(0, 4) === "http" ? (
                                <a href={menu.programa}>{menu.icono ? this.cargarIcono(menu): ""}{menu.nombre}</a>
                              ) : menu.programa === null && menu.hijos && menu.hijos.length > 0 ? (
                                <Link to={"/" + menu.hijos[0].programa + (menu.hijos[0].camino ? "/" + menu.hijos[0].camino : "")} className="menuitem">{menu.icono ? this.cargarIcono(menu): ""}{menu.nombre}</Link>
                              ) : (
                                <Link to={"/" + menu.programa + (menu.camino ? "/" + menu.camino : "")} className="menuitem">{menu.icono ? this.cargarIcono(menu): ""}{menu.nombre}</Link>
                              )}
                              {menu.programa === "novedadesOfertas" && ofertas && novedades && (ofertas !== 0 || novedades !== 0) ? (
                                <span className="tt-badge-news">{ofertas !== 0 || novedades !== 0 ? ofertas + novedades : ""}</span>
                              ) : (
                                ""
                              )}
                              {menu.hijos.length > 0 ? (
                                <Fragment>
                                  <div className="dropdown-menu">
                                    <div className="row tt-col-list">
                                      <div className="col">
                                        <ul className="tt-megamenu-submenu">
                                          {menu.hijos.map((hijo) => {
                                            return hijo.visible === "S" ? (
                                              <li key={hijo.id}>
                                                <Link to={"/" + hijo.programa + (hijo.camino ? "/" + hijo.camino : "")}> {hijo.icono ? this.cargarIcono(hijo): ""}{hijo.nombre}</Link>
                                              </li>
                                            ) : (
                                              ""
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </Fragment>
                              ) : (
                                ""
                              )}
                            </li>
                          ) : (
                            ""
                          );
                        })
                      : ""}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const desktopAuthObjects = (
      <div className="tt-obj-options obj-move-right" style={{ transform: "translateY(-4px)" }}>
        {menus !== undefined && menus.length > 0
          ? menus.map((menu) => {
              return menu.visible === "S" && menu.objeto_programa === "CARRITO" ? (
                <div key="boton_carrito" className="tt-desctop-parent-cart tt-parent-box">
                  <div
                    className="tt-cart tt-dropdown-obj"
                    //data-tooltip="Carrito"
                    data-tposition="bottom"
                  >
                    <button className="tt-dropdown-toggle">
                      {menu.icono ? this.cargarIcono(menu) : ""}
                      <span className="tt-badge-cart">{lineas ? lineas.length : "0"}</span>
                    </button>
                    <Carrito />
                  </div>
                </div>
              ) : (
                ""
              );
            })
          : ""}
        {menus && menus.length > 0
          ? menus.map((menu) => {
              if (menu.visible === "S" && menu.objeto_programa === "USUARIO") {
                menu.hijos.sort(ordenarMenu);
              }
              return menu.visible === "S" && menu.objeto_programa === "USUARIO" && !invitado ? (
                <div key="boton_usuario" className="tt-desctop-parent-account tt-parent-box">
                  <div className="tt-account tt-dropdown-obj">
                    <button className="tt-dropdown-toggle" data-tooltip="Mi cuenta" data-tposition="bottom">
                      {menu.icono !== null ? <i className={menu.icono}></i> : ""} <small style={{ paddingBottom: "5px" }}>{isAuthenticated ? user.usuario_web : ""}</small>
                    </button>
                    <div className="tt-dropdown-menu sub-mi-cuenta">
                      <div className="tt-mobile-add">
                        <button className="tt-close">{i18n.t("general.cerrar")}</button>
                      </div>
                      <div className="tt-dropdown-inner">
                        <ul>
                          {menu.hijos.map((hijo) => {
                            return hijo.visible === "S" ? (
                              <li key={hijo.id}>
                                <Link to={"/" + hijo.programa}>
                                  {this.cargarIcono(hijo)}
                                  {hijo.nombre}
                                </Link>
                              </li>
                            ) : (
                              ""
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              );
            })
          : ""}
      </div>
    );

    const mobileMenu = (
      <ul className="menu-mobile">
        {menus && menus.length > 0
          ? menus.map((menu) => {
              menu.hijos.sort(ordenarMenu);
              return menu.visible === "S" && menu.objeto_programa !== "CARRITO" && menu.objeto_programa !== "USUARIO" ? (
                <li key={menu.id}>
                  {menu.programa && menu.programa.substring(0, 4) === "http" ? (
                    <a href={menu.programa}>{menu.nombre}</a>
                  ) : menu.programa === null && menu.hijos && menu.hijos.length > 0 ? (
                    <Link to={"/" + menu.hijos[0].programa + (menu.hijos[0].camino ? "/" + menu.hijos[0].camino : "")}>{menu.nombre}</Link>
                  ) : (
                    <Link onClick={this.ocultarMenu} to={"/" + menu.programa + (menu.camino ? "/" + menu.camino : "")}>
                      {menu.nombre}
                    </Link>
                  )}
                  {menu.hijos.length > 0 ? (
                    <ul>
                      {menu.hijos.map((hijo) => {
                        return hijo.visible === "S" ? (
                          <li key={hijo.id}>
                            <Link onClick={this.ocultarMenu} to={"/" + hijo.programa + (hijo.camino ? "/" + hijo.camino : "")}>
                              {hijo.nombre}
                            </Link>
                          </li>
                        ) : (
                          ""
                        );
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              ) : (
                ""
              );
            })
          : ""}
      </ul>
    );
    return (
      <header id="cabecera-principal">
        <nav className="panel-menu mobile-main-menu">
          <div className="mm-navbtn-names">
            <div className="mm-closebtn" style={{ marginTop: "5px", marginRight: "5px" }}>
              <X size={16} weight="bold" />
            </div>
          </div>
          {mobileMenu}
        </nav>
        <div className="tt-mobile-header">
          <div className="container-fluid">
            <div className="tt-header-row">
              <div className="tt-mobile-parent-menu">
                {/* <div className="tt-menu-toggle">
                  <i className="icon-03"></i>
                </div> */}
              </div>
              <div className="tt-mobile-parent-cart tt-parent-box"></div>
              <div className="tt-mobile-parent-account tt-parent-box"></div>
            </div>
          </div>
          <div className="container-fluid tt-top-line">
            <div className="row">
              <div className="tt-logo-container">
                <Link onClick={this.onClick} to={"/"} className="tt-logo tt-logo-alignment">
                  {portal.logo !== null ? (
                    <img src={"data:image/png;base64, " + portal.logo} alt="" className="loading" data-was-processed="true" />
                  ) : (
                    // <span className="tt-title">{portal.descripcion}</span>
                    ""
                  )}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="tt-desktop-header">
          <div className="tt-color-scheme-01">
            <div className="container-fluid">
              <div className="tt-header-holder">
                <div className="tt-obj-logo">
                  <Link onClick={this.onClick} to={"/"} className="tt-logo tt-logo-alignment">
                    {portal.logo !== null ? (
                      <img src={"data:image/png;base64, " + portal.logo} alt="" className="loading" data-was-processed="true" />
                    ) : (
                      // <span className="tt-title">{portal.descripcion}</span>
                      ""
                    )}
                  </Link>
                </div>
                {/* <LanguageSelector /> */}
                {desktoLinks}
                {desktopAuthObjects}
              </div>
            </div>
          </div>
        </div>
        <div className="tt-stuck-nav">
          <div className="container">
            <div className="tt-header-row ">
              <div className="tt-stuck-parent-menu"></div>
              <div className="tt-stuck-parent-search tt-parent-box"></div>
              <div className="tt-stuck-parent-cart tt-parent-box"></div>
              <div className="tt-stuck-parent-account tt-parent-box"></div>
              <div className="tt-stuck-parent-multi tt-parent-box"></div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  pedido: state.pedidos.pedido,
  portal: state.portal.portal,
  menus: state.portal.menus,
  cookies: state.portal.cookies,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
  ofertas: state.listadoArticulos.ofertas,
  novedades: state.listadoArticulos.novedades,
  language: state.languageSelector.language,
});

export default connect(mapStateToProps, {
  getPedidoActivo,
  getTiras,
  getOfertas,
  getNovedades,
  getMenus,
  limpiarInformeSeleccionado,
  clearMessage,
})(Header);
