import React, { Component, Fragment, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getMenus } from "../../actions/portal";
import { ordenarMenu, ProtectedRoute } from "../../helpers/funciones";
import { Helmet } from "react-helmet";
import Loader from "../layout/Loader";
import Conversaciones from "../expedientes/Conversaciones";
import Login from "../usuarios/Login";
import Articulos from "../ecommerce/Articulos";
import Articulo from "../ecommerce/Articulo";
import Cuenta from "../usuarios/Cuenta";
import Informes from "../ecommerce/Informes";
import Resumen from "../ecommerce/Resumen";
import Registro from "../usuarios/Registro";
import Logout from "../usuarios/Logout";
import Recuperar from "../usuarios/Recuperar";
import ListasCompra from "../ecommerce/ListasCompra";
import Inicio from "./Inicio";
import Estatico from "./Estatico";
import Contacto from "./Contacto";
import PedidosAnteriores from "../ecommerce/PedidosAnteriores";
import PedidosPendientes from "../ecommerce/PedidosPendientes";
import PedidoHabitual from "../ecommerce/PedidoHabitual";
import NovedadesOfertas from "../ecommerce/NovedadesOfertas";
import Widgets from "../widgets/Widgets";
import RegistroCompleto from "../usuarios/RegistroCompleto";
import Expedientes from "../expedientes/Expedientes";
import Pedido from "../ecommerce/Pedido";
import Home from "../ecommerce/Home";
import Wompi from "../ecommerce/Wompi";
import Cardnet from "../ecommerce/Cardnet";
import Modular from "./Modular";
import ConfigCookies from "./ConfigCookies";
import RegistroHijo from "../usuarios/RegistroHijo";
import EfacturaVentas from "../efactura/EfacturaVentas";
import EfacturaCompras from "../efactura/EfacturaCompras";
import i18n from "../../lang/idiomas";
import ExpedientesNew from "../expedientes/ExpedientesNew";
import Plantillas from "../plantillas/Plantillas";

let loged;

export class ControladorRutas extends Component {
  static propTypes = {
    menus: PropTypes.array.isRequired,
    portal: PropTypes.object.isRequired,
    getMenus: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.portal !== this.props.portal && this.props.portal.descripcion && !this.props.isLoadingMenus) {
      this.props.getMenus();
    }
  }

  iterar_menus = (menu) => {
    if (menu.programa) {
      if (menu.publico === "S") {
        switch (menu.programa) {
          case "articulos":
            return <Route key={menu.programa} exact path="/articulos/:estadistico?/:familia?/:descripcion?" element={<Articulos />} />;
          case "home":
            return <Route key={menu.programa} exact path="/home" element={<Home />} />;
          case "login":
            return <Route key={menu.programa} exact path="/login" element={<Login />} />;
          case "registro":
            return <Route key={menu.programa} exact path="/registro" element={<Registro />} />;
          case "contacto":
            return <Route key={menu.programa} exact path="/contacto" element={<Contacto />} />;
          case "registroCompleto":
            return <Route key={menu.programa} exact path="/registroCompleto" element={<RegistroCompleto />} />;
          case "novedadesOfertas":
            return <Route key={menu.programa} exact path="/novedadesOfertas" element={<NovedadesOfertas />} />;
          case "pedido":
            return <Route key={menu.programa} exact path="/pedido" element={<Pedido />} />;

          default:
            return <Route />;
        }
      } else {
        switch (menu.programa) {
          case "articulos":
            return (
              <Route
                key={menu.programa}
                exact
                path="/articulos/:estadistico?/:familia?/:descripcion?"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <Articulos />
                  </ProtectedRoute>
                }
              />
            );
          case "home":
            return (
              <Route
                key={menu.programa}
                exact
                path="/home"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <Home />
                  </ProtectedRoute>
                }
              />
            );
          case "contacto":
            return (
              <Route
                key={menu.programa}
                exact
                path="/contacto"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <Contacto />
                  </ProtectedRoute>
                }
              />
            );
          case "listasCompra":
            return (
              <Route
                key={menu.programa}
                exact
                path="/listasCompra"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <ListasCompra />
                  </ProtectedRoute>
                }
              />
            );
          case "pedidoHabitual":
            return (
              <Route
                key={menu.programa}
                exact
                path="/pedidoHabitual"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <PedidoHabitual />
                  </ProtectedRoute>
                }
              />
            );
          case "pedidosAnteriores":
            return (
              <Route
                key={menu.programa}
                exact
                path="/pedidosAnteriores"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <PedidosAnteriores />
                  </ProtectedRoute>
                }
              />
            );
          case "pedidosPendientes":
            return (
              <Route
                key={menu.programa}
                exact
                path="/pedidosPendientes"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <PedidosPendientes />
                  </ProtectedRoute>
                }
              />
            );
          case "novedadesOfertas":
            return (
              <Route
                key={menu.programa}
                exact
                path="/novedadesOfertas"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <NovedadesOfertas />
                  </ProtectedRoute>
                }
              />
            );
          case "informes":
            return (
              <Route
                key={menu.programa}
                exact
                path="/informes"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <Informes />
                  </ProtectedRoute>
                }
              />
            );
          case "widgets":
            return (
              <Route
                key={menu.programa}
                exact
                path="/widgets"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <Widgets />
                  </ProtectedRoute>
                }
              />
            );
          case "expedientes":
            return (
              <Route
                key={menu.programa}
                exact
                path="/expedientes"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <ExpedientesNew />
                  </ProtectedRoute>
                }
              />
            );
          case "conversaciones":
            return (
              <Route
                key={menu.programa}
                exact
                path="/conversaciones"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <Conversaciones />
                  </ProtectedRoute>
                }
              />
            );
          case "pedido":
            return (
              <Route
                key={menu.programa}
                exact
                path="/pedido"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <Pedido />
                  </ProtectedRoute>
                }
              />
            );
          case "cuenta":
            return (
              <Route
                key={menu.programa}
                exact
                path="/cuenta"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <Cuenta />
                  </ProtectedRoute>
                }
              />
            );
          case "logout":
            return (
              <Route
                key={menu.programa}
                exact
                path="/logout"
                element={
                  <ProtectedRoute isAllowed={true}>
                    <Logout />
                  </ProtectedRoute>
                }
              />
            );
          case "registroHijos":
            return (
              <Route
                key={menu.programa}
                exact
                path="/registrohijos"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <RegistroHijo />
                  </ProtectedRoute>
                }
              />
            );
          case "efacturaVentas":
            return (
              <Route
                key={menu.programa}
                exact
                path="/efacturaVentas"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <EfacturaVentas />
                  </ProtectedRoute>
                }
              />
            );
          case "efacturaCompras":
            return (
              <Route
                key={menu.programa}
                exact
                path="/efacturaCompras"
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <EfacturaCompras />
                  </ProtectedRoute>
                }
              />
            );
          default:
            return <Route key={menu.programa} />;
        }
      }
    } else {
      if (menu.hijos.length > 0) {
        return menu.hijos.map(this.iterar_menus);
      } else {
        return "";
      }
    }
  };

  render() {
    const menus = this.props.menus;
    const portal = this.props.portal;

    let Rutainicio;
    let Componenteinicio;
    let menu_articulos;
    loged = !!(this.props.authenticated && !this.props.invitado);

    if (menus && menus.length > 0) {
      menus.sort(ordenarMenu);
      Rutainicio = menus.find((menu) => menu.activo === "S" && menu.programa && menu.programa.substring(0, 4) !== "http");

      menu_articulos = menus.find((menu) => menu.programa === "articulos");

      Componenteinicio = Rutainicio.programa;
    } else {
      Componenteinicio = null;
      menu_articulos = null;
    }

    if (portal.modo_mantenimiento === "S") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>{i18n.t("common.cpagmant")}</h1>
          <p>{i18n.t("common.cporfinm")}</p>
        </div>
      );
    }
    return (
      <Fragment>
        {menus && menus.length > 0 && portal.descripcion ? (
          <Fragment>
            <Helmet  htmlAttributes={{ lang: 'es' }}>
              <meta charSet="utf-8" />
              <meta name="description" content={portal.parametros_seo.descripcion ? portal.parametros_seo.descripcion : portal.descripcion ? portal.descripcion : "Portal Ecommerce"} />
              <meta name="keywords" content={portal.parametros_seo.palabras_clave ? portal.parametros_seo.palabras_clave : ""} />
              <title>{portal.parametros_seo.titulo ? portal.parametros_seo.titulo : portal.descripcion ? portal.descripcion : "Portal Ecommerce"}</title>
              {portal.favicon ? <link rel="icon" type="image/png" href={"data:image/png;base64, " + portal.favicon} sizes="16x16" /> : ""}
            </Helmet>
            <Suspense fallback={<Loader />}>
              <Routes>
                {Componenteinicio ? <Route key="inicio" exact path={"/"} element={<Inicio componente={Componenteinicio} />} /> : <Route key="no-inicio" />}

                {menus.map(this.iterar_menus)}

                <Route key="estatico" exact path="/estatico/:componente" element={<Estatico />} />
                <Route key="modular" exact path="/modular/:modulo" element={<Modular />} />
                <Route key="recuperar" exact path="/recuperar" element={<Recuperar />} />
                <Route
                  key="resumen"
                  exact
                  path="/resumen"
                  element={
                    <ProtectedRoute isAllowed={loged}>
                      <Resumen />
                    </ProtectedRoute>
                  }
                />
                <Route
                  key="wompi"
                  exact
                  path="/wompi"
                  element={
                    <ProtectedRoute isAllowed={loged}>
                      <Wompi />
                    </ProtectedRoute>
                  }
                />
                <Route
                  key="plantillas"
                  exact
                  path="/plantillas"
                  element={
                    <ProtectedRoute isAllowed={loged}>
                      <Plantillas />
                    </ProtectedRoute>
                  }
                />
                <Route key="cardnet" exact path="/cardnet" element={<Cardnet />} />
                <Route key="config_cookies" exact path="/configurarCookies" element={<ConfigCookies />} />

                {menu_articulos && menu_articulos.publico === "S" ? (
                  <Route key="articulo" exact path="/articulo/:id_articulo/:descrip_comercial" element={<Articulo />} />
                ) : (
                  <Route
                    key="articulo"
                    exact
                    path="/articulo/:id_articulo/:descrip_comercial"
                    element={
                      <ProtectedRoute isAllowed={loged}>
                        <Articulo />
                      </ProtectedRoute>
                    }
                  />
                )}
              </Routes>
            </Suspense>
          </Fragment>
        ) : (
          <Fragment></Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  menus: state.portal.menus,
  portal: state.portal.portal,
  isLoadingMenus: state.portal.isLoadingMenus,
  authenticated: state.auth.isAuthenticated,
  invitado: state.auth.invitado,
  user: state.auth.user,
});

export default connect(mapStateToProps, { getMenus })(ControladorRutas);
