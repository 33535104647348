import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getArticulosEquivalentes } from "../../actions/articulosEquivalentes";
import ListadoArticulos from "./ListadoArticulos";
import TablaArticulos from "./TablaArticulos";

export class ArticulosEquivalentes extends Component {
  static propTypes = {
    getArticulosEquivalentes: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.id_articulo) {
      this.props.getArticulosEquivalentes(this.props.id_articulo, this.props.pedido.domicilio_envio);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id_articulo !== this.props.id_articulo) {
      this.props.getArticulosEquivalentes(this.props.id_articulo, this.props.pedido.domicilio_envio);
    }
  }
  render() {
    switch (this.props.origen) {
      case "tabla":
        return (
          <Fragment>
            <TablaArticulos origen="equivalentes" id_articulo={this.props.id_articulo} />
          </Fragment>
        );
        break;
      case "ficha":
        return (
          <Fragment>
            <ListadoArticulos origen="equivalentes" id_articulo={this.props.id_articulo} />
          </Fragment>
        );
        break;

      default:
        return "";
        break;
    }
  }
}

const mapStateToProps = (state) => ({ pedido: state.pedidos.pedido });

export default connect(mapStateToProps, { getArticulosEquivalentes })(ArticulosEquivalentes);
